import React, { useEffect, useState } from "react";
import {
  Container,
  TabBar,
  TabRegister,
  TabAccess,
  Content,
  Center,
  HeaderTitle
} from "./styles";
import FormAccessControl from "./Tab2";
import FormRegister from "./Tab1";
import Header from "../../Header/";
import Form from "components/Forms/RegistrationForm/index";

interface Props {
  id: number;
}

const TabForm: React.FC<Props> = (props) => {
  const { id } = props;
  const [formRegisterActive, setFormRegisterActive] = useState<boolean>(true);
  const [formAccessControlActive, setFormAccessControlActive] =
    useState<boolean>(false);

  console.log("id:", id);
  return (
    <Container>
      <HeaderTitle>
        <Center>Modal Com Abas</Center>
      </HeaderTitle>
      <TabBar>
        <TabRegister
          active={formRegisterActive}
          onClick={() => {
            setFormAccessControlActive(false);
            setFormRegisterActive(true);
          }}
        >
          Cadastro
        </TabRegister>
        <TabAccess
          active={formAccessControlActive}
          onClick={() => {
            setFormAccessControlActive(true);
            setFormRegisterActive(false);
          }}
        >
          Controle De Acesso
        </TabAccess>
      </TabBar>
      <Content>
        {formRegisterActive && (
          <Form
            width="100%"
            id={id}
            isAdminRegistration={true}
            hideTitle={true}
            hideLoginButton={true}
          />
        )}
        {formAccessControlActive && <FormAccessControl personId={id} />}
      </Content>
    </Container>
  );
};

export default TabForm;
