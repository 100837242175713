import yup from '../../../'
export const AccessControl= yup.object({
        perfil: yup
              .string(),
        checkbox1:   yup
                .boolean(),
        checkbox2: yup
                .boolean(),
        checkbox3:   yup
                .boolean(),
        checkbox4: yup
                .boolean(),
        checkbox5: yup
                .boolean(),
        checkbox6:   yup
                .boolean(),
        checkbox7: yup
                .boolean(),
        checkbox8:   yup
                .boolean(),
        checkbox9: yup
                .boolean(),
        checkbox10: yup
                .boolean(),
}).required();
export default AccessControl;