import styled from "styled-components";
interface IPropsContent {
  noPadding: boolean;
}

export const Container = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 50px 0;
  /* background-color:rgba(0,0,0, 0.67); */

  ::-webkit-scrollbar {
    width: 20px;
    z-index: 999999;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #eee;
    -webkit-border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 3px;
  }

  @media only screen and (max-width: 728px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
    padding: 0;
  }
`;
export const ContainerBg = styled.div`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.67);
  left: 0;
  top: 0;
  z-index: 0;
`;
export const ContainerBg2 = styled.div`
  display: flex;
  position: fixed;
  width: calc(100vw - 20px);
  height: 100vh;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: -1;
`;
export const Content = styled.div<IPropsContent>`
  width: auto;
  height: auto;
  display: inline-table;
  z-index: 9999999;
  overflow: hidden;
  z-index: 1;
  ${(props) => !props.noPadding && `padding:50px 80px;`}
  @media only screen and (max-width: 728px) {
    padding: 10px;
  }
`;
