import React from "react";
import { Container, ContainerBg, Menu } from "./styles";
import * as icon from "@fortawesome/free-solid-svg-icons";
import Row from "./Row/";
import { isAdminLogged, isMobile } from "../../../util";
import RowProfile from "./RowProfile";
import { useSelector } from "react-redux";
import { RootState } from "store/ducks/state";
import { API_TYPE } from "../../../constants/system";
import {ENVIRONMENT_SYSTEM} from '../../../util/'
type ENVIRONMENTS = "PROD" | "DEV" | "HML";


export default function ApplicationLeftNav(props) {
  const { isNavBarOpen, setIsNavBarOpen } = props;
  const auth = useSelector((state: RootState) => state.auth);
  const [menuActive, setMenuActive] = React.useState(0);

  const HIDEN_MENU_IN = (ENVIRONMENTS: ENVIRONMENTS[], MENU) => {
    ENVIRONMENTS.map((ENVIRONMENT) => {
      if (ENVIRONMENT_SYSTEM().ENVIRONMENT === ENVIRONMENT) MENU = null;
    });

    return MENU;
  };


  const menuList = [
    {
      text: "Home",
      href: `/home`,
      icon: icon.faHome,
      isOpen: isNavBarOpen,
    },

    {
      text: "Voluntários",
      href: "/voluntarios",
      icon: icon.faUsers,
      isOpen: isNavBarOpen,
    },

    {
      text: "Apontamentos",
      href: "/apontamentos",
      icon: icon.faListCheck,
      isOpen: isNavBarOpen,
    },
    {
      text: "Pagamentos",
      href: "/pagamentos",
      icon: icon.faMoneyCheckDollar,
      isOpen: isNavBarOpen,
    },
    {
      text: "Arquivos",
      href: null,
      icon: icon.faFileArchive,
      isOpen: isNavBarOpen,
      subMenus: [
        {
          href: "/remessas",
          label: "Remessas",
        },
        {
          href: "/retornos-cpb",
          label: "Retornos CPB",
        },
        {
          href: "/retornos-MCIF-470",
          label: "Retornos MCIF 470",
        },
        {
          href: "/retornos-de-pagamentos",
          label: "Retornos Pagamento",
        },
      ],
    },{
      text: "Configurações",
      href: null,
      icon: icon.faSliders,
      isOpen: isNavBarOpen,
      subMenus: [
        {
          href: "/tabela-de-virgencia",
          label: "Tabela De Virgência",
        },
      ],
    },
    HIDEN_MENU_IN(["PROD", "HML"], {
      text: "Páginas",
      href: null,
      icon: icon.faPager,
      isOpen: isNavBarOpen,
      subMenus: [
        {
          href: "/logout",
          label: "Login",
        },
        {
          href: "/perfil-do-estudante/MTMxNQ==",
          label: "Perfil",
        },
        {
          href: "/tela-de-consulta",
          label: "Pesquisa",
        },

        {
          href: "/tela-de-consulta/MTQx",
          label: "PDF / Impressão",
        },
        {
          href: "/em-construcao",
          label: "Em Construção",
        },
      ],
    }),
    HIDEN_MENU_IN(["PROD", "HML"], {
      text: "UI Componentes",
      href: "/componentes",
      icon: icon.faGrip,
      isOpen: isNavBarOpen,
    }),
    {
      text: "Sair",
      href: "/logout",
      icon: icon.faSignOutAlt,
      isOpen: isNavBarOpen,
    },
  ];

  return (
    <Container visible={isMobile() ? isNavBarOpen : false}>
      {isMobile() && (
        <ContainerBg onClick={() => setIsNavBarOpen(!isNavBarOpen)} />
      )}
      <Menu>
        {isMobile() && <RowProfile />}
        {menuList.map((menu, key) => {
          return (
            menu && (
              <Row
                key={key}
                text={menu.text}
                href={
                  menu.subMenus && menu.subMenus.length > 0 ? "#" : menu.href
                }
                icon={menu.icon}
                isOpen={menu.isOpen}
                subMenus={menu.subMenus}
                isActive={menuActive === key}
                onClick={() => {
                  if (menuActive !== key) setMenuActive(key);
                  else setMenuActive(0);
                }}
              />
            )
          );
        })}
      </Menu>
    </Container>
  );
}
