import React, { useEffect, useState } from "react";
import {
  Container,
  Center,
  Form,
  RecuperarSenha,
  Column,
  Row,
  LoginHeader,
  Error
} from "./styles";

import {
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@mui/material";

import Header from "../../Header";
import {
  Visibility,
  VisibilityOff,
  Google,
  Facebook
} from "@mui/icons-material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaAuth } from "../../../yup/Schemas/Auth/";
import Button from "../../Button";

import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "store/ducks/state";
import BoxFlatAlert from "components/Alerts/BoxFlatAlert";

import Snackbar from "@mui/material/Snackbar";
import { Loading } from "components";
import {
  recoverPassword,
  resendActivationLink,
  redefinePassword
} from "store/ducks/user";
import Send from "@mui/icons-material/Send";

interface Props {
  header: string;
  onSubmit: any;
  isAdminArea: boolean;
  setFormType: any;
  loadingLogin?: boolean;
  errorMessage?: string;
  setErrorMessage?: any;
  successMessage?: string;
  setSuccessMessage?: any;
}

interface IAuth {
  username: string;
  password: string;
}

const LoginForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    header = "",
    onSubmit,
    isAdminArea = false,
    setFormType,
    loadingLogin,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage
  } = props;
  const url = window.location.pathname;
  const arrayUrlz = url.split("/");
  const tokenUrlRecoverPassword = arrayUrlz[arrayUrlz.length - 1];
  const isChangePassword = url.indexOf("redefinir-senha") > -1;
  const {
    register,
    getValues,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<IAuth>({ resolver: yupResolver(SchemaAuth) });

  const auth = useSelector((state: RootState) => state.auth);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isKeepConnected, setIsKeepConnected] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [eMsg, setEMsg] = React.useState(
    window.location.pathname.indexOf("error-essage") > -1
  );
  const [sMsg, setSMsg] = React.useState(
    window.location.pathname.indexOf("success-essage") > -1
  );
  const [errorConfirmPassword, setErrorConfirmPassword] = React.useState(false);
  const [arrayUrl, setArrayUrl] = React.useState(
    !eMsg && !sMsg ? null : window.location.pathname.split("/")
  );
  const [msg, setMsg] = React.useState(
    !eMsg && !sMsg ? null : arrayUrl[arrayUrl.length - 1]
  );
  const [mode, setMode] = React.useState(
    isChangePassword ? "nova-senha" : "login"
  );
  // const eMsg = window.location.pathname.indexOf('error-essage') > -1
  // const sMsg = window.location.pathname.indexOf('success-essage') > -1
  // const arrayUrl = !eMsg && !sMsg ? null : window.location.pathname.split('/')
  // const msg = !eMsg && !sMsg ? null : arrayUrl[arrayUrl.length - 1]
  // setMsg(!eMsg && !sMsg ? null : arrayUrl[arrayUrl.length - 1])

  useEffect(() => {
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  }, [snackbarOpen]);

  const handleSubmit2 = (data) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setLoading2(true);
    if (mode === "recuperar-senha") handleRecoverPassword(data);
    else if (mode === "reenviar-link-de-ativacao")
      handleResendActivationLink(data);
    else if (mode === "nova-senha") handleNewPassword(data);
  };

  const handleRecoverPassword = (data) => {
    dispatch(recoverPassword(data.username))
      .then((data) => {
        setSuccessMessage(data.msg);
        setLoading2(false);
      })
      .catch((error) => {
        setErrorMessage(error.msg);
        setLoading2(false);
      });
  };

  const handleNewPassword = (password) => {
    if (errorConfirmPassword) {
      setErrorMessage("As senhas devem corresponder");
      setLoading2(false);
      return;
    }
    dispatch(redefinePassword(tokenUrlRecoverPassword, password))
      .then((data) => {
        setSuccessMessage(data.msg);
        setLoading2(false);
      })
      .catch((error) => {
        setErrorMessage(error.msg);
        setLoading2(false);
      });
  };
  const handleResendActivationLink = (data) => {
    dispatch(resendActivationLink(data.username))
      .then((data) => {
        setSuccessMessage(data.msg);
        setLoading2(false);
      })
      .catch((error) => {
        setErrorMessage(error.msg);
        setLoading2(false);
      });
  };

  const handleHeader = (header) => {
    if (mode === "recuperar-senha") return "Recuperar Senha";
    else if (mode === "reenviar-link-de-ativacao")
      return "Reenviar Link de Ativação";
    else if (mode === "nova-senha") return "Nova Senha";
    else return header;
  };

  const renderButtons = () => {
    if (mode === "login")
      return (
        <Row style={{ marginTop: mode === "login" ? 5 : 20 }}>
          <Button
            noFontBold
            type="submit"
            typecolor={!isAdminArea ? "primary" : "primary_organizer"}
            loading={loadingLogin ? loadingLogin : false}
          >
            Entrar
          </Button>
        </Row>
      );
    else
      return (
        <Row style={{ marginTop: 20 }}>
          <Row style={{ gap: "10px" }}>
            {!successMessage && (
              <Column>
                <Button
                  noFontBold
                  loading={loading2}
                  disabled={loading2}
                  type="submit"
                  typecolor={!isAdminArea ? "primary" : "primary_organizer"}
                  onClick={() => {
                    if (mode === "recuperar-senha") {
                      handleSubmit2({ username: getValues("username") });
                    } else if (mode === "nova-senha") {
                      handleSubmit2(getValues("password"));
                    }
                  }}
                >
                  Enviar
                </Button>
              </Column>
            )}
            <Column>
              <Button
                noFontBold
                onClick={() => {
                  setMode("login");
                  setErrorMessage(null);
                  setSuccessMessage(null);
                }}
                typecolor={!isAdminArea ? "primary" : "primary_organizer"}
                variant="outlined"
              >
                Login
              </Button>
            </Column>
          </Row>
        </Row>
      );
  };

  return (
    <Container>
      <Row>
        <LoginHeader>
          <Header>{handleHeader(header)}</Header>
        </LoginHeader>
      </Row>
      {(successMessage || msg) && (
        <Row style={{ marginTop: -20 }}>
          <Column>
            <BoxFlatAlert
              successMessage={successMessage || (sMsg && decodeURI(msg))}
              errorMessage={eMsg && decodeURI(msg)}
              small={true}
            />
          </Column>
        </Row>
      )}
      <Form
        onSubmit={handleSubmit(mode === "login" ? onSubmit : handleSubmit2)}
      >
        {(mode === "login" ||
          mode === "recuperar-senha" ||
          mode === "reenviar-link-de-ativacao") && (
          <Row>
            <Column>
              <TextField
                onChange={(event) => {
                  console.log("target:", event);
                  // setEmail(event.target.value);
                }}
                inputProps={{ style: { textTransform: "lowercase" } }}
                type="text"
                error={errors.username && true}
                {...register("username", {
                  setValueAs: (v) => v.toLowerCase()
                })}
                fullWidth
                color={!isAdminArea ? "success" : "primary"}
                label={mode === "login" ? "CPF ou E-mail" : "Informe seu e-mail"}
              />
              {errors.username && <Error>{errors.username?.message}</Error>}
            </Column>
          </Row>
        )}
        <Row
          style={{
            display:
              mode === "login" || mode === "nova-senha" ? "block" : "none"
          }}
        >
          <Column>
            <FormControl
              color={!isAdminArea ? "success" : "primary"}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Senha
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={passwordVisible ? "text" : "password"}
                {...register("password")}
                error={errors.password && true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Altere a visibilidade do password"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      onMouseDown={() => {}}
                      edge="end"
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {errors.password && <Error>{errors.password?.message}</Error>}
          </Column>
        </Row>
        {mode === "nova-senha" && (
          <Row>
            <Column>
              <FormControl
                color={!isAdminArea ? "success" : "primary"}
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirmar Senha
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={passwordVisible ? "text" : "password"}
                  onChange={(event) => {
                    setErrorConfirmPassword(true);
                    if (event.target.value === getValues("password"))
                      setErrorConfirmPassword(false);
                    setErrorMessage(null);
                  }}
                  error={errorConfirmPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Altere a visibilidade do password"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        onMouseDown={() => {}}
                        edge="end"
                      >
                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {errorConfirmPassword && (
                <Error>As senhas devem corresponder.</Error>
              )}
            </Column>
          </Row>
        )}
        {errorMessage && (
          <>
            <Row>
              <Column>
                <BoxFlatAlert errorMessage={errorMessage} small={true} />
              </Column>
            </Row>
            {auth.isInactiveUser && (
              <Row>
                <Button
                  onClick={() => {
                    setMode("reenviar-link-de-ativacao");
                    setErrorMessage(null);
                  }}
                  variant="outlined"
                  startIcon={<Send />}
                  color={!isAdminArea ? "success" : "primary"}
                  style={{ margin: "0px auto 30px auto" }}
                >
                  Reenviar Link
                </Button>
              </Row>
            )}
          </>
        )}
        {mode === "login" && (
          <Row>
            <div>
              <Checkbox
                checked={isKeepConnected}
                onChange={() => setIsKeepConnected(!isKeepConnected)}
                className="checkbox"
              />
              Lembrar-me
            </div>
            {/* <RecuperarSenha
              onClick={() => {
                setMode("recuperar-senha");
                setErrorMessage(null);
                setSuccessMessage(null);
                setMsg(null);
                return false;
              }}
            >
              Esqueceu sua senha?
            </RecuperarSenha> */}
          </Row>
        )}
        {renderButtons()}
        {/* {mode === "login" && (
          <Row hideOnMobile={true}>
            <Center>ou</Center>
          </Row>
        )} */}
        {/* {mode === "login" && (
          <Row style={{ gap: 10 }} hideOnMobile={true}>
            <Column>
              <Button
                noFontBold
                onClick={() => {
                  setSnackbarOpen(true);
                }}
                name="facebook"
                typecolor={!isAdminArea ? "primary" : "primary_organizer"}
                variant="outlined"
                startIcon={
                  <Facebook color={!isAdminArea ? "success" : "primary"} />
                }
              >
                Facebook
              </Button>
            </Column>
            <Column>
              <Button
                noFontBold
                onClick={() => {
                  setSnackbarOpen(true);
                }}
                name="google"
                typecolor={!isAdminArea ? "primary" : "primary_organizer"}
                variant="outlined"
                startIcon={
                  <Google color={!isAdminArea ? "success" : "primary"} />
                }
              >
                Google
              </Button>
            </Column>
          </Row>
        )} */}
        {/* {mode === "login" && (
          <Row>
            <Center>Ainda não possui conta?</Center>
          </Row>
        )}
        {mode === "login" && (
          <Row>
            <Button
              noFontBold
              onClick={() => {
                setFormType("register");
              }}
              typecolor={!isAdminArea ? "secondary" : "secondary_organizer"}
            >
              Cadastre-se
            </Button>
          </Row>
        )} */}
      </Form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message="Desculpe! Esta funcionalidade está indisponível no momento."
        key={"top" + "center"}
      />
    </Container>
  );
};

export default LoginForm;
