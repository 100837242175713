import styled from 'styled-components'

export const Error = styled.div`
    width: 100%;
    display: flex;
    margin-left:10px;
    margin-top:5px;
    color:#d32f2f;
    font-size:0.75rem;
    font-weight:400;
    letter-spacing:0.03333em;
    line-height:1.66;
`;
