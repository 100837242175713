import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { RootState } from "store/ducks/state";
import { persistConfig } from "store";
import {
  dateNow,
  handleModalityfilters,
  handleToken,
  isEqualNumber,
  referenceToCache,
  titleize,
} from "../../util";
import { Loading } from "components";
import { Reducer } from "redux";
import createReducer from "util/createReducer";
import { dateToBR, monthAcronym, cacheExpired } from "util/index";
import { CACHE_MINUTES, CACHE_PREFIX } from "constants/system";

export enum ParametersTypes {
  ChampionshipsStart = "@user/ChampionshipsStart",
  ChampionshipsSuccess = "@user/ChampionshipsSuccess",
  ChampionshipsFailure = "@user/ChampionshipsFailure",

  SearchSeasonStart = "@user/SearchSeasonStart",
  SearchSeasonSuccess = "@user/SearchSeasonSuccess",
  SearchSeasonFailure = "@user/SearchSeasonFailure",
}

export type Actions = {
  ChampionshipsStart: {
    type: ParametersTypes.ChampionshipsStart;
    payload: any;
  };
  ChampionshipsSuccess: {
    type: ParametersTypes.ChampionshipsSuccess;
    payload: any;
  };
  ChampionshipsFailure: {
    type: ParametersTypes.ChampionshipsFailure;
    payload: any;
  };

  SearchSeasonStart: {
    type: ParametersTypes.SearchSeasonStart;
    payload: any;
  };
  SearchSeasonSuccess: {
    type: ParametersTypes.SearchSeasonSuccess;
    payload: any;
  };
  SearchSeasonFailure: {
    type: ParametersTypes.SearchSeasonFailure;
    payload: any;
  };
};

export interface LoadingSection {
  "loading.championsShips": boolean;
  "loading.searchSeason": boolean;
}

export interface ListChampionsShips {
  id_campeonato: number;
  id_temporada: number;
  descricao: string;
  data_inicio: Date;
  data_fim: Date;
}

export interface ParametersState {
  listSeason: any[];
  listChampionshipToSelect: ListChampionsShips[];
  listChampionshipToDashboard: ListChampionsShips[];
  error: any;
  errorSeason: any;
  loading: LoadingSection;
}

export const InitialState: ParametersState = {
  listSeason: [],
  listChampionshipToSelect: [],
  listChampionshipToDashboard: [],
  error: undefined,
  errorSeason: undefined,
  loading: {
    "loading.championsShips": false,
    "loading.searchSeason": false,
  },
};

export const parametersReducer: Reducer<ParametersState> = createReducer(
  InitialState,
  {
    [ParametersTypes.ChampionshipsStart](state: ParametersState) {
      state.loading["loading.championsShips"] = true;
      return state;
    },
    [ParametersTypes.ChampionshipsSuccess](
      state: ParametersState,
      action: Actions["ChampionshipsSuccess"]
    ) {
      state.loading["loading.championsShips"] = false;
      // console.log('parametersReducer.action.payload:', action.payload)
      state.listChampionshipToSelect = action.payload
        .listChampionshipToSelect as ListChampionsShips[];
      state.listChampionshipToDashboard = action.payload
        .listChampionshipToDashboard as ListChampionsShips[];
      // console.log('parametersReducer.state:', state)
      return state;
    },
    [ParametersTypes.ChampionshipsFailure](
      state: ParametersState,
      action: Actions["ChampionshipsFailure"]
    ) {
      state.loading["loading.championsShips"] = false;
      state.error = action.payload;
      return state;
    },

    [ParametersTypes.SearchSeasonStart](state: ParametersState) {
      state.loading["loading.searchSeason"] = true;
      return state;
    },
    [ParametersTypes.SearchSeasonSuccess](
      state: ParametersState,
      action: Actions["SearchSeasonSuccess"]
    ) {
      // console.log(
      //   "ParametersTypes.SearchSeasonSuccess.action.payload:",
      //   action.payload
      // );
      state.loading["loading.searchSeason"] = false;
      state.listSeason = action.payload;
      return state;
    },
    [ParametersTypes.SearchSeasonFailure](
      state: ParametersState,
      action: Actions["SearchSeasonFailure"]
    ) {
      state.loading["loading.searchSeason"] = false;
      state.errorSeason = action.payload;
      return state;
    },
  }
);

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

function testChampionShips(key, ref) {
  const mockChampionSnip = [
    {
      id_campeonato: 1,
      id_temporada: 1,
      descricao: "Escolar",
      data_inicio: "2022-01-20T03:00:00.000000Z",
      data_fim: "2022-01-30T03:00:00.000000Z",
    },
    {
      id_campeonato: 1,
      id_temporada: 1,
      descricao: "Inter-territorial",
      data_inicio: "2022-01-30T03:00:00.000000Z",
      data_fim: "2022-02-10T03:00:00.000000Z",
    },
    {
      id_campeonato: 1,
      id_temporada: 1,
      descricao: "Estadual",
      data_inicio: "2022-02-20T03:00:00.000000Z",
      data_fim: "2022-03-30T03:00:00.000000Z",
    },
  ];
  localStorage.setItem(
    key,
    btoa(
      JSON.stringify({
        ref: referenceToCache(),
        data: {
          listChampionshipToSelect:
            handleChampionShipList(mockChampionSnip).listChampionshipToSelect,
          listChampionshipToDashboard:
            handleChampionShipList(mockChampionSnip)
              .listChampionshipToDashboard,
        },
      })
    )
  );
}

export function getChamptionshipData(championshipOptions, idChampionship) {
  return championshipOptions && championshipOptions.length > 0
    ? championshipOptions.filter(
        (o) => Number(o.id_campeonato) === Number(idChampionship)
      )[0]
    : null;
}

export function getRangeOfTheChampionship(championshipData) {
  const range =
    championshipData &&
    championshipData.alcance &&
    championshipData.alcance.alcance
      ? championshipData.alcance
      : null;

  return range && range.alcance ? range.alcance : null;
}

function handleChampionShipList(data) {
  const listChampionshipToSelect = data.map((item) => {
    const dateIni = new Date(item.data_inicio);
    const dateEnd = new Date(item.data_fim);
    return {
      ...item,
      data_inicio: dateIni,
      data_fim: dateEnd,
      parentValue: item.id_temporada,
      value: item.id_campeonato,
      label:
        "\n" +
        titleize(item.descricao) +
        ` - De ${dateIni.getDate()}/${dateIni.getMonth() <= 9 ? "0" : ""}${
          dateIni.getMonth() + 1
        }/${dateIni.getFullYear()} Até ${dateEnd.getDate()}/${
          dateIni.getMonth() <= 9 ? "0" : ""
        }${dateEnd.getMonth() + 1}/${dateEnd.getFullYear()}` +
        "\n",
    };
  });
  const listChampionshipToDashboard = data.map((item) => {
    const dateIni = new Date(item.data_inicio);
    const dateEnd = new Date(item.data_fim);
    return {
      ...item,
      dateInitial: dateToBR(dateIni),
      dateFinal: dateToBR(dateEnd),
      nameTournament: titleize(item.descricao),
      day: dateIni.getDate(),
      month: monthAcronym(dateIni.getMonth()),
    };
  });

  return {
    listChampionshipToSelect,
    listChampionshipToDashboard,
  };
}

export function handleGetChampionship(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: ParametersTypes.ChampionshipsStart,
    } as Actions["ChampionshipsStart"]);
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-championship`;

      //testChampionShips(key, ref) //DESCOMENTE ESTA LINHA PARA REALIZAR O TESTE

      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS)) {
        dispatch({
          type: ParametersTypes.ChampionshipsSuccess,
          payload: localData.data,
        } as Actions["ChampionshipsSuccess"]);
        resolve(localData.data.listChampionshipToSelect);
      } else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/campeonatos`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const data = {
              listChampionshipToSelect: handleChampionShipList(response.data)
                .listChampionshipToSelect,
              listChampionshipToDashboard: handleChampionShipList(response.data)
                .listChampionshipToDashboard,
            };
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: data }))
            );
            dispatch({
              type: ParametersTypes.ChampionshipsSuccess,
              payload: data,
            } as Actions["ChampionshipsSuccess"]);
            resolve(data.listChampionshipToSelect);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function handleGetTypeModality():
  | any
  | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-typeModality`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/tipos`, { headers: { Authorization: `Bearer ${token}` } })
          .then((response: any) => {
            const list = response.data.map((item) => ({
              value: item.id_tipo,
              label: titleize(item.tipo),
            }));
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function getCurrentSeason(listOfSeasons: any[]) {
  let idSeason = null;
  listOfSeasons &&
    listOfSeasons.length > 0 &&
    listOfSeasons.map((item) => {
      const isOpen =
        new Date(item.data_inicio) <= new Date() &&
        new Date(item.data_fim) > new Date();
      if (isOpen && idSeason === null) idSeason = item.id_temporada;
    });

  return idSeason;
}

export const championshipRange = (championshipData) =>
  championshipData?.alcance;

export function getLastChampionship(
  idSeason: number,
  listOfChampionship: any[]
) {
  let idChampionship = null;
  listOfChampionship &&
    listOfChampionship
      .filter((o) => isEqualNumber(o.id_temporada, idSeason))
      .map((o) => {
        const finalDate = o.data_fim?.substring(0, 10);
        if (new Date(finalDate) <= new Date(dateNow()))
          idChampionship = o.id_campeonato;
      });

  return idChampionship;
}

export function getNextChampionship(
  idSeason: number,
  listOfChampionship: any[],
  containingClashes = false
) {
  // console.log(`getNextChampionship.containingClashes: ${containingClashes}`);
  let idChampionship = null;
  listOfChampionship &&
    listOfChampionship
      .filter((o) => isEqualNumber(o.id_temporada, idSeason))
      .map((o) => {
        const initialDate = o.data_inicio?.substring(0, 10);
        if (
          new Date(initialDate) >= new Date(dateNow()) &&
          (containingClashes
            ? o.qtd_confrontos.proximos.individual > 0 ||
              o.qtd_confrontos.proximos.coletivo > 0
            : true)
        )
          idChampionship = o.id_campeonato;
      });
  // console.log("idChampionship.idChampionship:", idChampionship);
  // console.log("idChampionship.listOfChampionship:", listOfChampionship);
  return idChampionship;
}

export function getCurrentChampionship(
  idSeason: number,
  listOfChampionship: any[],
  acceptLast = false,
  acceptNext = false,
  containingClashes = false,
  preference = ""
) {
  try {
    let last = null;
    let idChampionship = null;
    let hasOpened = false;
    listOfChampionship &&
      listOfChampionship
        .filter((o) => isEqualNumber(o.id_temporada, idSeason))
        .map((o) => {
          const initialDate =
            o && o.data_inicio && o.data_inicio.length > 0
              ? o.data_inicio.substring(0, 10)
              : null;
          const finalDate =
            o && o.data_fim && o.data_fim.length > 0
              ? o.data_fim.substring(0, 10)
              : null;
          const isOpened =
            new Date(dateNow()) >= new Date(initialDate) &&
            new Date(dateNow()) < new Date(finalDate);

          if (
            isOpened &&
            (idChampionship === null || last?.alcance?.alcance === "ESCOLAR")
          ) {
            hasOpened = true;
            idChampionship = o.id_campeonato;
          }

          last = o;
        });

    const idLast = (idChampionship = getLastChampionship(
      idSeason,
      listOfChampionship
    ));

    const idNextWithClashes = (idChampionship = getNextChampionship(
      idSeason,
      listOfChampionship,
      true
    ));

    const idNextWithotClashes = (idChampionship = getNextChampionship(
      idSeason,
      listOfChampionship,
      false
    ));

    if ((idChampionship === null || !hasOpened) && acceptLast)
      idChampionship = idLast;
    else if (idChampionship === null && acceptNext) {
      if (containingClashes) {
        idNextWithClashes > 0
          ? (idChampionship = idNextWithClashes)
          : idNextWithotClashes > 0
          ? (idChampionship = idNextWithotClashes)
          : (idChampionship = idLast);
      } else {
        idNextWithotClashes > 0
          ? (idChampionship = idNextWithotClashes)
          : (idChampionship = idLast);
      }
    } else if (
      idChampionship === null &&
      last &&
      last.id_campeonato &&
      last.id_campeonato > 0
    )
      idChampionship = last.id_campeonato;

    preference === "idNextWithClashes" &&
      idNextWithClashes > 0 &&
      (idChampionship = idNextWithClashes);

    //   console.log(
    //     "getCurrentChampionship:",
    //     `
    //   preference: ${preference};
    //   acceptNext: ${acceptNext};
    //   acceptLast: ${acceptLast};
    //   containingClashes: ${containingClashes};
    //   idChampionship: ${idChampionship};
    //   idNextWithClashes: ${idNextWithClashes};
    //   idNextWithotClashes: ${idNextWithotClashes};
    //   idLast: ${idLast};
    //   last.id_campeonato: ${last?.id_campeonato};`
    //   );
    // console.log("listOfChampionship:", listOfChampionship);
    return idChampionship;
  } catch (err) {
    console.log("getCurrentChampionship.err:", err);
  }
}

export function handleGetSeason(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: ParametersTypes.SearchSeasonStart,
    } as Actions["SearchSeasonStart"]);
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-season`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS)) {
        const list = localData.data;
        dispatch({
          type: ParametersTypes.SearchSeasonSuccess,
          payload: list,
        } as Actions["SearchSeasonSuccess"]);
        resolve(list);
      } else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/temporadas`, { headers: { Authorization: `Bearer ${token}` } })
          .then((response: any) => {
            const list = response.data.map((item) => {
              const dateIni = new Date(item.data_inicio);
              const dateEnd = new Date(item.data_fim);
              return {
                ...item,
                value: item.id_temporada,
                label:
                  "\n" +
                  titleize(item.descricao) +
                  ` - De ${dateIni.getDate()}/${
                    dateIni.getMonth() <= 9 ? "0" : ""
                  }${
                    dateIni.getMonth() + 1
                  }/${dateIni.getFullYear()} Até ${dateEnd.getDate()}/${
                    dateIni.getMonth() <= 9 ? "0" : ""
                  }${dateEnd.getMonth() + 1}/${dateEnd.getFullYear()}` +
                  "\n",
              };
            });
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            dispatch({
              type: ParametersTypes.SearchSeasonSuccess,
              payload: list,
            } as Actions["SearchSeasonSuccess"]);
            resolve(list);
          })
          .catch((err: any) => {
            const errorMsg =
              err.response && err.response.data ? err.response.data : err;
            dispatch({
              type: ParametersTypes.SearchSeasonFailure,
              payload: errorMsg,
            } as Actions["SearchSeasonFailure"]);
            reject(errorMsg);
          });
      }
    });
  };
}

export function handleGetNTE(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .get(`/ntes`, {
          headers: {
            Authorization: `Bearer ${handleToken(
              `${getState().auth.data.token}`
            )}`,
          },
        })
        .then((response: any) => {
          const list = response.data.map((item) => {
            let NTE = titleize(item.nome);
            if (NTE.indexOf("Nte ") > -1) NTE = NTE.replace("Nte ", "NTE ");
            return { ...item, value: item.id_nte, label: NTE };
          });
          console.log("handleGetNTE.list:", list);
          resolve(list);
        })
        .catch((err: any) => {
          reject(err.response && err.response.data ? err.response.data : err);
        });
    });
  };
}

export function handleGetSchoolSize(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-porte_escola`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/portes-escola`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const list = response.data.map((item) => {
              return { value: item.id_porte_escola, label: item.porte };
            });
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}
export function handleGetEducationLevel(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-nivel_ensino`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/niveis-ensino`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const list = response.data.map((item) => {
              return { value: item.id_nivel_ensino, label: item.nivel };
            });
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function handleGetDependencyAdministrative(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-dependencia_administrativa`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/dependencias`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const list = response.data.map((item) => {
              return { value: item.id_dependencia, label: item.dependencia };
            });
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            console.log("handleGetDependencyAdministrative: ", list);
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function handleGetSex(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-sex`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/sexos`, { headers: { Authorization: `Bearer ${token}` } })
          .then((response: any) => {
            const list = response.data.map((item) => ({
              value: item.id_sexo,
              label:
                item.sexo.toLowerCase().charAt(0).toUpperCase() +
                item.sexo.toLowerCase().substr(1),
            }));
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function getConfrontationRound():
  | any
  | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-rodada`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/rodadas`, { headers: { Authorization: `Bearer ${token}` } })
          .then((response: any) => {
            const list = response.data.map((item) => ({
              value: item.id_rodada,
              label: titleize(item.descricao),
            }));
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function handleGetDeficiency(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-deficiency`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(localData.data);
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/deficiencias`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const list = response.data.map((item) => ({
              value: item.id_deficiencia,
              label:
                item.descricao.toLowerCase().charAt(0).toUpperCase() +
                item.descricao.toLowerCase().substr(1),
            }));
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(list);
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}

export function handleGetModalityFilters(
  refFilter: any = null,
  parentValue: any = null
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      const key = `${persistConfig.key}@param/${CACHE_PREFIX}-modality`;
      let localData: any = localStorage.getItem(key);

      if (localData) localData = JSON.parse(atob(localData));

      if (!cacheExpired(localData, CACHE_MINUTES.FILTERS))
        resolve(handleModalityfilters(localData.data, refFilter, parentValue));
      else {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");

        api
          .get(`/modalidades/nested?per_page=999`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const list = response.data;
            localStorage.setItem(
              key,
              btoa(JSON.stringify({ ref: referenceToCache(), data: list }))
            );
            resolve(handleModalityfilters(list, refFilter, parentValue));
          })
          .catch((err: any) => {
            reject(err.response && err.response.data ? err.response.data : err);
          });
      }
    });
  };
}
