import React, { useEffect, useState } from "react";
import { Container, Row } from "./styles";
import BackgroundLoginPage from "components/Backgrounds/BackgroundLoginPage";
import Header from "components/Header";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaAuth } from "../../../../yup/Schemas/Auth";
import LoginForm from "components/Forms/LoginForm";
import RegistrationForm from "components/Forms/RegistrationForm";
import { connect } from "react-redux";
import { RootState } from "store/ducks/state";
import { AuthState, handleAuth } from "store/ducks/auth";
import { AuthBody } from "store/types";
import { isAdminArea, isMobile } from "../../../../util";
import { SuccessMessage } from "components/Alerts/BoxFlatAlert/styles";
import { dateTimeScreen } from "../../../../constants/";
import { isHD, log } from "util/index";
import { cleanState } from "store/ducks/auth";
interface StateProps {
  auth: AuthState;
  loading: boolean;
}
interface DispatchProps {
  handleAuth: (data: AuthBody) => Promise<any>;
  cleanState?: () => Promise<any>;
}

type Props = StateProps & DispatchProps;

interface IAuth {
  username: string;
  password: string;
}

const SignIn: React.FC<Props> = (props) => {
  const { auth, cleanState } = props;
  const { user } = auth;

  const [formType, setFormType] = useState(
    window.location.pathname.indexOf("cadastrar") > -1 ? "cadastrar" : "login"
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const redirect = () => (window.location.href = true ? "/" : "/");

  const onSubmitLogin: SubmitHandler<IAuth> = (data) => {
    setErrorMessage(null);
    props
      .handleAuth({
        usuario: data.username,
        senha: data.password,
      })
      .then((response) => {
        console.log("response:", response);
        window.location.href = "/home";
        // getAllProfilesWithPermissions(user)
        //   .then((res) => redirect())
        //   .catch((error) => setErrorMessage(error.msg));
      })
      .catch((error) => {
        console.log("error:", error);
        setErrorMessage(error);
      });
  };

  useEffect(() => {
    (async () => {
      setTimeout(() => cleanState(), 100);
    })();
  }, []);

  useEffect(() => {
    setErrorMessage(auth.error);
  }, [auth.error]);

  return (
    <BackgroundLoginPage isAdminArea={true} target_date={dateTimeScreen}>
      {formType === "login" ? (
        <LoginForm
          isAdminArea={true}
          header={"Login"}
          onSubmit={onSubmitLogin}
          setFormType={setFormType}
          loadingLogin={auth.loading["loading.auth"]}
          errorMessage={errorMessage}
          successMessage={successMessage}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <RegistrationForm
          width={isMobile() ? "100%" : isHD() ? "95%" : "80%"}
          isAdminRegistration={true}
          header={"Cadastre-se"}
          setFormType={setFormType}
        />
      )}
    </BackgroundLoginPage>
  );
};

function mapStateToProps(state: RootState): any {
  const { auth } = state;
  return { auth };
}

export default connect(mapStateToProps, (dispatch: any) => ({
  cleanState: (): any => dispatch(cleanState()),
  handleAuth: (data: AuthBody): any => dispatch(handleAuth(data)),
}))(SignIn);
