import yup from "../../../../";
import { ValidateCpf } from "util/validations/";
import { dateToBR, onlyNumber } from "../../../../../util";
import { isValidDate } from "../../../../../util/index";

export const People = yup
  .object({
    nome: yup.string().required(),

    cpf: yup
      .string()
      .required()
      .test("cpf", "CPF inválido", (value) => {
        return ValidateCpf(value ? value : "");
      }),

    rg: yup.string(),

    matricula: yup.string().required(),

    nis: yup.string(),

    dataNascimento: yup
      .string()
      .required()
      .test(
        "len",
        "Data de nascimento inválida.",
        (val) => new Date(val) <= new Date()
      ),

    sexo: yup
      .string()
      .required()
      .test("sexo", "Selecione o gênero", (value) => {
        return Number(value) > 0;
      }),

    municipio: yup.string().required(),

    unidade: yup.string().required().min(3)
  })
  .required();

export default People;
