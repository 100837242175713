import { useDispatch } from "react-redux";
import { Reducer } from "redux";
import createReducer from "util/createReducer";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { RootState } from "store/ducks/state";
import { persistConfig } from "..";
import { getToken } from "../../util";
import { checkTokeExpred } from "./alert";

export enum UserTypes {
  SearchAdhesionStart = "@user/SearchAdhesionStart",
  SearchAdhesionSuccess = "@user/SearchAdhesionSuccess",
  SearchAdhesionFailure = "@user/SearchAdhesionFailure"
}

export type Actions = {
  SearchAdhesionStart: {
    type: UserTypes.SearchAdhesionStart;
    payload: any;
  };
  SearchAdhesionSuccess: {
    type: UserTypes.SearchAdhesionSuccess;
    payload: any;
  };
  SearchAdhesionFailure: {
    type: UserTypes.SearchAdhesionFailure;
    payload: any;
  };
};

export interface LoadingSection {
  "loading.create": boolean;
  "loading.update": boolean;
  "loading.searchAdhsion": boolean;
}

export interface ListAdhesion {
  id_escola: number;
  nome_escola: string;
  id_nte: number;
  id_municipio: number;
  id_autorizacao: number;
}

export interface ISearchHeaders {
  currentpage?: string;
  lastpage?: string;
  total?: string;
}

export interface SchoolState {
  data: {};
  searhAdhsion: {
    list: ListAdhesion[];
    headers: ISearchHeaders;
  };
  loading: LoadingSection;
  error: any;
}

export const InitialState: SchoolState = {
  data: {},
  searhAdhsion: {
    list: [] as ListAdhesion[],
    headers: null as ISearchHeaders
  },
  loading: {
    "loading.create": false,
    "loading.update": false,
    "loading.searchAdhsion": false
  },
  error: undefined
};

export const schoolReducer: Reducer<SchoolState> = createReducer(
  InitialState,
  {
    [UserTypes.SearchAdhesionStart](state: SchoolState) {
      state.loading["loading.searchAdhsion"] = true;
      return state;
    },
    [UserTypes.SearchAdhesionSuccess](
      state: SchoolState,
      action: Actions["SearchAdhesionSuccess"]
    ) {
      state.loading["loading.searchAdhsion"] = false;
      state.searhAdhsion = {
        headers: action.payload.headers,
        list: action.payload.list
      };
      return state;
    },
    [UserTypes.SearchAdhesionFailure](
      state: SchoolState,
      action: Actions["SearchAdhesionFailure"]
    ) {
      state.loading["loading.searchAdhsion"] = false;
      state.error = action.payload;
      return state;
    }
  }
);

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function newAdhesion(
  data: any
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/inscricoes`,
          { ...data },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}

export function updateAdhesion(
  idAdhesion: number,
  data: any
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/inscricoes/${idAdhesion}`,
          { ...data },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}

export function getAdhesion(
  id: number
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .get(`/inscricoes/${id}`, {
          headers: { Authorization: `Bearer ${getToken(getState)}` }
        })
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}

export function getSchool(
  id: number
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .get(`/escolas/${id}`, {
          headers: { Authorization: `Bearer ${getToken(getState)}` }
        })
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}

export function handleSearchAdhesion(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.SearchAdhesionStart
    } as Actions["SearchAdhesionStart"]);
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      api
        .get(`/autorizacoes/escolas/filter${filters}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response: any) => {
          console.log("response:", response);
          const { data } = response;
          const payload = {
            list: data,
            headers: response?.headers
          };
          dispatch({
            type: UserTypes.SearchAdhesionSuccess,
            payload: payload
          } as Actions["SearchAdhesionSuccess"]);

          resolve(data);
        })
        .catch((err: any) => {
          const errorMsg =
            err.response && err.response.data ? err.response.data : err;
          dispatch({
            type: UserTypes.SearchAdhesionFailure,
            payload: errorMsg
          } as Actions["SearchAdhesionFailure"]);

          checkTokeExpred(dispatch, errorMsg);
          reject(errorMsg);
        });
    });
  };
}
