import React from "react";

import { ContentColumn, ContentColumnOption } from "./styles";
interface IProps {
  children: React.ReactNode;
  color?: string;
  bold?: boolean;
  cStyle?: any;
}
export default function Column<IProps>(props) {
  const { children, color, bold, cStyle = {} } = props;
  // console.log("children:", children);
  // console.log("cStyle:", cStyle);
  return (
    <ContentColumn color={color} weightBold={bold} style={cStyle}>
      {children}
    </ContentColumn>
  );
}
export function ColumnOption<IProps>(props) {
  const { children, cStyle } = props;
  return (
    <ContentColumnOption style={cStyle || {}}>{children}</ContentColumnOption>
  );
}
