import React,{useEffect,useState} from 'react';
import Button,{ButtonProps} from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Stack from '@mui/material/Stack';

// import 'typeface-roboto'

interface IButton extends ButtonProps{
    typecolor?: string;
    width?:string;
    height?:string;
    icon?:any;
    loading?:boolean;
    fontSize?:string;
    noFontBold?:boolean;
    styleIcon?: React.CSSProperties;
}

const ButtonStyled: React.FC<IButton> = ({...rest})=> {
    const[color, setColor] = useState<string>('');
    const {loading, width, children, typecolor, variant,  height, icon, styleIcon, fontSize, noFontBold} = rest;

    const colorPrimary = '#169a00';
    const colorSecondary = '#7ae469';
    const colorPrimaryOrganizer = '#004d91';
    const colorSecondaryOrganizer = '#00bbff';
    const colorInsertCompetitor = '#17A2B8';
    const colorDanger = '#ff2200';


    useEffect(() => {
        switch(typecolor) {
            case 'primary':setColor(colorPrimary);break;
            case 'secondary': setColor(colorSecondary); break;
            case 'primary_organizer': setColor(colorPrimaryOrganizer); break;
            case 'secondary_organizer': setColor(colorSecondaryOrganizer); break;
            case 'insertCompetitor':setColor(colorInsertCompetitor); break;
            case 'danger':setColor(colorDanger); break;
            default: setColor(colorPrimary);
        }
    },[typecolor])
    
    return (
            <Button
           
            disabled={loading || false}
            {...rest}
            sx={{
                width: width?width:'100%' ,
                height:height?height:'56px',
                fontWeight: noFontBold?'regular':'bold',
                color:variant==='outlined'?'gray':'white',
                border:variant==='outlined'?'1px solid'+ color:'',
                fontFamily: 'roboto',
                textTransform:'none',
                fontSize: fontSize?fontSize:'1em',
                backgroundColor:variant?
                                    variant==='contained'?
                                        color
                                    :'transparent'
                                :color,
                "&:hover":{
                    backgroundColor:variant?
                                        variant==='contained'?
                                            color+'95'
                                        :'transparent'
                                    :color+'95',
                    border:variant==='outlined'?'1px solid'+ color+'95':'',
                }
            }}>
                {   loading && <ReactLoading type="spin" width={35} height={35} color="#fff" />}
                {!loading && icon && 
                    <FontAwesomeIcon color="white" style={styleIcon}  icon={icon}/> 
                }
                { !loading?children:<></>    }
            </Button>
    );
}
export default ButtonStyled;