import styled from 'styled-components';
import THEME from '../../../constants/theme'

export const LineFeedBack = styled.div`
    font-size:15px;
    color:#707070;
`
export const FeedBackUpload = styled.div`
    display: flex;
    width:100%;
    /* background-color:red; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:15px;
`

export const Container = styled.div`
    width: 700px;
    height:auto;
    background-color: white;
    @media only screen and (max-width: 728px) {
        width:100%;
    }
`;
export const Content = styled.div`
    display: flex;
    width:700px;
    min-height:400px;
    background-color:#fff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border:1px solid red; */
    padding:50px;
`;