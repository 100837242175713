import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles";
import img from "../../assets/logoColorida.png";
import logoBrasao from "../../assets/brasaoHorizontalColoridaCortada.png";
import { IS_ELECTORAL_PERIOD } from "constants/system";

interface IFormPDF {
  data: any;
}
interface IPropsFormHeader {
  title: string;
}
interface IProps {
  data?: {};
  model: "PDF";
}

export const LayoutPDF: React.FC<IProps> = (props) => {
  const { data = {}, model } = props;
  const HandleModelPDF = () => {
    switch (model) {
      case "PDF":
        return <RenderLayout data={data ? data : null} />;
      default:
        return <></>;
    }
  };
  return HandleModelPDF();
};

const PageHeader: React.FC<IPropsFormHeader> = ({ title }) => (
  <View style={styles.header}>
    <View style={styles.container}>
      <View style={styles.title}>
        <Text>Ficha</Text>
        <Text>{title}</Text>
      </View>
      <View style={styles.logo}>
        <Image
          src={IS_ELECTORAL_PERIOD ? logoBrasao : img}
          style={styles.image}
        />
      </View>
    </View>
  </View>
);
export const RenderLayout: React.FC<IFormPDF> = ({ data = {} }) => {
  return (
    <Document>
      <Page wrap={false} size="A4" style={styles.page}>
        <PageHeader title="Impressão e PDF" />

        <View style={styles.section}>
          <View style={[styles.rowNoPadding, styles.height50]}>
            <Text style={styles.titleRow}>Dados Pessoais </Text>
          </View>
          <View style={[styles.row]}>
            <Text style={styles.flex1}>Nome: PESSOA DECENTE E HONESTA</Text>
            <Text style={styles.flex1}>
              E-mail: mechamequandoprecisar@site.com{" "}
            </Text>
          </View>
          <View style={[styles.row]}>
            <Text style={styles.flex1}>CPF: 123.456.789-00 </Text>
            <Text style={styles.flex1}>RG: 1234567890 </Text>
            <Text style={styles.flex1}>Telefone: (71) 9 9999-9999 </Text>
          </View>
          <View style={[styles.row, styles.marginB25, { border: "none" }]}>
            <Text style={styles.flex1}>Endereço: Rua das Pessoas Boas</Text>
            <Text style={styles.flex1}>Bairro: Paraíso</Text>
            <Text style={styles.flex1}>Cidade/UF: Vitória - ES</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.rowNoPadding, styles.height50]}>
            <Text style={styles.titleRow}>Mais Informações </Text>
          </View>
          <View style={[styles.column, { minHeight: 50 }]}>
            <Text style={styles.titleField}>Mais Informações 01:</Text>
            <Text style={styles.subTitleField}></Text>
          </View>
          <View style={[styles.column, { minHeight: 200 }]}>
            <Text style={styles.titleField}>Mais Informações 02:</Text>
            <Text style={styles.subTitleField}></Text>
          </View>
          <View style={[styles.column, { minHeight: 350 }]}>
            <Text style={styles.titleField}>Mais Informações 03:</Text>
            <Text style={styles.subTitleField}></Text>
          </View>

          <View style={[styles.column, { minHeight: 150 }]}>
            <Text style={styles.titleField}>Mais Informações 04:</Text>
            <Text style={styles.subTitleField}></Text>
          </View>

          <View style={[styles.column, { minHeight: 150, border: "none" }]}>
            <Text style={styles.titleField}>Mais Informações 05:</Text>
            <Text style={styles.subTitleField}></Text>
          </View>
        </View>

        <View style={{ margin: 10 }}>
          <Text style={styles.flex1}>
            * <b>Lorem Ipsum</b> is simply dummy text of the printing and
            typesetting industry.{" "}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default LayoutPDF;
