import React from "react";
import { Switch, Route } from "react-router";
import AuthMiddleware from "components/AuthMiddleware";
import Logout from "pages/Common/Auth/Logout";
import { connect } from "react-redux";
import { RootState } from "./store/ducks/state";
import { isLogged } from "./util/index";
import { AlertState } from "./store/ducks/alert";
import { AuthState } from "./store/ducks/auth";
import ReduxModal from "./components/Alerts/ReduxModal/index";
import Authentication from "pages/Common/Auth/Authentication";
import Layout from "components/Layout";
import StudentProfile from "pages/Common/StudentProfile";
import SynthesisStage from "components/Reports/SynthesisStage";
import Search from "pages/Search";
import Volunteers from "pages/Volunteers";
import Home from "pages/Common/Home";
import InConstruction from "pages/Common/InConstruction";
import UIComponents from "pages/UIComponents";

interface StateProps {
  auth?: AuthState;
  loading?: boolean;
  alert?: AlertState;
}

type Props = StateProps;

export const routes: React.FC<Props> = (props) => {
  const { auth, alert } = props;
  const { user } = auth;

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {!isLogged() ? (
        <Switch>
          <Route path="/" component={Authentication}></Route>
        </Switch>
      ) : (
        <Layout>
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route
              path="/perfil-do-estudante/:id"
              component={StudentProfile}
            ></Route>
            <Route
              path="/tela-de-consulta/:id"
              component={SynthesisStage}
            ></Route>
            <Route path="/tela-de-consulta" component={Search}></Route>
            <Route path="/voluntarios" component={Volunteers}></Route>
            <Route path="/home" component={Home}></Route>
            <Route path="/apontamentos" component={InConstruction}></Route>
            <Route path="/pagamentos" component={InConstruction}></Route>
            <Route
              path="/tabela-de-virgencia"
              component={InConstruction}
            ></Route>
            <Route path="/em-construcao" component={InConstruction}></Route>
            <Route path="/componentes" component={UIComponents}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </Layout>
      )}

      <ReduxModal
        loading={alert.loading["loading.alert"]}
        visible={alert.visible}
        title={alert.title}
        description={alert.description}
        icon={alert.icon}
        okButton={alert.okButton}
        backButton={alert.backButton}
        input={alert.input}
        confirmationButton={
          alert.confirmationButton
            ? {
                ...alert.confirmationButton,
                onClick: alert.confirmationButton.onClick,
              }
            : undefined
        }
        iconColor={
          alert.iconColor
            ? alert.iconColor
            : alert.type === "alert"
            ? "orangeRed"
            : alert.type === "confirm"
            ? "orange"
            : alert.type === "success"
            ? "green"
            : alert.type === "error"
            ? "red"
            : "gray"
        }
      />
    </div>
  );
};

function mapStateToProps(state: RootState): any {
  const { auth,  alert } = state;
  return { auth,  alert };
}

export default connect(mapStateToProps, (dispatch: any) => ({}))(routes);
