import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "store/ducks/state";
import { Reducer } from "redux";
import createReducer from "util/createReducer";

export enum fieldsMemoryTypes {
  setTmpValue = "@user/setTmpValue",
  SetInput = "@user/SetInput",
  SetSelect = "@user/SetSelect"
}

export type Actions = {
  setTmpValue: {
    type: fieldsMemoryTypes.setTmpValue;
    payload: any;
  };
  SetInput: {
    type: fieldsMemoryTypes.SetInput;
    payload: IInputText;
  };
  SetSelect: {
    type: fieldsMemoryTypes.SetSelect;
    payload: ISelect;
  };
};

export interface ISelectOption {
  value: any;
  lagel: string;
}

export interface IInputText {
  parentRef: string;
  ref: string;
  value: string;
}

export interface ISelect {
  parentRef: string;
  ref: string;
  value: any;
  options: ISelectOption[];
}

export interface fieldsMemoryState {
  inputs: IInputText[];
  selects: ISelect[];
  tmpValues: any;
}

export const InitialState: fieldsMemoryState = {
  inputs: [] as IInputText[],
  selects: [] as ISelect[],
  tmpValues: {}
};

export const fieldsMemoryReducer: Reducer<fieldsMemoryState> = createReducer(
  InitialState,
  {
    [fieldsMemoryTypes.setTmpValue](
      state: fieldsMemoryState,
      action: Actions["setTmpValue"]
    ) {
      // console.log("action.payload:", action.payload);
      state.tmpValues = {
        ...state.tmpValues,
        [action.payload.key]: action.payload.value
      };
      return state;
    },
    [fieldsMemoryTypes.SetInput](
      state: fieldsMemoryState,
      action: Actions["SetInput"]
    ) {
      let currentList = state.inputs.filter(
        (sel) =>
          !(
            String(sel.parentRef) === String(action.payload.parentRef) &&
            String(sel.ref) === String(action.payload.ref)
          )
      ) as IInputText[];

      let currentItem = state.inputs.filter(
        (sel) =>
          sel.parentRef === action.payload.parentRef &&
          sel.ref === action.payload.ref
      )[0] as IInputText;

      state.inputs = [
        ...currentList,
        {
          parentRef: action.payload.parentRef
            ? action.payload.parentRef
            : currentItem?.parentRef,
          ref: action.payload.ref ? action.payload.ref : currentItem?.ref,
          value: action.payload.value
            ? action.payload.value
            : currentItem?.value
        }
      ];
      return state;
    },
    [fieldsMemoryTypes.SetSelect](
      state: fieldsMemoryState,
      action: Actions["SetSelect"]
    ) {
      let currentList = state.selects.filter(
        (sel) =>
          !(
            String(sel.parentRef) === String(action.payload.parentRef) &&
            String(sel.ref) === String(action.payload.ref)
          )
      ) as ISelect[];

      let currentItem = state.selects.filter(
        (sel) =>
          sel.parentRef === action.payload.parentRef &&
          sel.ref === action.payload.ref
      )[0] as ISelect;

      state.selects = [
        ...currentList,
        {
          parentRef: action.payload.parentRef
            ? action.payload.parentRef
            : currentItem?.parentRef,
          ref: action.payload.ref ? action.payload.ref : currentItem?.ref,
          value: action.payload.value
            ? action.payload.value
            : currentItem?.value,
          options: action.payload.options
            ? action.payload.options
            : currentItem?.options
        }
      ];
      return state;
    }
  }
);

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function setTmpValue(key, value): any {
  return (dispatch, getState): any => {
    dispatch({
      type: fieldsMemoryTypes.setTmpValue,
      payload: {
        key: key,
        value: value
      }
    } as Actions["setTmpValue"]);
  };
}

export function getTmpValue(state, key): any {
  return state && state.tmpValues && state.tmpValues[key] ? state.tmpValues[key] : null;
}

export function setInput(dataInput: IInputText): any {
  return (dispatch, getState): any => {
    dispatch({
      type: fieldsMemoryTypes.SetInput,
      payload: dataInput
    } as Actions["SetInput"]);
  };
}

export function setSelect(dispatch, dataSelect: ISelect): any {
  dispatch({
    type: fieldsMemoryTypes.SetSelect,
    payload: dataSelect
  } as Actions["SetSelect"]);
}

export function getInput(state, parentRef, ref): any {
  return state && state.inputs && state.inputs.length > 0
    ? state.inputs.filter((i) => i.parentRef === parentRef && i.ref === ref)
    : null;
}
export function getSelect(state, parentRef, ref): any {
  const selectFiltered =
    state && state.selects && state.selects.length > 0
      ? state.selects.filter((i) => i.parentRef === parentRef && i.ref === ref)
      : undefined;
  return selectFiltered && selectFiltered[0] ? selectFiltered[0] : undefined;
}
