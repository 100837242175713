import styled from "styled-components";

interface IProps {}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const Content = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 15px;
  }
`;
export const Gap = styled.div`
  display: flex;
  gap: 8px;
`;

export const BoxButtonViewMode = styled.div`
  display: flex;
  padding: 15px;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;

  &:hover {
    background-color: #f9f9f9;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

`;

export const IconsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
