import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../Modal/index";
import * as fontAwsomeIcon from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "util/index";
import ReactLoading from "react-loading";

import {
  ContainerAlert,
  ContainerModal,
  TitleAlert,
  DescriptionAlert,
  ContainerButton,
  Button
} from "./styles";

interface IOkButton {
  label?: string;
  onClick?: () => void;
}

interface IBackButton {
  label?: string;
  onClick?: () => void;
}

interface IConfirmationButton {
  label?: string;
  onClick?: () => void;
}
interface IProps {
  visible?: boolean;
  setVisible?: any;
  description: string;
  title: string;
  icon?: any;
  styleIcon?: React.CSSProperties;
  styleContainerButton?: React.CSSProperties;
  style?: React.CSSProperties;
  styleContainerAlert?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
  styleDescription?: React.CSSProperties;
  backButton?: IBackButton;
  okButton?: IOkButton;
  confirmationButton?: IConfirmationButton;
  ref?: any;
  iconColor?: string;
  loading?: boolean;
  children: React.ReactNode;
}
const ModalAlert: React.FC<IProps> = (props) => {
  const {
    visible = false,
    setVisible = null,
    description,
    title,
    icon,
    styleContainerButton,
    style,
    styleIcon,
    styleContainerAlert,
    styleTitle,
    styleDescription,
    okButton = undefined,
    backButton = undefined,
    confirmationButton = undefined,
    ref = undefined,
    loading = false,
    iconColor = "orange"
  } = props;

  return (
    <Modal
      setVisible={setVisible}
      visible={visible}
      bttnExitStyle={
        isMobile() ? { position: "absolute", right: "20" } : { display: "flex" }
      }
    >
      <ContainerModal style={style}>
        <ContainerAlert style={styleContainerAlert}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ReactLoading
                type="spin"
                width={75}
                height={75}
                color="#c0c0c0"
              />
            </div>
          ) : (
            <FontAwesomeIcon
              style={styleIcon}
              color={iconColor}
              size="4x"
              icon={icon || fontAwsomeIcon.faExchangeAlt}
            />
          )}

          <TitleAlert style={styleTitle}>{title}</TitleAlert>
          <DescriptionAlert style={styleDescription}>
            {description}
          </DescriptionAlert>
        </ContainerAlert>
        <ContainerButton style={{ ...styleContainerButton, gap: "10px" }}>
          {okButton && (
            <Button ref={ref}>
              <span>{okButton.label || "OK"}</span>
              <input
                type="button"
                id="input-to-upload"
                onClick={() => okButton.onClick()}
              />
            </Button>
          )}

          {backButton && (
            <Button ref={ref}>
              <span>{backButton.label || "Voltar"}</span>
              <input
                type="button"
                id="input-to-upload"
                onClick={() => backButton.onClick()}
              />
            </Button>
          )}

          {confirmationButton && (
            <Button 
              style={{
                backgroundColor: "green",
                borderColor: "green"
              }}
              ref={ref}
            >
              <span style={{ color: "#fff" }}>
                {confirmationButton.label || "Confirmar"}
              </span>
              <input
                type="button"
                id="input-to-upload"
                onClick={() => confirmationButton.onClick()}
              />
            </Button>
          )}
          {props.children}
        </ContainerButton>
      </ContainerModal>
    </Modal>
  );
};

export default ModalAlert;
