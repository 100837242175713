import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalRedux from "./ModalRedux/index";
import * as fontAwsomeIcon from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "util/index";
import ReactLoading from "react-loading";
import { AlertState, closeAlert, setState } from "store/ducks/alert";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

import {
  ContainerAlert,
  ContainerModalRedux,
  TitleAlert,
  DescriptionAlert,
  ContainerButton,
  Button
} from "./styles";
import { RootState } from "store/ducks/state";

interface IOkButton {
  label?: string;
  onClick?: () => void;
}

interface IBackButton {
  label?: string;
  onClick?: () => void;
}

interface IConfirmationButton {
  label?: string;
  onClick?: (args: any) => any;
}
interface IProps {
  children?: React.ReactNode;
  visible?: boolean;
  setVisible?: any;
  description: string;
  title: string;
  icon?: any;
  iconColor?: string;
  styleIcon?: React.CSSProperties;
  styleContainerButton?: React.CSSProperties;
  style?: React.CSSProperties;
  styleContainerAlert?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
  styleDescription?: React.CSSProperties;
  backButton?: IBackButton;
  okButton?: IOkButton;
  confirmationButton?: IConfirmationButton;
  ref?: any;
  loading?: boolean;
  input?: {
    label: string;
    type: string;
    value?: any;
  };
}
const ReduxModalRedux: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const alert = useSelector((state: RootState) => state.alert);
  const {
    visible = false,
    setVisible = null,
    input = {
      label: undefined,
      type: "text",
      value: null
    },
    description,
    title,
    icon,
    styleContainerButton,
    style,
    styleIcon,
    styleContainerAlert,
    styleTitle,
    styleDescription,
    children,
    okButton = undefined,
    backButton = undefined,
    confirmationButton = undefined,
    ref = undefined,
    loading = false,
    iconColor = "orange"
  } = props;

  return (
    <ModalRedux
      visible={visible}
      bttnExitStyle={
        isMobile() ? { position: "absolute", right: "20" } : { display: "flex" }
      }
    >
      <ContainerModalRedux style={style}>
        <ContainerAlert style={styleContainerAlert}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ReactLoading
                type="spin"
                width={75}
                height={75}
                color="#c0c0c0"
              />
            </div>
          ) : (
            <FontAwesomeIcon
              style={styleIcon}
              color={iconColor}
              size="4x"
              icon={icon || fontAwsomeIcon.faExclamationTriangle}
            />
          )}

          <TitleAlert style={styleTitle}>{title}</TitleAlert>
          <DescriptionAlert style={styleDescription}>
            {description}
            {input && input.label && (
              <TextField
                style={{ marginTop: 50 }}
                type={input.type}
                fullWidth
                color={"primary"}
                label={input.label}
                onChange={({ target }) =>
                  dispatch(
                    setState({
                      ...alert,
                      input: {
                        ...input,
                        value: target.value
                      }
                    })
                  )
                }
              />
            )}
          </DescriptionAlert>
        </ContainerAlert>
        <ContainerButton style={{ ...styleContainerButton, gap: "10px" }}>
          {okButton && (
            <Button ref={ref}>
              <span>{okButton.label || "OK"}</span>
              <input
                type="button"
                id="input-to-upload"
                onClick={() =>
                  okButton.onClick ? okButton.onClick() : dispatch(closeAlert())
                }
              />
            </Button>
          )}

          {backButton && (
            <Button ref={ref}>
              <span>{backButton.label || "Voltar"}</span>
              <input
                type="button"
                id="input-to-upload"
                onClick={() =>
                  backButton.onClick
                    ? backButton.onClick()
                    : dispatch(closeAlert())
                }
              />
            </Button>
          )}

          {confirmationButton && (
            <Button
              style={{
                backgroundColor: "green",
                borderColor: "green"
              }}
              ref={ref}
            >
              <span style={{ color: "#fff" }}>
                {confirmationButton.label || "Confirmar"}
              </span>
              <input
                type="button"
                id="input-to-upload"
                onClick={() => {
                  console.log("alert1:", alert);
                  confirmationButton.onClick
                    ? confirmationButton.onClick(input.value)
                    : dispatch(closeAlert());
                }}
              />
            </Button>
          )}
          {children}
        </ContainerButton>
      </ContainerModalRedux>
    </ModalRedux>
  );
};

export default ReduxModalRedux;
