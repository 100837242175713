import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Container, { Row, Column, Error, Center } from "../styles";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../../../Button";
import Header from "../../../Header";

import {
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import {
  CPFMask,
  onlyNumber,
  RGMask,
  isMobile,
  isSmallMobile,
  isBRDate,
  isUSADate,
  dateToUSA,
  stringDateToUSA,
  stringToDate,
} from "../../../../util";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { ptBR } from "date-fns/locale";

import TextField from "@mui/material/TextField";

import * as SchemaNA from "../../../../yup/Schemas/Register/User/NoAdmin";
import * as SchemaA from "../../../../yup/Schemas/Register/User/Admin";
import ReactLoading from "react-loading";
import { searchCitys, searchSchool } from "store/ducks/searchSelect";
import { RootState } from "store/ducks/state";
import { handleGetNTE } from "store/ducks/parameters";
import { WindowSharp } from "@mui/icons-material";
import {
  getCity,
  getSchool,
  needNTE,
  needCityAndSchool,
  handleChangeFunction,
  getProfileIDReadMode,
  IMunicipio,
  IUnidade,
  IPeople,
} from "../functions";
import { isLogged, dateToBR, formatDate, isValidDate } from "util/index";
import BoxFlatAlert from "components/Alerts/BoxFlatAlert";
import { CurrentUserDisplayed } from "store/types";
import { Actions, handleAutocompleteUser, UserTypes } from "store/ducks/user";
import {
  handleChangeCurrentUserDisplayed,
  handleChangeCurrentUserDisplayedWithDispatch,
} from "store/ducks/user";

interface IOptions {
  value?: string;
  label?: string;
}
interface Props {
  header?: any;
  isAdminRegistration?: boolean;
  setFormType?: any;
  loadingLogin?: boolean;
  setStepForm?: any;
  width?: string;
  loading?: boolean;
  editMode?: boolean;
  hideTitle?: boolean;
  hideLoginButton?: boolean;
  cStyle?: React.CSSProperties;
  readOnly?: boolean;
  lastAutocomplete?: any;
  setLastAutocomplete?: any;
  setForceRender?: any;
}

export const FormStep1: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    editMode,
    loading,
    header = "",
    cStyle = {},
    isAdminRegistration = false,
    setFormType = null,
    loadingLogin = false,
    setStepForm = null,
    hideTitle = false,
    hideLoginButton = false,
    width,
    readOnly = false,
    lastAutocomplete,
    setLastAutocomplete,
    setForceRender,
  } = props;

  const { user } = useSelector((state: RootState) => state.auth);
  const {
    data: { currentUserDisplayed },
  } = useSelector((state: RootState) => state.user);

  const [formIsLoaded, setFormIsLoaded] = useState<boolean>(false);
  const [rgMasked, setRGMasked] = useState<String>();
  const [cpfMasked, setCPFMasked] = useState<String>();
  const [municipio, setMunicipio] = useState<IMunicipio>();
  const [unidade, setUnidade] = useState<IUnidade>();
  const [lockUnit, setLockUnit] = useState(false);
  const [municipios, setMunicipios] = useState<IMunicipio[]>();
  const [municipiosLoading, setMunicipiosLoading] = useState<boolean>(false);

  const [municipiosOpen, setMunicipiosOpen] = useState<boolean>(false);
  const [unidadesOpen, setUnidadesOpen] = useState<boolean>(false);
  const [unidadesLoading, setUnidadesLoading] = useState<boolean>(false);

  const [unidades, setUnidades] = useState<IUnidade[]>();
  const [unidadeVisible, setUnidadeVisible] = useState(false);
  const [loadingTextMunicipio, setLoadingTextMunicipio] = useState<string>(
    municipios && municipios.length > 0
      ? "Aguarde, estamos carregando os municípios..."
      : "Digite seu município..."
  );
  const [loadingTextUnidade, setLoadingTextUnidade] = useState<string>(
    unidades && unidades.length > 0
      ? "Aguarde, estamos carregando as unidades..."
      : "Digite sua unidade escolar..."
  );

  const [profileOptions, setProfileOptions] = useState([]);
  const [NTEOptions, setNTEOptions] = useState([]);
  const [profileData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const RGLabel = `RG${isAdminRegistration ? `*` : ``}`;

  const getProfileName = (profileOptions) => {
    const optionSelected =
      profileOptions && profileOptions.length
        ? (profileOptions.filter(
            (option) =>
              currentUserDisplayed &&
              currentUserDisplayed.id_perfil_solicitado &&
              Number(option.value) ===
                Number(currentUserDisplayed.id_perfil_solicitado)
          ) as IOptions[])
        : ([] as IOptions[]);

    if (optionSelected && optionSelected.length && optionSelected[0].label)
      return optionSelected[0].label;
    else return "";
  };

  const mustAlertProfile = () =>
    currentUserDisplayed &&
    currentUserDisplayed.id_perfil_usuario &&
    Number(currentUserDisplayed.id_perfil_usuario) === 1 &&
    profileOptions &&
    profileOptions.length > 0 &&
    errorMessage === null &&
    isAdminRegistration;

  mustAlertProfile() &&
    setErrorMessage &&
    setErrorMessage(
      `ATENÇÃO! A função "${getProfileName(
        profileOptions
      ).toUpperCase()}", deste cadastro, não pode ser confirmada automaticamente. Você pode ver quais são as permissões desta função através da aba "Controle De Acesso", que fica acima. Para confirmar a função deste cadastro, selecione-a e salve-a (no botão SALVAR abaixo, ou também através da aba "Controle De Acesso").`
    );

  useEffect(() => {
    // isAdminRegistration &&
    //   errorMessage &&
    //   errorMessage !== null &&
    //   !mustAlertProfile() &&
    //   setErrorMessage(null);
  }, [currentUserDisplayed]);

  // useEffect(() => {

  // }, [profileOptions]);

  React.useEffect(() => {
    isAdminRegistration &&
      (async () => {
        isAdminRegistration &&
          dispatch(handleGetNTE()).then((data) => setNTEOptions(data));
      })();
  }, []);

  React.useEffect(() => {
    console.log("useEffect.currentUserDisplayed:  ", currentUserDisplayed);
  }, [currentUserDisplayed]);

  useEffect(() => {
    (async () => {
      isAdminRegistration &&
        setProfileOptions(
          profileData &&
            profileData.length > 0 &&
            profileData
              .filter((p) => ![1, 2].includes(p.id_perfil_usuario))
              .map((p) => ({
                label: p.perfil,
                value: p.id_perfil_usuario,
              }))
        );
    })();
  }, [profileData]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPeople>({
    resolver: yupResolver(
      !isAdminRegistration
        ? SchemaNA.People
        : needNTE(currentUserDisplayed, isAdminRegistration, undefined)
        ? SchemaA.PeopleWithNTE
        : needCityAndSchool(
            currentUserDisplayed,
            isAdminRegistration,
            undefined
          )
        ? SchemaA.People
        : SchemaA.PeopleOthers
    ),
  });

  const handleChangeMunicipio = (
    value = null,
    acceptempty = false,
    setFirst = false
  ) => {
    // if (value === "" && !acceptempty) {
    //   return;
    // }
    setLoadingTextMunicipio("Aguarde, estamos carregando os municípios...");
    setMunicipiosLoading(true);

    dispatch(searchCitys(value))
      .then((data) => {
        if (data.length === 0) setLoadingTextMunicipio("Nada encontrado");
        setMunicipios(data);
        if (setFirst) {
          if (currentUserDisplayed?.municipio)
            setMunicipio(currentUserDisplayed?.municipio);
        }
        setMunicipiosLoading(false);
      })
      .catch((error) => {
        console.log("searchCitys.FormStep1.error:", error);
        setMunicipiosLoading(false);
      });
  };

  const handleChangeUnidade = (value = "", acceptempty = false) => {
    // if (value === "" && !acceptempty) {
    //   return;
    // }
    setLoadingTextUnidade("Aguarde, estamos carregando as escolas...");
    setUnidadesLoading(true);

    dispatch(
      searchSchool(
        value,
        municipio && municipio?.id_municipio
          ? String(municipio?.id_municipio)
          : null
      )
    )
      .then((data) => {
        if (data.length === 0) setLoadingTextUnidade("Nada encontrado");
        setUnidades(data);
        setUnidadesLoading(false);
      })
      .catch((error) => {
        console.log("searchSchool.FormStep1.error:", error);
        setUnidadesLoading(false);
      });
  };

  React.useEffect(() => {
    (async () => {
      // handleChangeMunicipio("", true, true);
    })();
  }, []);
  React.useEffect(() => {
    (async () => {
      if (formIsLoaded && !lockUnit) {
        setUnidadeVisible(municipio !== null);
        setUnidade(null as IUnidade);
        if (municipio && municipio.id_municipio && municipio.id_municipio > 0)
          handleChangeUnidade("", true);
      }
      if (municipio && editMode) {
        setUnidadeVisible(true);
      }
    })();
  }, [municipio]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "cpf":
        var CPF = onlyNumber(value);
        CPF = CPF.length > 10 ? CPFMask(CPF) : CPF;
        setCPFMasked(CPF);

        dispatch(
          handleChangeCurrentUserDisplayedWithDispatch({
            ...currentUserDisplayed,
            cpf: CPF,
          } as CurrentUserDisplayed)
        );

        break;

      case "rg":
        var RG = onlyNumber(value);
        RG = RG.length > 8 ? RGMask(RG) : RG;
        setRGMasked(RG);
        dispatch(
          handleChangeCurrentUserDisplayedWithDispatch({
            ...currentUserDisplayed,
            rg: RG,
          } as CurrentUserDisplayed)
        );
        break;
    }

    dispatch(
      handleChangeCurrentUserDisplayedWithDispatch({
        ...currentUserDisplayed,
        [name]: value,
      } as CurrentUserDisplayed)
    );
  };

  const onSubmit: SubmitHandler<IPeople> = (data) => {
    (async () => {
      // console.log("SUBMIT FEITO AQUI NO FORM1");

      setErrorMessage(null);

      console.log("onSubmit.step1.data1:", data);

      console.log("onSubmit.step1.data2:", data);
      const isBrDate = isBRDate(data.dataNascimento);
      const isUsaDate = isUSADate(data.dataNascimento);
      const newdate: Date = stringToDate(stringDateToUSA(data.dataNascimento));
      let nativityDate =
        isBrDate || isUsaDate
          ? dateToUSA(newdate)
          : dateToUSA(new Date(data.dataNascimento));
      data.matricula = onlyNumber(data.matricula);
      console.log("onSubmit.step1.data.dataNascimento:", data.dataNascimento);
      console.log("onSubmit.step1.isBrDate:", isBrDate);
      console.log("onSubmit.step1.nativityDate:", nativityDate);

      console.log("onSubmit.step1.nativityDate1:", nativityDate);
      console.log(
        "onSubmit.step1.new Date(nativityDate)1:",
        new Date(nativityDate)
      );

      console.log("onSubmit.step1.nativityDate2:", nativityDate);
      console.log(
        "onSubmit.step1.new Date(nativityDate)2:",
        new Date(nativityDate)
      );

      console.log(
        "onSubmit.step1.new Date(nativityDate)2:",
        new Date(nativityDate)
      );

      console.log(
        "onSubmit.step1.currentUserDisplayed1:",
        currentUserDisplayed
      );
      dispatch(
        handleChangeCurrentUserDisplayedWithDispatch({
          ...currentUserDisplayed,
          cpf: data.cpf,
          id_nte: Number(data.id_nte),
          id_perfil_solicitado: Number(data.id_perfil_solicitado),
          matricula: data.matricula,
          rg: data.rg === "" ? null : data.rg,
          nome: data.nome,
          sexo: Number(data.sexo),
          municipio: !needCityAndSchool(
            currentUserDisplayed,
            isAdminRegistration,
            undefined
          )
            ? null
            : municipio,
          unidade: !needCityAndSchool(
            currentUserDisplayed,
            isAdminRegistration,
            undefined
          )
            ? null
            : getSchool(currentUserDisplayed, unidade),
          dataNascimento: nativityDate
            ? stringToDate(stringDateToUSA(nativityDate))
            : null,
        } as CurrentUserDisplayed)
      );

      console.log(
        "onSubmit.step1.currentUserDisplayed2:",
        currentUserDisplayed
      );
      console.log("step1.data3:", data);
      const nextStep = isAdminRegistration ? 3 : 2;
      setStepForm(nextStep);
    })();
  };

  function renderTitle() {
    return (
      <Row>
        <Header>Título do formulário</Header>
      </Row>
    );
  }

  if (
    !municipio &&
    currentUserDisplayed &&
    currentUserDisplayed?.municipio &&
    currentUserDisplayed?.municipio?.id_municipio > 0
  ) {
    setLockUnit(true);
    const city = getCity(currentUserDisplayed);
    setMunicipios([city]);
    setMunicipio(city);
    setTimeout(() => {
      document.getElementById("combo-box-city") &&
        document.getElementById("combo-box-city").focus();
      document.getElementById("combo-box-city") &&
        document.getElementById("combo-box-city").blur();
    }, 100);
    if (
      !unidade &&
      currentUserDisplayed &&
      currentUserDisplayed?.unidade &&
      currentUserDisplayed?.unidade?.id_escola > 0
    ) {
      const school = getSchool(currentUserDisplayed, unidade);
      setUnidades([school]);
      setUnidade(school);
      setUnidadeVisible(true);
      setTimeout(() => {
        document.getElementById("combo-box-school") &&
          document.getElementById("combo-box-school").focus();
        document.getElementById("combo-box-school") &&
          document.getElementById("combo-box-school").blur();
      }, 300);
    }

    setLockUnit(false);
  }

  const styleFooterBackLogin = isSmallMobile()
    ? { marginBottom: "1.3em", marginTop: "1.3em" }
    : { marginBottom: "1.3em" };

  useEffect(() => {
    setTimeout(() => setFormIsLoaded(true), 300);
  }, []);

  console.log(
    "getProfileIDReadMode:",
    getProfileIDReadMode(currentUserDisplayed, true)
  );

  const registerNativiteDate = (currentUserDisplayed) => {
    console.log(
      "registerNativiteDate@CURRENT_DATA:",
      dateToBR(new Date(currentUserDisplayed?.dataNascimento))
    );
    return currentUserDisplayed && currentUserDisplayed?.dataNascimento
      ? dateToBR(new Date(currentUserDisplayed?.dataNascimento))
      : undefined;
  };

  const handleGetNativiteDate = (currentUserDisplayed): Date => {
    console.log(
      "handleGetNativiteDate.currentUserDisplayed:",
      currentUserDisplayed
    );
    console.log(
      "DATA NASCIMENTO: ",
      new Date(currentUserDisplayed?.dataNascimento).toLocaleDateString("pt-br")
    );
    // console.log('DATA NASCIMENTO2: ', new Date(dateToBR(new Date(currentUserDisplayed?.dataNascimento))))
    // console.log('handleGetNativiteDate@TEST: ', currentUserDisplayed &&
    // currentUserDisplayed.dataNascimento &&
    // onlyNumber(dateToBR(new Date(currentUserDisplayed?.dataNascimento))).toString().length === 8 )

    // console.log('handleGetNativiteDate@DATA: ',new Date(dateToBR(new Date(currentUserDisplayed?.dataNascimento))))
    const result =
      currentUserDisplayed &&
      currentUserDisplayed.dataNascimento &&
      onlyNumber(dateToBR(new Date(currentUserDisplayed?.dataNascimento))) !=
        null &&
      onlyNumber(
        dateToBR(new Date(currentUserDisplayed?.dataNascimento))
      ).toString().length === 8
        ? new Date(currentUserDisplayed?.dataNascimento)
        : null;

    console.log("handleGetNativiteDate.result:", result);
    return result;
  };

  const handleAutocomplete = (isAdmin) => {
    try {
      const mustCheck =
        currentUserDisplayed &&
        currentUserDisplayed.cpf &&
        currentUserDisplayed.cpf.length > 0 &&
        onlyNumber(currentUserDisplayed.cpf).length === 11 &&
        isValidDate(currentUserDisplayed?.dataNascimento);

      console.log("handleAutocomplete.mustCheck:", mustCheck);
      console.log(
        "handleAutocomplete.currentUserDisplayed1:",
        currentUserDisplayed
      );

      if (mustCheck) {
        const autocompleteRef = `${onlyNumber(
          currentUserDisplayed.cpf
        )},${dateToBR(currentUserDisplayed.dataNascimento)}`;

        if (lastAutocomplete !== autocompleteRef) {
          console.log("autocompleteRef:", autocompleteRef);
          console.log("LastAutocomplete:", lastAutocomplete);
          console.log(
            "LastAutocomplete !== autocompleteRef:",
            lastAutocomplete !== autocompleteRef
          );
          setLastAutocomplete(autocompleteRef);
          setTimeout(() => {
            dispatch(
              handleAutocompleteUser(
                isAdmin,
                currentUserDisplayed.cpf,
                dateToBR(currentUserDisplayed.dataNascimento)
              )
            )
              .then((response) => {
                console.log("response", response);
                const userData =
                  response && response.length > 0 ? response[0] : undefined;

                if (userData) {
                  dispatch(
                    handleChangeCurrentUserDisplayedWithDispatch({
                      ...currentUserDisplayed,
                      nome: userData?.nome,
                      rg: userData?.rg,
                      sexo: userData?.sexo,
                      email: userData?.email,
                      matricula: userData?.matricula,
                      id_perfil_usuario: userData?.id_perfil_usuario,
                      id_perfil_solicitado: userData?.id_perfil_usuario,
                      unidade: {
                        id_escola: userData?.id_escola,
                        id_municipio: userData?.id_municipio,
                        nome_escola: userData?.escola,
                      },
                      municipio: {
                        id_municipio: userData?.id_municipio,
                        municipio: userData?.municipio,
                      },
                      responsaveis: [
                        {
                          nome: userData?.nome_responsavel,
                          cpf: userData?.cpf_responsavel,
                          rg: userData?.rg_responsavel,
                        },
                      ],
                    } as CurrentUserDisplayed)
                  );
                  setForceRender(true);
                }
              })
              .catch((error) => console.log("error:", error));
          }, 200);
        }
      }

      console.log(
        "handleAutocomplete.currentUserDisplayed2:",
        currentUserDisplayed
      );
    } catch (err) {
      console.log("handleAutocomplete.err:", err);
    }
  };

  useEffect(() => {
    console.log(
      "====>>> handleAutocomplete.currentUserDisplayed:",
      currentUserDisplayed
    );
    !editMode && handleAutocomplete(isAdminRegistration);
  }, [currentUserDisplayed?.dataNascimento, currentUserDisplayed?.cpf]);

  return loading ? (
    <Container style={cStyle} width={width}>
      {renderTitle()}
      <Row
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 50,
        }}
      >
        <ReactLoading type="spin" width={100} height={100} color="#c0c0c0" />
      </Row>
    </Container>
  ) : (
    <Container id="frm-step1" onSubmit={handleSubmit(onSubmit)} width={width}>
      {renderTitle()}
      <Row>
        <Column>
          <TextField
            disabled={readOnly}
            label="CPF*"
            {...register("cpf")}
            inputProps={{ maxLength: 14 }}
            onChange={handleChange}
            color={"success"}
            name="cpf"
          />
          {errors.cpf && <Error>{errors.cpf?.message}</Error>}
        </Column>

        <Column>
          <Controller
            control={control}
            {...register("dataNascimento", {
              value: registerNativiteDate(currentUserDisplayed),
            })}
            name="dataNascimento"
            render={({ field }) => {
              // setTimeout(() => {
              //   if (isValidDate(handleGetNativiteDate(currentUserDisplayed)))
              //     field.onChange(
              //       handleGetNativiteDate(currentUserDisplayed)
              //         ? handleGetNativiteDate(currentUserDisplayed)
              //         : field.value
              //     );
              // }, 100);

              return (
                <LocalizationProvider
                  locale={ptBR}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    disabled={readOnly}
                    value={null}
                    label="Data Nascimento*"
                    disableFuture
                    mask="__/__/____"
                    openTo="year"
                    views={["year", "month", "day"]}
                    onChange={(date) => {
                      console.log("field.onChange(date);:", date);
                      field.onChange(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.dataNascimento && true}
                      />
                    )}
                  />
                </LocalizationProvider>
              );
            }}
          />
          {errors.dataNascimento && (
            <Error>{errors.dataNascimento?.message}</Error>
          )}
        </Column>
      </Row>
      <BoxFlatAlert
        cStyle={{
          marginTop: 20,
          marginBottom: 20,
        }}
        errorMessage={errorMessage}
        hideTitle
      />

      <Row>
        <Column>
          <TextField
            disabled={readOnly}
            label={"RG"}
            {...register("rg")}
            inputProps={{ minLength: 6, maxLength: 14 }}
            name="rg"
            color={"success"}
            onChange={handleChange}
          />
          {errors.rg && <Error>{errors.rg?.message}</Error>}
        </Column>
        <Column>
          <FormControl>
            <InputLabel error={errors.sexo && true}>Gênero*</InputLabel>
            <Select
              disabled={readOnly}
              label="Sexo"
              color="success"
              sx={{ textAlign: "left" }}
              {...register("sexo", { required: true })}
              onChange={({ target }) => {}}
            >
              <MenuItem value="1" sx={{ textAlign: "left" }}>
                Masculino
              </MenuItem>
              <MenuItem value="2" sx={{ textAlign: "left" }}>
                Feminino
              </MenuItem>
            </Select>
          </FormControl>
          {errors.sexo && <Error>{errors.sexo?.message}</Error>}
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            label="Nome completo*"
            {...register("nome")}
            color={"success"}
            disabled={readOnly}
          />
          {errors.nome && <Error>{errors.nome?.message}</Error>}
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            disabled={readOnly}
            label={`Matricula${!isAdminRegistration ? "*" : ""} (só números)`}
            {...register("matricula")}
            inputProps={{ type: "number" }}
            color={"success"}
          />
          {errors.matricula && <Error>{errors.matricula?.message}</Error>}
        </Column>
        {!isAdminRegistration && (
          <Column>
            <TextField
              disabled={readOnly}
              label="NIS"
              {...register("nis")}
              inputProps={{ maxLength: 20 }}
              color={"success"}
              name="nis"
            />
          </Column>
        )}
        {isAdminRegistration && (
          <Column
            style={
              [null, 3, 4].includes(
                getProfileIDReadMode(currentUserDisplayed, true)
              )
                ? isMobile()
                  ? {}
                  : { maxWidth: 150 }
                : {}
            }
          >
            <FormControl>
              <InputLabel error={errors.id_perfil_solicitado && true}>
                Função*
              </InputLabel>
              <Select
                disabled={readOnly}
                id="id_perfil_solicitado"
                label="Gênero"
                error={errors.perfil && true}
                color={"success"}
                sx={{ textAlign: "left" }}
                {...register("id_perfil_solicitado", { required: true })}
                onChange={({ target }) =>
                  handleChangeFunction(target, currentUserDisplayed, dispatch)
                }
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {profileOptions &&
                  profileOptions.length > 0 &&
                  profileOptions.map(
                    (o, key) =>
                      isLogged() && (
                        <MenuItem
                          key={`RegistFormStep1MenuItem${key}`}
                          value={Number(o.value)}
                        >
                          {o.label}
                        </MenuItem>
                      )
                  )}
              </Select>
            </FormControl>
            {errors.id_perfil_solicitado && (
              <Error>{errors.id_perfil_solicitado?.message}</Error>
            )}
          </Column>
        )}
      </Row>

      <Row>
        <Column
          style={{
            display: needNTE(
              currentUserDisplayed,
              isAdminRegistration,
              undefined
            )
              ? "flex"
              : "none",
          }}
        >
          <FormControl>
            <InputLabel>
              {currentUserDisplayed?.id_nte >= 1 &&
              currentUserDisplayed?.id_nte > 0
                ? "Seu NTE*"
                : "Selecione seu NTE*"}
            </InputLabel>
            <Select
              disabled={readOnly}
              name="idNTE"
              label="NTE"
              color={"success"}
              sx={{ textAlign: "left" }}
              {...register("id_nte", { required: true })}
              onChange={({ target }) => {}}
            >
              <MenuItem value="">
                <em>Selecione...</em>
              </MenuItem>
              {NTEOptions &&
                NTEOptions.length > 0 &&
                NTEOptions.map((NTE, key) => (
                  <MenuItem key={`NTE${key}`} value={Number(NTE.value)}>
                    {NTE.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {errors.id_nte && <Error>{errors.id_nte?.message}</Error>}
        </Column>

        <Column
          style={{
            display: needCityAndSchool(
              currentUserDisplayed,
              isAdminRegistration,
              undefined
            )
              ? "flex"
              : "none",
          }}
        >
          <Autocomplete
            disabled={readOnly}
            color={"success"}
            loading
            disablePortal
            id="combo-box-city"
            open={municipiosOpen}
            onOpen={() => {
              setMunicipiosOpen(true);
              if (!municipios || municipios.length < 1)
                handleChangeMunicipio("", true);
            }}
            onClose={() => {
              setMunicipiosOpen(false);
            }}
            clearOnBlur={true}
            loadingText={loadingTextMunicipio}
            options={
              municipios && municipios.length ? municipios : ([] as unknown[])
            }
            getOptionLabel={(option) => (option as IMunicipio)?.municipio || ""}
            filterOptions={(x) => x}
            fullWidth
            onInputChange={(event, value) => {
              formIsLoaded && handleChangeMunicipio(value);
            }}
            renderInput={(params) => (
              <TextField
                label="Município*"
                placeholder="Digite o nome do município..."
                error={errors.municipio && true}
                {...params}
                {...register("municipio")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {municipiosLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(event, data) => {
              return typeof data !== "string" ? setMunicipio(data) : {};
            }}
          />
          {errors.municipio && <Error>{errors.municipio?.message}</Error>}
        </Column>
      </Row>
      <Row
        style={{
          display: needCityAndSchool(
            currentUserDisplayed,
            isAdminRegistration,
            undefined
          )
            ? "flex"
            : "none",
        }}
      >
        <Column>
          {unidadeVisible ? (
            <Autocomplete
              defaultValue={currentUserDisplayed?.unidade || ""}
              disabled={
                readOnly ||
                (municipio === null &&
                  editMode &&
                  !(currentUserDisplayed && currentUserDisplayed?.unidade))
              }
              loadingText={loadingTextUnidade}
              onInputChange={(event, value) => {
                formIsLoaded && handleChangeUnidade(value);
              }}
              id="combo-box-school"
              disablePortal
              filterOptions={(x) => x}
              options={
                unidades && unidades.length ? unidades : ([] as unknown[])
              }
              getOptionLabel={(option) =>
                (option as IUnidade).nome_escola || ""
              }
              loading
              open={unidadesOpen}
              onOpen={() => {
                setUnidadesOpen(true);
                if (!unidades || unidades.length < 1)
                  handleChangeUnidade("", true);
              }}
              onClose={() => {
                setUnidadesOpen(false);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  placeholder="Digite o nome da escola..."
                  label="Unidade Escolar*"
                  {...params}
                  error={errors.unidade && true}
                  {...register("unidade")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {unidadesLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              onChange={(event, data) => {
                if (typeof data !== "string") {
                  setUnidade(data);
                }
                return {};
              }}
            />
          ) : (
            <></>
          )}
          {errors.unidade && <Error>{errors.unidade?.message}</Error>}
        </Column>
      </Row>

      <Row>
        <Button
          noFontBold
          id="btn-step1"
          type="submit"
          typecolor={"primary_organizer"}
        >
          SALVAR
        </Button>
      </Row>
      {!readOnly && !hideLoginButton && !editMode && (
        <>
          <Row style={styleFooterBackLogin}>
            <Center>Já possui conta?</Center>
          </Row>
          <Row style={{ marginBottom: "1.3em" }}>
            <Button
              noFontBold
              onClick={() => {
                setFormType("login");
              }}
              typecolor={"primary_organizer"}
              variant="outlined"
            >
              Faça Login
            </Button>
          </Row>
        </>
      )}
    </Container>
  );
};

export default FormStep1;
