import React from "react";
import { Container } from "./styles";
interface IProps {
  fullContent: boolean;
  children: React.ReactNode;
}
const ApplicationContent: React.FC<IProps> = ({ children, fullContent }) => {
  return <Container fullContent={fullContent}>{children}</Container>;
};
export default ApplicationContent;
