import React, { ButtonHTMLAttributes } from 'react';
import * as icon from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from '../../../util/index';


import {
  Button,
  Text,
} from './styles'
interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // onClick:any;
  icon: any;
  text: string;
  style?: React.CSSProperties;
  styleIcon?: React.CSSProperties;
  txtStyle?: React.CSSProperties;
  cClassName?: any;
  className?: any;
  width?: string;
  hideTextInMobile?: boolean;
}
const IconButton: React.FC<IProps> = ({ icon, hideTextInMobile = false, text, cClassName, styleIcon, style, txtStyle, width, className, ...props }) => {

  return (
    <Button width={width} className={cClassName} style={style} {...props}>
      {icon && <FontAwesomeIcon color="white" style={styleIcon} icon={icon} className={className} />}
      {(!hideTextInMobile || !isMobile()) && <Text style={txtStyle}>{text}</Text>}
    </Button>
  );
}

export default IconButton;