import React from "react";
import { Container } from "./styles";
import Button, { ButtonProps } from "@mui/material/Button";

interface IProps extends ButtonProps {
  label: string;
  cStyle?: React.CSSProperties;
}

export const InputFilter: React.FC<IProps> = ({ ...rest }) => {
  const { label, cStyle = null } = rest;
  delete rest.cStyle;
  return (
    <Container style={cStyle && cStyle !== null ? cStyle : {}}>
      <Button style={{width: '100%'}} {...rest}>
        {label}
      </Button>
    </Container>
  );
};

export default InputFilter;
