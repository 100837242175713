import styled from "styled-components";
interface IProps {}

export const Container = styled.a<IProps>`
  width: 100%;
  height: auto;
  display: flex;
  gap:14px;
  padding:10px 0 14px 0;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b4b4b458;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
export const Icon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;
export const Text = styled.div`
  height: 100%;
  font-size: 1.1em;
  color: white;
  align-items: center;
  display: flex;
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    font-size: 1em;
  }
  @media only screen and (max-width: 728px) {
    font-size: 14px;
  }
`;

export default Container;
