import styled from "styled-components";

interface IProps {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  overflow: auto;
  flex-direction: row;
  justify-content: space-between;

  overflow: hidden;

  @media only screen and (max-width: 728px) {
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    gap: 35px;
    margin-bottom: 30px;
  }
`;

export const ContainerSelectPage = styled.div`
  display: flex;
  width: 220px;
  height: 33px;
  align-items: center;
  justify-content: left;
`;
export const Text = styled.div`
  font-size: 0.9em;
`;

export const Paginator = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 98%;
  border: 1px solid rgb(144, 144, 144);
  border-radius: 5px;
  @media only screen and (max-width: 728px) {
    margin-top: 15px;
    width: 100%;
    height: 36px;
  }
`;

export const Back = styled.div`
  display: flex;
  width: 100px;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgb(144, 144, 144);
  cursor: pointer;
  animation: all 0.3s;
  &&:hover {
    background-color: rgb(144, 144, 144);
    color: white;
  }
  &&:active {
    background-color: rgb(0, 119, 225);
    color: white;
  }
`;

export const Count = styled.div<IProps>`
  display: flex;
  flex: 1;
  width: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgb(144, 144, 144);
  border-left: 1px solid rgb(144, 144, 144);
  cursor: pointer;
  animation: all 0.3s;
  &&:hover {
    background-color: rgb(144, 144, 144);
    color: white;
  }
  &&:active {
    background-color: rgb(0, 119, 225);
    color: white;
  }
  ${(props) =>
    props.active &&
    ` 
        background-color:rgb(0,119,225);         
        color:white;
    `}
`;

export const Prox = styled.div`
  display: flex;
  width: 100px;
  height: 100%;
  border-left: 1px solid rgb(144, 144, 144);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: all 0.3s;
  &&:hover {
    background-color: rgb(144, 144, 144);
    color: white;
  }
  &&:active {
    background-color: rgb(0, 119, 225);
    color: white;
  }
`;

export const Registers = styled.div`
  display: flex;
  width: auto;

  align-items: center;
  justify-content: center;
  font-size: 0.9em;
`;
