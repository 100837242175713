import styled from 'styled-components'

export const Container = styled.h1`
    display:flex !important;
    width:100% !important;
    box-sizing: border-box !important;
    flex-direction: column !important;
    font-weight: bold !important;
    font-size:2.5em !important;
    margin: 0 0 0.75em 0 !important;
    color: black !important;
    font-weight: bold !important;
    text-align: left !important;
    @media only screen and (min-width : 320px) and (max-width : 480px) {
        font-size:2em !important;
    }
    @media only screen and (min-width : 480px) and (max-width : 1366px) {
        margin: 0 0 1em 0 !important;
        font-size:1.8em !important;
    }
`;

export default Container;