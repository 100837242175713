import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { userReducer } from "./user";
import { parametersReducer } from "./parameters";
import { alertReducer } from "./alert";
import { schoolReducer } from "./schools";
import { fieldsMemoryReducer } from "./fieldsMemory";
import { searchPageReducer } from "./searchPage";
import { Volunteers } from "./volunteers";
export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  searchPage: searchPageReducer,
  volunteers: Volunteers,
  school: schoolReducer,
  parameters: parametersReducer,
  alert: alertReducer,
  fieldsMemory: fieldsMemoryReducer,
});
