// Base64 to String and String to Base64
import "moment/locale/pt-br";
import moment from "moment";
import jwt_decode from "jwt-decode";
import React from "react";

import {
  API_TYPE,
  APP_INFO,
  OFFLINE_PASSWORD,
  ENVIRONMENTS,
  ONLINE_SYSTEM,
} from "../constants/system";
import { text } from "@fortawesome/fontawesome-svg-core";

export function ENVIRONMENT_SYSTEM() {
  const CURRENT_ENVIRONMENT_URL = REMOVE_HTTP_HTTPS(window.location.href);
  let ENVIRONMENT_SYSTEM = ENVIRONMENTS[0];
  ENVIRONMENTS.map((ENVIRONMENT) => {
    if (CURRENT_ENVIRONMENT_URL.includes(REMOVE_HTTP_HTTPS(ENVIRONMENT.URL)))
      ENVIRONMENT_SYSTEM = ENVIRONMENT;
  });
  return ENVIRONMENT_SYSTEM;
}
export const REMOVE_HTTP_HTTPS = (url) => {
  let url_treated = url;
  if (url_treated.indexOf("https://") > -1) {
    url_treated = url_treated.replace("https://", "");
  } else if (url_treated.indexOf("http://") > -1) {
    url_treated = url_treated.replace("http://", "");
  }
  return url_treated;
};

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
export const Base64 = {
  btoa: (input = ""): string => {
    const str = input;
    let output = "";

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = "="), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4));

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        );
      }

      block = (block << 8) | charCode;
    }

    return output;
  },

  atob: (input = ""): string => {
    const str = input.replace(/=+$/, "");

    let output = "";

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  },
};
moment.locale("pt-br");

export const weekDay = (d: Date) => {
  var week = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ];

  return d ? week[d.getDay()] : d;
};

export const datesOfCurrentWeek = (): any[] => {
  const startOfWeek = moment().startOf("isoWeek");
  const endOfWeek = moment().endOf("isoWeek");

  const days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, "d");
  }

  const convertDates = days.map((d) => {
    return moment(d).unix();
  });

  return convertDates;
};

export const datesInRange = (from: string, to: string): any[] => {
  const startDate = moment(from);
  const endDate = moment(to);

  const days = [];
  let day = startDate;

  while (day <= endDate) {
    days.push(day.toDate());
    day = day.clone().add(1, "d");
  }

  const convertDates = days.map((d) => {
    return moment(d).format("YYYY-MM-DD");
  });

  return convertDates;
};
export const sundaysInMonth = (m?: any): any[] => {
  const sunday = moment(m || moment())
    .startOf("month")
    .day("Sunday");
  if (sunday.date() > 7) sunday.add(7, "d");
  const month = sunday.month();
  const days = [];
  while (month === sunday.month()) {
    days.push(sunday.format("YYYY-MM-DD"));
    sunday.add(7, "d");
  }

  return days;
};

export function month(numberMonty) {
  var month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return month[numberMonty];
}
export function dateFormatedNameMonth(date) {
  var monthF = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return day + " de " + monthF[month] + " de " + year;
}
export function monthAcronym(numberMonty) {
  var month = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];
  return month[numberMonty];
}
export function isValidDate(date) {
  if (!date || !(date instanceof Date)) date = new Date(date);
  const dateBR = dateToBR(date);
  return (
    dateBR &&
    dateBR !== null &&
    dateBR !== undefined &&
    onlyNumber(dateBR).length === 8
  );
}

export function getTimeOfDateTime(date, hourCycle = "h23") {
  return date.toLocaleTimeString([], {
    hourCycle: hourCycle,
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatTime(d = new Date(), ampm = true, withSeconds = true) {
  var hour = d.getHours();

  if (ampm) {
    var a = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
  }

  hour = checkDigit(hour);
  var minute = checkDigit(d.getMinutes());
  var second = checkDigit(d.getSeconds());

  // https://stackoverflow.com/questions/1408289/how-can-i-do-string-interpolation-in-javascript
  return ampm
    ? `${hour}:${minute}${withSeconds ? ":" + second : ""} ${a}`
    : `${hour}:${minute}${withSeconds ? ":" + second : ""}`;
}

export function checkDigit(t) {
  return t < 10 ? `0${t}` : t;
}
export function addZeroLeft(value, maxLength) {
  if (!value) return " ";
  maxLength = maxLength - 1;
  let newValue = value;
  if (value.length > maxLength) {
    return value;
  }
  for (let i = 0; i < maxLength; i++) {
    newValue = `0${newValue}`;
    if (newValue.length > maxLength) {
      return newValue;
    }
  }
  return newValue;
}

export const dateNow = () => dateToUSA(new Date());

export function dateToBR(
  date: Date,
  withTime = false,
  ampm = false,
  withComma = false,
  firstMonth = false
) {
  // console.log("dateToBR.data:", date);
  if (!date) return "";
  const first = firstMonth
    ? `${date.getMonth() + 1}/${date.getDate()}`
    : `${date.getDate() < 10 ? "0" : ""}${date.getDate()}/${
        date.getMonth() + 1 < 10 ? "0" : ""
      }${date.getMonth() + 1}`;
  let d = !date ? "" : `${first}/${date.getFullYear()}`;

  while (d.indexOf("NaN") > -1) d = d.replace("NaN", "");
  while (d.indexOf("//") > -1) d = d.replace("//", "");
  if (onlyNumber(d) < 8) d = null;

  if (withTime) d += `${withComma ? "," : ""} ` + formatTime(date, ampm);

  return d;
}

export function stringDateToUSA(date: any, withTime = false) {
  if (
    !(
      date &&
      date !== null &&
      date !== undefined &&
      date.length >= 10 &&
      date.indexOf("/") > -1
    )
  )
    return date;
  else {
    date = date.split("/");
    return `${date[2]}-${date[1]}-${date[0]}${withTime ? "00:00:00" : ""}`;
  }
}
export function stringToDate(
  _date,
  _format = "yyyy-mm-dd",
  _delimiter = "-"
): Date {
  // console.log(`=====>>>> stringToDate(${_date}, ${_format}, ${_delimiter})`);
  var formatLowerCase = _format.toLowerCase();
  var formatItems = formatLowerCase.split(_delimiter);
  var dateItems = _date.split(_delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;

  // stringToDate("17/9/2014", "dd/MM/yyyy", "/");
  // stringToDate("9/17/2014", "mm/dd/yyyy", "/");
  // stringToDate("9-17-2014", "mm-dd-yyyy", "-");
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function dateToUSA(date: Date) {
  let d = !date
    ? ""
    : `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" : ""}${
        date.getMonth() + 1
      }-${date.getDate() + 1 < 10 ? "0" : ""}${date.getDate()}`;

  while (d.indexOf("NaN") > -1) d = d.replace("NaN", "");
  while (d.indexOf("--") > -1) d = d.replace("--", "");
  if (onlyNumber(d) < 8) d = null;

  return d;
}

export const isBRDate = (date: string) => {
  const x =
    date && date != null && date !== undefined && date.length >= 10
      ? date.substring(2, 3)
      : null;
  // console.log("isBRDate.date:", date);
  // console.log("isBRDate.x:", x);
  return x === "/";
};

export const isUSADate = (date: string) =>
  date &&
  date != null &&
  date !== undefined &&
  date.length >= 10 &&
  date.substring(2, 3) === "-";

export const formatDate = (
  date?: any,
  dateFormat?: string,
  fromFormat?: string
): any => {
  if (date) {
    date = date.replace(/\//g, "-");
    if (!moment(date).isValid()) {
      if (fromFormat) {
        return moment(date, (fromFormat && fromFormat) || "YYYY-MM-DD").format(
          dateFormat || ""
        );
      }
      return moment(date).format(dateFormat || "");
    }
  }
  return moment(date || moment()).format(dateFormat || "");
};

export const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const handlePersist = (persist) => {
  while (persist && persist.indexOf("\\") > -1)
    persist = persist.replace("\\", "");

  while (persist && persist.indexOf('"{') > -1)
    persist = persist.replace('"{', "{");

  while (persist && persist.indexOf('}"') > -1)
    persist = persist.replace('}"', "}");

  return persist;
};

export const getAuthFromLocalStorage = () => {
  try {
    let persist = handlePersist(localStorage.getItem("persist:JEB"));
    // console.log("getAuthFromLocalStorage.persist:", persist);
    const jPersist = JSON.parse(persist);
    return jPersist && jPersist.auth ? jPersist.auth : null;
  } catch (err) {
    // console.log("getAuthFromLocalStorage.err:", err);
    return null;
  }
};

export const export_file_api_url = (situation: number) => {
  const url = `${ENVIRONMENT_SYSTEM().API}/voluntarios/export?authorization=${getTokenFromLocalStorage()}&id_situacao=${situation}`;
  return url;
};
export const export_file_shipping_api_url = (id: number) => {
  const url = `${ENVIRONMENT_SYSTEM().API}/arquivos/${id}/download?authorization=${getTokenFromLocalStorage()}`;
  return url;
};

export const open_url_navigator_web = async (url) => {
  console.log("🚀 ~ file : util~index.tsx ~ line 432 ~ constopen_url_navigator_web= ~ url", url)
  window.location.href = url;
};


export function getToken(getState) {
  let token = handleToken(`${getState().auth.data.token}`);
  return token;
}
export const getTokenFromLocalStorage = () => {
  const auth = getAuthFromLocalStorage();
  const { data, userLogged } = auth;
  const { token } = data as any;
  return handleToken(token);
};

export const axiosGetHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  };
};
export const getUserLoggedFromLocalStorage = (): any =>
  jwt_decode(getTokenFromLocalStorage());

export const isLogged = (auth: any = null) => {
  try {
    if (!auth) auth = getAuthFromLocalStorage();

    const { data, userLogged } = auth;
    const { token } = data as any;

    return token && token.length > -1;
  } catch (err) {
    return false;
  }
};

export const loggedUserData = (auth: any = null) => auth?.userLogged;

// export const isSmallMobile = () => !isMobile() && window.screen.width <= 320;
export const isSmallMobile = () =>
  window.screen.width <= 320 && window.screen.height <= 568;
export const isMobile = () =>
  window.screen.width < 640 || window.screen.height < 480;
export const isHD = () =>
  window.screen.width <= 1366 || window.screen.height <= 720;
export const isFullHD = () =>
  window.screen.width >= 1920 || window.screen.height >= 1080;
export const isMobileOrHD = () => isMobile() || isHD();
export const isHDOrFullHD = () => isHD() || isFullHD();
export const isTablet = () =>
  !isSmallMobile() && !isMobile() && window.screen.width < 1366;

export const isAdminArea = () =>
  window.location.pathname.indexOf("gestao") > -1;
export const isAdminLogged = (auth: any, reference = null) => {
  if (!isLogged()) return window.location.pathname.indexOf("gestao") > -1;

  const userLogged = auth && auth.user ? auth.user : null; // getUserLoggedFromLocalStorage();
  // console.log("isAdminLogged.auth:", auth);
  // console.log("isAdminLogged.userLogged[" + reference + "]:", userLogged);
  const is_admin = Boolean(Number(userLogged?.is_organizador || 0));
  return is_admin;
  // return window.location.pathname.indexOf('gestao') > -1
};
export function generatorCpf(isMask = undefined) {
  const cpfNumber = createCpfNumber();

  if (isMask)
    return cpfNumber
      .toString()
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  return cpfNumber;
}

function createCpfNumber() {
  const segment1 = randomSegments();
  const segment2 = randomSegments();
  const segment3 = randomSegments();

  const dig1 = verifyDigit(segment1, segment2, segment3);
  const dig2 = verifyDigit(segment1, segment2, segment3, dig1);

  return `${segment1}${segment2}${segment3}${dig1}${dig2}`;
}

function verifyDigit(num1, num2, num3, num4 = undefined) {
  let sumDigit;

  if (num4 !== undefined) {
    sumDigit = sumDigitCpf(`${num1}${num2}${num3}${num4}`, 11);
    return calculateRestCpf(sumDigit);
  }

  sumDigit = sumDigitCpf(`${num1}${num2}${num3}`, 10);
  return calculateRestCpf(sumDigit);
}

function randomSegments() {
  const aleat = Math.floor(Math.random() * 999);
  return aleat.toString().padStart(3, "0");
}

function sumDigitCpf(value, length) {
  var sumDigit = 0;

  for (let i = length, j = 0; i >= 2; i--, j++)
    sumDigit += parseInt(value.charAt(j)) * i;

  return sumDigit;
}

function calculateRestCpf(sumDigit) {
  const rest = sumDigit % 11;
  return rest < 2 ? 0 : 11 - rest;
}

export function CPFMask(cpf) {
  return cpf.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4");
}
export function onlyNumber(txt) {
  if (!txt || !txt.length) return txt;
  else {
    //return txt.replace(/[^0-9]/g, "");
    while (txt.indexOf(" ") > -1) txt = txt.replace(" ", "");
    while (txt.indexOf(",") > -1) txt = txt.replace(",", "");
    while (txt.indexOf(".") > -1) txt = txt.replace(".", "");
    while (txt.indexOf("-") > -1) txt = txt.replace("-", "");
    while (txt.indexOf("/") > -1) txt = txt.replace("/", "");
    return txt;
  }
}
export function RGMask(rg) {
  const v = rg.toUpperCase().replace(/[^\dX]/g, "");
  if (v.length === 8 || v.length === 9)
    return v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, "$1.$2.$3-$4");
  else if (v.length === 10)
    return v.replace(/^(\d{1,2})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  else return rg;
}

export function randomKey() {
  return (
    Math.random().toString(36).slice(-8) +
    Math.random().toString(36).slice(-8) +
    Math.random().toString(36).slice(-8) +
    Math.random().toString(36).slice(-8)
  );
}

export function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateRandomNames(type = "general") {
  const femaleNames = [
    "Luiza",
    "Maria",
    "Paula",
    "Ana",
    "Emma",
    "Brigite",
    "Iolanda",
    "Justine",
  ];
  const maleNames = [
    "Luiz",
    "Mauro",
    "Paulo",
    "Olavo",
    "Jackson",
    "Gregory",
    "Levi",
    "Joseph",
  ];
  const surnames = [
    "Silva",
    "Santos",
    "Ferrary",
    "Clifford",
    "Lennox",
    "Parker",
    "Jackson",
    "Monroe",
  ];

  if (type === "female")
    return `${femaleNames.concat(femaleNames)[randomInt(0, 14)]} ${
      surnames[randomInt(0, 7)]
    } ${surnames[randomInt(0, 7)]}`;
  else
    return `${maleNames.concat(maleNames)[randomInt(0, 14)]} ${
      surnames[randomInt(0, 7)]
    } ${surnames[randomInt(0, 7)]}`;
}

export function loginSuggestion(name, includeLastName = false) {
  name = name.replace(/\s(de|da|dos|das)\s/g, " "); // Remove os de,da, dos,das.
  var initials = name.match(/\b(\w)/gi); // Iniciais de cada parte do nome.
  var nome = name.split(" ")[0].toLowerCase(); // Primeiro nome.
  var surname = initials
    .splice(1, initials.length - 1)
    .join("")
    .toLowerCase(); // Iniciais
  return includeLastName ? nome + surname : nome;
}

export function firstName(name) {
  name = name.replace(/\s(de|da|dos|das)\s/g, " "); // Remove os de,da, dos,das.
  var initials = name.match(/\b(\w)/gi); // Iniciais de cada parte do nome.
  var nome = name.split(" ")[0].toLowerCase(); // Primeiro nome.
  var surname = initials
    .splice(1, initials.length - 1)
    .join("")
    .toLowerCase(); // Iniciais
  return titleize(nome);
}

export const mockData = (isAdmin) => {
  const email = `${isAdmin ? "prof" : "aluno"}${randomInt(
    9999999,
    99999999999
  )}@gmail.com`;

  return [
    {
      unidade: {
        id_escola: 622,
        id_municipio: 2927408,
        nome_escola:
          "CENT EST DE REFERENCIA DO ENSINO MEDIO COM INTER TECNOLOGICA-CEMITEC ",
      },
      municipio: {
        id_municipio: 2927408,
        municipio: "SALVADOR",
        uf: "BA",
      },
      sexo: 1,
      dataNascimento:
        "Thu Jan 20 2022 18:44:08 GMT-0300 (Horário Padrão de Brasília)",
      matricula: "25234523542345",
      rg: `2${randomInt(0, 9)}.61${randomInt(0, 9)}.64${randomInt(
        0,
        9
      )}-${randomInt(0, 9)}`,
      cpf: CPFMask(generatorCpf()),
      nome: generateRandomNames("male"),
      id_perfil_usuario: 3,
      perfil: 3,
      id_nte: null,
    },
    {
      responsaveis: [
        {
          nome: generateRandomNames("male"),
          cpf: CPFMask(generatorCpf()),
          rg: `2${randomInt(0, 9)}.61${randomInt(0, 9)}.64${randomInt(
            0,
            9
          )}-${randomInt(0, 9)}`,
          id_sexo: 1,
        },
        {
          nome: generateRandomNames("female"),
          cpf: CPFMask(generatorCpf()),
          rg: `2${randomInt(0, 9)}.61${randomInt(0, 9)}.64${randomInt(
            0,
            9
          )}-${randomInt(0, 9)}`,
          id_sexo: 2,
        },
      ],
      deficiencia: {
        auditiva: false,
        visual: false,
        fisica: false,
        outras: false,
        descricao: "",
      },
    },
    {
      email: email,
      emailConfirmation: email,
      senha: "password",
      senhaConfirmation: "password",
    },
  ];
};

export function handleFilterSex(filters, data) {
  return filters.map((filter) => {
    if (filter.ref === "id_sexo")
      filter = {
        ...filter,
        options: data.map((item) => {
          return {
            value: item.id_sexo,
            label:
              item.sexo.toLowerCase().charAt(0).toUpperCase() +
              item.sexo.toLowerCase().substr(1),
          };
        }),
      };
    return filter;
  });
}

export const handleIDNumberOrNull = (id) => {
  return Number(id) > 0 ? Number(id) : null;
};

export function handleSpecialCharacters(str) {
  str = str.replace(/[^\w\s]/gi, "");
  while (str.indexOf(" ") > -1) str = str.replace(" ", "-");

  return str;
}

export function titleize(text) {
  try {
    const ignoredWords = ["NTE", "CEPEE", "SEC"];
    if (text.indexOf(" ") > -1) {
      var words = text.split(" ");
      for (var a = 0; a < words.length; a++) {
        var w = words[a];
        if (w.length > 0)
          words[a] = ignoredWords.includes(w)
            ? w
            : w.toLowerCase().charAt(0).toUpperCase() +
              (w.length > 1 ? w.toLowerCase().substr(1) : "");
      }
      text = words.join(" ");
      return text;
    } else
      return ignoredWords.includes(text)
        ? text
        : text.toLowerCase().charAt(0).toUpperCase() +
            (text.length > 1 ? text.toLowerCase().substr(1) : "");
  } catch (err) {
    return text;
  }
}

export function handleFilterDeficiency(filters, data) {
  return filters.map((filter) => {
    if (filter.ref === "deficiencias")
      filter = {
        ...filter,
        options: data.map((item) => {
          return {
            value: item.id_deficiencia,
            label:
              item.descricao.toLowerCase().charAt(0).toUpperCase() +
              item.descricao.toLowerCase().substr(1),
          };
        }),
      };
    return filter;
  });
}

export function handleModalityfilters(data, ref = null, parentValue = null) {
  //console.log(`handleModalityfilters.data(${ref}[${parentValue}]):`, data)
  switch (ref) {
    case "id_modalidade" || null:
      return data.map((item) => ({
        ...item,
        parentValue: item.tipos.map((type) => type.id_tipo),
        value: item.id_modalidade,
        label:
          item.modalidade.toLowerCase().charAt(0).toUpperCase() +
          item.modalidade.toLowerCase().substr(1),
      }));
    case "id_submodalidade": {
      let list = [];
      data.map((m) => {
        m.submodalidades.map((s) => {
          list.push({
            ...s,
            parentValue: m.id_modalidade,
            value: s.id_submodalidade,
            label:
              s.submodalidade.toLowerCase().charAt(0).toUpperCase() +
              s.submodalidade.toLowerCase().substr(1),
          });
        });
      });
      return list;
    }
    case "id_submodalidade_opcao": {
      let list = [];
      data.map((m) => {
        m.submodalidades.map((s) => {
          s.submodalidade_opcoes.map((o) => {
            list.push({
              ...o,
              parentValue: o.id_submodalidade,
              value: o.id_submodalidade_opcao,
              label:
                o.opcao.toLowerCase().charAt(0).toUpperCase() +
                o.opcao.toLowerCase().substr(1),
            });
          });
        });
      });
      return list;
    }
    case "id_categoria": {
      let list = [];
      data.map((m) => {
        m.categorias.map((s) => {
          list.push({
            ...s,
            parentValue: m.id_modalidade,
            value: s.id_categoria,
            label:
              s.categoria.toLowerCase().charAt(0).toUpperCase() +
              s.categoria.toLowerCase().substr(1),
          });
        });
      });
      return list;
    }
    case "id_sexo": {
      let list = [];
      data.map((m) => {
        m.sexos.map((s) => {
          list.push({
            ...s,
            parentValue: m.id_modalidade,
            value: s.id_sexo,
            label:
              s.sexo.toLowerCase().charAt(0).toUpperCase() +
              s.sexo.toLowerCase().substr(1),
          });
        });
      });
      return list;
    }
    default:
      return data.map((item) => ({
        ...item,
        value: item.id_modalidade,
        label:
          item.modalidade.toLowerCase().charAt(0).toUpperCase() +
          item.modalidade.toLowerCase().substr(1),
      }));
  }
}

export function handleFilterParentValue(filters, item) {
  const parentItem = filters.filter((f) => f.ref === item.parent);
  const parentValue =
    parentItem && parentItem.length ? parentItem[0].value : null;
  return parentValue;
}

export function onChangeFilter(
  ref,
  value,
  filters,
  setFilters,
  setLastValue = undefined
) {
  let newFilters = filters.map((filter) => {
    if (filter.ref === ref)
      filter =
        setLastValue === undefined
          ? { ...filter, value: value }
          : { ...filter, value: value, setLastValue: setLastValue };
    return filter;
  });
  setFilters(newFilters);
}

export function utilOnChangeFilter(
  ref,
  value,
  filters,
  setFilters,
  setLastValue = undefined
) {
  onChangeFilter(ref, value, filters, setFilters, setLastValue);
}

export function lineBreaks(txt, style = {}) {
  if (!(txt && txt.length > 0 && txt.indexOf("\n") > -1)) return txt;

  return txt.split("\n").map(function (item, idx) {
    if (item && item.length)
      return (
        <div key={`${idx}`} style={style}>
          {item}
          <br />
        </div>
      );
  });
}

export const urlHash = () => window.location.hash.substring(1);

export const mustRectSystem = () => {
  const hash = urlHash();
  // console.log("hash:", hash);
  return (
    !ONLINE_SYSTEM &&
    !(
      hash &&
      hash.length > 0 &&
      String(hash).trim() === String(OFFLINE_PASSWORD).trim()
    )
  );
};

export const redirectSystem = () =>
  (window.location.href = "http://escolas.educacao.ba.gov.br/jogosestudantis");

export const log = (title, value = null) => console.log(title, value);

export const referenceToCache = () => {
  return new Date().getTime();
  // `${new Date().getFullYear()}${
  //   new Date().getMonth() < 10 ? "0" : ""
  // }${new Date().getMonth()}${
  //   new Date().getDate() < 10 ? "0" : ""
  // }${new Date().getDate()}${
  //   new Date().getHours() < 10 ? "0" : ""
  // }${new Date().getHours()}${
  //   new Date().getMinutes() < 10 ? "0" : ""
  // }${new Date().getMinutes()}`;
};

export const cacheExpired = (localData, cacheMaxMinutes, ref = null) => {
  // console.log("cacheExpired.ref:", ref);
  // console.log("cacheExpired.localData:", localData);
  if (!(localData && localData.ref && String(localData.ref).length > 0))
    return true;
  else {
    let refCache = Number(localData.ref);

    const minutesRefCache = new Date(refCache).getTime() / 60 / 60;
    const minutesNow = new Date().getTime() / 60 / 60;

    const minutesOfDifference = minutesNow - minutesRefCache;
    const expired: boolean = minutesOfDifference > cacheMaxMinutes;

    // console.log("minutesRefCache:", minutesRefCache);
    // console.log("minutesNow:", minutesNow);
    // console.log("minutesOfDifference:", minutesOfDifference);
    // console.log("expired:", expired);
    return expired;
  }
};

export const validValueAndComplement = (
  value,
  complement1 = "",
  complement2 = ""
) => {
  return value && value.length > 0 ? complement1 + value + complement2 : "";
};
export const isEmpty = (value) => {
  try {
    return !(
      value &&
      value !== null &&
      value !== undefined &&
      value.length > 0
    );
  } catch (err) {
    return true;
  }
};

export const handleSchoolName = (name) => {
  if (!name || name.length === 0) return name;
  name = name.toUpperCase();
  while (name.indexOf("GRUPO") > -1) name = name.replace("GRUPO", "GP");
  while (name.indexOf("UNIDADE") > -1) name = name.replace("UNIDADE", "UN");
  while (name.indexOf("ANEXO") > -1) name = name.replace("ANEXO", "ANX");
  name = name.replace("ESCOLAR", "Esc.");
  while (name.indexOf("ESCOLA") > -1) name = name.replace("ESCOLA", "Esc.");
  while (name.indexOf("COLÉRIO") > -1) name = name.replace("COLÉGIO", "Col.");
  while (name.indexOf("COLEGIO") > -1) name = name.replace("COLEGIO", "Col.");
  while (name.indexOf("ESTADUAL") > -1) name = name.replace("ESTADUAL", "Est.");
  while (name.indexOf("MUNICIPAL") > -1)
    name = name.replace("MUNICIPAL", "Mun.");
  while (name.indexOf("PARTICULAR") > -1)
    name = name.replace("PARTICULAR", "Part.");
  return titleize(name);
};

export const isEqualNumber = (n1, n2) => n1 && n2 && Number(n1) === Number(n2);

export const handleReduxError = (err) => {
  console.log("handleCreateUser.err.response:", err.response);
  let errors = "";
  let details = "";
  try {
    errors =
      err.response && err.response.data && err.response.data.msg
        ? err.response.data.msg
        : err.response && err.response.data
        ? err.response.data
        : err;

    if (err && err.response && err.response.data && err.response.data.errors) {
      Object.keys(err.response.data.errors).map((key) => {
        if (
          err.response.data.errors[key] &&
          err.response.data.errors[key].length
        )
          details += `\n- ${err.response.data.errors[key]}`;
      });
    }
  } catch (cErr) {
    console.log("handleReduxError.cErr:", cErr);
  }

  errors !== "" &&
    details !== "" &&
    (errors = `${errors}. DETALHES: ${details}`);

  errors === "" && (errors = null);

  console.log("handleReduxError.errors:", errors);
  console.log("handleReduxError.details:", details);
  return errors;
};

export function extractCompetitorName(str) {
  if (str && str.indexOf("—")) {
    str = str.split("—");
    str = str[0];
  }
  return str;
}

export const isInLogoutUrl = () => window.location.href.indexOf("/logout") > -1;

export const isInHomeUrl = () => window.location.href.indexOf("/home") > -1;

export const handleFiltersToOnsearch = (filters) => {
  let thisFilters = "";
  filters.map((filter) => {
    if (filter.value && String(filter.value).length) {
      const thisFilter = `&${filter.ref}=${filter.value}`;

      while (thisFilters.indexOf(thisFilter) > -1)
        thisFilters = thisFilters.replace(thisFilter, "");
      thisFilters += thisFilter;
    }
  });

  console.log("handleFiltersToOnsearch.thisFilters:", thisFilters);
  return thisFilters;
};

export const handleToken = (token) => {
  while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");
  return token;
};

export const getResponseError = (err) =>
  err.response && err.response.data
    ? err.response.data?.msg
    : JSON.stringify(err);

export function getBaseUrl() {
  let url = null;
  switch (API_TYPE) {
    case "PROD":
      url = APP_INFO.BASE_URL_PROD;
      break;
    case "HML":
      url = APP_INFO.BASE_URL_HML;
      break;
    case "DEV":
      url = APP_INFO.BASE_URL_DEV;
      break;

    default:
      break;
  }
  return url;
}

export function getApiType() {
  const URL = window.location.href;
  console.log("🚀 ~ file: index.tsx ~ line 1071 ~ getApiType ~ URL", URL);
  const API_TYPE_REF: string =
    URL.indexOf("localhost") > -1 ||
    URL.indexOf(
      APP_INFO.FRONT_URL_DEV.replace("http://", "").replace("/api", "")
    ) > -1
      ? "DEV"
      : URL.indexOf(
          APP_INFO.FRONT_URL_HML.replace("http://", "").replace("/api", "")
        ) > -1
      ? "HML"
      : URL.indexOf(
          APP_INFO.FRONT_URL_PROD.replace("http://", "").replace("/api", "")
        ) > -1
      ? "PROD"
      : "ERROR";
  console.log("API_TYPE_REF:", API_TYPE_REF);
  return API_TYPE_REF;
}
