import yup from "../../../..";
import { ValidateCpf } from "util/validations";
import { onlyNumber, isValidDate } from '../../../../../util/index';

export const PeopleOthers = yup
  .object({
    id_perfil_solicitado: yup.string().required(),

    nome: yup.string().required(),

    cpf: yup
      .string()
      .required()
      .test("cpf", "CPF inválido", (value) => {
        return ValidateCpf(value ? value : "");
      }),

    rg: yup
      .string()
      .required()
      .test(
        "len",
        "Informe todos os digitos do RG",
        (val) => String(val).length >= 6 && String(val).length <= 14
      ),

    matricula: yup.string(),

    dataNascimento: yup
      .string()
      .required(),
      // .test(
      //   "len",
      //   "Campo obrigatório!",
      //   (val) => isValidDate(val)
      // )
      //.test(
      //   "len",
      //   "Data de nascimento inválida.",
      //   (val) => new Date(val) <= new Date()
      // ),


    sexo: yup.string().required()
  })
  .required();

export default PeopleOthers;
