import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonSimple from "components/Buttons/simpleButton";
import { isHD, isMobile } from "../../../util";
import { isAdminLogged } from "util/index";
import { RootState } from "store/ducks/state";
import { useSelector } from "react-redux";

import {
  ContentHeader,
  ContentIcon,
  ContentName,
  ContentChildren,
} from "./styles";

interface IProps {
  icon: any;
  text: string;
  children?: React.ReactNode;
  styleIcon?: React.CSSProperties;
  onClick?: any;
  buttons?: any[];
}

const Admin: React.FC<IProps> = ({
  icon,
  text,
  children,
  styleIcon,
  onClick,
  buttons,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  return (
    <ContentHeader
      onClick={onClick}
      isAdminLogged={true}
      bgTransparent={text && text.indexOf("Dashboard") > -1}
    >
      <ContentIcon>
        <FontAwesomeIcon
          size={isMobile() ? "2x" : isHD() ? "2x" : "3x"}
          icon={icon}
          color={
            window.screen.width > 768
              ? "rgba(112, 112, 112, 0.6)"
              : text && text.indexOf("Dashboard") > -1
              ? "rgba(112, 112, 112, 0.6)"
              : "#fff"
          }
          style={styleIcon}
        />
      </ContentIcon>
      <ContentName bgTransparent={text && text.indexOf("Dashboard") > -1}>
        {text}
      </ContentName>
      <ContentChildren>
        {buttons &&
          buttons.length > 0 &&
          buttons.map((button) => (
            <ButtonSimple
              hideTextInMobile
              style={button.style || {}}
              onClick={button.onClick || undefined}
              icon={button.icon || icon.faPlus}
              text={button.label || "Button"}
              styleIcon={{ width: "15px", height: "15px" }}
            />
          ))}
      </ContentChildren>
    </ContentHeader>
  );
};
export default Admin;
