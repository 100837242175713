import React, { useState, useEffect } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormPersonalData from "components/Forms/FormPersonalData";
import ModalAlert from "components/Alerts/ModalAlert/index";
import {
  Container,
  Content,
  CollumLeft,
  AvatarNoAuthorized,
  BoxImage,
  ContainerAlert,
  ContainerModal,
  BoxTabs,
  TitleAlert,
  DescriptionAlert,
  ContainerCard,
  ContainerProvideHeight,
  ContainerAuthorizathion,
  ButtonAuthorized,
  Card,
  CollumRight,
  Header,
  BoxTabItem,
  TabItem,
  ButtonLight,
  BoxLine,
  FormContent,
  PageHeader,
} from "./styles";
import ContentHeader from "components/ApplicationMainScreen/ApplicationContentHeader";
import * as icon from "@fortawesome/free-solid-svg-icons";
import MyDocuments from "./MyDocuments/index";
import Form from "components/Forms/RegistrationForm/index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/ducks/state";
import { titleize, firstName, isMobile } from "util/index";
import Resizer from "react-image-file-resizer";
import Modal from "components/Modal";
import { isAdminLogged } from "util/index";
import FormAccessControl from "components/Forms/RegistrationOrganizerForm/Tab2";

import {
  handleUpdateUser,
  handleChangeCurrentUserDisplayed,
} from "store/ducks/user";
import { CurrentUserDisplayed } from "store/types";
import { validateImage, getUserData } from "store/ducks/user";
import { handleGetUserData } from "components/Forms/RegistrationForm/functions";
import ReactLoading from "react-loading";
import { showAlert, alertLoading, alertSuccess } from "store/ducks/alert";
const avatar = "/broken-image.jpg";
interface IContainerDataLoading {
  isLoading: boolean;
  children: any;
}

const resizeFile = (file, width = 175, height = 175, quality = 75) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "webp",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

function extensionValidate(type) {
  if (type.indexOf("image/") > -1) type = type.replace("image/", "");
  if (!["jpg", "png", "gif", "jpeg", "webp"].includes(type)) {
    alert(
      "A extensão ." +
        type +
        " não é permitida. Use apenas imagens nos formatos .JPG, .JPEG, .PNG ou .GIF"
    );
    return false;
  } else return true;
}

export default function StudentProfile(props: RouteComponentProps) {
  // console.log("🚀 ~ file: index.tsx ~ line 91 ~ StudentProfile ~ data", currentUserDisplayed)
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(isMobile() ? 0 : 1);
  const [foto, setFoto] = useState(avatar);
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const { id_pessoa, nome, email } = user;
  const { id } = useParams<{ id: string }>();
  // console.log("🚀 ~ file: index.tsx ~ line 96 ~ StudentProfile ~ id", id)
  const isModeProfileAdmin = !id && true ? true : false;
  // console.log("🚀 ~ file: index.tsx ~ line 98 ~ StudentProfile ~ isModeProfileAdmin", isModeProfileAdmin)
  const [personId, setPersonId] = useState<number>(
    true
      ? isModeProfileAdmin
        ? id_pessoa
        : Number(atob(id))
      : id_pessoa
  );

  React.useEffect(() => {
    if (isModeProfileAdmin) {
      dispatch(getUserData(id_pessoa, true, false))
        .then((response) => {
          handleGetUserData(response, true, false, true, dispatch);
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file StudentProfile: index.tsx ~ line 114 ~ React.useEffect ~ error",
            error
          );
        });
    }
  }, []);

  const userData = useSelector((state: RootState) => state.user);
  // console.log("🚀 ~ file: index.tsx ~ line 88 ~ StudentProfile ~ userData", userData)
  const {
    data: { currentUserDisplayed },
  } = userData;
  console.log("user:", user);
  console.log("personId:", personId);
  console.log("useParams:", useParams());
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalIsWaitApproval, setmodalIsWaitApproval] =
    useState<boolean>(false);
  const [modalIsWaitApprovalRefuse, setmodalIsWaitApprovalRefuse] =
    useState<boolean>(false);

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => hiddenFileInput.current.click();
  useEffect(() => {
    console.log("MODALVISIBLE: ", modalVisible);
  }, [modalVisible]);

  const WasDenied = (currentUserDisplayed) =>
    currentUserDisplayed &&
    currentUserDisplayed.imagem &&
    currentUserDisplayed.imagem.arquivo &&
    !currentUserDisplayed.imagem.aprovado &&
    currentUserDisplayed.imagem.id_organizador > 0;

  const IsWaitApproval = (currentUserDisplayed) =>
    currentUserDisplayed &&
    currentUserDisplayed.imagem &&
    currentUserDisplayed.imagem.arquivo &&
    !currentUserDisplayed.imagem.aprovado &&
    !currentUserDisplayed.imagem.id_organizador;

  const mssgApproval = true
    ? "A foto só deve ser aceita se for individual, no estilo de foto para documento."
    : " As foto só será aceita se for individual, no estilo de foto para documento.";

  const mssgApprovalComplement = true
    ? ""
    : "Se sua foto não estiver de acordo com estes parâmetros, por favor envie outra!";

  const title = `${
    true && !isModeProfileAdmin
      ? tabActive === 0
        ? "Dados Cadastrais"
        : tabActive === 1
        ? "Perfil"
        : "Documentos"
      : tabActive === 0
      ? "Cadastro"
      : tabActive === 1
      ? "Dados"
      : "Documentos"
  }`;

  const onChange = async (event) => {
    console.log("files:", event.target.files);
    const file = event.target.files[0];
    if (extensionValidate(file.type)) {
      const image = await resizeFile(file);
      const strImage = String(image);
      setFoto(strImage);
      handleChangeCurrentUserDisplayed(dispatch, {
        ...currentUserDisplayed,
        imagem: {
          arquivo: strImage,
          aprovado: false,
          id_organizador: null,
        },
      } as CurrentUserDisplayed);
      dispatch(handleUpdateUser(currentUserDisplayed.id_pessoa, false))
        .then((res) => {
          console.log("handleUpdateUser.then:", 1);
          dispatch(getUserData(currentUserDisplayed.id_pessoa, true, false))
            .then((response) => {
              console.log("getUserData.then:", 1);
              handleGetUserData(response, true, false, true, dispatch);
            })
            .catch((error) => {
              console.log("getUserData.error:", error);
            });
        })
        .catch((error) => {
          console.log("handleUpdateUser.error:", error);
        });
    }
  };

  useEffect(() => {
    console.log("currentUserDisplayed:", currentUserDisplayed);
  }, [currentUserDisplayed]);
  if (
    foto === avatar &&
    currentUserDisplayed &&
    currentUserDisplayed.imagem &&
    currentUserDisplayed.imagem.arquivo &&
    currentUserDisplayed.imagem.arquivo.length > 0
  )
    setFoto(currentUserDisplayed.imagem.arquivo);

  useEffect(() => {
    console.log(
      "IsWaitApproval(currentUserDisplayed):",
      IsWaitApproval(currentUserDisplayed)
    );
  }, [IsWaitApproval(currentUserDisplayed)]);

  const ContainerDataLoading: React.FC<IContainerDataLoading> = (props) => {
    const { isLoading, children } = props;
    return !isLoading ? (
      children
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          minHeight: 100,
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <ReactLoading type="spin" width={100} height={100} color="#c0c0c0" />
      </div>
    );
  };

  const HeaderScreen: React.FC = () => {
    return (
      <ContentHeader
        icon={
          tabActive !== 0 && isMobile()
            ? icon.faChevronLeft
            : icon.faAddressCard
        }
        text={title}
        styleIcon={
          tabActive !== 0 && isMobile()
            ? { color: "#BCBCBC", marginLeft: "-30px" }
            : { color: "#BCBCBC" }
        }
        onClick={() => {
          if (tabActive !== 0 && isMobile()) {
            setTabActive(0);
          }
          return;
        }}
      />
    );
  };

  const MenuLeft: React.FC = () => {
    return (
      <CollumLeft
        style={tabActive !== 0 && isMobile() ? { display: "none" } : {}}
      >
        <DataAndImageProfile />
        <OptionsMenuLeft />
      </CollumLeft>
    );
  };

  const OptionsMenuLeft: React.FC = () => {
    return (
      <BoxTabs>
        <OptionMenuLeftDataRegister />
        <OptionMenuLeftMyDocuments />
        <OptionMenuLeftExcluded />
      </BoxTabs>
    );
  };

  const DataAndImageProfile: React.FC = () => {
    return (
      <>
        <PageHeader isAdminLogged={true} />
        <ContainerProvideHeight>
          <ContainerCard>
            <Card>
              <BoxImage>
                <Avatar
                  src={
                    !foto || userData.loading["loading.getUser"] ? avatar : foto
                  }
                  style={{ width: 150, height: 150 }}
                />
                <ButtonLight>
                  <FontAwesomeIcon color="#707070" icon={icon.faCamera} />
                  <span>Inserir Foto</span>
                  <input
                    type="button"
                    id="input-to-upload"
                    // onChange={(event) => console.log('clicouoooooooooooooooooooou')}
                    onClick={(event) => {
                      setModalVisible(true);
                    }}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />
                </ButtonLight>
              </BoxImage>
              {(IsWaitApproval(currentUserDisplayed) ||
                WasDenied(currentUserDisplayed)) &&
                !isModeProfileAdmin && (
                  <>
                    <AvatarNoAuthorized
                      style={{
                        paddingTop: "25px",
                        marginLeft: 20,
                        marginRight: 20,
                        backgroundColor: WasDenied(currentUserDisplayed)
                          ? "orangeRed"
                          : "orange",
                      }}
                    >
                      <FontAwesomeIcon
                        color="white"
                        size="3x"
                        icon={icon.faExclamationTriangle}
                      />
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          color: "white",
                          marginBottom: 15,
                        }}
                      >
                        {" "}
                        {!WasDenied(currentUserDisplayed)
                          ? "Aguardando Aprovação"
                          : "Foto recusada"}{" "}
                      </div>
                      {WasDenied(currentUserDisplayed)
                        ? "Por favor, envie outra foto individual, no estilo de foto para documento!"
                        : `${mssgApproval} ${mssgApprovalComplement}`}
                    </AvatarNoAuthorized>
                  </>
                )}

              {!WasDenied(currentUserDisplayed) &&
                IsWaitApproval(currentUserDisplayed) &&
                true &&
                currentUserDisplayed &&
                currentUserDisplayed.imagem &&
                currentUserDisplayed.imagem.id_imagem &&
                currentUserDisplayed.imagem.id_imagem > 0 && (
                  <ContainerAuthorizathion>
                    <ButtonAuthorized
                      onClick={() => {
                        dispatch(
                          showAlert({
                            title: "APROVAR FOTO!",
                            description: mssgApproval,
                            backButton: {
                              label: "Voltar",
                            },
                            confirmationButton: {
                              label: "Aprovar",
                              onClick: () => {
                                dispatch(alertLoading());
                                handleChangeCurrentUserDisplayed(dispatch, {
                                  ...currentUserDisplayed,
                                  imagem: {
                                    ...currentUserDisplayed.imagem,
                                    id_organizador: user?.id_organizador,
                                    aprovado: true,
                                  },
                                } as CurrentUserDisplayed);
                                dispatch(
                                  validateImage(
                                    currentUserDisplayed?.imagem.id_imagem,
                                    true
                                  )
                                ).then((res) =>
                                  dispatch(alertSuccess(res.msg))
                                );
                              },
                            },
                          })
                        );
                      }}
                      style={{ backgroundColor: "forestGreen" }}
                    >
                      Aprovar
                    </ButtonAuthorized>
                    <ButtonAuthorized
                      onClick={() => {
                        dispatch(
                          showAlert({
                            title: "RECUSAR FOTO!",
                            description: "Deseja realmente recusar esta foto?",
                            backButton: {
                              label: "Voltar",
                            },
                            confirmationButton: {
                              label: "Recusar",
                              onClick: () => {
                                dispatch(alertLoading());
                                handleChangeCurrentUserDisplayed(dispatch, {
                                  ...currentUserDisplayed,
                                  imagem: {
                                    ...currentUserDisplayed.imagem,
                                    id_organizador: user?.id_organizador,
                                    aprovado: false,
                                  },
                                } as CurrentUserDisplayed);
                                dispatch(
                                  validateImage(
                                    currentUserDisplayed?.imagem.id_imagem,
                                    false
                                  )
                                ).then((res) =>
                                  dispatch(alertSuccess(res.msg))
                                );
                              },
                            },
                          })
                        );
                      }}
                      style={{ backgroundColor: "orangeRed" }}
                    >
                      Recusar
                    </ButtonAuthorized>
                  </ContainerAuthorizathion>
                )}
              <h1 style={{ marginTop: 25 }}>
                {currentUserDisplayed && currentUserDisplayed.nome
                  ? titleize(firstName(currentUserDisplayed.nome))
                  : ""}
              </h1>
              <h2 style={{ marginTop: "3px", padding: 15 }}>
                {currentUserDisplayed && currentUserDisplayed.email
                  ? currentUserDisplayed.email
                  : ""}
              </h2>
            </Card>
          </ContainerCard>
        </ContainerProvideHeight>
      </>
    );
  };

  const OptionMenuLeftDataRegister: React.FC = () => {
    return (
      <BoxLine
        isAdminLogged={true}
        active={tabActive === 1}
        onClick={() => setTabActive(1)}
      >
        <FontAwesomeIcon
          icon={icon.faUserCircle}
          color={
            isMobile()
              ? true
                ? "#0077e1"
                : "#0d6300"
              : "#707070"
          }
          style={{ width: "20px" }}
        />
        {true ? "Dados" : "Dados"}
        {isMobile() && (
          <FontAwesomeIcon
            icon={icon.faChevronRight}
            color={
              isMobile()
                ? true
                  ? "#0077e1"
                  : "#0d6300"
                : "#707070"
            }
            style={{
              width: "20px",
              position: "absolute",
              right: "10px",
            }}
          />
        )}
      </BoxLine>
    );
  };

  const OptionMenuLeftMyDocuments: React.FC = () => {
    return (
      !isModeProfileAdmin && (
        <BoxLine
          isAdminLogged={true}
          active={tabActive === 2}
          onClick={() => setTabActive(2)}
        >
          <FontAwesomeIcon
            icon={icon.faFile}
            color={
              isMobile()
                ? true
                  ? "#0077e1"
                  : "#0d6300"
                : "#707070"
            }
            style={{ width: "20px" }}
          />
          {true ? "" : "Meus "}
          Documentos
          {isMobile() && (
            <FontAwesomeIcon
              icon={icon.faChevronRight}
              color={
                isMobile()
                  ? true
                    ? "#0077e1"
                    : "#0d6300"
                  : "#707070"
              }
              style={{
                width: "20px",
                position: "absolute",
                right: "10px",
              }}
            />
          )}
        </BoxLine>
      )
    );
  };

  const OptionMenuLeftExcluded: React.FC = () => {
    return (
      (!true || isModeProfileAdmin) && (
        <BoxLine
          isAdminLogged={true}
          active={tabActive === 3}
          onClick={() => setTabActive(3)}
        >
          <FontAwesomeIcon
            icon={icon.faClose}
            color={
              isMobile()
                ? true
                  ? "#0077e1"
                  : "#0d6300"
                : "#707070"
            }
            style={{ width: "20px" }}
          />
          Excluir Conta
          {isMobile() && (
            <FontAwesomeIcon
              icon={icon.faChevronRight}
              color={
                isMobile()
                  ? true
                    ? "#0077e1"
                    : "#0d6300"
                  : "#707070"
              }
              style={{
                width: "20px",
                position: "absolute",
                right: "10px",
              }}
            />
          )}
        </BoxLine>
      )
    );
  };

  const ContentDataRight: React.FC = () => {
    return (
      <CollumRight
        style={
          isMobile()
            ? tabActive === 0
              ? { display: "none" }
              : { boxShadow: "none" }
            : {}
        }
      >
        {!isMobile() && (
          <>
            <Header isAdminLogged={true}>
              <h1>{title.toUpperCase()}</h1>
            </Header>
            <MenuRight />
          </>
        )}
        <ContainerDataLoading
          isLoading={
            (tabActive === 1 || tabActive === 0) &&
            personId &&
            personId > 0 &&
            userData.loading["loading.getUser"]
          }
        >
          {tabActive === 1 && (
            <FormContent>
              {/* <FormPersonalData /> */}
              <div
                style={{
                  marginTop: -25,
                  marginLeft: -40,
                  marginRight: -40,
                  marginBottom: 50,
                  zIndex: 0,
                }}
              >
                <Form
                  width="100%"
                  id={personId}
                  isAdminRegistration={isModeProfileAdmin}
                  hideTitle={true}
                  hideLoginButton={true}
                  readOnly={!true || isModeProfileAdmin}
                />
                {/* {isModeProfileAdmin&&(
                    <FormAccessControl personId={personId} />
                  )} */}
              </div>
            </FormContent>
          )}
        </ContainerDataLoading>
        {tabActive === 2 && (
          <FormContent>
            <MyDocuments iconStyle={{ fontSize: 100 }} />
          </FormContent>
        )}
      </CollumRight>
    );
  };

  const MenuRight: React.FC = () => {
    return (
      <BoxTabItem>
        <TabItem
          isAdminLogged={true}
          active={tabActive === 1}
          onClick={() => {
            setTabActive(1);
          }}
        >
          Dados
        </TabItem>
        {!isModeProfileAdmin && (
          <TabItem
            isAdminLogged={true}
            active={tabActive === 2}
            onClick={() => setTabActive(2)}
          >
            Documentos
          </TabItem>
        )}
      </BoxTabItem>
    );
  };

  return (
    <Container>
      <HeaderScreen />
      <Content isPaddingTop={tabActive !== 0 && isMobile()}>
        <MenuLeft />
        <ContentDataRight />
      </Content>

      <ModalAlert
        setVisible={setModalVisible}
        visible={modalVisible}
        title="Carregar foto!"
        description={mssgApproval}
        icon={icon.faExclamationTriangle}
      >
        <ButtonLight style={{ backgroundColor: "green" }}>
          <span style={{ color: "#fff" }}>OK</span>
          <input
            type="file"
            id="input-to-upload"
            onChange={(event) => {
              onChange(event);
              setModalVisible((state) => !state);
            }}
            ref={hiddenFileInput}
            style={{ display: "none" }}
          />
        </ButtonLight>
      </ModalAlert>
    </Container>
  );
}
