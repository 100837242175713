import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { rootReducer } from './ducks';
import '../config/ReactotronConfig';
declare global {
  interface Console {
    tron: any;
  }
}
export const persistConfig = {
  key: 'JEB',
  storage,
  whitelist: ['auth', 'user'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  // process.env.NODE_ENV === 'development'
  compose(applyMiddleware(thunk), console.tron.createEnhancer()),
  // : applyMiddleware(thunk),
);
const persistor = persistStore(store);
export { store, persistor };
