import styled from 'styled-components'
interface IProps{
    active:boolean
}
export const Container = styled.div<IProps>`
    display:flex;
    justify-content:center;
    align-items:center;
    width:30px;
    flex-direction:row;
    height:30px;
    border:1px solid rgb(133,133,133);
    border-radius:5px;
    color:rgb(182,182,182);
    cursor:pointer;
    transition:all 0.3s;
    &&:hover{
        background-color: rgba(133,133,133, 0.75);
        color:white;
    }
    &&:active{

    }
    ${(props)=>props.active && `
        background-color: rgba(133,133,133, 0.75);
        color:white;
    `}
`;
