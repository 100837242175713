import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    flex: 1,
    padding: 30,
    // backgroundColor: '#E4E4E4'
  },
  header: {
    marginTop: 20,
    border: "1px solid #4f81bd",
    margin: 10,
  },
  title: {
    flex: 2,
    padding: "30px",
  },
  logo: {
    flex: 1,
    padding: "10px",
    // border: '1px solid #4f81bd',
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "90px",
    height: "90px",
    margin: "0 auto",
  },
  container: {
    display: "flex",
    width: "100%",
    height: "120px",
    // background: 'linear-gradient(180deg, #87cefa, #fff)',
    flexDirection: "row",
  },
  section: {
    // width: '80%',
    marginTop: 20,
    border: "1px solid #4f81bd",
    margin: 10,
    // padding: 10,
    flexGrow: 1,
    height: "auto",
    minHeight: "auto",
    maxHeight: "auto",
    marginBottom: 30,
  },
  marginB10: { marginBottom: 10 },
  marginB15: { marginBottom: 15 },
  marginB20: { marginBottom: 20 },
  marginB25: { marginBottom: 25 },
  borderAll: { border: "1px solid #4f81bd" },
  borderBottom: { borderBottom: "1px solid #4f81bd" },
  row: {
    flex: 1,
    minHeight: 25,
    display: "flex",
    padding: 10,
    borderBottom: "1px solid #4f81bd",
    textAlign: "left",
    flexDirection: "row",
    height: "auto",
  },
  column: {
    flex: 1,
    minHeight: 20,
    display: "flex",
    padding: 10,
    borderBottom: "1px solid #4f81bd",
    textAlign: "left",
    flexDirection: "column",
    height: "auto",
  },
  rowNoPadding: {
    flex: 1,
    minHeight: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#4f81bd",
  },
  titleRow: {
    fontSize: "14px",
    color: "#fff",
  },
  flex1: {
    flex: 1,
    fontSize: "10px",
  },
  titleField: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  subTitleField: {
    marginTop: 10,
    fontSize: "10px",
  },
  flex2: {
    flex: 2,
    fontSize: "10px",
  },
  flex3: {
    flex: 3,
    fontSize: "10px",
  },
  height30: { height: 30, minHeight: 30, maxHeight: 30 },
  height35: { height: 35, minHeight: 35, maxHeight: 35 },
  height50: { height: 50, minHeight: 50, maxHeight: 50 },
});
export default styles;
