import { useDispatch } from "react-redux";
import { Reducer } from "redux";
import createReducer from "util/createReducer";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { RootState } from "store/ducks/state";
import { checkTokeExpred } from "./alert";
import { handleToken } from "../../util";

export enum UserTypes {
  SearchVolunteersStart = "@user/SearchVolunteersStart",
  SearchVolunteersSuccess = "@user/SearchVolunteersSuccess",
  SearchVolunteersFailure = "@user/SearchVolunteersFailure",
}

export type Actions = {
  SearchVolunteersStart: {
    type: UserTypes.SearchVolunteersStart;
    payload: any;
  };
  SearchVolunteersSuccess: {
    type: UserTypes.SearchVolunteersSuccess;
    payload: any;
  };
  SearchVolunteersFailure: {
    type: UserTypes.SearchVolunteersFailure;
    payload: any;
  };
};

export interface LoadingSection {
  "loading.create": boolean;
  "loading.update": boolean;
  "loading.search": boolean;
  "loading.get": boolean;
}

export interface ISearchHeaders {
  currentpage?: string;
  lastpage?: string;
  total?: string;
}

export interface ISearchVolunteersData {}

export const initialSearchVolunteersData = {} as ISearchVolunteersData;

export interface SearchVolunteersState {
  data: ISearchVolunteersData;
  search: {
    list: any[];
    headers: ISearchHeaders;
  };
  loading: LoadingSection;
  error: any;
}

export const InitialState: SearchVolunteersState = {
  data: initialSearchVolunteersData,
  search: {
    list: [] as any[],
    headers: null as ISearchHeaders,
  },
  loading: {
    "loading.create": false,
    "loading.update": false,
    "loading.search": false,
    "loading.get": false,
  },
  error: undefined,
};

export const Volunteers: Reducer<SearchVolunteersState> = createReducer(
  InitialState,
  {
    [UserTypes.SearchVolunteersStart](state: SearchVolunteersState) {
      state.loading["loading.search"] = true;
      return state;
    },
    [UserTypes.SearchVolunteersSuccess](
      state: SearchVolunteersState,
      action: Actions["SearchVolunteersSuccess"]
    ) {
      state.loading["loading.search"] = false;
      state.search = {
        headers: action.payload.headers,
        list: action.payload.list,
      };
      return state;
    },
    [UserTypes.SearchVolunteersFailure](
      state: SearchVolunteersState,
      action: Actions["SearchVolunteersFailure"]
    ) {
      state.loading["loading.search"] = false;
      state.error = action.payload;
      return state;
    },
  }
);

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function handleSearchVolunteersSearch(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.SearchVolunteersStart,
    } as Actions["SearchVolunteersStart"]);

    return new Promise((resolve, reject) => {
      api
        .get(
          `/voluntarios${filters}`,
          {
            headers: {
              Authorization: `Bearer ${handleToken(
                `${getState().auth.data.token}`
              )}`,
            },
          }
        )
        .then((response: any) => {
          dispatch({
            type: UserTypes.SearchVolunteersSuccess,
            payload: {
              list: response.data,
              headers: response?.headers,
            },
          } as Actions["SearchVolunteersSuccess"]);

          resolve(response.data);
        })
        .catch((err: any) => {
          const errorMsg =
            err.response && err.response.data ? err.response.data : err;
          dispatch({
            type: UserTypes.SearchVolunteersFailure,
            payload: errorMsg,
          } as Actions["SearchVolunteersFailure"]);

          checkTokeExpred(dispatch, errorMsg);
          reject(errorMsg);
        });
    });
  };
}

export function editVolunter(
  id: any,
  data: any,
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {

    return new Promise((resolve, reject) => {
      api
        .put(
          `/voluntarios/${id}`,data,
          {
            headers: {
              Authorization: `Bearer ${handleToken(
                `${getState().auth.data.token}`
              )}`,
            },
          }
        )
        .then((response: any) => {
          // dispatch(
          //   handleSearchVolunteersSearch(filters)
          // );
          resolve(response.data);
        })
        .catch((err: any) => {
          const errorMsg =
            err.response && err.response.data ? err.response.data : err;
          checkTokeExpred(dispatch, errorMsg);
          reject(errorMsg);
        });
    });
  };
}

export function deleteVolunter(
  id: number,
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {

    return new Promise((resolve, reject) => {
      api
        .delete(
          `/voluntarios/${id}`,
          {
            headers: {
              Authorization: `Bearer ${handleToken(
                `${getState().auth.data.token}`
              )}`,
            },
          }
        )
        .then((response: any) => {
          // dispatch(
          //   handleSearchVolunteersSearch(filters)
          // );
          resolve(response.data);
        })
        .catch((err: any) => {
          const errorMsg =
            err.response && err.response.data ? err.response.data : err;
          checkTokeExpred(dispatch, errorMsg);
          reject(errorMsg);
        });
    });
  };
}

export function generateShippingVolunters(
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {

    return new Promise((resolve, reject) => {
      
      api
        .get(
          `/arquivos/gerar-remessa-cadastro`,
          {
            headers: {
              Authorization: `Bearer ${handleToken(
                `${getState().auth.data.token}`
              )}`,
            },
          }
        )
        .then((response: any) => {
          // dispatch(
          //   handleSearchVolunteersSearch(filters)
          // );
          resolve(response.data);
        })
        .catch((err: any) => {
          const errorMsg =
            err.response && err.response.data ? err.response.data : err;
          checkTokeExpred(dispatch, errorMsg);
          reject(errorMsg);
        });
    });
  };
}

export function verifiedVolunteer(
  id?:number
  ): any | ThunkAction<Promise<any>, RootState, any, any> {
    return async (dispatch, getState): Promise<any> => {
  
      return new Promise((resolve, reject) => {
        
        api
          .post(
            `/voluntarios/${id}/verificar`,{},
            {
              headers: {
                Authorization: `Bearer ${handleToken(
                  `${getState().auth.data.token}`
                )}`,
              },
            }
          )
          .then((response: any) => {
            // dispatch(
            //   handleSearchVolunteersSearch(filters)
            // );
            resolve(response.data);
          })
          .catch((err: any) => {
            const errorMsg =
              err.response && err.response.data ? err.response.data : err;
            checkTokeExpred(dispatch, errorMsg);
            reject(errorMsg);
          });
      });
    };
  }
