import yup from '../../../../'
export const User= yup.object({
    email:   yup
            .string()
            .required(),
    emailConfirmation: yup
            .string()
            .required()
            .oneOf([yup.ref('email'), null], 'Os Emails devem corresponder'),

    senha:   yup
            .string()
            .required(),
    senhaConfirmation: yup
            .string()
            .required()
            .oneOf([yup.ref('senha'), null], 'As senhas devem corresponder')
}).required();
export default User;