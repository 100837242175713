import styled from 'styled-components'
import logo from '../../assets/logo.png'

interface IProps{
    isAdminLogged:boolean;
}

export const Container = styled.div<IProps>`
    width:290px;
    height:100%;
    padding-right:20px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:  ${(props)=>props.isAdminLogged? '#0078e1':'#169A00'};
    transition: all 0.3s;
    color:white !important;
    &:hover{
        background-color:  ${(props)=>props.isAdminLogged? '#0078e175':'#169A0075'};
    }
    .buttonMenu{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: flex-start;
    }
    .spacer{
        display:flex;
        flex-grow: 1;
    }
`;

export const Image = styled.div`
    width:50px;
    height:50px;
    display:flex;
    align-items: center;
    background-color: red;
    transition: all 0.3s;
    color:white !important;
`;

export const Name = styled.div`
    display:flex;
    width:auto;
    max-width: 230px;
    height:100%;
    align-items: center;
    margin:0 10px;
    font-size: 0.7em;
    text-transform: capitalize;
    transition: all 0.3s;
    color:white;
    font-weight: bold;
`;

export const UserName = styled.div`
    display:flex;
    width:auto;
    max-width: 230px;
    height:100%;
    align-items: center;
    margin:0 10px;
    font-size: 0.7em;
    text-transform: capitalize;
    transition: all 0.3s;
    color:white;
`;

export const menu= {
    backgroundColor: 'rgb(34,34,34)', 
    color:'white', 
    boxShadow: 1,
}
export const menuItem = {
    width:290, 
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 0.1)',
    },
}



export default Container;