import React from "react";
import { Container, Divisor, TextColumn } from "./styles";

import { useForm, useWatch, SubmitHandler } from "react-hook-form";
import AutoCompleteControlled from "../../../Inputs/Controlled/AutoComplete";
import TextInputControlled from "../../../Inputs/Controlled/TextInput";
import SelectControlled from "../../../Inputs/Controlled/Select";
import ButtonSimple from "../../../Buttons/simpleButton";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { editVolunter } from "../../../../store/ducks/volunteers";
import { alertLoading, closeAlert } from "../../../../store/ducks/alert";
import { dateToUSA, addZeroLeft } from "../../../../util/";
import Button from "../../../Button";

interface IFormVolunteers {
  id: any;
  data?: any;
  onSearch?: any;
}

const FormVolunteers: React.FC<IFormVolunteers> = (props) => {
  const { id, data, onSearch } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [mssg, setMssg] = React.useState<any>();

  // console.log("🚀 ~ file: index.tsx ~ line 18 ~ data", data);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  // const permit
  const rules = React.useCallback(() => {
    const situation1 = {
      cpf_disable: false,
      agencia_disable: false,
      nome_disable: false,
      rg_disable: false,
      orgao_expedidor_disable: false,
      uf_disable: false,
      dtNasc_disable: false,
      sexo_disable: false,
      logradouro_disable: false,
      numero_disable: false,
      complemento_disable: false,
      bairro_disable: false,
      estado_disable: false,
      cep_disable: false,
      email_disable: false,
      telefone_principal_disable: false,
      telefone_secundario_disable: false,
      nome_mae_disable: false,
      municipio_disable: false,
      nte_disable: true,
      escola_disable: false,
      situacao_disable: false,
    };
    const situation2 = {
      cpf_disable: true ? true : false,
      agencia_disable: true,
      nome_disable: true,
      rg_disable: false,
      orgao_expedidor_disable: false,
      uf_disable: false,
      dtNasc_disable: true,
      sexo_disable: false,
      logradouro_disable: true,
      numero_disable: true,
      complemento_disable: true,
      bairro_disable: true,
      estado_disable: true,
      cep_disable: true,
      email_disable: false,
      telefone_principal_disable: false,
      telefone_secundario_disable: false,
      nome_mae_disable: true,
      municipio_disable: true,
      nte_disable: true,
      escola_disable: false,
      situacao_disable: false,
    };
    const situation3 = {
      cpf_disable: true,
      agencia_disable: false,
      nome_disable: false,
      rg_disable: false,
      orgao_expedidor_disable: false,
      uf_disable: false,
      dtNasc_disable: false,
      sexo_disable: false,
      logradouro_disable: false,
      numero_disable: false,
      complemento_disable: false,
      bairro_disable: false,
      estado_disable: false,
      cep_disable: false,
      email_disable: false,
      telefone_principal_disable: false,
      telefone_secundario_disable: false,
      nome_mae_disable: false,
      municipio_disable: false,
      nte_disable: true,
      escola_disable: false,
      situacao_disable: false,
    };
    if (
      data?.id_situacao === "1" ||
      data?.id_situacao === "2" ||
      data?.id_situacao === "5"
    )
      return situation1; // importado e verificado - Retorno Inconsistente
    if (data?.id_situacao == "4" || data?.id_situacao == "3") return situation2; // Pendente CPB e Pendente MCIF
    if (data?.id_situacao == "6") return situation3; // Cadastro concluido
  }, [data]);

  const permissions = rules();

  const handleData = React.useCallback(() => {
    const sexo = data?.sexo.toLowerCase() == "masculino" ? 1 : 2;
    // console.log("🚀 ~ file: index.tsx ~ line 126 ~ handleData ~ data", data)
    return {
      nome: data?.nome,
      cpf: data?.nu_cpf,
      rg: data?.nu_rg,
      orgao_expedidor: data?.orgao_expedidor_rg,
      uf: data?.uf,
      // dtNasc: "2017-05-24",
      dtNasc: dateToUSA(new Date(data?.data_nascimento)),
      sexo: sexo,
      logradouro: data?.logradouro,
      numero: data?.numero,
      complemento: data?.complemento,
      bairro: data?.bairro,
      estado: data?.estado ? data?.estado : "",
      cep: data?.cep,
      email: data?.endereco_email,
      telefone_principal: data?.nu_telefone,
      telefone_secundario: data?.nu_telefone_secundario,
      nome_mae: data?.nome_mae,
      agencia: addZeroLeft(data?.numero_agencia, 4),
      municipio: {
        id_municipio: data?.id_municipio,
        municipio: data?.municipio,
        uf: data?.uf,
        value: data?.id_municipio,
        label: data?.municipio,
      },
      nte: data?.nome_nte,
      escola: {
        cod_sec: data?.cod_sec,
        id_escola: data?.id_escola,
        nome_escola: data?.nome_escola,
        value: data?.id_escola,
        label: data?.nome_escola,
      },
      situacao: data?.label_situacao,
    };
  }, [data]);

  const handleSendData = (data) => {
    return {
      nu_cpf: data?.cpf,
      nome: data?.nome,
      data_nascimento: data?.dtNasc,
      nu_rg: data?.rg,
      orgao_expedidor_rg: data?.orgao_expedidor,
      uf_expedidor_rg: data?.uf,
      sexo: data?.sexo == 1 ? "Masculino" : "Feminino",
      nome_mae: data?.nome_mae,
      logradouro: data?.logradouro,
      numero: data?.numero,
      bairro: data?.bairro,
      complemento: data?.complemento,
      cep: data?.cep,
      municipio: data?.municipio?.municipio,
      uf: data?.estado,
      numero_agencia: data?.agencia,
      endereco_email: data?.email,
      nu_telefone: data?.telefone_principal,
      nu_telefone_secundario: data?.telefone_secundario,
      cod_sec: data?.escola?.cod_sec,
    };
  };

  const defaultValues = handleData();
  // console.log("🚀 ~ file: index.tsx ~ line 197 ~ defaultValues", defaultValues);

  React.useEffect(() => {
    setValue("data", defaultValues);
  }, []);

  const RENDER_USE_WATCH: React.FC<any> = () => {
    const USE_WATCH_CONTROL = useWatch({
      control,
      name: "data",
    });
    const data = JSON.stringify(USE_WATCH_CONTROL);
    // console.log("🚀 ~ file: index.tsx ~ line 200 ~ data", data);
    return <></>;
  };

  const RENDER_ESTADO: React.FC<any> = () => {
    const USE_WATCH_CONTROL = useWatch({
      control,
      name: "data.municipio",
    });
    setValue("data.estado", USE_WATCH_CONTROL?.uf);
    return (
      <TextInputControlled
        noRequired
        control={control}
        errors={errors}
        label="Estado"
        name="data.estado"
        disabled={permissions?.estado_disable}
      />
    );
  };

  const RENDER_NTE: React.FC<any> = () => {
    const USE_WATCH_CONTROL = useWatch({
      control,
      name: "data.escola",
    });
    // console.log(
    //   "🚀 ~ file: index.tsx ~ line 229 ~ USE_WATCH_CONTROL",
    //   USE_WATCH_CONTROL
    // );
    if (USE_WATCH_CONTROL?.nome_nte) {
      setValue("data.nte", USE_WATCH_CONTROL?.nome_nte);
    }

    return (
      <TextInputControlled
        noRequired
        control={control}
        errors={errors}
        disabled={permissions?.nte_disable}
        label="NTE"
        name="data.nte"
      />
    );
  };
  const onSubmit: SubmitHandler<any> = (payload) => {
    // dispatch(alertLoading());
    setMssg(null);
    setLoading(true);
    dispatch(editVolunter(data?.id_voluntario, handleSendData(payload.data)))
      .then((data) => {
        setMssg({ sucess: true, msg: data?.msg });
        onSearch();
        // console.log("SaveNTEsReport@data: ", data);
      })
      .catch((error) => {
        // console.log("🚀 ~ file: index.tsx ~ line 261 ~ error", error);
        setMssg({ sucess: false, msg: error?.msg, detail: error?.errors });
        // setMssg(error?.msg);
        // console.log("SaveNTEsReport@error: ", error);
      })
      .finally(() => {
        setLoading(false);
        // dispatch(closeAlert());
      });
  };

  const RENDER_ERRORS: React.FC<any> = ({ errors }) => {
    if (!errors) return null;
    let errorsMessage = [];
    for (const property in errors) {
      errorsMessage = [...errorsMessage, ...errors[property]];
    }

    return (
      <>
        {errorsMessage.map((error) => {
          return (
            <Divisor
              row
              flex={1}
              style={{
                color: "red",
                fontSize: 20,
                marginBottom: 10,
              }}
            >
              {error}
            </Divisor>
          );
        })}
      </>
    );
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Divisor column flex={1}>
        <Divisor row flex={1}>
          <Divisor flex={4}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Nome Completo"
              name="data.nome"
              disabled={permissions?.nome_disable}
            />
          </Divisor>
          <Divisor flex={2}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              disabled={permissions?.cpf_disable}
              label="CPF"
              name="data.cpf"
            />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={3}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="RG"
              name="data.rg"
              disabled={permissions?.rg_disable}
            />
          </Divisor>
          <Divisor flex={2}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Orgão Expedidor"
              name="data.orgao_expedidor"
              disabled={permissions?.orgao_expedidor_disable}
            />
          </Divisor>
          <Divisor flex={1}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="UF"
              name="data.uf"
              disabled={permissions?.uf_disable}
            />
          </Divisor>
          <Divisor flex={3}>
            <TextInputControlled
              noRequired
              date
              control={control}
              errors={errors}
              defaultValue={defaultValues?.dtNasc}
              label="Data de Nascimento"
              InputLabelProps={{ shrink: true }}
              name="data.dtNasc"
              disabled={permissions?.dtNasc_disable}
            />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={1}>
            <SelectControlled
              control={control}
              label="Sexo"
              defaultValue={defaultValues?.sexo}
              errors={errors}
              name={`data.sexo`}
              options={[
                { value: 1, label: "Masculino" },
                { value: 2, label: "Feminino" },
              ]}
              disabled={permissions?.sexo_disable}
            />
          </Divisor>
          <Divisor flex={3}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Logradouro"
              name="data.logradouro"
              disabled={permissions?.logradouro_disable}
            />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={1}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="N°"
              name="data.numero"
              disabled={permissions?.numero_disable}
            />
          </Divisor>
          <Divisor flex={2}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Complemento"
              name="data.complemento"
              disabled={permissions?.complemento_disable}
            />
          </Divisor>
          <Divisor flex={5}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Bairro"
              name="data.bairro"
              disabled={permissions?.bairro_disable}
            />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={4}>
            <AutoCompleteControlled
              noRequired
              defaultSelectedOption={defaultValues?.municipio}
              control={control}
              errors={errors}
              label="Municipio"
              name="data.municipio"
              optionsSearchJEB="id_municipio"
              disabled={permissions?.municipio_disable}
            />
          </Divisor>
          <Divisor flex={2}>
            <RENDER_ESTADO />
          </Divisor>
          <Divisor flex={2}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="CEP"
              name="data.cep"
              disabled={permissions?.cep_disable}
            />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={2}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Email"
              name="data.email"
              disabled={permissions?.email_disable}
            />
          </Divisor>
          <Divisor flex={1}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Telefone Principal"
              name="data.telefone_principal"
              disabled={permissions?.telefone_principal_disable}
            />
          </Divisor>
          <Divisor flex={1}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Telefone Secundário"
              name="data.telefone_secundario"
              disabled={permissions?.telefone_secundario_disable}
            />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={4}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              label="Nome da Mãe"
              name="data.nome_mae"
              disabled={permissions?.nome_mae_disable}
            />
          </Divisor>
          <Divisor flex={1}>
            <TextInputControlled
              noRequired
              control={control}
              errors={errors}
              disabled={permissions?.agencia_disable}
              label="Agência"
              name="data.agencia"
            />
          </Divisor>
          <Divisor flex={2}>
            <RENDER_NTE />
          </Divisor>
        </Divisor>
        <Divisor row flex={1}>
          <Divisor flex={3}>
            <AutoCompleteControlled
              noRequired
              control={control}
              errors={errors}
              defaultSelectedOption={defaultValues?.escola}
              label="Escola"
              name="data.escola"
              optionsSearchJEB="id_escola"
              disabled={permissions?.escola_disable}
            />
          </Divisor>
          <Divisor flex={1}>
            <TextInputControlled
              noRequired
              disabled={true}
              control={control}
              errors={errors}
              label="Situação"
              name="data.situacao"
            />
          </Divisor>
        </Divisor>
        <Divisor column flex={1} center>
          <Button
            type={"submit"}
            loading={loading}
            disabled={loading}
            typecolor={"primary_organizer"}
            fontSize="27px"
            width="254px"
            height="64px"
            noFontBold
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            Salvar
          </Button>
          <Divisor
            row
            flex={1}
            style={{
              color: `${mssg?.sucess ? "green" : "red"}`,
              fontSize: 25,
              marginBottom: 30,
            }}
          >
            {mssg?.msg}
          </Divisor>
          <RENDER_ERRORS errors={mssg?.detail} />
        </Divisor>
        <RENDER_USE_WATCH />
      </Divisor>
    </Container>
  );
};

export default FormVolunteers;
