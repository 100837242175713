import React from "react";

import { ListHeader } from "./styles";
import Column, { ColumnOption } from "../Column";
import { isMobile } from "util/index";

interface IProps {
  children?: React.ReactNode;
  columns: any;
  styleListHeader?:React.CSSProperties
}
const Header: React.FC<IProps> = ({ columns,styleListHeader }) => {
  return (
    <ListHeader style={styleListHeader}>
      {columns.map((column, index) => {
        if (column) {
          let cstyles = {};
          const children =
            column && column.text !== null && column.text !== undefined
              ? column.text
              : column;
          // console.log("column:", column);
          // console.log("cstyles:", cstyles);
          // console.log("children==>>", children);
          if (column && column.styles)
            cstyles = { ...cstyles, ...column.styles };
          return column === null ? (
            <ColumnOption key={`list-header-column-option-${index}`} />
          ) : (
            <Column
              key={`list-header-column-${index}`}
              bold={true}
              cStyle={cstyles}
            >
              {children}
            </Column>
          );
        }
      })}
    </ListHeader>
  );
};
export default React.memo(Header);
