import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { RootState } from "store/ducks/state";
import { persistConfig } from "store";
import { getToken } from "../../util";

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function searchCitys(
  text: string = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/municipios/search/${
            text.length === 0 ? " " : text
          }?page=1&per_page=30`,
          { headers: { Authorization: `Bearer ${getToken(getState)}` } }
        )
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}

export function searchPlace(
  text: string = "",
  id_type_place:number = 1,
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/locais/filter?id_tipo_local=${id_type_place}&local=${
            text.length === 0 ? " " : text
          }&page=1&per_page=30`,
          { headers: { Authorization: `Bearer ${getToken(getState)}` } }
        )
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}

export function searchSchool(
  text: string = "",
  idCity: string = "",
  isAnexo: '1' | '0' | '' = '',
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      !(text && text.length > 0) && (text = " ");
      api
        .get(
          idCity.length === 0
            ? `/escolas/search/${text}?page=1&per_page=50`
            : `/escolas/por-municipio/${idCity}/search/${
                text.length === 0 ? " " : text
              }?page=1&per_page=50`,
          { headers: { Authorization: `Bearer ${getToken(getState)}` } }
        )
        .then((response: any) => resolve(response.data))
        .catch((err: any) =>
          reject(err.response && err.response.data ? err.response.data : err)
        );
    });
  };
}
