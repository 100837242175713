import React from 'react';

import {
  Container,
} from './styles'
interface IProps{
    onClick: any;
    active?: boolean;
    style?:React.CSSProperties;
    children: React.ReactNode;
}

const IconButton: React.FC<IProps> = ({children, onClick,active,style}) => {
    return (
        <Container style={style} onClick={onClick} active={active}>
            {children}
        </Container>
    );
}
export default IconButton;