import styled from 'styled-components'

interface IProps {
    
}

export const Container = styled.div<IProps>`
    display:flex;
    flex-direction: column;
    width:100%; 
    padding: 0px;
    margin: 0px;
`;