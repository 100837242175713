import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Controller } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import { connect, useDispatch, useSelector } from "react-redux";
import api from "../../../../services/api";
import { axiosGetHeaders, titleize } from "../../../../util";

import {
  handleGetTypeModality,
  handleGetSeason,
  handleGetChampionship,
  handleGetNTE,
  handleGetDependencyAdministrative,
  handleGetSex,
  handleGetDeficiency,
  handleGetModalityFilters,
} from "../../../../store/ducks/parameters";

interface ISelectControlled {
  name: string;
  label?: string;
  control: any;
  errors: any;
  options?: { value: any; label: string }[];
  optionsSearchName?: string;
  unique?: boolean;
  sx?: any;
  disabled?: boolean;
  defaultValue?: any;
}

const SelectControlled: React.FC<ISelectControlled> = (props) => {
  const {
    sx,
    name,
    label,
    options,
    control,
    optionsSearchName,
    unique = false,
    disabled = false,
    defaultValue,
    errors,
  } = props;

  const dispatch = useDispatch();

  const [thisOptions, setThisOptions] = React.useState(options);
  const [loading, setLoading] = React.useState(
    optionsSearchName ? true : false
  );

  React.useEffect(() => {
    optionsSearchName === "id_situacao" &&
      (!options || options.length === 0) &&
      SearchSituation();
  }, []);

  function SearchSituation() {
    api
      .get(`/situacoes`, axiosGetHeaders())
      .then((response: any) => {
        const list = response.data
          .filter((item) => Number(item.id_sexo) !== 3)
          .map((item) => ({
            value: item.id_situacao,
            label: item.label_situacao,
          }));
        setThisOptions(list);
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return <CircularProgress color="inherit" size={20} />;
  }

  return (
    <FormControl sx={{ m: 1, width: "100%", margin: 0, ...sx }} size="small">
      <InputLabel id={`id-input-label-select-jeb${name}-${label}`}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <Select
            defaultValue={defaultValue}
            labelId={`label-id-select-jeb${name}-${label}`}
            id={`id-select-jeb${name}-${label}`}
            value={value}
            label="Age"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            disabled={disabled}
            sx={{ textAlign: "left" }}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {thisOptions.map((option) => (
              <MenuItem value={option.value}>{option.label} </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};
export default React.memo(SelectControlled);

const handleUnique = (list) => {
  const exist = (newList, item) =>
    newList.filter((i) => i.value === item.value && i.label === item.label)
      .length;
  let newList = [];
  list.map((item) => {
    // console.log('handleUnique.item:', item)
    if (!exist(newList, item)) newList.push(item);
  });

  return newList;
};
