export const types_people_disability = [
    { id: 3, name: 'Visual'},
    { id: 4, name: 'Motora'},
    { id: 5, name: 'Mental'},
    { id: 6, name: 'Auditiva'}
];

function searchIDDisabilityByName(name){
    return types_people_disability.filter((item)=> item.name === name)[0].id
}

function searchNameDisabilityByID(id){
    return types_people_disability.filter((item)=> item.id === id)[0].name
}

export default {
    types_people_disability,
    searchIDDisabilityByName,
    searchNameDisabilityByID,
}