import React, { CSSProperties } from "react";
import { Container } from "./styles";
import ContentLoader, { Facebook } from "react-content-loader";
import { BulletList } from "react-content-loader";
import { randomInt } from "util/index";

interface IProps {
  children: React.ReactElement;
  rows: number;
  loading: boolean;
  width?: any;
  key?: string;
  cStyle?: CSSProperties;
}

const ContentLoaderCustomized: React.FC<IProps> = ({
  loading,
  rows,
  children,
  width = "94%",
  key = randomInt(999999, 999999999999),
  cStyle = {}
}) => {
  const MyLoader = (key) => (
    <ContentLoader
      key={key}
      height={50}
      width={width}
      speed={0.5}
      backgroundColor={"#dfdddd"}
      foregroundColor={"#e9e6e6"}
      viewBox="0 0 100% 70"
      style={cStyle ? cStyle : { marginTop: 20, opacity: 0.61 }}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="50" height="45" />
      <rect x="75" y="0" rx="0" ry="0" width="100%" height="25" />
      <rect x="75" y="35" rx="0" ry="0" width="200%" height="10" />
    </ContentLoader>
  );

  const renderLoader = () => {
    let content = [];
    let marginT = 0;
    for (let i = 0; i < rows; i++) {
      content.push(MyLoader(i));
      marginT = i === 1 ? 0 : 40;
    }
    return (
      <div style={{ marginTop: marginT }}> {content.map((row) => row)}</div>
    );
  };

  return loading ? renderLoader() : <Container key={key}>{children}</Container>;
};

export default ContentLoaderCustomized;
