import styled from "styled-components";

interface IProps {
  width?: number;
  pair?: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: 60px;
  border-bottom: 1px solid #70707045;
  transition: 0.5s;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: left;
  background-color: ${(props) =>
    props.pair ? "rgba(155, 155, 155, 0.05)" : "transparent"};

  @media only screen and (max-width: 728px) {
    flex-direction: column;
    height: auto;
    border-bottom: 1px solid #70707045;
    gap: 15px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  &:hover {
    background-color: rgba(155, 155, 155, 0.30);
    border-left: 5px solid rgba(155, 155, 155, 0.55);
    padding-left: 10px;
  }
`;
interface IPropsText {
  bg: string;
}
export const Text = styled.div<IPropsText>`
  display: flex;
  width: 74px;
  height: 25px;
  border-radius: 10px;
  background-color: ${(props) => props.bg};
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div<IProps>`
  display: block;
  width: ${(props) => props.width + "px" || "100%"};
  border-bottom: 0;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  margin-bottom: -7px;

  @media only screen and (min-width: 728px) {
    display: none;
  }
`;

export const ContentColumn = styled.div`
  max-width: 100%;

  @media only screen and (max-width: 728px) {
    max-width: calc(100vw - 20px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    white-space: normal;
    text-align: left;
  }
`;

export const Status = styled.div<IPropsText>`
  display: flex;
  /* width: auto; */
  width:150px;
  padding: 0 5px;
  /* height: 25px; */
  border-radius: 10px;
  background-color: ${(props) => props.bg};
  color: white;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
`;
