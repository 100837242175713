import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Container } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import api from "services/api";
import { axiosGetHeaders } from "../../../util";
import { SearchcController } from "./functions";

interface IParameters {
  reference: string;
  value: any;
}

interface IProps {
  parent?: string;
  name?: "id_escola" | "id_municipio" | string;
  label: string;
  onChange: any;
  ref?: any;
  onInputChange?: any;
  onChangeOptions?: any;
  value?: string;
  defaultSelectedOption?: IOption;
  cStyles?: any;
  containerStyle?: React.CSSProperties;
  searchFilters?: string;
  disabled?: boolean;
  options?: any;
  fullWidth?: boolean;
  parentValue?: number;
  autoFocus?: boolean;
  sx?: any;
  size?: any;
  error?: boolean;
  textFieldStyles?: any;
  textFieldSx?: any;
  getOptionLabel?: any;
  inputValue?: string;
  InputLabelProps?: any;
  APICallWaiting?: number;
  onOpen?: any;
  onClose?: any;
  otherParameters?: IParameters;
}

export interface IOption {
  [string: string]: any;
  parentValue?: any;
  value?: any;
  label?: any;
}

export const AutoCompleteCustom: React.FC<IProps> = ({
  parent,
  name,
  defaultSelectedOption,
  label,
  onChange,
  onInputChange,
  onChangeOptions,
  value,
  parentValue,
  cStyles,
  containerStyle,
  searchFilters = "",
  ref,
  options = [],
  disabled = false,
  fullWidth = false,
  autoFocus = false,
  sx,
  error = false,
  size,
  textFieldStyles,
  textFieldSx,
  getOptionLabel,
  inputValue = undefined,
  InputLabelProps,
  APICallWaiting = 500,
  onOpen,
  onClose,
  otherParameters,
}) => {
  const [inputValueChange, setInputValueChange] = React.useState(inputValue);
  const [isLoading, setIsLoading] = React.useState(true);
  const [filteredOptions, setFilteredOptions] = React.useState<IOption[]>([]);
  const [selectedOption, setselectedOption] = React.useState<IOption>(
    defaultSelectedOption
  );
  const [thisOptions, setThisOptions] = React.useState<IOption[]>(
    options as IOption[]
  );

  useEffect(() => {
    SearchcController(name, " ", setIsLoading, setThisOptions);
  }, []);

  React.useEffect(() => {
    const fOptions = filterOptions(
      thisOptions,
      parentValue,
      ["id_submodalidade"].includes(name)
    );
    setFilteredOptions(fOptions);
    onChangeOptions && onChangeOptions(fOptions);
  }, [thisOptions, parentValue, value]);

  useEffect(() => {
    setselectedOption(defaultSelectedOption);
  }, [defaultSelectedOption]);

  return (
    <Container style={containerStyle ? containerStyle : cStyles}>
      <Autocomplete
        style={cStyles}
        ref={ref}
        sx={sx}
        size={size}
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option) => option.label || ""
        }
        disablePortal
        inputValue={inputValueChange || ""}
        loading
        freeSolo={true}
        loadingText={isLoading ? "Aguarde, estamos carregando os dados..." : ""}
        fullWidth={fullWidth}
        disabled={disabled}
        value={handleValue(value, selectedOption, thisOptions)}
        options={filteredOptions}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(event, values) => {
          setselectedOption(values as IOption);
          onChange && onChange(event, values);
        }}
        onInputChange={(event, inputValue) => {
          if (!inputValue) inputValue = "";
          setInputValueChange(inputValue);
          onInputChange && onInputChange(event, inputValue);
          defaultSelectedOption = undefined;
          value = null;

          handleInputChange(
            name,
            inputValue,
            SearchcController,
            APICallWaiting,
            setIsLoading,
            setThisOptions
          );
        }}
        renderInput={(params) => (
          <TextField
            style={textFieldStyles ? textFieldStyles : cStyles}
            {...params}
            error={error}
            label={label}
            sx={[{ backgroundColor: "white" }, textFieldSx && textFieldSx]}
            autoFocus={autoFocus || error}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Container>
  );
};

export default React.memo(AutoCompleteCustom);

const filterOptions = (options, parentValue, print = false) => {
  let newOptions = [];
  options &&
    options.length > 0 &&
    options.map((option) => {
      if (!parentValue || String(option.parentValue) === String(parentValue))
        newOptions.push(option);
      else if (
        Object.prototype.toString.call(option.parentValue) ===
          "[object Array]" &&
        option.parentValue.includes(Number(parentValue))
      )
        newOptions.push(option);
    });

  return newOptions as IOption[];
};

async function handleInputChange(
  name,
  inputValue,
  SearchcController,
  APICallWaiting,
  setIsLoading,
  setThisOptions
) {
  incrementLastDigitation();
  const thisLastDigitation = getLastDigitation();
  setTimeout(async function executeAPICall() {
    const canRun = thisLastDigitation === getLastDigitation();
    if (canRun) {
      incrementLastAPICall();
      SearchcController(name, inputValue, setIsLoading, setThisOptions);
    }
  }, APICallWaiting);
}

function handleValue(value, selectedOption, thisOptions) {
  return selectedOption
    ? selectedOption
    : thisOptions && thisOptions.length > 0 && value && Number(value) > 0
    ? (thisOptions.filter(
        (option) => String(option.value) === String(value)
      )[0] as IOption)
    : null;
}

function getLastDigitation(): number {
  return Number(localStorage.getItem("AutoCompleteCustom-lastDigitation")) + 0;
}

function getLastAPICall(): number {
  return Number(localStorage.getItem("AutoCompleteCustom-lastAPICall")) + 0;
}

function incrementLastDigitation() {
  localStorage.setItem(
    "AutoCompleteCustom-lastDigitation",
    String(getLastDigitation() + 1)
  );
}

function incrementLastAPICall() {
  localStorage.setItem(
    "AutoCompleteCustom-lastAPICall",
    String(getLastAPICall() + 1)
  );
}
