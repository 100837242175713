// import { styles } from 'constants';
import styled from "styled-components";
interface IProps {
  isAdminLogged: boolean;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  flex-direction: column;
  padding-bottom: 50px;
`;
interface IContent{
  isPaddingTop?:boolean
}
export const Content = styled.div<IContent>`
  padding: 10px;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 75vw;
  margin: auto auto;
  margin-top: calc(30px + 1vh) !important;
  background-position: cover;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    max-width: none;
    padding: 0 30px !important;
    /* margin-top: -1px !important; */
  }
  ${props=>props.isPaddingTop &&`
    padding-top:-30px;
  `}
`;

export const PageHeader = styled.div<IProps>`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100vw;
    height: 180px;
    background-color: ${(props) =>
      props.isAdminLogged ? "rgb(0,77,145)" : "#0d6300"};
    margin: 0px;
    /* margin-top: -25px;
    margin-left: -25px; */
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
`;

export const CollumLeft = styled.div`
  background-color: #fff;
  width: 500px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  margin-bottom: 10px;
  padding-bottom: 20px;

  h1 {
    font-size: 30px;
    color: #000;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    min-height: 0;
    margin: 0;
    padding: 0;
    box-shadow: none;
    margin-top:15px;
    border-radius: 20px;
  }
`;
export const ContainerCard = styled.div`
  @media only screen and (max-width: 768px) {
    /* width: 100vw; */
    /* border:1px solid blue; */
    /* padding:30px; */
    display: flex;
    height: auto;
    background-color: transparent;
    position: relative;
    left: 0;
    width: 100%;
  }
`;
export const ContainerProvideHeight = styled.div`
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    /* border:1px solid red; */
    width: 100%;
    position:relative;

  }
`;
export const Card = styled.div`
  @media only screen and (max-width: 768px) {
    width: calc(100vw - 30px);
    /* border:1px solid red; */
    display: flex;
    height: auto;
    flex-direction: column;
    box-shadow: 0px 3px 25px 12px rgb(0 0 0 / 15%);
    border-radius: 20px;
    /* margin-top: -50px; */
    padding-top: 10px;
    padding-bottom: 35px;
    margin: 0 auto;
    background-color: white;
  }
`;
export const ButtonLight = styled.label`
  width: 150px;
  margin: 0 auto;
  margin-top: 15px;
  border: 1px solid #777;
  background-color: transparent; // #169a00;
  color: #707070;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #c0c0c0;
  }

  span {
    color: #707070;
  }
`;

export const BoxImage = styled.a`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
`;

export const BoxTabs = styled.ul`
  margin-top: 50px;
  color: #707070;
  margin-left: 0px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  height: auto;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    gap: 20px;
    margin-top:40px;
  }
`;

interface IPropsBoxLine {
  active: boolean;
  isAdminLogged: boolean;
}

export const BoxLine = styled.a<IPropsBoxLine>`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }
  font-size: 18px;
  text-align: left;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
  /* border-left: ${(props) =>
    props.active ? "5px solid #169a00" : "5px solid transparent"}; */
  ${(props) =>
    !props.active
      ? `border-left: 5px solid transparent;`
      : props.isAdminLogged
      ? `border-left: 5px solid rgb(0,77,145);`
      : `border-left: 5px solid #169a00;`}
  padding-left: 27px;
  height: 50px;
  background-color: ${(props) => (props.active ? "#F7F7F7" : "transparent")};

  @media only screen and (max-width: 768px) {
    border-left: 0;
    /* width: calc(100vw - 50px); */
    border-radius: 15px;
    border: 1px solid #707070bd;
    background-color: #e9e9e9;
    padding-left: 17px;
    gap: 10px;
    position: relative;
    color: ${(props) => (props.isAdminLogged ? "#0077e1" : "#0d6300")};
  }
`;

export const CollumRight = styled.div`
  background-color: #fff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  padding: 0 43px;

  @media only screen and (max-width: 768px) {
    /* display: none; */
    padding: 0 20px;
  }
`;

export const Header = styled.div<IProps>`
  padding: 40px 0;
  font-size: 23px;

  h1 {
    text-align: left;
    font-weight: bold;
    color: ${(props) => (props.isAdminLogged ? "rgb(0,77,145)" : "#169a00")};
  }
`;

export const BoxTabItem = styled.div`
  display: flex;
  border-bottom: 1px solid #707070;
  width: 100%;
  gap: 23px;
  justify-content: flex-start;
`;

interface IPropsTabItem {
  active: boolean;
  isAdminLogged: boolean;
}

export const TabItem = styled.a<IPropsTabItem>`
  cursor: pointer;
  padding: 18px;
  ${(props) =>
    !props.active
      ? `border-bottom: 5px solid transparent;`
      : props.isAdminLogged
      ? `border-bottom: 5px solid rgb(0,77,145);`
      : `border-bottom: 5px solid #169a00;`}
  background-color: ${(props) => (props.active ? "#F7F7F7" : "transparent")};
`;

export const FormContent = styled.div`
  margin-top: 40px;
  width: 100%;
  height: auto;
  z-index: 0;
`;
export const ContainerModal = styled.div`
  background-color: white;
  padding: 54px;
  width: 500px;
  @media only screen and (max-width: 728px) {
    width: calc(100vw - 30px);
  }
`;
export const ContainerAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const DescriptionAlert = styled.div`
  font-size: 16px;
  color: #707070;
  margin-bottom: 15px;
`;
export const TitleAlert = styled.div`
  font-size: 20px;
  color: #707070;
  font-weight: 500;
`;
export const AvatarNoAuthorized = styled.div`
  font-size: 16px;
  background-color: orange;
  padding: 15px 15px;
  width: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  /* margin-bottom:13px; */
`;

export const ContainerAuthorizathion = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px;
  padding-bottom:0;
  gap: 15px;
`;
export const ButtonAuthorized = styled.div`
  display: flex;
  flex: 1;
  height: 35px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.55);
  }
`;
