import * as React from "react";
// import IconButton from '@mui/material/IconButton';
import IconButton from "../../Buttons/iconButton";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Container from "./styles";

const ITEM_HEIGHT = 48;

interface IProps {
  children: React.ReactNode;
}
export default function Option<IProps>(props) {
  const { children } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <IconButton active={open} onClick={handleClick}>
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto"
          }
        }}
      >
        {children}
      </Menu>
    </Container>
  );
}
