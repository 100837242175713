import React from "react";
import {
  Container,
  Icon,
  BoxMenu,
  BoxSubMenu,
  MenuItems,
  MenuTitle,
  SubMenu,
  ArrowIcon
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fIcon from "@fortawesome/free-solid-svg-icons";

interface IPropsRow {
  icon: any;
  text: string;
  href: string;
  isOpen: boolean;
  subMenus?: any;
  isActive?: boolean;
  onClick?: any;
}

const Row: React.FC<IPropsRow> = ({
  text,
  icon,
  href,
  isOpen,
  subMenus,
  isActive = false,
  onClick
}) => {
  const existSubmenu = subMenus && subMenus.length > 0;
  return (
    <Container
      isOpen={isOpen}
      href={href && href.length > 0 ? href : ""}
      onClick={onClick}
    >
      <BoxMenu style={existSubmenu && isActive ? { height: "auto" } : {}}>
        <Icon>
          <FontAwesomeIcon size="lg" color="white" icon={icon} />
        </Icon>
        {isOpen && (
          <MenuItems>
            <MenuTitle>
              {text}{" "}
              {existSubmenu && (
                <ArrowIcon>
                  <FontAwesomeIcon
                    size="sm"
                    color="white"
                    icon={isActive ? fIcon.faAngleUp : fIcon.faAngleDown}
                  />
                </ArrowIcon>
              )}
            </MenuTitle>
            {existSubmenu && isActive && (
              <BoxSubMenu>
                {subMenus.map((item, index) => item && (
                  <SubMenu href={item.href}>{item.label} </SubMenu>
                ))}
              </BoxSubMenu>
            )}
          </MenuItems>
        )}
      </BoxMenu>
    </Container>
  );
};

export default Row;
