import styled from 'styled-components'

export const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
`;
export const Text = styled.div`
    font-size: 30px;
    margin-top: 10px;
    color: #70707040;
    font-weight: lighter;
   @media only screen and (max-width: 728px) {
    font-size: 18px;
  }
`;