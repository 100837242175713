import styled from 'styled-components';

interface ITabRegister{
    active: boolean;
}
interface ITabAccess{
    active: boolean;
}
export const Container = styled.div`
    width: 700px;
    height:auto;
    background-color: white;
    @media only screen and (max-width: 728px) {
        width:100%;
    }
`;
export const Content = styled.div`
    width: 100%;
    height:auto;
    /* padding: 50px 40px 66px 40px ; */
    @media only screen and (max-width: 728px) {
        padding: 0 10px;
        min-width: calc(100vw - 20px);
    }
`;
export const TabBar = styled.div`
    width:100%;
    height:74px;
    background-color:#0077E1;
    display:flex;
    flex-direction: row;

    @media only screen and (max-width : 1366px) {
        margin-top: -30px;
    }
`;
export const TabRegister = styled.button<ITabRegister>`
    width:160px;
    height:58px;
    background-color:${(props)=> props.active?'#FFFFFF':'#0077E1'};
    color:${(props)=> !props.active?'#FFFFFF':'#0077E1'};
    align-self: flex-end;
    font-size:21px;

`;
export const Center = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex:1;
    color:#FFFFFF;
    font-weight: inherit;
`;
export const TabAccess = styled.button<ITabAccess>`
    width:auto;
    min-width: 160px;
    padding-left: 30px;
    padding-right: 30px;
    height:58px;
    background-color:${(props)=> props.active?'#FFFFFF':'#0077E1'};
    color:${(props)=> !props.active?'#FFFFFF':'#0077E1'};
    align-self: flex-end;
    font-size:21px;
`;
export const HeaderTitle = styled.h1`
    display:flex !important;
    width:100% !important;
    box-sizing: border-box !important;
    flex-direction: column !important;
    font-weight: bold !important;
    font-size:2.5em !important;
    padding: 1em 0 1em 0 !important;
    color: black !important;
    font-weight: bold !important;
    text-align: left !important;
    background-color:#0077E1;
    color:#FFFFFF;

    @media only screen and (min-width : 320px) and (max-width : 480px) {
        font-size:1.6em !important;
        padding-top:60px !important;
    }
    @media only screen and (min-width : 480px) and (max-width : 1366px) {
        margin: 0 0 1em 0 !important;
        font-size:1.7em !important;
    }
`;
export default Container;