import styled from 'styled-components'

interface IProps {
    mode: string
}

export const Container = styled.div<IProps>`
    
    ${(props) => props.mode === 'normal' && `
        display:flex;
        width:100%;
        background-color: rgb(247, 247, 247);
        border:1px solid rgba(180, 180, 180, 0.999);
        border-radius: 5px;
        flex-wrap: wrap;
        padding-top: 7px;
        padding-right: 30px; 

        @media only screen and (max-width: 768px) {
            border:1px solid rgba(180, 180, 180, 0.19);
            border-radius: 0px;
            margin: -15px 0 0 -15px;
            width: 100vw;
            padding-right: 20px;
        }
    `}

    ${(props) => props.mode === 'flat' && `
        display:flex;
        width: 100%;
        padding: 0;
        flex-wrap: wrap;
        background: #EFF2F4;
        gap:28px 40px;
        padding:40px;
        @media only screen and (max-width: 728px) {
            justify-content: center;
            padding:40px 15px;
        }
    `}
`;

export const sxButtonNormal = {
    width:'82px',
    height:'38px',
    border:'1px solid #707070',
    backgroundColor: '#F8F8F8',
    borderRadius: '5px',
    color:'#000000',
    textTransform: 'capitalize',
    fontSize:'16px'
}
export const sxButtonFlat = (isAdmin) => {
    return {
        backgroundColor: isAdmin ? "#0077e1" : "#169A00",
        color: "white",
        borderRadius: 0,
        textTransform: "capitalize",
        "&:hover": {
        backgroundColor: isAdmin ? "#004d91" : "#169A0075"
        }
    }
}