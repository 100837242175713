import styled from "styled-components";
interface IPropsContainer {
  visible: boolean;
}
export const Container = styled.div<IPropsContainer>`
  transition: 0.2s;
  grid-area: LEFTNAV;
  background-color: rgb(34, 34, 34);
  padding-bottom: 30px;
  overflow: auto;
  ${(props) => props.visible && `display:none;`}
  @media only screen and (max-width: 728px) {
    width: 280px;
    max-width: 95%;
    height: calc(100% - 55px);
    position: absolute;
    z-index: 9;
    margin-top: 55px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    opacity: 0.55;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 25px;
    background-color: #999;
    -webkit-border-radius: 3px;
  }
`;
export const Menu = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99999999999999;
`;
export const ContainerBg = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;

  @media only screen and (max-width: 728px) {
    height: calc(100vh - 55px);
  }
`;
