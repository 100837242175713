import React from 'react';
import {
    Container,
    Image,
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IPropsIcon{
    width: string;
    height: string;
    icon?:any;
    href?:string;
    size:any;
    color:string;
    bg?:boolean;
    onClick?:any;
    isAdminLogged?: boolean;
    bgImg?:string;
    bgHeight?:string;
    bgWidth?:string;
    marginLeft?: number;
    cStyle?:React.CSSProperties;
    iconStyle?:React.CSSProperties;
    imageStyle?:React.CSSProperties;
    cClassName?:string;
}
const ButtonIcon: React.FC<IPropsIcon> = ({cStyle = {}, iconStyle,cClassName, imageStyle, width, height, icon, href, size, color, bg, onClick, isAdminLogged, bgImg, bgHeight,bgWidth, marginLeft }) => {

    return (
        <Container  isAdminLogged={isAdminLogged} className={cClassName} onClick={onClick} style={cStyle} background={bg} width={width} href={href} height={height} marginLeft={marginLeft}>
            { icon && <FontAwesomeIcon size={size} style={iconStyle} color={color} icon={icon}/>   }
            { bgImg && <Image bgWidth={bgWidth} style={imageStyle} bgHeight={bgHeight}  bgImg={bgImg} />}
        </Container>
    );
}

export default ButtonIcon;