import styled from 'styled-components'

interface IProps {
}

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
`;
export const ContainerList = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    overflow: auto;
    height:auto;
    margin-top: 55px;
    margin-bottom:30px;

    @media only screen and (max-width: 728px){
        margin-top: 0px;
    }
`;

export const ListContent = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
`;


export const NothingFound = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:auto;
    color: #c0c0c0;
    padding: 20px 0;
    font-weight: bold;
    font-size: 25px;
`;