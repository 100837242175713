import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icon from '@fortawesome/free-solid-svg-icons';
import {
    Container,
    Text,
} from './styles'
interface IProps {
    iconStyle?: React.CSSProperties
    cStyle?: React.CSSProperties
    txtStyle?: React.CSSProperties
}
const MyDocuments: React.FC<IProps> = (props) => {
    const { iconStyle, cStyle = {}, txtStyle } = props;

    return (
        <Container style={cStyle}>
            <FontAwesomeIcon color="rgba(112, 112, 112, 0.25)" size="3x" style={iconStyle} icon={icon.faClock} />
            <Text style={txtStyle}>Esta área estará disponível em breve...</Text>
        </Container>
    );
}
export default MyDocuments;