import { Reducer } from "redux";
import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { CurrentUserDisplayed, User, UserCompetitorBody } from "store/types";
import { RootState } from "store/ducks/state";
import createReducer from "util/createReducer";
import { handleGetUserData } from "components/Forms/RegistrationForm/functions";
import { handleDeficiency } from "components/Forms/RegistrationForm/functions";
import { IImage, School, City, Responsible, Deficiency } from "../types";
import {
  log,
  onlyNumber,
  loginSuggestion,
  randomInt,
  dateToUSA,
  handleReduxError
} from "util/index";
import { checkTokeExpred } from "./alert";
import { isEmpty, isValidDate } from "util/index";

export enum UserTypes {
  ChangeCurrentUserDisplayed = "@user/ChangeCurrentUserDisplayed",

  ValidateImageStart = "@user/ValidateImageStart",
  ValidateImageSuccess = "@user/ValidateImageSuccess",
  ValidateImageFailure = "@user/ValidateImageFailure",

  AutoCompleteStart = "@user/AutoCompleteStart",
  AutoCompleteSuccess = "@user/AutoCompleteSuccess",
  AutoCompleteFailure = "@user/AutoCompleteFailure",

  CreateStart = "@user/CreateStart",
  CreateSuccess = "@user/CreateSuccess",
  CreateFailure = "@user/CreateFailure",
  GetStart = "@user/GetStart",
  GetSuccess = "@user/GetSuccess",
  GetFailure = "@user/GetFailure",
  ForgetPasswordStart = "@user/ForgetPasswordStart",
  ForgetPasswordSuccess = "@user/ForgetPasswordSuccess",
  ForgetPasswordFailure = "@user/ForgetPasswordFailure",
  ChangeEmailStart = "@user/ChangeEmailStart",
  ChangeEmailSuccess = "@user/ChangeEmailSuccess",
  ChangeEmailFailure = "@user/ChangeEmailFailure",
  ChangePasswordStart = "@user/ChangePasswordStart",
  ChangePasswordSuccess = "@user/ChangePasswordSuccess",
  ChangePasswordFailure = "@user/ChangePasswordFailure",
  ChangePersonalDataStart = "@user/ChangePersonalDataStart",
  ChangePersonalDataSuccess = "@user/ChangePersonalDataSuccess",
  ChangePersonalDataFailure = "@user/ChangePersonalDataFailure",
  CleanState = "@user/CleanState",
  GetMyUserStart = "@user/GetMyUserStart",
  GetMyUserSuccess = "@user/GetMyuserSuccess",
  GetMyUserFailure = "@user/GetMyUserFailure",
  ChangeAvatarStart = "@user/ChangeAvatarStart",
  ChangeAvatarSuccess = "@user/ChangeAvatarSuccess",
  ChangeAvatarFailure = "@user/ChangeAvatarFailure",
  ResetError = "@user/ResetError",

  SearchUsersStart = "@user/SearchUsersStart",
  SearchUsersSuccess = "@user/CSearchUsersSuccess",
  SearchUsersFailure = "@user/SearchUsersFailure",

  SearchUsersOrganizersStart = "@user/SearchUsersOrganizersStart",
  SearchUsersOrganizersSuccess = "@user/SearchUsersOrganizersSuccess",
  SearchUsersOrganizersFailure = "@user/SearchUsersOrganizersFailure",

  ActivateUserStart = "@user/ActivateUserStart",
  ActivateUserSuccess = "@user/ActivateUserSuccess",
  ActivateUserFailure = "@user/ActivateUserFailure"
}

export type Actions = {
  ValidateImageStart: { type: UserTypes.ValidateImageStart };
  ValidateImageSuccess: { type: UserTypes.ValidateImageSuccess; payload: User };
  ValidateImageFailure: { type: UserTypes.ValidateImageFailure; payload: any };

  CreateStart: { type: UserTypes.CreateStart };
  CreateSuccess: { type: UserTypes.CreateSuccess; payload: User };
  CreateFailure: { type: UserTypes.CreateFailure; payload: any };

  AutoCompleteStart: { type: UserTypes.AutoCompleteStart };
  AutoCompleteSuccess: { type: UserTypes.AutoCompleteSuccess; payload: User };
  AutoCompleteFailure: { type: UserTypes.AutoCompleteFailure; payload: any };

  GetStart: { type: UserTypes.GetStart };
  GetSuccess: { type: UserTypes.GetSuccess; payload: CurrentUserDisplayed };
  ChangeCurrentUserDisplayed: {
    type: UserTypes.ChangeCurrentUserDisplayed;
    payload: CurrentUserDisplayed;
  };
  GetFailure: { type: UserTypes.GetFailure; payload: any };
  ForgetPasswordStart: { type: UserTypes.ForgetPasswordStart };
  ForgetPasswordSuccess: {
    type: UserTypes.ForgetPasswordSuccess;
    payload: any;
  };
  ForgetPasswordFailure: {
    type: UserTypes.ForgetPasswordFailure;
    payload: any;
  };
  ChangeEmailStart: { type: UserTypes.ChangeEmailStart };
  ChangeEmailSuccess: { type: UserTypes.ChangeEmailSuccess };
  ChangeEmailFailure: { type: UserTypes.ChangeEmailFailure; payload: any };
  ChangePasswordStart: { type: UserTypes.ChangePasswordStart };
  ChangePasswordSuccess: { type: UserTypes.ChangePasswordSuccess };
  ChangePasswordFailure: {
    type: UserTypes.ChangePasswordFailure;
    payload: any;
  };
  ChangePersonalDataStart: { type: UserTypes.ChangePersonalDataStart };
  ChangePersonalDataSuccess: { type: UserTypes.ChangePersonalDataSuccess };
  ChangePersonalDataFailure: {
    type: UserTypes.ChangePersonalDataFailure;
    payload: any;
  };
  CleanState: { type: UserTypes.CleanState };
  GetMyUserStart: { type: UserTypes.GetMyUserStart };
  GetMyUserSuccess: { type: UserTypes.GetMyUserSuccess; payload: any };
  GetMyUserFailure: { type: UserTypes.GetMyUserFailure; payload: any };
  ChangeAvatarStart: { type: UserTypes.ChangeAvatarStart };
  ChangeAvatarSuccess: { type: UserTypes.ChangeAvatarSuccess };
  ChangeAvatarFailure: { type: UserTypes.ChangeAvatarFailure; payload: any };
  ResetError: { type: UserTypes.ResetError; payload: any };

  SearchUsersStart: {
    type: UserTypes.SearchUsersStart;
    payload: any;
  };
  SearchUsersSuccess: {
    type: UserTypes.SearchUsersSuccess;
    payload: any;
  };
  SearchUsersFailure: {
    type: UserTypes.SearchUsersFailure;
    payload: any;
  };

  SearchUsersOrganizersStart: {
    type: UserTypes.SearchUsersOrganizersStart;
    payload: any;
  };
  SearchUsersOrganizersSuccess: {
    type: UserTypes.SearchUsersOrganizersSuccess;
    payload: any;
  };
  SearchUsersOrganizersFailure: {
    type: UserTypes.SearchUsersOrganizersFailure;
    payload: any;
  };

  ActivateUserStart: { type: UserTypes.ActivateUserStart; payload: any };
  ActivateUserSuccess: { type: UserTypes.ActivateUserSuccess; payload: any };
  ActivateUserFailure: { type: UserTypes.ActivateUserFailure; payload: any };
};

export interface LoadingSection {
  "loading.search": boolean;
  "loading.create": boolean;
  "loading.activate": boolean;
  "loading.getUser": boolean;
  "loading.forgetPassword": boolean;
  "loading.changeEmail": boolean;
  "loading.changePassword": boolean;
  "loading.changePersonalData": boolean;
  "loading.getMyUser": boolean;
  "loading.changeAvatar": boolean;
  "loading.validateImage": boolean;
}

export interface PessoaFisica {
  cpf: string;
  dataNascimento: number;
  genero: string;
  idPessoa: number;
  nome: string;
  nomeSocial: string;
  orgaoExpedidor: any;
  rg: string;
}

export interface MyUserBody {
  email: string;
  idUsuario: number;
  imagem: string;
  nomeUsuario: string;
  primeiroNome: string;
  pessoaFisica: PessoaFisica;
}

export interface SearchItem {
  id: number;
  date: any;
  nome: any;
  unidade: any;
  status: any;
  buttons: any;
  ativo: boolean;
  temporada: any;
  de: any;
  ate: any;
}
export interface UserState {
  data: {
    userCreated: User;
    currentUserDisplayed: CurrentUserDisplayed;
    forgetPassword: any;
    myUser: MyUserBody;
  };
  search: {
    list: SearchItem[];
    headers: ISearchHeaders;
  };
  loading: LoadingSection;
  error: any;
  activateError: any;
  activateMsg: any;
  validateImateError: any;
  alidateImageMsg: any;
}

export interface ISearchHeaders {
  currentpage?: string;
  lastpage?: string;
  total?: string;
}

export const InitialState: UserState = {
  data: {
    userCreated: {} as User,
    currentUserDisplayed: null as CurrentUserDisplayed,
    forgetPassword: {} as any,
    myUser: {
      pessoaFisica: {
        nome: "",
        nomeSocial: ""
      } as PessoaFisica
    } as MyUserBody
  },
  search: {
    list: [] as SearchItem[],
    headers: null as ISearchHeaders
  },
  loading: {
    "loading.search": false,
    "loading.create": false,
    "loading.activate": false,
    "loading.getUser": false,
    "loading.forgetPassword": false,
    "loading.changeEmail": false,
    "loading.changePassword": false,
    "loading.changePersonalData": false,
    "loading.getMyUser": false,
    "loading.changeAvatar": false,
    "loading.validateImage": false
  },
  error: undefined,
  activateError: undefined,
  activateMsg: undefined,
  validateImateError: undefined,
  alidateImageMsg: undefined
};

export const userReducer: Reducer<UserState> = createReducer(InitialState, {
  [UserTypes.CreateStart](state: UserState) {
    state.error = undefined;
    state.loading["loading.create"] = true;
    return state;
  },
  [UserTypes.CreateSuccess](
    state: UserState,
    action: Actions["CreateSuccess"]
  ) {
    state.loading["loading.create"] = false;
    state.data.userCreated = action.payload;
    return state;
  },
  [UserTypes.CreateFailure](
    state: UserState,
    action: Actions["CreateFailure"]
  ) {
    state.loading["loading.create"] = false;
    state.error = action.payload;
    return state;
  },

  [UserTypes.ActivateUserStart](state: UserState) {
    state.loading["loading.activate"] = true;
    return state;
  },
  [UserTypes.ActivateUserSuccess](
    state: UserState,
    action: Actions["ActivateUserSuccess"]
  ) {
    state.loading["loading.activate"] = false;
    state.activateMsg = action.payload;
    return state;
  },
  [UserTypes.ActivateUserFailure](
    state: UserState,
    action: Actions["ActivateUserFailure"]
  ) {
    state.loading["loading.activate"] = false;
    state.activateError = action.payload;
    return state;
  },

  [UserTypes.SearchUsersStart](state: UserState) {
    state.loading["loading.search"] = true;
    return state;
  },
  [UserTypes.SearchUsersSuccess](
    state: UserState,
    action: Actions["SearchUsersSuccess"]
  ) {
    state.loading["loading.search"] = false;

    state.search = {
      headers: action.payload.headers,
      list: action.payload.list.map((item, index) => {
        const date = new Date(item.data_criacao);
        let unity = String(item.nome_escola || "");
        unity +=
          (unity.length > 0 ? " — " : "") +
          String(
            item.nome_nte && item.nome_nte.length > 0 ? `${item.nome_nte}` : ""
          );
        return {
          id: Number(item.id_pessoa),
          date: `${date.getDate() < 10 ? "0" : ""}${date.getDate()} /${
            date.getMonth() + 1 < 10 ? "0" : ""
          }${date.getMonth() + 1} /${date.getFullYear()}`,
          nome: String(item.nome),
          unidade: unity && unity.length > 0 ? unity : "***",
          status: String(item.ativo === "1" ? "Ativo" : "Inativo"),
          buttons: null,
          ativo: item.ativo === "1"
        } as SearchItem;
      })
    };
    return state;
  },
  [UserTypes.SearchUsersFailure](
    state: UserState,
    action: Actions["SearchUsersFailure"]
  ) {
    state.loading["loading.search"] = false;
    state.error = action.payload;
    return state;
  },

  [UserTypes.GetStart](state: UserState) {
    state.loading["loading.getUser"] = true;
    return state;
  },
  [UserTypes.GetSuccess](state: UserState, action: Actions["GetSuccess"]) {
    state.loading["loading.getUser"] = false;
    state.data.currentUserDisplayed = action.payload as CurrentUserDisplayed;
    if (!state.data.currentUserDisplayed.imagem)
      state.data.currentUserDisplayed.imagem = {
        arquivo: null,
        aprovado: false,
        id_imagem: null,
        id_organizador: null
      } as IImage;
    return state;
  },
  [UserTypes.GetFailure](state: UserState, action: Actions["GetFailure"]) {
    state.loading["loading.getUser"] = false;
    state.error = action.payload;
    return state;
  },

  [UserTypes.ChangeCurrentUserDisplayed](
    state: UserState,
    action: Actions["ChangeCurrentUserDisplayed"]
  ) {
    state.data.currentUserDisplayed = action.payload as CurrentUserDisplayed;
    return state;
  },

  [UserTypes.ForgetPasswordStart](state: UserState) {
    state.loading["loading.forgetPassword"] = true;
    return state;
  },
  [UserTypes.ForgetPasswordSuccess](
    state: UserState,
    action: Actions["ForgetPasswordSuccess"]
  ) {
    state.loading["loading.forgetPassword"] = false;
    state.data.forgetPassword = action.payload;
    return state;
  },
  [UserTypes.ForgetPasswordFailure](
    state: UserState,
    action: Actions["ForgetPasswordFailure"]
  ) {
    state.loading["loading.forgetPassword"] = false;
    state.error = action.payload;
    return state;
  },
  [UserTypes.ChangeEmailStart](state: UserState) {
    state.loading["loading.changeEmail"] = true;
    return state;
  },
  [UserTypes.ChangeEmailSuccess](state: UserState) {
    state.loading["loading.changeEmail"] = false;
    return state;
  },
  [UserTypes.ChangeEmailFailure](
    state: UserState,
    action: Actions["ChangeEmailFailure"]
  ) {
    state.loading["loading.changeEmail"] = false;
    state.error = action.payload;
    return state;
  },
  [UserTypes.ChangePasswordStart](state: UserState) {
    state.loading["loading.changePassword"] = true;
    return state;
  },
  [UserTypes.ChangePasswordSuccess](state: UserState) {
    state.loading["loading.changePassword"] = false;
    return state;
  },
  [UserTypes.ChangePasswordFailure](
    state: UserState,
    action: Actions["ChangePasswordFailure"]
  ) {
    state.loading["loading.changePassword"] = false;
    state.error = action.payload;
    return state;
  },
  [UserTypes.ChangePersonalDataStart](state: UserState) {
    state.loading["loading.changePersonalData"] = true;
    return state;
  },
  [UserTypes.ChangePersonalDataSuccess](state: UserState) {
    state.loading["loading.changePersonalData"] = false;
    return state;
  },
  [UserTypes.ChangePersonalDataFailure](
    state: UserState,
    action: Actions["ChangePersonalDataFailure"]
  ) {
    state.loading["loading.changePersonalData"] = false;
    state.error = action.payload;
    return state;
  },
  [UserTypes.CleanState](state: UserState) {
    state = InitialState;
    return state;
  },
  [UserTypes.GetMyUserStart](state: UserState) {
    state.loading["loading.getMyUser"] = true;
    return state;
  },
  [UserTypes.GetMyUserSuccess](
    state: UserState,
    action: Actions["GetMyUserSuccess"]
  ) {
    state.loading["loading.getMyUser"] = false;
    state.data.myUser = action.payload;
    return state;
  },
  [UserTypes.GetMyUserFailure](
    state: UserState,
    action: Actions["GetMyUserFailure"]
  ) {
    state.loading["loading.getMyUser"] = false;
    state.error = action.payload;
    return state;
  },
  [UserTypes.ValidateImageStart](
    state: UserState,
    action: Actions["ValidateImageStart"]
  ) {
    state.loading["loading.validateImage"] = true;
    return state;
  },
  [UserTypes.ValidateImageSuccess](
    state: UserState,
    action: Actions["ValidateImageSuccess"]
  ) {
    state.loading["loading.validateImage"] = false;
    state.alidateImageMsg = action.payload;
    return state;
  },
  [UserTypes.ValidateImageFailure](
    state: UserState,
    action: Actions["ValidateImageFailure"]
  ) {
    state.loading["loading.validateImage"] = false;
    state.validateImateError = action.payload;
    return state;
  },
  [UserTypes.ChangeAvatarStart](state: UserState) {
    state.loading["loading.changeAvatar"] = true;
    return state;
  },
  [UserTypes.ChangeAvatarSuccess](state: UserState) {
    state.loading["loading.changeAvatar"] = false;
    return state;
  },
  [UserTypes.ChangeAvatarFailure](
    state: UserState,
    action: Actions["ChangeAvatarFailure"]
  ) {
    state.loading["loading.changeAvatar"] = false;
    state.error = action.payload;
    return state;
  },
  [UserTypes.ResetError](state: UserState, action: Actions["ResetError"]) {
    state.error = action.payload;
    return state;
  }
});

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function validateImage(
  id: number,
  value: boolean
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.ValidateImageStart
    } as Actions["ValidateImageStart"]);

    return new Promise((resolve, reject) => {
      api
        .patch(
          `/imagens/aprovar/${id}`,
          {
            aprovado: value === true ? 1 : 0
          },
          { headers: { Authorization: `Bearer ${getState().auth.data.token}` } }
        )
        .then((response: any) => {
          dispatch({
            type: UserTypes.ValidateImageSuccess,
            payload: response.data?.msg
          } as Actions["ValidateImageSuccess"]);
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          dispatch({
            type: UserTypes.ValidateImageFailure,
            payload: errors
          } as Actions["ValidateImageFailure"]);
          reject(errors);
        });
    });
  };
}

export function handleGetMyUser():
  | any
  | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.GetMyUserStart
    } as Actions["GetMyUserStart"]);
    const user = getState().auth.userLogged;
    const idUser = (user && user.id) || "";
    console.log(idUser);
    return new Promise((resolve, reject) => {
      dispatch({
        type: UserTypes.GetMyUserFailure,
        payload: null
      } as Actions["GetMyUserFailure"]);

      reject(null);

      // api
      //   .get(`/usuarios/${idUser}`, {
      //     headers: {
      //       Authorization: `Bearer ${getState().auth.data.token}`,
      //     },
      //   })
      //   .then((response: any) => {
      //     console.tron.log(response);
      //     dispatch({
      //       type: UserTypes.GetMyUserSuccess,
      //       payload: response.data,
      //     } as Actions['GetMyUserSuccess']);
      //     resolve(response.data);
      //   })
      //   .catch((err: any) => {
      //     console.tron.log(err);
      //     dispatch({
      //       type: UserTypes.GetMyUserFailure,
      //       payload: err.response && err.response.data ? err.response.data : err,
      //     } as Actions['GetMyUserFailure']);

      //     reject(err.response && err.response.data ? err.response.data : err);
      //   });
    });
  };
}

export function handleChangeCurrentUserDisplayed(
  dispatch: any,
  data: CurrentUserDisplayed
) {
  dispatch({
    type: UserTypes.ChangeCurrentUserDisplayed,
    payload: data as CurrentUserDisplayed
  } as Actions["ChangeCurrentUserDisplayed"]);
}

export function handleChangeCurrentUserDisplayedWithDispatch(
  data: CurrentUserDisplayed
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return (dispatch, getState): any => {
    dispatch({
      type: UserTypes.ChangeCurrentUserDisplayed,
      payload: data as CurrentUserDisplayed
    } as Actions["ChangeCurrentUserDisplayed"]);
  };
}

export function getUserData(
  id: number,
  editMode: boolean,
  isAdminRegistration: boolean
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({ type: UserTypes.GetStart } as Actions["GetStart"]);

    return new Promise((resolve, reject) => {
      api
        .get(`/pessoas/${id}`, {
          headers: { Authorization: `Bearer ${getState().auth.data.token}` }
        })
        .then((response: any) => {
          const { data } = response;
          const newData = handleGetUserData(
            data,
            editMode,
            isAdminRegistration,
            true,
            dispatch
          );

          dispatch({
            type: UserTypes.GetSuccess,
            payload: newData as CurrentUserDisplayed
          } as Actions["GetSuccess"]);
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          dispatch({
            type: UserTypes.GetFailure,
            payload: errors
          } as Actions["GetFailure"]);

          reject(errors);
        });
    });
  };
}

export function handleUpdateProfileId(
  personId: number,
  profileId: number
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({ type: UserTypes.CreateStart } as Actions["CreateStart"]);

    return new Promise((resolve, reject) => {
      api
        .put(
          `/perfis/pessoa/${personId}`,
          { id_perfil_usuario: profileId },
          { headers: { Authorization: `Bearer ${getState().auth.data.token}` } }
        )
        .then((response: any) => {
          const { data } = response;
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          reject(errors);
        });
    });
  };
}

export function handleAutocompleteUser(
  isAdmin: boolean = false,
  cpf: string,
  nativityDate: string
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    console.log("handleAutocompleteUser ===>>>> ");
    dispatch({
      type: UserTypes.AutoCompleteStart
    } as Actions["AutoCompleteStart"]);

    return new Promise((resolve, reject) => {
      api
        .get(
          (isAdmin ? `/autocompletar/servidores` : `/autocompletar/alunos`) +
            `?cpf=${onlyNumber(cpf)}&dataNascimento=${nativityDate}`,
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => {
          console.log("handleAutocompleteUser.response", response);
          const { data } = response;

          dispatch({
            type: UserTypes.AutoCompleteSuccess,
            payload: data
          } as Actions["AutoCompleteSuccess"]);
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          console.log("handleAutocompleteUser.err.response", err.response);
          dispatch({
            type: UserTypes.AutoCompleteFailure,
            payload: errors
          } as Actions["AutoCompleteFailure"]);

          reject(errors);
        });
    });
  };
}

export function handleCreateUser(
  isAdminRegistration: boolean
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    console.log("handleCreateUser");
    dispatch({ type: UserTypes.CreateStart } as Actions["CreateStart"]);
    const dataToSave = handlePayloadUser(
      getState().user.data.currentUserDisplayed,
      isAdminRegistration,
      false
    );
    const userBody: any = { ...dataToSave };
    console.log("handleCreateUser.userData:", dataToSave);

    return new Promise((resolve, reject) => {
      api
        .post(
          `/users`,
          { ...userBody },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => {
          const { data } = response;

          dispatch({
            type: UserTypes.CreateSuccess,
            payload: data
          } as Actions["CreateSuccess"]);
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          dispatch({
            type: UserTypes.CreateFailure,
            payload: errors
          } as Actions["CreateFailure"]);

          reject(errors);
        });
    });
  };
}

export function handleUpdateUser(
  id: number,
  isAdminRegistration: boolean
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({ type: UserTypes.CreateStart } as Actions["CreateStart"]);
    const dataToSave = handlePayloadUser(
      getState().user.data.currentUserDisplayed,
      isAdminRegistration,
      true,
      dispatch
    );
    const userBody: any = { ...dataToSave };
    console.log("handleUpdateUser.userData:", dataToSave);

    return new Promise((resolve, reject) => {
      api
        .put(
          `/pessoas/${id}`,
          { ...userBody },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => {
          const { data } = response;

          dispatch({
            type: UserTypes.CreateSuccess,
            payload: data
          } as Actions["CreateSuccess"]);
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          dispatch({
            type: UserTypes.CreateFailure,
            payload: errors
          } as Actions["CreateFailure"]);

          reject(errors);
        });
    });
  };
}

export function handleSearchUsers(
  type: string,
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.SearchUsersStart
    } as Actions["SearchUsersStart"]);
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      api
        .get(`/pessoas/filter?type=${type}${filters}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response: any) => {
          const { data } = response;
          const payload = {
            list: data,
            headers: response?.headers
          };
          dispatch({
            type: UserTypes.SearchUsersSuccess,
            payload: payload
          } as Actions["SearchUsersSuccess"]);
          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          const errorMsg = errors;
          dispatch({
            type: UserTypes.SearchUsersFailure,
            payload: errorMsg
          } as Actions["SearchUsersFailure"]);

          checkTokeExpred(dispatch, errors);
          reject(errorMsg);
        });
    });
  };
}

// export function handleSearchUsersOrganizers(
//   filters: any = ""
// ): any | ThunkAction<Promise<any>, RootState, any, any> {
//   return async (dispatch, getState): Promise<any> => {
//     dispatch({
//       type: UserTypes.SearchUsersOrganizersStart
//     } as Actions["SearchUsersOrganizersStart"]);
//     let token = `${getState().auth.data.token}`;
//     while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

//     return new Promise((resolve, reject) => {
//       api
//         .get(`/pessoas/filter?type=organizador${filters}`, {
//           headers: { Authorization: `Bearer ${token}` }
//         })
//         .then((response: any) => {
//           const { data } = response;
//           // dispatch({
//           //   type: UserTypes.SearchUsersSuccess,
//           //   payload: data,
//           // } as Actions['SearchUsersSuccess']);

//           resolve(response.data);
//         })
//         .catch((err: any) => {
//           // dispatch({
//           //   type: UserTypes.SearchUsersFailure,
//           //   payload: err.response && err.response.data ? err.response.data : err,
//           // } as Actions['SearchUsersFailure']);

//           reject(err.response && err.response.data ? err.response.data : err);
//         });
//     });
//   };
// }

export function activateUser(
  urlToken: string = "",
  personID: number = null
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.ActivateUserStart
    } as Actions["ActivateUserStart"]);
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      if (personID && personID > 0)
        api
          .patch(
            `/pessoas/ativar/${personID}`,
            {
              ativo: true
            },
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response: any) => {
            console.log("activateUser.err:", response);
            const { data } = response;
            dispatch({
              type: UserTypes.ActivateUserSuccess,
              payload: data && data.msg ? data.msg : undefined
            } as Actions["ActivateUserSuccess"]);

            resolve(response.data);
          })
          .catch((err: any) => {
            const errors = handleReduxError(err);
            console.log("activateUser.err:", err);
            dispatch({
              type: UserTypes.ActivateUserFailure,
              payload: errors
            } as Actions["ActivateUserFailure"]);

            reject(errors);
          });
      else
        api
          .get(`/users/activate/${urlToken}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((response: any) => {
            const { data } = response;
            dispatch({
              type: UserTypes.ActivateUserSuccess,
              payload: data && data.msg ? data.msg : undefined
            } as Actions["ActivateUserSuccess"]);

            resolve(response.data);
          })
          .catch((err: any) => {
            const errors = handleReduxError(err);
            dispatch({
              type: UserTypes.ActivateUserFailure,
              payload: errors
            } as Actions["ActivateUserFailure"]);

            reject(errors);
          });
    });
  };
}

export function deleteAccount(
  password: string = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      api
        .delete(`/users/delete-me`, {
          headers: {
            Authorization: `Bearer ${getState().auth.data.token}`
          },
          data: {
            senha: password
          }
        })
        .then((response: any) => resolve(response.data))
        .catch((err: any) => reject(handleReduxError(err)));
    });
  };
}

export function recoverPassword(
  email: string = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.ActivateUserStart
    } as Actions["ActivateUserStart"]);
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      api
        .post(
          `/auth/request-pass-recover`,
          { usuario: email },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => {
          const { data } = response;
          // dispatch({
          //   type: UserTypes.SearchUsersSuccess,
          //   payload: data,
          // } as Actions['SearchUsersSuccess']);

          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          // dispatch({
          //   type: UserTypes.SearchUsersFailure,
          //   payload: err.response && err.response.data ? err.response.data : err,
          // } as Actions['SearchUsersFailure']);

          reject(errors);
        });
    });
  };
}

export function resendActivationLink(
  email: string = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.ActivateUserStart
    } as Actions["ActivateUserStart"]);
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      api
        .post(
          `/users/resend-email-activation`,
          { email: email },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => {
          const { data } = response;
          // dispatch({
          //   type: UserTypes.SearchUsersSuccess,
          //   payload: data,
          // } as Actions['SearchUsersSuccess']);

          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          // dispatch({
          //   type: UserTypes.SearchUsersFailure,
          //   payload: err.response && err.response.data ? err.response.data : err,
          // } as Actions['SearchUsersFailure']);

          reject(errors);
        });
    });
  };
}

export function redefinePassword(
  tokenUser: string = "",
  password: string = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.ActivateUserStart
    } as Actions["ActivateUserStart"]);
    let token = `${getState().auth.data.token}`;
    while (token.indexOf("Bearer ") > -1) token = token.replace("Bearer ", "");

    return new Promise((resolve, reject) => {
      api
        .post(
          `/auth/set-pass-recover`,
          { token_rec_senha: tokenUser, nova_senha: password },
          {
            headers: { Authorization: `Bearer ${getState().auth.data.token}` }
          }
        )
        .then((response: any) => {
          const { data } = response;
          // dispatch({
          //   type: UserTypes.SearchUsersSuccess,
          //   payload: data,
          // } as Actions['SearchUsersSuccess']);

          resolve(response.data);
        })
        .catch((err: any) => {
          const errors = handleReduxError(err);
          // dispatch({
          //   type: UserTypes.SearchUsersFailure,
          //   payload: err.response && err.response.data ? err.response.data : err,
          // } as Actions['SearchUsersFailure']);

          reject(errors);
        });
    });
  };
}

const getProfileIDReadModeToSave = (
  formDataToSave: any,
  isAdminRegistration,
  editMode = false
) => {
  if (!isAdminRegistration) return 2;
  else {
    if (!editMode) return null;
    else return Number(formDataToSave.id_perfil_solicitado);
  }
};

export const handlePayloadUser = (
  formDataToSave: any,
  isAdminRegistration,
  editMode = false,
  dispatch = null
): any => {
  console.log("handlePayloadUser.formDataToSave1:", formDataToSave);
  try {
    const id_perfil_usuario = getProfileIDReadModeToSave(
      formDataToSave,
      isAdminRegistration,
      editMode
    );
    if (
      dispatch !== null &&
      editMode &&
      id_perfil_usuario !== Number(formDataToSave?.id_perfil_usuario)
    )
      handleChangeCurrentUserDisplayed(dispatch, {
        ...formDataToSave,
        id_perfil_usuario: id_perfil_usuario
      } as CurrentUserDisplayed);

    let pessoa = {
      nome: formDataToSave.nome,
      cpf: onlyNumber(formDataToSave.cpf),
      rg: onlyNumber(formDataToSave.rg),
      telefone: formDataToSave.telefone,
      data_nascimento: !isValidDate(formDataToSave?.dataNascimento)
        ? null
        : dateToUSA(new Date(formDataToSave.dataNascimento)),
      id_sexo: Number(formDataToSave.sexo),
      deficiencias: handleDeficiency(formDataToSave.deficiencia)
    };

    const nativityDate =
      formDataToSave.dataNascimento &&
      formDataToSave.dataNascimento.length >= 10
        ? new Date(formDataToSave.dataNascimento)
        : new Date();
    const nickname =
      loginSuggestion(formDataToSave.nome) +
      (nativityDate.getMonth() + 1 < 10 ? "0" : "") +
      (nativityDate.getMonth() + 1) +
      nativityDate.getFullYear() +
      nativityDate.getDate() +
      new Date().getHours() +
      new Date().getMinutes() +
      new Date().getSeconds() +
      onlyNumber(formDataToSave.cpf).substr(7) +
      onlyNumber(formDataToSave.cpf).substr(0, 3) +
      randomInt(99, 999);

    formDataToSave = {
      usuario: {
        nickname: nickname,
        email: formDataToSave.email,
        senha: formDataToSave.senha
      },
      pessoa:
        !formDataToSave.nis || !formDataToSave.nis.length
          ? pessoa
          : {
              ...pessoa,
              nis: formDataToSave.nis
            },
      imagem:
        formDataToSave && formDataToSave.imagem && formDataToSave.imagem.arquivo
          ? formDataToSave.imagem
          : null,
      escola: {
        id_escola:
          formDataToSave.unidade &&
          formDataToSave.unidade.id_escola &&
          formDataToSave.unidade.id_escola > 0
            ? formDataToSave?.unidade?.id_escola
            : null
      },
      organizador: isAdminRegistration
        ? {
            matricula: formDataToSave.matricula,
            id_nte: formDataToSave.id_nte
              ? Number(formDataToSave.id_nte)
              : formDataToSave.organizador &&
                formDataToSave.organizador.length > 0 &&
                formDataToSave.organizador.id_nte
              ? Number(formDataToSave.organizador.id_nte)
              : null,
            id_cargo: 1,
            id_perfil_solicitado: formDataToSave.id_perfil_solicitado
              ? Number(formDataToSave.id_perfil_solicitado)
              : null
          }
        : null,
      competidor: !isAdminRegistration
        ? {
            matricula: formDataToSave.matricula
          }
        : null,
      responsaveis: !isAdminRegistration ? formDataToSave.responsaveis : null
    };

    if (id_perfil_usuario && id_perfil_usuario > 0)
      formDataToSave = {
        ...formDataToSave,
        usuario: {
          ...formDataToSave.usuario,
          id_perfil_usuario: id_perfil_usuario
        },
        pessoa: {
          ...formDataToSave.pessoa,
          id_perfil_usuario: id_perfil_usuario
        }
      };
  } catch (err) {
    console.log("handlePayloadUser.err:", err);
  }
  console.log("handlePayloadUser.formDataToSave2:", formDataToSave);
  return formDataToSave;
};
