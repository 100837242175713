import React from "react";
import { isEmpty, lineBreaks } from "../../../util";
import {
  Container,
  ErrorMessage,
  ErrorMessageTitle,
  SuccessMessage,
  SuccessMessageTitle,
  InfoMessageTitle,
  InfoMessage
} from "./styles";

const BoxFlatAlert = ({
  errorMessage = null,
  successMessage = null,
  infoMessage = null,
  small = false,
  hideTitle = false,
  width = "100%",
  cStyle = {}
}) => {
  return !errorMessage && !successMessage && !infoMessage ? (
    <></>
  ) : (
    <Container width={width} style={cStyle}>
      {!isEmpty(errorMessage) && (
        <ErrorMessage small={small}>
          {!hideTitle && (
            <ErrorMessageTitle small={small}>ERRO!</ErrorMessageTitle>
          )}
          {lineBreaks(errorMessage, {
            color: "red",
            fontWeight: "bold",
            lineHeight: "25px"
          })}
        </ErrorMessage>
      )}
      {!isEmpty(successMessage) && (
        <SuccessMessage small={small}>
          {!hideTitle && (
            <SuccessMessageTitle small={small}>SUCESSO!</SuccessMessageTitle>
          )}
          {lineBreaks(successMessage, {
            color: "green",
            fontWeight: "bold",
            lineHeight: "25px"
          })}
        </SuccessMessage>
      )}
      {!isEmpty(infoMessage) && (
        <InfoMessage small={small}>
          {!hideTitle && (
            <InfoMessageTitle small={small}>INSTRUÇÕES!</InfoMessageTitle>
          )}
          {lineBreaks(infoMessage, {
            color: "#31313138",
            fontWeight: "bold",
            lineHeight: "25px"
          })}
        </InfoMessage>
      )}
    </Container>
  );
};

export default BoxFlatAlert;
