import React, { useState } from "react";
import {
  Container,
  Row,
  Column,
  Center,
  BoxContent,
  Title,
  Error,
} from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import { RootState } from "store/ducks/state";
import BoxFlatAlert from "components/Alerts/BoxFlatAlert";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Header from "../../Header";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { handleGetDependencyAdministrative } from "store/ducks/parameters";
import { getSchool } from "store/ducks/schools";

import Button from "../../Button";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { AutoCompleteCustom } from "components";

import { ptBR } from "date-fns/locale";
import Checkbox from "@mui/material/Checkbox";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { handleGetEducationLevel } from "store/ducks/parameters";
import { isMobile, capitalizeFirstLetter } from "../../../util";

import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";

interface Props {
  id?: number;
  className?: string;
  collective?: boolean;
}
interface IAutocompleteControlled {
  label: string;
  options?: any[];
  name: string;
  optionsSearchJEB?: string;
  textFieldStyles?: any;
  textFieldSx?: any;
  cStyles?: any;
  containerStyle?: any;
  noRequired?: boolean;
  disabled?: boolean;
}
interface ITextInputControlled {
  label: string;
  name: string;
  number?: boolean;
  minRows?: number;
  multiline?: boolean;
  noRequired?: boolean;
  disabled?: boolean;
  rules?: {};
  maxLength?: number;
  noMessage?: boolean;
  defaultValue?: string;
}
interface IDatePickerControlled {
  label: string;
  name: string;
  value?: boolean;
  onChange?: number;
  noRequired?: boolean;
  rules?: any;
}
interface IModelDataForm {
  unidadeEscolar: null | { label: string; value: number | string };
  nte: null | { label: string; value: number | string };
  municipio: null | { label: string; value: number | string };
  porte: null | { label: string; value: number | string };
  nivelEnsino: null | { label: string; value: number | string };
  nome: null | string;
  qtdEstudanteFeminino: null | string | number;
  qtdEstudanteMasculino: null | string | number;
  qtdEstudanteDeficienteFeminino: null | string | number;
  qtdEstudanteDeficienteMasculino: null | string | number;
  qtdEstudante: null | string | number;
  objetivo: null | string;
  conhecimentoTratado: null | string;
  descricaoProjeto: null | string;
  recursosUtilizados: null | string;
  dificuldades: null | string;
  aspectosRelevantes: null | string;
  elementosSuperados: null | string;
  assinatura: null | string;
  dependenciaAdministrativa: null | number;
}
interface IRadioButtonControlled {
  label: string;
  name: string;
  disabled?: boolean;
}
interface ICheckboxControlled {
  label: string;
  name: string;
  noRequired?: boolean;
  value?: any;
}
const defaultValues = {
  data: {
    unidadeEscolar: null, // FORMATO: {label:'exemplo', value:1}
    porte: null, // FORMATO: {label:'exemplo', value:1}
    nivelEnsino: [], // FORMATO: {label:'exemplo', value:1}
    nome: null,
    qtdEstudanteFeminino: null,
    qtdEstudanteMasculino: null,
    qtdEstudanteDeficienteFeminino: null,
    qtdEstudanteDeficienteMasculino: null,
    qtdEstudante: null,
    objetivo: null,
    conhecimentoTratado: null,
    descricaoProjeto: null,
    recursosUtilizados: null,
    dificuldades: null,
    aspectosRelevantes: null,
    elementosSuperados: null,
    assinatura: null,
    dependenciaAdministrativa: null,
    realizacaoInicio: null,
    realizacaoFim: null,
  },
};

export const SimpleForm: React.FC<Props> = (props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [processingSave, setProcessingSave] = useState(false);
  const { id = null, className } = props;
  const [isLoadingRadio, setIsLoadingRadio] = React.useState(true);
  const [isLoadingCheck, setIsLoadingCheck] = React.useState(true);
  const [refreshCountyAndNTE, setRefreshCountyAndNTE] =
    React.useState<any>(true);
  const [radioDependencys, setRadioDependencys] = React.useState([]);
  const [checkboxDependencys, setCheckboxDependencys] = React.useState([
    { value: 5, label: "FUNDAMENTAL" },
    { value: 6, label: "INFANTIL" },
    { value: 7, label: "MEDIO" },
    { value: 8, label: "SUPERIOR" },
  ]);
  const [disableDependency, setDisableDependency] = React.useState<boolean>();
  const [idSintese, setIdSintese] = React.useState<number>(id);

  const [schoolData, setSchoolData] = React.useState({
    name: "",
    nte: "",
    municipio: "",
  });
  const [formStep, setFormStep] = React.useState(0); // 0 - MOSTRAR TODOS FORM EM 1 PAGINA SÓ; 1 - MOSTRA FORM1; 2 - MOSTRA FORM2; 3- MOSTRA FORM3;
  const {
    control,
    setValue,
    trigger,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: defaultValues,
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    handleDispatchDepedency();

    // if (idSintese && idSintese > 0) {
    //   setIsLoading(true);
    //   dispatch(GetSearchPage(Number(idSintese)))
    //     .then((data) => {
    //       console.log("GetSearchPage@data: ", data);
    //       const dataFormated = null;// handleDataForm(data);
    //       console.log(
    //         "🚀 ~ file: index.tsx ~ line 199 ~ .then ~ dataFormated",
    //         dataFormated
    //       );
    //       setValue("data", dataFormated);
    //       setSchoolData({
    //         name: data?.escola?.nome_escola,
    //         nte: data?.escola?.nte?.nome,
    //         municipio: data?.escola?.municipio?.municipio,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log("error  SINTESE", err.msg);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // }
    // else {
    //   dispatch(getSchool(user?.id_escola))
    //     .then((data) => {
    //       console.log("🚀 ~ file: index.tsx ~ line 217 ~ .then ~ data", data);
    //       if (data?.id_dependencia) {
    //         setValue("data.dependenciaAdministrativa", data?.id_dependencia);
    //         setDisableDependency(true);
    //       }
    //       setValue("data.unidadeEscolar", {
    //         label: "",
    //         value: user?.id_escola,
    //       });
    //       setSchoolData({
    //         name: data?.nome_escola,
    //         nte: data?.nte,
    //         municipio: data?.municipio,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log("getSchool@err: ", error);
    //     });
    // }
  }, []);

  const onSubmit: SubmitHandler<any> = ({ data }) => {
    // setProcessingSave(true);
    console.log("SUBMIT FEITO AQUI NO FORM1 DATA: ", data);
    console.log("SUBMIT FEITO AQUI NO FORM1 DATA: ", data);
    console.log(
      "SUBMIT FEITO AQUI NO FORM1 MODE EDIT: : ",
      idSintese && idSintese > 0
    );
    // dispatch(
    //   idSintese && idSintese > 0
    //     ? updateSynthesis(Number(idSintese), handleDataPost(data))
    //     : newSynthesis(data)
    // )
    //   .then((data) => {
    //     // console.log('newSynthesis@data: ',data)
    //     setErrorMessage(null);
    //     setSuccessMessage(
    //       `Síntese ${
    //         idSintese && idSintese > 0 ? `alterada` : `criada`
    //       } com sucesso.`
    //     );
    //     if (!(idSintese && idSintese > 0)) setIdSintese(data?.id_sintese);
    //   })
    //   .catch((err) => {
    //     console.log("newSynthesis@error: ", err);
    //     setSuccessMessage(null);
    //     setErrorMessage(err.msg);
    //   })
    //   .finally(() => setProcessingSave(false));
  };

  const completeFormStep = () => {
    setFormStep((cur) => cur + 1);
  };
  const backFormStep = () => {
    setFormStep((cur) => (cur > 0 ? cur - 1 : cur));
  };
  const handleTriggersValidateForm = async () => {
    const arrayFieldsStep1 = [
      "data.unidadeEscolar",
      "data.porte",
      // "data.nivelEnsino",
      "data.dependenciaAdministrativa",
      "data.nome",
      "data.qtdEstudanteFeminino",
      "data.qtdEstudanteMasculino",
      "data.qtdEstudante",
    ];
    const arrayFieldsStep2 = [
      "data.qtdEstudanteDeficienteMasculino",
      "data.qtdEstudanteDeficienteFeminino",
      "data.objetivo",
      "data.conhecimentoTratado",
      "data.descricaoProjeto",
      "data.recursosUtilizados",
    ];
    const arrayFieldsStep3 = [
      "data.dificuldades",
      "data.aspectosRelevantes",
      "data.elementosSuperados",
      "data.assinatura",
    ];
    const result = await trigger(
      formStep === 1
        ? arrayFieldsStep1
        : formStep === 2
        ? arrayFieldsStep2
        : formStep === 3
        ? arrayFieldsStep3
        : []
    );
    return result;
  };

  const handlerValidations = (name, value) => {
    if (name === "defMasc") {
      const qtdStudMasc = Number(getValues("data.qtdEstudanteMasculino"));
      if (Number(value) > qtdStudMasc) return false;
      return true;
    }
    if (name === "defFem") {
      const qtdStudFem = Number(getValues("data.qtdEstudanteFeminino"));
      if (Number(value) > qtdStudFem) return false;
      return true;
    }
    if (name === "date") {
      const dateInitial = getValues("data.realizacaoInicio");
      const dateFinal = getValues("data.realizacaoFim");
      if (dateInitial > dateFinal) return false;
      return true;
    }
  };

  const handleValidationcheckbox = (value) => {
    return value.length > 0;
  };

  const RenderButtons = ({ type }) => {
    return (
      <Button
        type={type === "submit" ? type : "button"}
        loading={type === "submit" && processingSave}
        disabled={processingSave}
        onClick={async () => {
          if (type === "next") {
            const isFormValid = await handleTriggersValidateForm();
            if (isFormValid) {
              completeFormStep();
            }
          } else if (type === "back") {
            backFormStep();
          }
          // else if (type === 'submit'){
          //   setProcessingSave(true)
          // }
        }}
        typecolor={
          type === "submit" || type === "next"
            ? "primary_organizer"
            : "secondary_organizer"
        }
        fontSize="27px"
        width="254px"
        height="64px"
        noFontBold
      >
        {type === "submit" && "Salvar"}
        {type === "next" && "Próximo"}
        {type === "back" && "Voltar"}
      </Button>
    );
  };

  const RenderButtonForm = () => {
    return (
      <Row style={{ marginTop: 20 }}>
        <Center>
          {formStep > 0 && formStep !== 1 && <RenderButtons type="back" />}
          {formStep > 0 && formStep < 3 && <RenderButtons type="next" />}
          {(formStep === 3 || !formStep) && <RenderButtons type="submit" />}
        </Center>
      </Row>
    );
  };

  const handleDispatchDepedency = () => {
    setIsLoadingRadio(true);
    dispatch(handleGetDependencyAdministrative())
      .then((data) => {
        setRadioDependencys(data);
      })
      .catch((err) => {
        console.log("handleGetDependencyAdministrative@error: ", err.msg);
      })
      .finally(() => {
        setIsLoadingRadio(false);
      });
  };

  const TextInputControlled: React.FC<ITextInputControlled> = React.memo(
    (props) => {
      const {
        name,
        label,
        number = false,
        multiline = false,
        minRows = 1,
        maxLength = false,
        disabled = false,
        noRequired = false,
        rules = {},
        noMessage = false,
        defaultValue,
      } = props;
      const isError =
        errors && errors?.data && errors?.data[name.replace("data.", "")];
      return (
        <>
          <Controller
            name={name}
            control={control}
            rules={Object.assign({ required: !noRequired }, rules)}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  type={number ? "number" : "text"}
                  error={isError ? true : false}
                  multiline={multiline}
                  minRows={minRows}
                  onFocus={(event) => {
                    const qtdStudFem = Number(
                      getValues("data.qtdEstudanteFeminino")
                    );
                    const qtdStudMasc = Number(
                      getValues("data.qtdEstudanteMasculino")
                    );
                    const qtdStudTotal = qtdStudFem + qtdStudMasc;
                    if (qtdStudTotal > 0) {
                      setValue("data.qtdEstudante", String(qtdStudTotal));
                    }
                    event.target.select();
                  }}
                  size="small"
                  defaultValue={defaultValue}
                  disabled={disabled}
                  helperText={
                    (isError
                      ? isError.message
                        ? isError.message
                        : "Campo obrigatório"
                      : "") as unknown as React.ReactNode
                  }
                  onChange={(event) => {
                    onChange(
                      number
                        ? event.target.value.replace("-", "")
                        : event.target.value
                    );
                  }}
                  fullWidth
                  InputProps={{
                    inputProps: Object.assign(
                      {},
                      number ? { min: 0 } : {},
                      maxLength ? { maxLength: maxLength } : {}
                    ),
                  }}
                  value={value}
                  label={label}
                />
              );
            }}
          />
        </>
      );
    }
  );
  const DatePickerControlled: React.FC<IDatePickerControlled> = React.memo(
    (props) => {
      const { name, label, noRequired = false, rules } = props;
      const isError =
        errors && errors?.data && errors?.data[name.replace("data.", "")];
      return (
        <>
          <Controller
            name={name}
            control={control}
            rules={Object.assign({ required: !noRequired }, rules)}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DatePicker
                  // disabled={false}
                  value={value}
                  label={label}
                  mask="__/__/____"
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      error={isError ? true : false}
                      helperText={
                        (isError && isError.message
                          ? isError.message.toString().length > 1
                            ? isError.message
                            : "Campo obrigatório"
                          : "") as unknown as React.ReactNode
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </>
      );
    }
  );

  const RadioButtonControlled: React.FC<IRadioButtonControlled> = React.memo(
    (props) => {
      const { name, label, disabled } = props;
      const isError =
        errors && errors?.data && errors?.data[name.replace("data.", "")];
      return (
        <FormControl>
          <FormLabel
            disabled={disabled}
            error={isError ? true : false}
            component="legend"
            sx={{ textAlign: "left" }}
          >
            {label}
          </FormLabel>
          {isLoadingRadio && <CircularProgress color="inherit" size={20} />}
          <Controller
            rules={{ required: true }}
            control={control}
            name={name}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {radioDependencys.map((radio) => (
                  <FormControlLabel
                    value={radio.value}
                    control={<Radio />}
                    label={radio.label}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      );
    }
  );

  const handleVerificationCheckedCheckbox = (value) => {
    const singleValue = getValues("data.nivelEnsino").filter(
      (item) => item.value === value
    );
    return singleValue.length > 0;
  };
  const CheckBoxControlled: React.FC<ICheckboxControlled> = React.memo(
    (props) => {
      const { name, label, noRequired = false, value } = props;
      return (
        <FormControlLabel
          control={
            <input
              {...register(`${name}`, {
                validate: (v) => handleValidationcheckbox(v),
              })}
              defaultChecked={handleVerificationCheckedCheckbox(value)}
              type="checkbox"
              value={value}
            />
          }
          label={<text style={{ marginLeft: 5 }}>{label}</text>}
          sx={{ marginLeft: 0 }}
        />
      );
    }
  );

  const RenderStep1 = ({ visible }) => {
    return (
      visible && (
        <>
          <Row>
            <Title>Subtítulo</Title>
          </Row>
          <Row>
            <FormControl>
              <FormLabel
                error={
                  errors && errors.data && (errors.data as any).nivelEnsino
                    ? true
                    : false
                }
                component="legend"
                sx={{ textAlign: "left" }}
              >
                Nível de Ensino*
              </FormLabel>
              <Column style={{ flexDirection: "row", marginTop: 5 }}>
                {checkboxDependencys.length > 0 ? (
                  checkboxDependencys.map((checkbox, index) => {
                    const fieldName = `data.nivelEnsino`;
                    return (
                      <CheckBoxControlled
                        name={`${fieldName}`}
                        value={Number(checkbox.value)}
                        label={capitalizeFirstLetter(checkbox.label)}
                      />
                    );
                  })
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Column>
              <Error>
                {
                  (errors && errors.data && (errors.data as any).nivelEnsino
                    ? (errors.data as any).nivelEnsino.message
                      ? (errors.data as any).nivelEnsino.message
                      : "Campo obrigatório"
                    : "") as unknown as React.ReactNode
                }
              </Error>
            </FormControl>
          </Row>
          <Row>
            <Column>
              <AutoCompleteCustom
                label={"Escola"}
                name={"id_escola"}
                size="small"
                onChange={undefined}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <AutoCompleteCustom
                label={"NTE"}
                name={"id_municipio"}
                size="small"
                onChange={undefined}
              />
            </Column>
          </Row>

          <Row>
            <Title>Subtítulo</Title>
          </Row>
          <Row>
            <Column>
              <TextInputControlled
                name="data.nome"
                label="Campo de Texto*"
                maxLength={150}
              />
            </Column>
          </Row>

          <Row>
            <FormControl fullWidth>
              <FormLabel component="legend" sx={{ textAlign: "left" }}>
                Datepickers:
              </FormLabel>
              <BoxContent>
                <DatePickerControlled
                  name="data.realizacaoInicio"
                  label="Data Inicial*"
                />
                <DatePickerControlled
                  name="data.realizacaoFim"
                  label="Data Final*"
                  rules={{
                    validate: {
                      checkEmail: (v) =>
                        handlerValidations("date", v) ||
                        "A data final não pode ser menor que a inicial.",
                    },
                  }}
                />
              </BoxContent>
            </FormControl>
          </Row>
        </>
      )
    );
  };

  const RenderStep2 = ({ visible }) => {
    return (
      visible && (
        <>
          <Row>
            <Title>Subtítulo</Title>
          </Row>
          <Row>
            <Column>
              <TextInputControlled
                name="data.objetivo"
                label="Observações*"
                multiline
                minRows={2}
                maxLength={150}
              />
            </Column>
          </Row>
        </>
      )
    );
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)} className={className}>
      <Header>
        <Center>
          {idSintese && idSintese > 0
            ? `Editar Síntese #${idSintese} `
            : "Modal Com Form 01"}
        </Center>
      </Header>
      <RenderStep1 visible={!isLoading || formStep === 1 || !formStep} />
      <RenderStep2 visible={!isLoading || formStep === 2 || !formStep} />

      <Row>
        <Column>
          <BoxFlatAlert
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </Column>
      </Row>
      <RenderButtonForm />
    </Container>
  );
};

export default SimpleForm;
