import React from "react";

import { Container, Name, UserName, Image, menu, menuItem } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/ducks/state";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar from "../../assets/profileDefault.png";
import {
  isAdminLogged,
  getUserLoggedFromLocalStorage,
  firstName,
  isLogged,
} from "../../util";

interface IProps {
  isAdminLogged: boolean;
}

const MenuProfile: React.FC<IProps> = ({ isAdminLogged }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goRouter = (router) => {
    var url = (isAdminLogged ? "/" : "/estudante/") + router;
    if (router === "logout") url = "/" + router;
    window.location.replace("" + url);
  };

  const userLoggedData = useSelector((state: RootState) => state.auth.user);

  return (
    <Container isAdminLogged={isAdminLogged}>
      <Button
        className="buttonMenu"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar alt="Remy Sharp" src={avatar} />
        <div>
          <Name>
            {userLoggedData &&
              userLoggedData.nome &&
              firstName(userLoggedData?.nome)}
          </Name>
          <UserName>
            {userLoggedData &&
            userLoggedData.perfil &&
            userLoggedData?.perfil.length < 20
              ? `(${userLoggedData?.perfil})`
              : null}
          </UserName>
        </div>

        <div className="spacer"></div>

        <FontAwesomeIcon size="lg" color="white" icon={faChevronDown} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: menu,
        }}
      >
        <MenuItem onClick={() => goRouter("perfil")} sx={menuItem}>
          Meus dados
        </MenuItem>
        {isAdminLogged && (
          <MenuItem onClick={() => goRouter("configuracoes")} sx={menuItem}>
            Configurações
          </MenuItem>
        )}
        <MenuItem onClick={() => goRouter("logout")} sx={menuItem}>
          Sair
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default MenuProfile;
