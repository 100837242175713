import React, { useState, useEffect } from "react";
import backgroundCompetiter from "../../../assets/background_competiter.webp";
import backgroundOrganizer from "../../../assets/background_organizer.webp";
import backgroundHome from "../../../assets/bg_home.webp";
import Admin from "../../../assets/Admin.png";
import NoAdmin from "../../../assets/NoAdmin.png";
import logoGovernoEstado from "../../../assets/logoGovernoDoEstado.png";
import {
  isMobile,
  monthNames,
  mustRectSystem,
  redirectSystem
} from "util/index";

import {
  Container,
  ContainerInformation,
  ContainerBackground,
  Logo,
  LogoSudesb,
  ContainerCronometro,
  ContainerWelcome,
  Data,
  LinearGradient,
  Rodape,
  ContainerBg,
  BoxContainer,
  Box,
  Cronometro,
  LogoGoverno,
  ContainerButtons,
  Button,
  ContainerButtonIcon,
  ContainerButtonInfo,
  Icon,
  ButtonText,
  ContainerLogo,
  TextRodape,
  WelcameTitle,
  WelcameText,
  ContainerClashes,
  ContainerHome,
  GridLayout,
  RightNav,
  Content
} from "./styles";

import imgLogo from "../../../assets/logo.svg";
import imgBlazon from "../../../assets/brazaoVertical.png";
import imgColoredBlazon from "../../../assets/brazaoVerticalColorido.png";
import logoSudesb from "../../../assets/logoSudesb.svg";
import {
  ONLINE_SYSTEM,
  OFFLINE_PASSWORD,
  IS_ELECTORAL_PERIOD
} from "../../../constants/system";
import { urlHash, isHD, isFullHD } from "util/index";

interface IBackgroundImg {
  children?: React.ReactNode;
  pageName?: string;
  target_date: Date;
  isAdminArea: boolean;
  isHome?: boolean;
}
const BackgroundLoginPage: React.FC<IBackgroundImg> = ({
  children,
  target_date,
  isAdminArea,
  isHome
}) => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const isCronometerZeroed = new Date() >= new Date(target_date);

  setInterval(function () {
    if (!isCronometerZeroed) {
      timer();
    }
  }, 1000);

  function timer() {
    var segundos_f = (target_date.getTime() - new Date().getTime()) / 1000;
    var dias = segundos_f / 86400;
    var segundos_f = segundos_f % 86400;
    var horas = segundos_f / 3600;
    var segundos_f = segundos_f % 3600;
    var minutos = segundos_f / 60;
    var segundos = segundos_f % 60;
    setDays(Math.floor(dias));
    setHours(Math.floor(horas));
    setMinutes(Math.floor(minutos));
    setSeconds(Math.floor(segundos));
  }

  const hash = urlHash();

  useEffect(() => {
    mustRectSystem() && redirectSystem();
  }, []);

  return mustRectSystem() ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 50
      }}
    >
      Redirecionando...
    </div>
  ) : (
    <Container isHome={isHome}>
      <ContainerHome>
        <ContainerBackground
          isHome={isHome || false}
          imgUrl={
            isHome
              ? backgroundHome
              : isAdminArea
              ? backgroundOrganizer
              : backgroundCompetiter
          }
        >
          <LinearGradient
            isAdminArea={isAdminArea}
            isHome={isHome}
            style={
              isHome && !isMobile() && window.screen.height < 650
                ? { paddingTop: "10vh" }
                : {}
            }
          >
            {IS_ELECTORAL_PERIOD ? (
              <ContainerLogo>
                {isMobile() && <Logo logo={logoGovernoEstado} />}
                <Logo
                  filter={"drop-shadow(1px -2px 1px #00152e)"}
                  height={(isFullHD() ? 33 : 37) * 0.8 + "vh"}
                  width={(isFullHD() ? 33 : 37) + "vh"}
                  logo={imgColoredBlazon}
                  style={!isMobile() ? { marginLeft: 40, marginRight: 0 } : {}}
                />
              </ContainerLogo>
            ) : (
              <ContainerLogo>
                <Logo
                  logo={logoGovernoEstado}
                  style={
                    !isMobile()
                      ? {
                          marginLeft: 0,
                          marginRight: 20,
                          width: "9vw",
                          height: "8.5vw",
                          backgroundSize: "9vw 8.5vw",
                          marginTop: "2.5vh"
                        }
                      : {}
                  }
                ></Logo>
                <Logo
                  logo={imgLogo}
                  style={!isMobile() ? { marginLeft: 40, marginRight: 0 } : {}}
                ></Logo>
                {!isMobile() && (
                  <Logo
                    logo={logoSudesb}
                    style={{
                      width: "15vw",
                      height: "10vw",
                      backgroundSize: "15vw 15vw",
                      marginLeft: 30,
                      marginRight: "-3.5vw",
                      marginTop: "2vh"
                    }}
                  ></Logo>
                )}
              </ContainerLogo>
            )}
            {isCronometerZeroed && (
              <ContainerWelcome>
                <WelcameTitle IS_ELECTORAL_PERIOD={IS_ELECTORAL_PERIOD}>
                  {IS_ELECTORAL_PERIOD
                    ? "EDUCA+ BAHIA"
                    : "Bem-vindo!"}
                </WelcameTitle>
                <WelcameText>
                 Para entrar no sistema do EDUCA+ BAHIA, faça login com seu CPF ou e-mail e senha.
                 {/* , ou realize seu cadastro. */}
                </WelcameText>
              </ContainerWelcome>
            )}
            {!isCronometerZeroed && (
              <ContainerCronometro>
                <Data>
                  {target_date.getDate()} de{" "}
                  {monthNames[target_date.getMonth()]} de{" "}
                  {target_date.getFullYear()}
                </Data>
                <Cronometro>
                  <BoxContainer>
                    <Box>{days}</Box>
                    dias
                  </BoxContainer>
                  <BoxContainer>
                    <Box>{hours}</Box>
                    horas
                  </BoxContainer>
                  <BoxContainer>
                    <Box>{minutes}</Box>
                    minutos
                  </BoxContainer>
                  <BoxContainer>
                    <Box>{seconds}</Box>
                    segundos
                  </BoxContainer>
                </Cronometro>
              </ContainerCronometro>
            )}
            {!isHome ? (
              <></>
            ) : (
              <ContainerButtons>
                <Button
                  isAdminArea={false}
                  href={
                    "/estudante/login" +
                    (hash && hash.length > 0 ? `#${hash}` : "")
                  }
                >
                  <ContainerButtonIcon>
                    <Icon background={Admin} />
                  </ContainerButtonIcon>
                  <ContainerButtonInfo>
                    <ButtonText>ÁREA DO</ButtonText>
                    <ButtonText>ESTUDANTE</ButtonText>
                  </ContainerButtonInfo>
                </Button>
                <Button
                  isAdminArea={true}
                  href={
                    "/login" +
                    (hash && hash.length > 0 ? `#${hash}` : "")
                  }
                >
                  <ContainerButtonIcon>
                    <Icon background={NoAdmin} />
                  </ContainerButtonIcon>
                  <ContainerButtonInfo>
                    <ButtonText>ÁREA DO PROFESSOR</ButtonText>
                    <ButtonText>E GESTÃO</ButtonText>
                  </ContainerButtonInfo>
                </Button>
              </ContainerButtons>
            )}
            {!isHome && (
              <Rodape>
                <LogoSudesb>
                  <TextRodape>
                    @ {new Date().getFullYear()} | SEC - Todos os direitos
                    reservados à Secretaria da Educação do Estado da Bahia
                    <br />
                    Assessoria de Planejamento e Gestão, Coordenação de Gestão
                    Organizacional e de TIC
                  </TextRodape>
                </LogoSudesb>
                {!IS_ELECTORAL_PERIOD && (
                  <LogoGoverno
                    isHome={isHome}
                    imgUrl={logoGovernoEstado}
                  ></LogoGoverno>
                )}
                {/* <LogoGoverno imgUrl={logoSudesb}></LogoGoverno> */}
              </Rodape>
            )}
          </LinearGradient>
        </ContainerBackground>

        {isHome && (
          <ContainerBg>
            <ContainerClashes>
              <GridLayout>
                <RightNav>
                  
                </RightNav>
                <Content>
                </Content>
              </GridLayout>
            </ContainerClashes>
            <Rodape
              style={{
                backgroundColor: "rgb(34,99,141)",
                padding: "15px",
                height: "auto"
              }}
            >
              <TextRodape>
                @ {new Date().getFullYear()} | SEC - Todos os direitos
                reservados à Secretaria da Educação do Estado da Bahia
                <br />
                Assessoria de Planejamento e Gestão, Coordenação de Gestão
                Organizacional e de TIC
              </TextRodape>
            </Rodape>
          </ContainerBg>
        )}
      </ContainerHome>
      <ContainerInformation isHome={isHome}>{children}</ContainerInformation>
    </Container>
  );
};

export default BackgroundLoginPage;
