import React from "react";
import { Container, FeedBackUpload,Content, LineFeedBack } from "./styles";
import FilledInput from "@mui/material/FilledInput";
import ReactLoading from "react-loading";
import ButtonSimple from "../../../components/Buttons/simpleButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../../services/api";
import {getTokenFromLocalStorage} from '../../../util'



interface IInputFileForm {}

const InputFileForm: React.FC<IInputFileForm> = (props) => {
  const {} = props;

  const [listData, setListData] = React.useState<any[]>(null);

  const UPLOADS: React.FC<any> = ({ listData }) => {
    const [isLoading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState<any>();
    const [error, setError] = React.useState<any>();
    if (listData) return null;

    const POST = (file: any) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append(
        "voluntarios_arquivo",
        file[0],
        "voluntarios_arquivo.xlsx"
      );

      api
        .post("voluntarios/import", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })
        .then(function (response) {
          setListData(response.data);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const onChangeFile = (e) => {
      const isXlsx = e?.target?.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      const isXls = e?.target?.files[0].type == "application/vnd.ms-excel"
      if(isXlsx) {
        setFile(e?.target?.files);
        setError(null)
      }else{
        setError("O arquivo importado é inválido.")
      }
    };
    const handleSendFile = () => {
      if(!file) {
        setError("Por favor selecione um arquivo.")
        return
      }
      if(error) {return}
      POST(file);
    };

    if (isLoading)
      return (
        <ReactLoading type="spin" width={100} height={100} color="#c0c0c0" />
      );
    return (
      <>

        <FilledInput type="file" onChange={onChangeFile} />
        <LineFeedBack style={{marginTop:10,color: "#cacaca"}}>Selecione um arquivo de extensão .xlsx </LineFeedBack>
        <ButtonSimple
          hideTextInMobile
          style={{ marginTop: 25 }}
          onClick={handleSendFile}
          disabled={!file?true:false}
          icon={faPlus}
          text={"Enviar"}
          txtStyle={{ fontSize: 15 }}
          styleIcon={{ width: "15px", height: "15px" }}
        />
        {error &&  <LineFeedBack style={{marginTop:30,color: "red"}}>{error} </LineFeedBack>}
      </>
    );
  };

  const ContentFeedBackUpload: React.FC<any> = ({ listData }) => {
    if (!listData) return null;
    return (
      <FeedBackUpload>
        <FeedBackUpload style={{ flexDirection: "row" }}>
          <LineFeedBack>Total: {listData.total}</LineFeedBack>
          <LineFeedBack>Importados: {listData.importados}</LineFeedBack>
          <LineFeedBack>Falhas: {listData.falhas}</LineFeedBack>
        </FeedBackUpload>
        {listData?.errors.map((item) => {
          const FEEDBACK_ERRORS_CPF = (
            <LineFeedBack style={{ marginTop: 30 }}>
              CPF: {item.cpf}
            </LineFeedBack>
          );
          const errors = Object.keys(item.errors);
          const FEEDBACK_ERRORS_INDIVIDUALS = errors.map((key) => {
            return <LineFeedBack>{item.errors[key]}</LineFeedBack>;
          });

          return (
            <>
              {FEEDBACK_ERRORS_CPF}
              {FEEDBACK_ERRORS_INDIVIDUALS}
            </>
          );
        })}
      </FeedBackUpload>
    );
  };

  return (
    <Container>
      <Content>
        <UPLOADS listData={listData} />
        <ContentFeedBackUpload listData={listData} />
      </Content>
    </Container>
  );
};

export default InputFileForm;
