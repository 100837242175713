import yup from "../../../../";
import { ValidateCpf } from "util/validations/";
import { onlyNumber } from "../../../../../util/index";

export const Responsible = yup
  .object({
    // nomePai: yup.string().notRequired(),

    // cpfPai: yup
    //   .string()
    //   .notRequired()
    //   .test("cpf", "CPF inválido", (value) => {
    //     return (
    //       String(onlyNumber(value)).length === 0 ||
    //       (value ? ValidateCpf(value) : true)
    //     );
    //   }),

    // rgPai: yup
    //   .string()
    //   .notRequired()
    //   .test(
    //     "len",
    //     "Informe todos os digitos do RG",
    //     (val) =>
    //       String(onlyNumber(val)).length === 0 ||
    //       (String(val).length >= 6 && String(val).length <= 14)
    //   ),

    // nomeMae: yup.string().required(),

    // cpfMae: yup
    //   .string()
    //   .required()
    //   .test("cpf", "CPF inválido", (value) => {
    //     return ValidateCpf(value ? value : "");
    //   }),

    // rgMae: yup
    //   .string()
    //   .required()
    //   .test(
    //     "len",
    //     "Informe todos os digitos do RG",
    //     (val) => String(val).length >= 6 && String(val).length <= 14
    //   ),
    // auditiva: yup.boolean().oneOf([true], "Message"),
    // visual: yup.boolean(),
    // fisica: yup.boolean(),
    // outras: yup.boolean(),
    // descricao: yup.string()
  })
  .required();

export default Responsible;
