import React, { useState } from "react";
import {
  Container,
  Content,
  Center,
  HeaderTitle,
} from "./styles";
import Form from "./Form";

interface Props {
  id: number;
  data?: any
  onSearch?: any
}

const TabForm: React.FC<Props> = (props) => {
  const { id,data,onSearch } = props;
  console.log("🚀 ~ file: index.tsx ~ line 17 ~ TabForm:data", data)
  console.log("id:", id);
  return (
    <Container>
      <HeaderTitle>
        <Center>Editar Voluntários</Center>
      </HeaderTitle>
      <Content>
        <Form onSearch={onSearch} id={id} data={data} />
      </Content>
    </Container>
  );
};

export default TabForm;
