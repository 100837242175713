import React from "react";
import { Error } from "./styles";
import { TextField } from "@mui/material";

import { Controller } from "react-hook-form";

interface ITextInputControlled {
  label: string;
  name: string;
  number?: boolean;
  minRows?: number;
  multiline?: boolean;
  noRequired?: boolean;
  disabled?: boolean;
  rules?: {};
  maxLength?: number;
  noMessage?: boolean;
  defaultValue?: any;
  control: any;
  errors: any;
  onFocus?: any;
  noShowError?: boolean;
  style?: any;
  InputProps?: any;
  sx?: any;
  date?: boolean;
  variant?: "filled" | "standard" | "outlined";
  placeholder?: string;
  onChange?: any;
  InputLabelProps?: any;
}

const TextInputControlled: React.FC<ITextInputControlled> = React.memo(
  (props) => {
    const {
      name,
      label,
      number = false,
      multiline = false,
      minRows = 1,
      maxLength = false,
      disabled = false,
      noRequired = false,
      rules = {},
      noMessage = false,
      defaultValue,
      control,InputLabelProps,
      errors,
      onFocus,
      style,
      noShowError = false,
      sx,
      InputProps,
      date,
      placeholder,
      variant,
    } = props;
    const isError =
      errors && errors?.data && errors?.data[name.replace("data.", "")];
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={Object.assign({ required: !noRequired }, rules)}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                type={number ? "number" : date ? "date" : "text"}
                error={isError ? true : false}
                multiline={multiline}
                minRows={minRows}
                onFocus={onFocus}
                variant={variant}
                size="small"
                placeholder={placeholder}
                style={{ backgroundColor: "#fff", ...style }}
                sx={sx}
                defaultValue={defaultValue}
                disabled={disabled}
                helperText={
                  (!noShowError && isError
                    ? isError.message
                      ? isError.message
                      : "Campo obrigatório"
                    : "") as unknown as React.ReactNode
                }
                onChange={(event) => {
                  onChange(
                    number
                      ? event.target.value.replace("-", "")
                      : event.target.value
                  );
                }}
                fullWidth
                InputProps={
                  InputProps
                    ? InputProps
                    : {
                        inputProps: Object.assign(
                          {},
                          number ? { min: 0 } : {},
                          maxLength ? { maxLength: maxLength } : {}
                        ),
                      }
                }
                InputLabelProps={InputLabelProps}
                value={value}
                label={label}
              />
            );
          }}
        />
      </>
    );
  }
);

export default React.memo(TextInputControlled);
