import styled from 'styled-components';
interface IProps{
    width?:string;
}
export const Container = styled.form<IProps>`
    width: 100%;
    height:auto;
    padding: 26px 30px 46px 30px;
    @media only screen and (min-width : 320px) and (max-width : 480px) {
        padding: 26px 10px;
    }
`;

export const Row = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    @media only screen and (min-width : 320px) and (max-width : 480px) {
        margin-bottom: 15px;
    }
`;
export const RowLast = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-top: 53px;
`;
export const Column = styled.div`
    width: 100%;
    flex-direction: column;
    display: flex;
    margin:0 5px;
`;
export const Error = styled.div`
    width: 100%;
    height:19px;
    display: flex;
    margin:0;
    color:red;
`;
export const Center = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex:1;
    color:inherit;
    font-weight: inherit;
`;
export const Text = styled.div`
    margin-top:13px;
    font-size: 16px;
    font-weight: bold;
    color:black;
    text-align: left;
    width:100%;
`;

export const selectStyle = {
    width:'178px',
    border:'1px solid #707070',
    borderRadius:'5px',
    height:'38px',
    paddingLeft:'22px',
    color:'#707070'
}

export default Container;