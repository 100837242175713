import { data as constantsData } from "../../../constants";
import {
  Actions,
  handleCreateUser,
  handleUpdateUser,
  UserTypes,
} from "store/ducks/user";
import { randomInt, onlyNumber, loginSuggestion } from "../../../util";
import { CurrentUserDisplayed, Deficiency, IImage } from "store/types";
import { handleChangeCurrentUserDisplayed } from "store/ducks/user";
import { profile } from "console";
import { log, dateToUSA } from "util/index";

export interface IMunicipio {
  municipio?: string;
  rn?: number;
  id_municipio?: number;
  uf?: string;
}

export interface IUnidade {
  rn?: string;
  id_escola?: number;
  id_municipio?: number;
  nome_escola?: string;
}

export interface IPeople {
  nome: string;
  cpf: string;
  rg: string;
  matricula?: string;
  nis?: string;
  dataNascimento: string;
  sexo: string;
  id_perfil_usuario?: string;
  id_perfil_solicitado?: string;
  perfil?: string;
  municipio?: string;
  id_nte?: string;
  unidade?: string;
}

export const needNTE = (
  currentUserDisplayed,
  isAdminRegistration,
  allProfileWithPermissions = null
) => true;
export const needCityAndSchool = (
  currentUserDisplayed,
  isAdminRegistration,
  allProfileWithPermissions = null
) => true;

export const getCity = (currentUserDisplayed) => {
  if (
    currentUserDisplayed &&
    currentUserDisplayed &&
    currentUserDisplayed.municipio &&
    currentUserDisplayed.municipio.id_municipio &&
    currentUserDisplayed.municipio.id_municipio > 0
  )
    return {
      id_municipio: Number(currentUserDisplayed?.municipio?.id_municipio),
      municipio: String(currentUserDisplayed?.municipio?.municipio),
    } as IMunicipio;
  else return null as IMunicipio;
};

export const getSchool = (currentUserDisplayed, unidade) => {
  if (unidade && unidade.id_escola && unidade.id_escola > 0)
    return {
      id_escola: Number(unidade?.id_escola),
      id_municipio: Number(unidade?.id_municipio),
      nome_escola: String(unidade?.nome_escola),
    } as IUnidade;
  else if (
    currentUserDisplayed &&
    currentUserDisplayed &&
    currentUserDisplayed.unidade &&
    currentUserDisplayed.unidade.id_escola &&
    currentUserDisplayed.unidade.id_escola > 0
  )
    return {
      id_escola: Number(currentUserDisplayed?.unidade?.id_escola),
      id_municipio: Number(currentUserDisplayed?.unidade?.id_municipio),
      nome_escola: String(currentUserDisplayed?.unidade?.nome_escola),
    } as IUnidade;
  else return null as IUnidade;
};

export const getProfileIDReadMode = (
  currentUserDisplayed: any,
  considerRequestedProfile: boolean = false
) => {
  if (
    currentUserDisplayed &&
    currentUserDisplayed.id_perfil_usuario &&
    currentUserDisplayed.id_perfil_usuario > 1
  )
    return Number(currentUserDisplayed.id_perfil_usuario);
  else
    return considerRequestedProfile &&
      currentUserDisplayed &&
      currentUserDisplayed.id_perfil_solicitado
      ? Number(currentUserDisplayed.id_perfil_solicitado)
      : undefined;
};

export const handleChangeFunction = (
  target,
  currentUserDisplayed = null,
  dispatch = null
) => {
  if (dispatch !== null) {
    handleChangeCurrentUserDisplayed(dispatch, {
      ...currentUserDisplayed,
      id_perfil_usuario: Number(target.value),
    } as CurrentUserDisplayed);
  }

  // setMunicipios([] as IMunicipio[])
  // setMunicipio(null as IMunicipio)
  // setUnidade(null as IUnidade)
};

export const submitRegistrationForm = (
  id,
  isAdminRegistration,
  editMode,
  dispatch,
  setStepForm,
  setErrorMessage,
  setSuccessMessage,
  setProcessingRegistration
) => {
  setErrorMessage(null);
  setSuccessMessage(null);
  setProcessingRegistration(true);
  dispatch(
    id > 0
      ? handleUpdateUser(id, isAdminRegistration)
      : handleCreateUser(isAdminRegistration)
  )
    .then((response) => {
      setProcessingRegistration(false);
      setSuccessMessage(response.msg);
      setStepForm(editMode ? 1 : 4);
    })
    .catch((error) => {
      setProcessingRegistration(false);
      setErrorMessage(error);
    });
};

export const handleDeficiency = (Deficiency) => {
  let newDeficiency = [];
  try {
    if (Deficiency) {
      if (Deficiency?.visual)
        newDeficiency.push(constantsData.searchIDDisabilityByName("Visual"));
      if (Deficiency?.motora)
        newDeficiency?.push(constantsData.searchIDDisabilityByName("Motora"));
      if (Deficiency?.mental)
        newDeficiency?.push(constantsData.searchIDDisabilityByName("Mental"));
      if (Deficiency.auditiva)
        newDeficiency?.push(constantsData.searchIDDisabilityByName("Auditiva"));
    }
  } catch (err) {
    log("handleDeficiency.error:", err);
  }

  return newDeficiency;
};

const getCity2 = (data, isAdminRegistration, editMode) => {
  let city = {
    id: null,
    name: null,
  };
  if (
    editMode &&
    isAdminRegistration &&
    data &&
    data.organizador &&
    data.organizador.length > 0 &&
    data.organizador[0].escola &&
    data.organizador[0].escola.municipio
  )
    city = {
      id: data.organizador[0].escola.municipio?.id_municipio,
      name: data.organizador[0].escola.municipio?.municipio,
    };
  else if (
    editMode &&
    !isAdminRegistration &&
    data &&
    data.competidor &&
    data.competidor.length > 0 &&
    data.competidor[0].escola &&
    data.competidor[0].escola.municipio
  )
    city = {
      id: data.competidor[0].escola.municipio?.id_municipio,
      name: data.competidor[0].escola.municipio?.municipio,
    };
  return city;
};

const getSchool2 = (data, isAdminRegistration, editMode) => {
  let school = {
    id: null,
    name: null,
  };
  if (
    editMode &&
    isAdminRegistration &&
    data &&
    data.organizador &&
    data.organizador.length > 0 &&
    data.organizador[0].escola
  )
    school = {
      id: data.organizador[0].escola?.id_escola,
      name: data.organizador[0].escola?.nome_escola,
    };
  else if (
    editMode &&
    !isAdminRegistration &&
    data &&
    data.competidor &&
    data.competidor.length > 0 &&
    data.competidor[0].escola
  )
    school = {
      id: data.competidor[0].escola?.id_escola,
      name: data.competidor[0].escola?.nome_escola,
    };
  return school;
};

export const handleGetUserData = (
  data,
  editMode,
  isAdminRegistration,
  toCurrentUserDisplayed: boolean = false,
  dispatch: any = null
) => {
  const email = `${isAdminRegistration ? "prof" : "aluno"}${randomInt(
    9999999,
    99999999999
  )}@gmail.com`;
  const city = getCity2(data, isAdminRegistration, editMode);
  const school = getSchool2(data, isAdminRegistration, editMode);
  const step1 = {
    id_pessoa: Number(data?.id_pessoa),
    imagem: data.imagem as IImage,
    unidade: {
      rn: "1",
      id_escola: school?.id,
      id_municipio: city?.id,
      nome_escola: school?.name,
    },
    municipio: {
      id_municipio: city?.id,
      municipio: city?.name,
      uf: "BA",
    },
    sexo: Number(data.id_sexo),
    dataNascimento: new Date(data.data_nascimento).toString(),
    matricula: isAdminRegistration
      ? data.organizador && data.organizador.length
        ? data.organizador[0].matricula
        : null
      : data.competidor && data.competidor.length
      ? data.competidor[0].matricula
      : null,
    rg: data.rg,
    cpf: data.cpf,
    nome: data.nome,
    id_perfil_solicitado:
      data.organizador && data.organizador.length
        ? Number(data.organizador[0].id_perfil_solicitado)
        : null,
    id_perfil:
      data.usuarios && data.usuarios.length
        ? Number(data.usuarios[0].id_perfil_usuario)
        : null,
    id_nte: data.id_nte
      ? Number(data?.id_nte)
      : data.organizador && data.organizador.length
      ? Number(data.organizador[0]?.id_nte)
      : null,
    nis: data?.nis,
  };

  const Responsables =
    data?.competidor &&
    data?.competidor.length > 0 &&
    data?.competidor[0]?.responsaveis &&
    data?.competidor[0]?.responsaveis.length
      ? data.competidor[0]?.responsaveis
      : [];

  const step2 = {
    responsaveis: Responsables.map((r) => {
      return {
        id_pessoa: r?.id_pessoa,
        nome: r?.nome,
        cpf: r?.cpf,
        rg: r?.rg,
        id_sexo: r?.id_sexo,
      };
    }),
    deficiencia: {
      auditiva:
        data.deficiencias &&
        data.deficiencias.length > 0 &&
        data.deficiencias.filter(
          (d) =>
            Number(d.id_deficiencia) ===
            Number(constantsData.searchIDDisabilityByName("Auditiva"))
        ).length > 0
          ? true
          : false,
      visual:
        data.deficiencias &&
        data.deficiencias.length > 0 &&
        data.deficiencias.filter(
          (d) =>
            Number(d.id_deficiencia) ===
            Number(constantsData.searchIDDisabilityByName("Visual"))
        ).length > 0
          ? true
          : false,
      motora:
        data.deficiencias &&
        data.deficiencias.length > 0 &&
        data.deficiencias.filter(
          (d) =>
            Number(d.id_deficiencia) ===
            Number(constantsData.searchIDDisabilityByName("Motora"))
        ).length > 0
          ? true
          : false,
      mental:
        data.deficiencias &&
        data.deficiencias.length > 0 &&
        data.deficiencias.filter(
          (d) =>
            Number(d.id_deficiencia) ===
            Number(constantsData.searchIDDisabilityByName("Mental"))
        ).length > 0
          ? true
          : false,
      descricao: null,
    } as Deficiency,
  };

  const step3 = {
    id_perfil_usuario:
      data.usuarios && data.usuarios.length
        ? Number(data.usuarios[0].id_perfil_usuario)
        : null,
    email:
      data.usuarios && data.usuarios.length ? data.usuarios[0].email : null,
  };

  let dataToCurrentUserDisplayed = {};
  if (!editMode) {
    data &&
      data.map((item) => {
        dataToCurrentUserDisplayed = { ...dataToCurrentUserDisplayed, ...item };
      });
  } else dataToCurrentUserDisplayed = { ...step1, ...step2, ...step3 };

  if (toCurrentUserDisplayed && dispatch) {
    {
      // handleChangeCurrentUserDisplayed(dispatch, null as CurrentUserDisplayed);
      handleChangeCurrentUserDisplayed(
        dispatch,
        dataToCurrentUserDisplayed as CurrentUserDisplayed
      );
    }
    return dataToCurrentUserDisplayed as CurrentUserDisplayed;
  } else return null;
};
