import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import { handleResetState } from "store/ducks/reset";
import { handleLogout } from "store/ducks/auth";
import { isInLogoutUrl, isLogged } from "../../../../util";

export interface Props {
  handleLogout: () => Promise<void>;
  handleResetState: () => Promise<any>;
  history: any;
}

export class Logout extends Component<Props> {
  async componentDidMount(): Promise<void> {
    const { handleLogout, handleResetState, history } = this.props;
    !isLogged() && history.push("/login");

    if (isInLogoutUrl()) {
      handleLogout().then((ress) => {
        handleResetState().then(() => {
          history.push("/login");
        });
      });
    }
  }

  render(): ReactElement {
    return <div />;
  }
}

export default connect(undefined, (dispatch: any) => ({
  handleLogout: (): any => dispatch(handleLogout()),
  handleResetState: (): any => dispatch(handleResetState()),
}))(Logout);
