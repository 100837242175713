import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "store/ducks/state";
import { getUserData } from "store/ducks/user";
import { handleAuth, AuthState } from "store/ducks/auth";
import FormStep1 from "./Steps/step1";
import { mockData } from "../../../util";
import { AuthBody, CurrentUserDisplayed } from "store/types";
import { handleGetUserData, submitRegistrationForm } from "./functions";
import ReactLoading from "react-loading";
import { Row } from "./styles";
import { handleChangeCurrentUserDisplayed } from "store/ducks/user";
import { MOCK_DATA_RELEASED } from "../../../constants/system";
interface StateProps {
  auth?: AuthState;
  loading?: boolean;
}
interface OtherProps {
  id?: number;
  header?: any;
  isAdminRegistration?: boolean;
  setFormType?: any;
  width?: string;
  hideTitle?: boolean;
  hideLoginButton?: boolean;
  formStyle?: React.CSSProperties;
  readOnly?: boolean;
  loading?: boolean;
}

interface DispatchProps {
  handleAuth?: (data: AuthBody) => Promise<any>;
}

type Props = StateProps & DispatchProps & OtherProps;

export const RegistrationForm: React.FC<Props> = (props) => {
  const {
    data: { currentUserDisplayed },
  } = useSelector((state: RootState) => state.user);
  // console.log("currentUserDisplayed:", currentUserDisplayed);

  const dispatch = useDispatch();
  const {
    id = null,
    isAdminRegistration = false,
    setFormType = null,
    hideTitle = false,
    hideLoginButton = false,
    width,
    formStyle,
    readOnly = false,
    loading = true,
  } = props;

  const [forceRender, setForceRender] = React.useState(false);
  const [lastAutocomplete, setLastAutocomplete] = React.useState(null);
  const [editMode] = React.useState(id !== null);
  const [isLoading, setIsLoading] = React.useState(loading);
  const [stepForm, setStepForm] = React.useState<any>(1);
  const [errorMessage, setErrorMessage] = React.useState<string>(null);
  const [successMessage, setSuccessMessage] = React.useState<string>(null);
  const [processingRegistration, setProcessingRegistration] =
    React.useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setForceRender(false);
    }, 10);
  }, [forceRender]);
  useEffect(() => {
    (async () => {
      if (!editMode)
        handleChangeCurrentUserDisplayed(
          dispatch,
          null as CurrentUserDisplayed
        );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (editMode) {
        if (stepForm === 2)
          (
            document.getElementById(
              "btn-step" + (isAdminRegistration ? 3 : 2)
            ) as HTMLFormElement
          ).click();
        else if (stepForm === 3)
          (document.getElementById("btn-step3") as HTMLFormElement).click();
      }
    })();
  }, [stepForm]);

  useEffect(() => {
    setIsLoading(false);

    !editMode && handleChangeCurrentUserDisplayed(dispatch, null);

    if (MOCK_DATA_RELEASED)
      handleGetUserData(
        mockData(isAdminRegistration),
        editMode,
        isAdminRegistration,
        true,
        dispatch
      );

    console.log(`id:, ${id}; id_pessoa:, ${currentUserDisplayed?.id_pessoa}`);
    console.log(`currentUserDisplayed:`, currentUserDisplayed);

    if (
      id &&
      id > 0 &&
      Number(id) !== Number(currentUserDisplayed?.id_pessoa)
    ) {
      setIsLoading(true);
      handleChangeCurrentUserDisplayed(dispatch, null);
      dispatch(getUserData(id, editMode, isAdminRegistration))
        .then((response) => {
          handleGetUserData(
            response,
            editMode,
            isAdminRegistration,
            true,
            dispatch
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("getUserData.error:", error);
          setIsLoading(false);
        });
    } else if (Number(currentUserDisplayed?.id_pessoa) > 0) {
      handleChangeCurrentUserDisplayed(
        dispatch,
        currentUserDisplayed as CurrentUserDisplayed
      );
    }
  }, []);

  function renderFormEditMode() {
    return isLoading ? (
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 300,
          minHeight: 500,
        }}
      >
        <ReactLoading type="spin" width={100} height={100} color="#c0c0c0" />
      </Row>
    ) : (
      <>
        <FormStep1
          setForceRender={setForceRender}
          lastAutocomplete={lastAutocomplete}
          setLastAutocomplete={setLastAutocomplete}
          readOnly={readOnly}
          loading={isLoading}
          editMode={true}
          isAdminRegistration={isAdminRegistration}
          header={"Cadastro De Estudante"}
          setStepForm={setStepForm}
          width={width}
          hideTitle={hideTitle}
          hideLoginButton={hideLoginButton}
          cStyle={formStyle}
        />
      </>
    );
  }

  function renderFormNormal() {
    switch (stepForm) {
      case 1:
        return (
          <FormStep1
            setForceRender={setForceRender}
            lastAutocomplete={lastAutocomplete}
            setLastAutocomplete={setLastAutocomplete}
            readOnly={readOnly}
            loading={isLoading}
            editMode={false}
            isAdminRegistration={isAdminRegistration}
            header={"Cadastro De Estudante"}
            setStepForm={setStepForm}
            width={width}
            setFormType={setFormType}
            hideLoginButton={hideLoginButton}
            cStyle={formStyle}
          />
        );

      default:
        return (
          <FormStep1
            setForceRender={setForceRender}
            lastAutocomplete={lastAutocomplete}
            setLastAutocomplete={setLastAutocomplete}
            readOnly={readOnly}
            editMode={false}
            isAdminRegistration={isAdminRegistration}
            header={"Cadastro De Estudante"}
            setStepForm={setStepForm}
            width={width}
            cStyle={formStyle}
          />
        );
    }
  }

  return forceRender ? (
    <></>
  ) : (
    <>{editMode ? renderFormEditMode() : renderFormNormal()}</>
  );
};

function mapStateToProps(state: RootState): any {
  const { auth } = state;
  return { auth };
}

export default connect(mapStateToProps, (dispatch: any) => ({
  handleAuth: (data: AuthBody): any => dispatch(handleAuth(data)),
}))(RegistrationForm);
