import styled from 'styled-components'
interface IProps {
    row?: boolean
    column?: boolean
    center?: boolean
    flex?: number
}
export const Divisor = styled.div<IProps>`
    display: flex;
    ${props => props.flex && `flex:${props.flex};`}
    ${props => props.row && `flex-direction:row;`}
    ${props => props.column && `flex-direction:column;`}
    ${props => props.center && `justify-content:center;align-items:center;`}
    gap: 20px;

    /* border:1px solid red; */
    
`;
export const Container = styled.form`
    display: flex;
    padding:15px;
    margin:25px;
    /* border:1px solid blue; */
`;
export const TextColumn = styled.div<any>`
display:flex;
width:100%;
flex-wrap: wrap;
    font-size:15px;
    font-family: roboto;   
    color:#707070;
    ${props => props?.bold && `
        font-weight:bold;
    `}
    ${props => props?.alignStart && `
        text-align:start;
    `}
`