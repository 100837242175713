import React from "react";
import { Error } from "./styles";
import AutoCompleteJEB from "../../AutoCompleteCustom";
import { Controller } from "react-hook-form";

interface IAutoCompleteControlled {
  label: string;
  options?: any[];
  name: string;
  optionsSearchJEB: string;
  textFieldStyles?: any;
  textFieldSx?: any;
  cStyles?: any;
  containerStyle?: any;
  noRequired?: boolean;
  disabled?: boolean;
  errors: any;
  control: any;
  parent?: string;
  parentValue?: any;
  defaultSelectedOption?: any;
}

const AutocompleteControlled: React.FC<IAutoCompleteControlled> = (props) => {
  const {
    name,
    options,
    disabled = false,
    label,
    optionsSearchJEB = "",
    textFieldStyles,
    textFieldSx,
    cStyles,
    containerStyle,
    noRequired = false,
    errors,
    control,
    parent,
    parentValue,
    defaultSelectedOption,
  } = props;
  const isError =
    errors && errors?.data && errors?.data[name.replace("data.", "")];

  // console.log("🚀 ~ file: index.tsx ~ line 41 ~ isError", isError);

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: !noRequired, minLength: 2 }}
        render={({ field: { onChange, value } }) => {
          return (
            <AutoCompleteJEB
              defaultSelectedOption={defaultSelectedOption}
              parent={parent}
              parentValue={parentValue}
              label={label}
              disabled={disabled}
              textFieldStyles={textFieldStyles}
              textFieldSx={textFieldSx}
              cStyles={{
                ...{ textAlign: "left ", color: "red" },
                ...cStyles,
              }}
              containerStyle={containerStyle}
              value={value}
              inputValue={value?.label}
              getOptionLabel={(item) => (item.label ? item.label : "")}
              name={optionsSearchJEB}
              error={isError ? true : false}
              size="small"
              // onChangeOptions={(options) =>
              //   dispatch(updateInscriptionOptions("championship", options))
              // }
              onChange={(event, item) => {
                onChange(item);
              }}
            />
          );
        }}
      />
      {isError && (
        <Error>
          {
            (isError
              ? isError?.message
                ? isError.message
                : "Campo obrigatório"
              : "") as unknown as React.ReactNode
          }
        </Error>
      )}
    </>
  );
};

export default React.memo(AutocompleteControlled);
