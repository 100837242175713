import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "../../../../util";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { closeAlert } from "store/ducks/alert";
import { Container, ContainerBg, ContainerBg2, Content } from "./styles";

interface IProps {
  visible: boolean;
  noPadding?: boolean;
  bttnExitStyle?: React.CSSProperties;
  children: React.ReactNode;
}

export const ModalRedux: React.FC<IProps> = (props) => {
  const { children, visible, noPadding, bttnExitStyle } = props;
  const dispatch = useDispatch();

  return (
    <>
      {visible && (
        <>
          <ContainerBg onClick={() => dispatch(closeAlert())} />
          <Container>
            <>
              <ContainerBg2 onClick={() => dispatch(closeAlert())} />
              <Content noPadding={noPadding}>
                <FontAwesomeIcon
                  onClick={() => dispatch(closeAlert())}
                  size="3x"
                  icon={icon.faWindowClose}
                  style={
                    isMobile()
                      ? bttnExitStyle
                        ? bttnExitStyle
                        : {
                            width: 40,
                            height: 40,
                            color: "#c0c0c0",
                            float: "right",
                            marginTop: 6,
                            marginRight: 10,
                            marginBottom: 10,
                            cursor: "pointer"
                          }
                      : {
                          width: 40,
                          height: 40,
                          color: "#c0c0c0",
                          float: "right",
                          marginTop: 6,
                          marginRight: 10,
                          marginBottom: -46,
                          cursor: "pointer"
                        }
                  }
                />
                {children}
              </Content>
            </>
          </Container>
        </>
      )}
    </>
  );
};

export default ModalRedux;
