import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";

import { Container, Label } from "./styles";

interface IProps {
  label: string;
  onChange: any;
  value?: any;
  defaultValue?: any;
  cStyles?: any;
  onSearch?: any;
  mode?: "normal" | "flat";
}

const useStyles = makeStyles((theme) => ({
  modeNormalStyle: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#707070",
    },
  },
  modeFlatStyle: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
      borderRadius: 0,
    },
  },
}));

export const InputFilter: React.FC<IProps> = ({
  label,
  onChange,
  value,
  defaultValue,
  cStyles,
  onSearch,
  mode = "normal",
}) => {
  const classes = useStyles();

  return (
    <Container mode={mode} style={cStyles}>
      {mode !== "flat" && <Label>{label}</Label>}
      <FormControl
        fullWidth={mode === "flat"}
        classes={
          mode === "flat"
            ? { root: classes.modeFlatStyle }
            : { root: classes.modeNormalStyle }
        }
        style={mode !== "flat" ? cStyles : {}}
      >
        {/* {console.log('LABEL: ',label,'INPUT FILTER CSS: ',cStyles, )} */}
        <TextField
          placeholder={mode === "flat" ? label : ""}
          InputProps={{
            style: Object.assign(
              { height: "38px", color: "#707070", backgroundColor: "white" },
              mode !== "flat" ? cStyles : {}
            ),
          }}
          variant="outlined"
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          onKeyUp={(event) =>
            !event.ctrlKey &&
            event.key &&
            event.key === "Enter" &&
            onSearch &&
            onSearch()
          }
        />
      </FormControl>
    </Container>
  );
};

export default InputFilter;
