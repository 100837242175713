import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import SaveIcon from "@mui/icons-material/Save";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Container, Content, Gap, Row, Title } from "./styles";
import ContentHeader from "components/ApplicationMainScreen/ApplicationContentHeader";
import * as icon from "@fortawesome/free-solid-svg-icons";
import ButtonSimple from "components/Buttons/simpleButton";
import ContentFilters from "components/ApplicationMainScreen/ApplicationContentFilters";
// import List from "components/List";
// import IconButton from "components/Buttons/iconButton";
import { Edit, Visibility } from "@mui/icons-material";
import Modal from "components/Modal";
import SimpleForm from "components/Forms/SimpleForm";
import RegistrationOrganizerForm from "components/Forms/RegistrationOrganizerForm";
import {
  isAdminLogged,
  onlyNumber,
  isHD,
  titleize,
  isMobile,
} from "../../util";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/ducks/state";
import { RowLast } from "components/Forms/RegistrationProfileUsers/styles";
import {
  alertLoading,
  showAlert,
  alertError,
  alertSuccess,
  confirmationAlert,
  closeAlert,
  alertInformation,
} from "store/ducks/alert";
import { AutoCompleteCustom } from "components";

interface IDataRow {
  id: number;
  date?: string;
  nome?: string;
  unidade?: string;
  status?: string;
  modalidade?: string;
  buttons: any;
}
interface IOptions {
  label: any;
  value: any;
}
interface IFilter {
  ref: string;
  value: any;
  label: string;
  options?: IOptions[];
  type: string;
  parent?: string;
  hintWhenEmpty?: string;
  hideEmpty?: boolean;
  parentIsNotRequired?: boolean;
  style?: any;
  unique?: boolean;
  hideMixed?: boolean;
}

const half = () => window.screen.width / 2 - 31;

export default function UIComponents(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  // const { search, loading } = useSelector((state: RootState) => state.synthesis);
  const searchPage = useSelector((state: RootState) => state.searchPage);
  // console.log("auth@auth: ", auth);
  console.log("search@search: ", searchPage);
  const { user } = auth;
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    window.screen.height <= 600 ? 5 : 8
  );
  const [thisComponentIsLoaded, setThisComponenteIsLoaded] =
    React.useState<boolean>(false);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalVisible2, setModalVisible2] = React.useState<boolean>(false);
  const [idModal, setIdModal] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  function handleClick() {
    setLoading(true);
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [checked, setChecked] = React.useState([true, false]);

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked]);
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 400,
    color: theme.palette.text.primary,
  }));

  const message = `Truncation should be conditionally applicable on this long line of text
 as this is a much longer line than what the container can support. `;

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="Child 1"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Child 2"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  return (
    <Container>
      <ContentHeader icon={icon.faGrip} text="Componentes de UI/UX">
        <>
          {" "}
          {
            <>
              <ButtonSimple
                hideTextInMobile
                style={{}}
                onClick={() => {}}
                icon={icon.faPlus}
                text="Novo"
                styleIcon={{ width: "15px", height: "15px" }}
              />
              <ButtonSimple
                hideTextInMobile
                style={{}}
                onClick={() => {}}
                icon={icon.faFileExport}
                text="Exportar"
                styleIcon={{ width: "15px", height: "15px" }}
              />
            </>
          }
        </>
      </ContentHeader>
      <Content>
        <Row>
          <Title>Modal</Title>
        </Row>
        <Row>
          <Button
            variant="contained"
            disableElevation
            onClick={() => setModalVisible(true)}
          >
            MODAL COMUM
          </Button>
          <Button variant="contained" onClick={() => setModalVisible2(true)}>
            MODAL COM ABAS
          </Button>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Title>Dialogos</Title>
        </Row>
        <Row>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(
                alertInformation(
                  "ATENÇÃO!",
                  "Aqui tem uma informação importante para você."
                )
              );
            }}
          >
            INFORMAÇÃO
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(
                confirmationAlert(
                  "Deseja realmente realziar esta ação?",
                  () => {
                    alert("Clicou em confirmar!");
                    dispatch(closeAlert());
                  }
                )
              );
            }}
          >
            CONFIRMAÇÃO
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              setTimeout(() => {
                dispatch(
                  showAlert({
                    title: "CONFIRME!",
                    description: `Informe sua senha, abaixo, para concluir esta ação.`,
                    input: { label: "Senha:", type: "password" },
                    backButton: {
                      label: "Voltar",
                    },
                    confirmationButton: {
                      onClick: (value) => {
                        dispatch(closeAlert());
                      },
                    },
                  })
                );
              }, 100);
            }}
          >
            PROMPT
          </Button>

          <Button
            variant="outlined"
            onClick={() =>
              dispatch(alertSuccess("A ação foi realizada com sucesso!"))
            }
          >
            SUCESSO
          </Button>
          <Button variant="outlined" onClick={() => dispatch(alertLoading())}>
            CARREGANDO...
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              dispatch(alertError("Não foi possível realizar esta ação!"))
            }
          >
            ERRO
          </Button>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Title>Botões</Title>
        </Row>
        <Row>
          <Button variant="contained">Contained</Button>
          <Button variant="outlined">Outlined</Button>
          <Button variant="contained" disabled>
            Disabled
          </Button>
          <Button variant="text">Text</Button>
          <Button>Primary</Button>
          <Button href="#text-buttons">Link</Button>
          <Button disabled>Disabled</Button>
        </Row>
        <Row>
          <Button variant="contained" href="#contained-buttons">
            Link
          </Button>

          <Button variant="contained" disableElevation>
            Disable elevation
          </Button>
          <Button variant="contained" color="success">
            Success
          </Button>
          <Button variant="outlined">Primary</Button>
          <Button variant="outlined" href="#outlined-buttons">
            Link
          </Button>
          <Button variant="outlined" color="error">
            Error
          </Button>
          <Button variant="outlined" disabled>
            Disabled
          </Button>

          <Button color="secondary">Secondary</Button>
        </Row>
        <Row>
          <Box sx={{ "& button": { m: 1 } }}>
            <div>
              <Button size="small">Small</Button>
              <Button size="medium">Medium</Button>
              <Button size="large">Large</Button>
            </div>
            <div>
              <Button variant="outlined" size="small">
                Small
              </Button>
              <Button variant="outlined" size="medium">
                Medium
              </Button>
              <Button variant="outlined" size="large">
                Large
              </Button>
            </div>
            <div>
              <Button variant="contained" size="small">
                Small
              </Button>
              <Button variant="contained" size="medium">
                Medium
              </Button>
              <Button variant="contained" size="large">
                Large
              </Button>
            </div>
          </Box>
          <Box>
            <FormControlLabel
              sx={{
                display: "block",
              }}
              control={
                <Switch
                  checked={loading}
                  onChange={() => setLoading(!loading)}
                  name="loading"
                  color="primary"
                />
              }
              label="Loading"
            />
            <Box sx={{ "& > button": { m: 1 } }}>
              <LoadingButton
                size="small"
                onClick={handleClick}
                loading={loading}
                variant="outlined"
                disabled
              >
                disabled
              </LoadingButton>
              <LoadingButton
                size="small"
                onClick={handleClick}
                loading={loading}
                loadingIndicator="Loading…"
                variant="outlined"
              >
                Fetch data
              </LoadingButton>
              <LoadingButton
                size="small"
                onClick={handleClick}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                Send
              </LoadingButton>
              <LoadingButton
                size="small"
                color="secondary"
                onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                Save
              </LoadingButton>
            </Box>

            <Box sx={{ "& > button": { m: 1 } }}>
              <LoadingButton
                onClick={handleClick}
                loading={loading}
                variant="outlined"
                disabled
              >
                disabled
              </LoadingButton>
              <LoadingButton
                onClick={handleClick}
                loading={loading}
                loadingIndicator="Loading…"
                variant="outlined"
              >
                Fetch data
              </LoadingButton>
              <LoadingButton
                onClick={handleClick}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                Send
              </LoadingButton>
              <LoadingButton
                color="secondary"
                onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Row>
        <Row>
          <Button variant="contained" component="label">
            Upload
            <input hidden accept="image/*" multiple type="file" />
          </Button>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input hidden accept="image/*" type="file" />
            <PhotoCamera />
          </IconButton>
          <Button variant="outlined" startIcon={<DeleteIcon />}>
            Delete
          </Button>
          <Button variant="contained" endIcon={<SendIcon />}>
            Send
          </Button>
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="delete" disabled color="primary">
            <DeleteIcon />
          </IconButton>
          <IconButton color="secondary" aria-label="add an alarm">
            <AlarmIcon />
          </IconButton>
          <IconButton color="primary" aria-label="add to shopping cart">
            <AddShoppingCartIcon />
          </IconButton>
        </Row>

        <Row style={{ marginTop: 40 }}>
          <Title>Campos/Controles De Formulário</Title>
        </Row>
        <Row>
          <Switch {...label} defaultChecked />
          <Switch {...label} />
          <Switch {...label} disabled defaultChecked />
          <Switch {...label} disabled />
        </Row>
        <Row>
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
                <FormControlLabel
                  value="disabled"
                  disabled
                  control={<Radio />}
                  label="other"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Row>
        <Row>
          <div>
            <Checkbox
              {...label}
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
            />
            <Checkbox
              {...label}
              icon={<BookmarkBorderIcon />}
              checkedIcon={<BookmarkIcon />}
            />
            <Checkbox {...label} defaultChecked size="small" />
            <Checkbox {...label} defaultChecked />
            <Checkbox
              {...label}
              defaultChecked
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />
            <Checkbox {...label} defaultChecked />
            <Checkbox {...label} defaultChecked color="secondary" />
            <Checkbox {...label} defaultChecked color="success" />
            <Checkbox {...label} defaultChecked color="default" />
            <Checkbox
              {...label}
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
          </div>
        </Row>
        <Row>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Label"
              />
              <FormControlLabel
                disabled
                control={<Checkbox />}
                label="Disabled"
              />
            </FormGroup>
          </Box>
          <Box>
            <FormControlLabel
              label="Parent"
              control={
                <Checkbox
                  checked={checked[0] && checked[1]}
                  indeterminate={checked[0] !== checked[1]}
                  onChange={handleChange1}
                />
              }
            />
            {children}
          </Box>
        </Row>
        <Row>
          <AutoCompleteCustom
            label={"Escola"}
            name={"id_escola"}
            size="small"
            onChange={(event, values) => {
              console.log("AutoCompleteCustom.values:", values);
            }} 
          />
        </Row>
        <Row>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Google Maps"
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
        </Row>
        <Row>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                label="Size"
                id="outlined-size-small"
                defaultValue="Small"
                size="small"
              />
              <TextField
                label="Size"
                id="outlined-size-normal"
                defaultValue="Normal"
              />
            </div>
            <div>
              <TextField
                label="Size"
                id="filled-size-small"
                defaultValue="Small"
                variant="filled"
                size="small"
              />
              <TextField
                label="Size"
                id="filled-size-normal"
                defaultValue="Normal"
                variant="filled"
              />
            </div>
            <div>
              <TextField
                label="Size"
                id="standard-size-small"
                defaultValue="Small"
                size="small"
                variant="standard"
              />
              <TextField
                label="Size"
                id="standard-size-normal"
                defaultValue="Normal"
                variant="standard"
              />
            </div>
          </Box>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Title>Listas</Title>
        </Row>
        <Row>
          <Box sx={{ flexGrow: 1 }}>
            <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                  </ListItem>
                </List>
              </Grid>
            </StyledPaper>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                  </ListItem>
                </List>
              </Grid>
            </StyledPaper>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
            <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>W</Avatar>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap>{message}</Typography>
                </Grid>
              </Grid>
            </StyledPaper>
            <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>W</Avatar>
                </Grid>
                <Grid item xs>
                  <Typography noWrap>{message}</Typography>
                </Grid>
              </Grid>
            </StyledPaper>
            <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>W</Avatar>
                </Grid>
                <Grid item xs>
                  <Typography>{message}</Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          </Box>
        </Row>
      </Content>
      <Modal visible={modalVisible} setVisible={setModalVisible} noPadding>
        <SimpleForm id={idModal} />
      </Modal>
      <Modal visible={modalVisible2} setVisible={setModalVisible2} noPadding>
        <RegistrationOrganizerForm id={idModal} />
      </Modal>
    </Container>
  );
}
