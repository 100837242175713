import styled from 'styled-components';

interface IProps {
    width?: string;
    small?: boolean;
}


export const Container = styled.div<IProps>`
    width: ${(props) => props.width ? props.width : '100%'};
    height:auto;
    padding: 0px;
    margin: 0 auto;
    background-color: white;
`;

export const ErrorMessageTitle = styled.h1<IProps>`
width: 100%;
color: red !important;
font-size: ${(props) => props.small ? 25 : 35}px !important;
margin-bottom: 10px;
font-weight: bold !important;
`;

export const ErrorMessage = styled.div<IProps>`
color: red !important;
font-size: ${(props) => props.small ? 16 : 21}px !important;
margin-top: 10px;
margin-bottom: 10px;
background-color: #f8f8f8;
padding: 10px;
border-radius: 7px;
font-weight: bold !important;
width: ${(props) => props.width ? props.width : '100%'};
`;

// @media only screen and (min-width: 728px) and (max-width: 1366px) {
    
// }

export const SuccessMessageTitle = styled.h1<IProps>`
width: 100%;
color: green !important;
font-size: ${(props) => props.small ? 25 : 35}px !important;
margin-bottom: 10px;
font-weight: bold !important;
`;

export const SuccessMessage = styled.div<IProps>`
color: green !important;
font-size: ${(props) => props.small ? 16 : 21}px !important;
margin-top: 10px;
margin-bottom: 10px;
background-color: #f8f8f8;
padding: 25px 25px;
border-radius: 7px;
font-weight: bold !important;
width: ${(props) => props.width ? props.width : '100%'};
`;

export const InfoMessageTitle = styled.h1<IProps>`
width: 100%;
color: #3131315e !important;
font-size: ${(props) => props.small ? 25 : 35}px !important;
margin-bottom: 10px;
font-weight: bold !important;
`

export const InfoMessage = styled.div<IProps>`
color: #31313138 !important;
font-size: ${(props) => props.small ? 16 : 21}px !important;
margin-top: 10px;
margin-bottom: 10px;
background-color: #f8f8f8;
padding: 25px 25px;
border-radius: 7px;
font-weight: bold !important;
width: ${(props) => props.width ? props.width : '100%'};
`;
