import React from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Container,
  Paginator,
  Back,
  Prox,
  Count,
  Text,
  Registers,
  ContainerSelectPage
} from "./styles";
interface IProps {
  page: number;
  setPage: any;
  rowsPerPage: number;
  setRowsPerPage: any;
  countData: number;
  lastpage?: number;
}
const Pagination: React.FC<IProps> = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  countData,
  lastpage = null
}) => {
  const renderButtonsPages = () => {
    let buttons = [];

    if (window.screen.width > 768)
      for (let i = page - 2; i < page; i++)
        i > 0 &&
          buttons.push(
            <Count
              key={`left${i}`}
              onClick={() => {
                setPage(i);
              }}
            >
              {i}
            </Count>
          );

    buttons.push(
      <Count key={`center`} active style={{ maxWidth: 61 }}>
        {page}
      </Count>
    );

    if (window.screen.width > 768)
      for (
        let i = page + 1;
        i <= page + 2 && (lastpage === null || i <= lastpage);
        i++
      )
        buttons.push(
          <Count
            key={`right${i}`}
            onClick={() => {
              setPage(i);
            }}
          >
            {i}
          </Count>
        );

    return (
      <Paginator>
        <Back
          onClick={() => {
            setPage(page > 1 ? page - 1 : 1);
          }}
          style={{ flex: 1, minWidth: 100 }}
        >
          {"« Anterior"}
        </Back>
        {buttons.map((button) => button)}
        <Prox
          onClick={() => {
            setPage(page + 1);
          }}
          style={{ flex: 1, minWidth: 100 }}
        >
          {"Próximo »"}
        </Prox>
      </Paginator>
    );
  };

  let first = page * rowsPerPage - rowsPerPage + 1;
  let last = page * rowsPerPage;
  if (last > countData) last = countData;

  return (
    <Container>
      {window.screen.width <= 768 && renderButtonsPages()}
      <ContainerSelectPage>
        <Text>Exibir</Text>
        <Select
          sx={{
            height: "32px",
            margin: "0 8px",
            minWidth: window.screen.width <= 768 ? 130 : "auto"
          }}
          onChange={(value) => setRowsPerPage(value.target.value)}
          defaultValue={rowsPerPage}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
        <Text>por página</Text>
      </ContainerSelectPage>
      <Registers>
        {first} - {last} de {countData} registros
      </Registers>
      {window.screen.width > 768 && renderButtonsPages()}
    </Container>
  );
};
export default Pagination;
