import styled from 'styled-components'
interface IProps {
    color?: string;
    weightBold?: boolean;
}

export const ContentColumn = styled.div<IProps>`
    display:flex;
    flex:1;
    margin-right: 20px;
    font-size:1em;
    align-items:center;
    white-space: wrap;
    text-align: left;
    line-height: 1.25em;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight:${(props) => props.weightBold ? 'bold' : 'normal'};
    ${(props) => props.color === 'link' && `color: rgb(65,154,233);`}

    @media only screen and (max-width: 728px){
        display: flex;
        flex-direction: column;
        align-items:start;
    }

`;

export const ContentColumnOption = styled.div`
    display:flex;
    flex:1;
    font-size:0.9em;
    align-items:center;
    white-space: nowrap;
    justify-content: right;
    overflow: hidden !important;
    text-overflow: ellipsis;
    @media only screen and (max-width: 728px){
        align-items:start;
    }
`;