import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Center,
  Text,
  Column,
  RowLast,
  Row,
  selectStyle,
} from "./styles";

import {
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Header from "../../../Header";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Schema from "../../../../yup/Schemas/Organizer/Access";
import { getUserData } from "store/ducks/user";
import { handleUpdateProfileId } from "store/ducks/user";
import { connect } from "react-redux";
import Button from "../../../Button";
import BoxFlatAlert from "components/Alerts/BoxFlatAlert";
import { RootState } from "store/ducks/state";

interface IProps {
  personId?: number;
}

interface IAccess {
  perfil: string;
  // checkbox1: boolean;
  // checkbox2: boolean;
  // checkbox3: boolean;
  // checkbox4: boolean;
  // checkbox5: boolean;
  // checkbox6: boolean;
  // checkbox7: boolean;
  // checkbox8: boolean;
  // checkbox9: boolean;
  // checkbox10: boolean;
}
const profiles = [
  { key: 1, label: "Professor/Gestor", value: 1 },
  { key: 2, label: "Facilitador", value: 2 },
];

const Tab2: React.FC<IProps> = (props) => {
  const {
    data: { currentUserDisplayed },
  } = useSelector((state: RootState) => state.user);
  const { personId } = props;

  const dispatch = useDispatch();
  const pId = 1;
  const [profileId, setProfileId] = useState(pId);
  const [profileData, setProfileData] = useState(undefined);
  const [profileOptions, setProfileOptions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>(null);
  const [successMessage, setSuccessMessage] = React.useState<string>(null);
  useEffect(() => {
    if (Number(profileId) === 1)
      setErrorMessage("Por favor, selecione a função deste cadastro!");
  }, [profileOptions, profileId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAccess>({ resolver: yupResolver(Schema) });

  const handleSalve = () => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    dispatch(handleUpdateProfileId(personId, profileId))
      .then((response) => setSuccessMessage(response.msg), setLoading(false))
      .catch((error) => setErrorMessage(error.msg), setLoading(false));
  };

  useEffect(() => {
    console.log("profileData:", profileData);
  }, [profileData]);

  const onSubmit: SubmitHandler<IAccess> = (data) => {
    console.log("Tab2.onsubmit:", data);
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  useEffect(
    () =>
      setProfileOptions(
        profileData &&
          profileData.length > 0 &&
          profileData
            .filter((p) => p.id_perfil_usuario !== 2)
            .map((p) => {
              return {
                label: p.perfil,
                value: p.id_perfil_usuario,
              };
            })
      ),
    [profileData]
  );




  const handleChangeProfile = (value) => {
    setProfileId(value);
  };

  console.log("profileId:", profileId);
  pId && !profileId && handleChangeProfile(pId);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Header>Título da Tab</Header>
        <div style={{ width: "100%", height: 400 }}></div>
      </Row>
    </Container>
  );
};

export default Tab2;
