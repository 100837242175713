import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";

import { Container, Label } from "./styles";
import api from "services/api";
import { axiosGetHeaders, titleize } from "../../../util";
import { IOption } from "components/Inputs/AutoCompleteCustom";
import { SearchcController } from "./functions";
interface IOptions {
  parentValue?: any;
  label: string;
  value: string;
}
interface IProps {
  disabled?: boolean;
  name?: string;
  label: string;
  options: any;
  onChange: any;
  onChangeFilter?: any;
  value?: any;
  setFirstValue?: boolean;
  setLastValue?: boolean;
  cStyles?: any;
  parent?: string;
  parentValue?: any;
  hintWhenEmpty?: string;
  noHintWhenEmpty?: boolean;
  parentIsNotRequired?: boolean;
  labelStyle?: React.CSSProperties;
  hideMixed?: boolean;
  hideEmpty?: boolean;
  unique?: boolean;
  mode?: "flat" | "normal" | "flat-round";
  filters?: any;
  setFilters?: any;
}
const useStyles = makeStyles((theme) => ({
  modeNormalStyleCustom: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#707070",
    },
  },
  modeFlatStyleCustom: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
      borderRadius: 0,
    },
  },
  modeFlatRoundStyleCustom: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c0c0c0",
      borderRadius: 3,
    },
  },
}));

export const SelectFilter: React.FC<IProps> = ({
  disabled = false,
  name,
  label,
  options,
  onChangeFilter,
  onChange,
  value,
  setLastValue = false,
  setFirstValue = false,
  cStyles,
  labelStyle,
  parent,
  parentValue,
  hintWhenEmpty = null,
  noHintWhenEmpty = false,
  hideEmpty = false,
  unique = false,
  parentIsNotRequired = false,
  hideMixed = false,
  mode = "normal",
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const [thisOptions, setThisOptions] = React.useState(options);
  const classes = useStyles();

  React.useEffect(() => {
    filters &&
      setFilters &&
      setFilters(
        filters.map((f) =>
          f.ref === name ? { ...f, options: thisOptions } : f
        )
      );
  }, [thisOptions]);

  const handleHint = (
    hintWhenEmpty,
    parentValue,
    thisOptions,
    thisOptionsFiltered
  ) => {
    if (parent && parent.length > 0 && parentValue === null)
      return hintWhenEmpty;
    else if (
      parent &&
      parent.length > 0 &&
      parentValue !== null &&
      thisOptions.length &&
      !thisOptionsFiltered.length
    )
      return "Não se aplica";
    else return "Selecione";
  };

  const handleUnique = (list) => {
    const exist = (newList, item) =>
      newList.filter((i) => i.value === item.value && i.label === item.label)
        .length;
    let newList = [];
    list.map((item) => {
      // console.log('handleUnique.item:', item)
      if (!exist(newList, item)) newList.push(item);
    });

    return newList;
  };

  useEffect(() => {
    SearchcController(name, options, setThisOptions);
  }, []);

  const thisOptionsFiltered =
    thisOptions && thisOptions.length
      ? thisOptions.filter(
          (item) =>
            !item.parentValue ||
            String(item.parentValue) === String(parentValue)
        )
      : [];

  const checkParentValue = (
    parent,
    parentValue,
    itemParentValue,
    parentIsNotRequired = false
  ) => {
    if (parent && !parentIsNotRequired && !parentValue) return false;
    else if (!parent || (parentIsNotRequired && !parentValue)) return true;
    else if (!parentValue || !itemParentValue) return true;
    else if (!itemParentValue) return true;
    else if (Array.isArray(itemParentValue))
      return parentValue && itemParentValue.includes(Number(parentValue));
    else return String(itemParentValue) === String(parentValue);
  };

  if (setFirstValue) {
    const firstValue =
      thisOptions && thisOptions.length > 0 && thisOptions[0].value
        ? thisOptions[0].value
        : null;
    firstValue &&
      firstValue !== value &&
      setTimeout(() => onChangeFilter(name || null, firstValue), 100);
  } else if (setLastValue) {
    const lastValue =
      thisOptions &&
      thisOptions.length > 0 &&
      thisOptions[thisOptions.length - 1].value
        ? thisOptions[thisOptions.length - 1].value
        : null;
    lastValue &&
      lastValue !== value &&
      setTimeout(() => onChangeFilter(name || null, lastValue), 100);
  }

  return hideEmpty &&
    parent !== null &&
    parent.length &&
    parentValue === null ? (
    <></>
  ) : (
    <Container
      mode={mode}
      style={cStyles}
      key={`SelectFilter-container-${name}`}
    >
      <Label mode={mode} style={labelStyle} key={`SelectFilter-label-${name}`}>
        {label}
      </Label>
      <FormControl
        fullWidth
        classes={
          mode === "flat"
            ? { root: classes.modeFlatStyleCustom }
            : mode === "flat-round"
            ? { root: classes.modeFlatRoundStyleCustom }
            : { root: classes.modeNormalStyleCustom }
        }
        key={`SelectFilter-formControl-${name}`}
      >
        {["flat", "flat-round"].includes(mode) && (
          <InputLabel id="demo-simple-select-label" sx={{ top: 0 }}>
            {label}
          </InputLabel>
        )}
        <Select
          disabled={disabled}
          label={["flat", "flat-round"].includes(mode) ? label : ""}
          key={`SelectFilter-${name}`}
          sx={[
            {
              minWidth: "150px",
              height: "38px",
              backgroundColor: "white",
              textAlign: "left",
              color: "#707070",
            },
            ["flat"].includes(mode) ? { width: "100%" } : cStyles,
          ]}
          onChange={onChange}
          value={value}
          defaultValue=""
          fullWidth
        >
          {!noHintWhenEmpty && (
            <MenuItem value="" key={`SelectFilter-menuItem-${name}`}>
              <em>
                {handleHint(
                  hintWhenEmpty,
                  parentValue,
                  thisOptions,
                  thisOptionsFiltered
                )}
              </em>
            </MenuItem>
          )}
          {thisOptions &&
            thisOptions
              .filter((item) =>
                checkParentValue(
                  parent,
                  parentValue,
                  item.parentValue,
                  parentIsNotRequired
                )
              )
              .map((item, index) => (
                <MenuItem
                  key={`SelectFilter-item-${name}${index}`}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Container>
  );
};

export default SelectFilter;
