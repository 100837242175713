import styled from "styled-components";

interface IProps {
  isAdminLogged: boolean;
}
export const Container = styled.div<IProps>`
  transition: 0.2s;
  grid-area: TOPBAR;
  background-color: ${(props) =>
    props.isAdminLogged ? "rgb(0,77,145)" : "#0E6300"};
  display: flex;
  padding: 0 50px 0 0;
  align-items: center;
  @media only screen and (max-width: 728px) {
    padding: 0;
  }
  .logoGov {
    width: 250px;
    @media only screen and (min-width: 1280px) and (max-width: 1566px) {
      width: 225px;
    }
  }
  .buttonMenu {
    margin-left: 32px;
    @media only screen and (min-width: 1280px) and (max-width: 1566px) {
      margin-left: 20px;
    }
    @media only screen and (max-width: 728px) {
      margin-left: 10px;
    }
  }
`;
export const Right = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: right;
  @media only screen and (max-width: 728px) {
    display: none;
  }
`;
export const Left = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Text = styled.div`
  width: 300px;
  height: 100%;
  font-size: 1.4em;
  color: white;
  align-items: center;
  display: flex;
  @media only screen and (max-width: 728px) {
    width: auto;
    /* border:1px solid red; */
    font-size: 20px;
  }
`;
export const styleContainerMenu = {
  width: "30px",
  marginLeft: "15px",
  marginRight: "14px",
  backgroundColor: "transparent"
};
