import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import ContentHeader from "components/ApplicationMainScreen/ApplicationContentHeader";
import * as icon from "@fortawesome/free-solid-svg-icons";
import Option from "components/List/Option";
import ContentFilters from "components/ApplicationMainScreen/ApplicationContentFilters";
import List from "components/List";
import IconButton from "components/Buttons/iconButton";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Modal from "components/Modal";
import SimpleForm from "components/Forms/SimpleForm";
import RegistrationOrganizerForm from "components/Forms/RegistrationOrganizerForm";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchPageSearch } from "store/ducks/searchPage";
import { SYSTEM_GLOBAL_PARAMETERS } from "constants/system";
import { RootState } from "store/ducks/state";
import { IFilter } from "components/ApplicationMainScreen/ApplicationContentFilters";
import { Container, Content, Gap, IconsBox, BoxButtonViewMode } from "./styles";
import {
  isMobile,
  isFullHD,
  handleFiltersToOnsearch,
  onChangeFilter,
} from "../../util";
import {
  Check,
  Edit,
  Visibility,
  Print,
  Settings,
  GridOn,
  GridView,
} from "@mui/icons-material";

export default function Search1(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const searchPage = useSelector((state: RootState) => state.searchPage);
  const [viewMode, setViewMode] = React.useState<number>(1);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalVisible2, setModalVisible2] = React.useState<boolean>(false);
  const [idModal, setIdModal] = React.useState(null);
  const [filters, setFilters] = React.useState<IFilter[]>(pageFilters);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    SYSTEM_GLOBAL_PARAMETERS.DEFAULT_ROWS_PER_PAGE
  );

  useEffect(() => {
    (async () => {
      onSearch();
    })();
  }, [, page, rowsPerPage, viewMode]);

  const handleModal = (id = null, index = 1) => {
    index === 2 ? setModalVisible2(true) : setModalVisible(true);
    setIdModal(id);
  };

  const onSearch = () =>
    dispatch(
      handleSearchPageSearch(
        `?page=${page}&per_page=${rowsPerPage}&view_mode=${viewMode}${handleFiltersToOnsearch(
          filters
        )}`
      )
    );

  return (
    <Container>
      <ContentHeader
        icon={icon.faSearch}
        text={"Título Da Página De Pesquisa"}
        buttons={pageButtons}
      />
      <Content>
        <ContentFilters
          filters={filters}
          onSearch={onSearch}
          onChangeFilter={(ref, value) =>
            onChangeFilter(ref, value, filters, setFilters)
          }
        />
        <ButtonViewMode viewMode={viewMode} setViewMode={setViewMode} />
        <List
          forceArrayToColumns={true}
          ColumnsName={ColumnNames}
          isLoading={searchPage.loading["loading.search"]}
          infor={searchPage.search.headers}
          List={handleList(searchPage.search.list, Buttons, handleModal)}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Content>
      <Modal visible={modalVisible2} setVisible={setModalVisible2} noPadding>
        <SimpleForm id={idModal} />
      </Modal>
      <Modal visible={modalVisible} setVisible={setModalVisible} noPadding>
        <RegistrationOrganizerForm id={idModal} />
      </Modal>
    </Container>
  );
}

function ButtonViewMode({ viewMode, setViewMode }) {
  return (
    <IconsBox>
      <BoxButtonViewMode onClick={() => setViewMode(viewMode === 1 ? 2 : 1)}>
        {viewMode === 1 ? (
          <GridOn color="inherit" />
        ) : (
          <GridView color="inherit" />
        )}
      </BoxButtonViewMode>
    </IconsBox>
  );
}

const Buttons = (id, handleModal) => {
  return (
    <Gap>
      <IconButton
        key={`search-page-btn-1-${id}`}
        onClick={() => handleModal(id, 1)}
      >
        <Visibility color="inherit" />
      </IconButton>
      <IconButton
        key={`search-page-btn-2-${id}`}
        onClick={() => window.open("/tela-de-consulta/MTQx", "_blank")}
      >
        <Print color="inherit" />
      </IconButton>
      <IconButton
        key={`search-page-btn-3-${id}`}
        onClick={() => handleModal(id, 2)}
      >
        <Edit color="inherit" />
      </IconButton>
      <Option id={id}>
        <MenuItem onClick={() => {}}>
          <ListItemIcon>
            <Check sx={{ color: "#169A00" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText disableTypography sx={{ color: "#169A00" }}>
            Ação 1
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <ListItemIcon>
            <Edit sx={{ color: "#169A00" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText disableTypography sx={{ color: "#169A00" }}>
            Ação 2
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <ListItemIcon>
            <Settings sx={{ color: "#169A00" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText disableTypography sx={{ color: "#169A00" }}>
            Ação 3
          </ListItemText>
        </MenuItem>
      </Option>
    </Gap>
  );
};

const handleList = (list, Buttons, handleModal) => {
  return (
    list &&
    list.length > 0 &&
    list.map((item, index) => {
      return {
        id: { styles: { maxWidth: 100, minWidth: 100 }, text: item.id },
        distritos: {
          styles: { width: "auto" },
          text: {
            title: `${item.nome}, ${item.municipio.nome}`,
            subTitle: `${item.municipio.nome}`,
          },
        },
        mesoregiao: {
          styles: { width: "auto" },
          text: {
            title: `${item.municipio.microrregiao.nome}`,
            subTitle: `${item.municipio.microrregiao.mesorregiao.nome}`,
          },
        },
        estado: {
          styles: { width: "auto" },
          text: `${item.municipio.microrregiao.mesorregiao.UF.sigla} - ${item.municipio.microrregiao.mesorregiao.UF.regiao.nome}`,
        },
        buttons: {
          styles: { maxWidth: 150, minWidth: 150 },
          buttons: Buttons(item.id, handleModal),
        },
      };
    })
  );
};

const pageButtons = [
  {
    label: "Novo",
    icon: icon.faPlus,
    onClick: () => {},
  },
  {
    label: "Imprimir",
    icon: icon.faPrint,
    onClick: () => {},
  },
  {
    label: "Exportar",
    icon: icon.faFileExport,
    onClick: () => {},
  },
];

const ColumnNames = [
  {
    styles: { maxWidth: 100, minWidth: 100 },
    text: "COD",
  },
  {
    styles: { width: "auto" },
    text: "Distrito",
  },
  {
    styles: { width: "auto", paddingLeft: 18 },
    text: "Mesoregião",
  },
  {
    styles: { width: "auto", paddingLeft: 20 },
    text: "Estado",
  },
  {
    styles: { maxWidth: 150, minWidth: 150 },
    text: "",
  },
];

const pageFilters = [
  {
    ref: "id_sexo",
    value: null,
    type: "select",
    label: "Gênero",
    style: isFullHD()
      ? { minWidth: 150, maxWidth: 150 }
      : { minWidth: 75, maxWidth: 75 },
    options: [],
  },
  {
    ref: "cpf",
    value: null,
    type: "input",
    label: "CPF",
    style: isFullHD()
      ? { minWidth: 150, maxWidth: 150 }
      : { minWidth: 120, maxWidth: 120 },
  },
  {
    ref: "name",
    value: null,
    type: "input",
    label: "Name",
    style: isFullHD()
      ? { minWidth: 250, maxWidth: 250 }
      : { minWidth: 200, maxWidth: 200 },
  },

  {
    ref: "id_nte",
    value: null,
    type: "select",
    label: "NTE",
    style: isFullHD()
      ? { minWidth: 250, maxWidth: 250 }
      : { minWidth: 150, maxWidth: 150 },
    options: [],
  },
  {
    ref: "id_escola",
    value: null,
    type: "autocomplete",
    label: "Escola",
    style: isFullHD()
      ? { minWidth: 300, maxWidth: 300 }
      : { minWidth: 250, maxWidth: 250 },
  },
] as IFilter[];
