import styled from 'styled-components';
// import 'typeface-roboto'
interface IProps {
    width?: string;
}
export const Container = styled.form<IProps>`
    
    ${(props)=>props.width? `width:${props.width};`:`width:600px;` }
    height:auto;
    padding: 41px 54px 29px 54px;
    background-color: white;
    margin: 0 auto;
    @media only screen and (max-width: 728px) {
        padding: 41px 0 29px 0;
    }
`;

export const Center = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex:1;
`;


export const Row = styled.div`
    /* background-color: red; */
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.3em;
    @media only screen and (min-width : 480px) and (max-width : 1366px) {
        margin-bottom: 0.9em;
    }
    .formCheckbox{
        flex-direction: row;
    }
    @media only screen and (max-width : 320px) and (max-height : 568px) {
        flex-direction: column;
        margin: 0;
    }
`;
export const Column = styled.div`
    /* background-color: red; */
    width: 100%;
    flex-direction: column;
    display: flex;
    margin:0 5px;
    @media only screen and (max-width : 320px) and (max-height : 568px) {
        flex-direction: column;
        margin-bottom: 1.3em;
    }
`;

export const Error = styled.div`
    width: 100%;
    display: flex;
    margin:0;
    color:red;
    /* border:1px solid red; */
`;

export const BoxConfirmationTitle = styled.h1`
    /* background-color: red; */
    width: 100%;
    flex-direction: row;
    display: flex;
    font-size: 45px;
    color: green;
    font-weight: bold;
    margin-bottom: 30px;
`;

export const BoxConfirmationMessage = styled.div`
    /* background-color: red; */
    width: 100%;
    flex-direction: row;
    display: flex;
    color: #403f3f;
    font-size: 22px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 20px;
`;

export const ErrorMessageTitle = styled.h1<IProps>`
    width: 100%;
    color: red !important;
    font-size: 35px !important;
    margin-bottom: 10px;
    font-weight: bold !important;
`;

export const SuccessMessageTitle = styled.h1<IProps>`
    width: 100%;
    color: green !important;
    font-size: 35px !important;
    margin-bottom: 10px;
    font-weight: bold !important;
`;

export const ErrorMessage = styled.div<IProps>`
    color: red !important;
    font-size: 21px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #efeaea;
    padding: 20px 10px;
    border-radius: 7px;
    font-weight: bold !important;
    width: ${(props) => props.width ? props.width : '70%'};
`;

export const SuccessMessage = styled.div<IProps>`
    color: green !important;
    font-size: 21px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #efeaea;
    padding: 20px 10px;
    border-radius: 7px;
    font-weight: bold !important;
    width: ${(props) => props.width ? props.width : '70%'};
`;

export default Container;