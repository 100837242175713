import { IOption } from "components/Inputs/AutoCompleteCustom";
import api from "services/api";
import { axiosGetHeaders, titleize } from "../../../util";

export async function SearchcController(name, options, setThisOptions) {
  name === "id_nte" && notHaveOptions(options) && SearchNTE(setThisOptions);
  name === "id_sexo" && notHaveOptions(options) && SearchSex(setThisOptions);
  name === "id_situacao" && notHaveOptions(options) && SearchSituation(setThisOptions);
}

export async function SearchNTE(setThisOptions) {
  return await api
    .get(`/ntes?per_page=99`, axiosGetHeaders())
    .then((response: any) => {
      const list = response.data.map((item) => {
        let NTE = titleize(item.nome);
        if (NTE.indexOf("Nte ") > -1) NTE = NTE.replace("Nte ", "NTE ");
        return { ...item, value: item.id_nte, label: NTE };
      });
      setThisOptions(list as IOption[]);
    })
    .catch((err: any) => defaultOptions(err));
}

export async function SearchSex(setThisOptions) {
  return await api
    .get(`/sexos`, axiosGetHeaders())
    .then((response: any) => {
      const list = response.data
        .filter((item) => Number(item.id_sexo) !== 3)
        .map((item) => ({
          value: item.id_sexo,
          label:
            item.sexo.toLowerCase().charAt(0).toUpperCase() +
            item.sexo.toLowerCase().substr(1),
        }));
      setThisOptions(list as IOption[]);
    })
    .catch((err: any) => defaultOptions(err));
}

export async function SearchSituation(setThisOptions) {
  return await api
    .get(`/situacoes`, axiosGetHeaders())
    .then((response: any) => {
      const list = response.data
        .filter((item) => Number(item.id_sexo) !== 3)
        .map((item) => ({
          value: item.id_situacao,
          label:
            item.label_situacao,
        }));
      setThisOptions(list as IOption[]);
    })
    .catch((err: any) => defaultOptions(err));
}

const defaultOptions = (err) =>
  [
    {
      value: null,
      label: String(err),
    },
  ] as IOption[];

const notHaveOptions = (options) => !options || options.length === 0;
