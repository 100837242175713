import styled from "styled-components";

interface IProps {
  hideOnMobile?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 70%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  @media only screen and (min-width: 720px) {
    max-width: 500px;
    /* margin-top: 20vh; */
  }
  /* @media only screen and (min-width : 1280px) and (max-height : 720px) {
        margin-top: 20vh;
    } */
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
  }
  .checkbox {
    margin-left: -5px;
    margin-right: -4px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  z-index: 1;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
  }
`;

export const LoginHeader = styled.div`
  /* @media only screen and (min-height: 480px) and (max-height: 768px) {
    margin-top: 150px;
  }; */
  @media only screen and (min-width: 1280px) and (max-height: 768px) {
    margin-top: 150px;
  } ;
`;

export const Opcoes = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1.6em;
  background-color: green;

  > div {
    display: flex;
    background-color: red;
    align-items: center;
  }
`;
export const Error = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  color: red;
`;

export const RecuperarSenha = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
  &:hover {
    color: black;
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Row = styled.div<IProps>`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.3em;
  @media only screen and (min-width: 480px) and (max-width: 1366px) {
    margin-bottom: 0.9em;
  }

  @media only screen and (max-width: 1300px) {
    ${(props) => (props.hideOnMobile === true ? "display: none;" : "")}
  }
`;
export const Column = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  /* margin:0 5px; */
`;

export default Container;
