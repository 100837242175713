import { IOption } from ".";
import api from "services/api";
import { axiosGetHeaders } from "../../../util";

const defaultOptions = (err) =>
  [
    {
      value: null,
      label: String(err),
    },
  ] as IOption[];

  
/**
 * Este método é o controlador de busca do autocomplete. 
 * Para que ele reconheça uma nova rota é necessário criar uma funcion para ler esta rota e também adicioná-la abaixo, dentro do controlador.
 *
 * @example
 *  SearchcController( name, value = " ", setIsLoading,  setThisOptions)
 *
 * @returns void
 */
export async function SearchcController(
  name,
  value = " ",
  setIsLoading,
  setThisOptions
) {
  setIsLoading(true);
  name === "id_escola" && setThisOptions(await SearchSchool(value));
  name === "id_municipio" && setThisOptions(await SearchCity(value));
  name === "id_nte" && setThisOptions(await SearchNTE(value));
  setIsLoading(false);
}

/**
 * Este método faz a busca na rota de escolas para preencher o autocomplete de escola.
 * 
 * @example
 *  SearchSchool(value)
 *
 * @returns void
 */
export async function SearchSchool(value = " ") {
  const url = `/escolas?nome_escola=${value}&page=1&per_page=50`;
  return await api
    .get(url, axiosGetHeaders())
    .then((response: any) =>
      response.data.map(
        (item) =>
          ({
            ...item,
            value: item.id_escola,
            label: item.nome_escola,
          } as IOption[])
      )
    )
    .catch((err: any) => defaultOptions(err));
}

/**
 * Este método faz a busca na rota de municipios para preencher o autocomplete de municipio.
 * 
 * @example
 *  SearchCity(value)
 *
 * @returns void
 */
export async function SearchCity(value = " ") {
  const url = `/municipios?municipio=${value}&page=1&per_page=50`;
  return await api
    .get(url, axiosGetHeaders())
    .then((response: any) =>
      response.data.map(
        (item) =>
          ({
            ...item,
            value: item.id_municipio,
            label: item.municipio,
          } as IOption[])
      )
    )
    .catch((err: any) => defaultOptions(err));
}
export async function SearchNTE(value = " ") {
  const url = `/ntes?nome=${value}&page=1&per_page=50`;
  return await api
    .get(url, axiosGetHeaders())
    .then((response: any) =>
      response.data.map(
        (item) =>
          ({
            ...item,
            value: item.id_nte,
            label: item.nome,
          } as IOption[])
      )
    )
    .catch((err: any) => defaultOptions(err));
}
