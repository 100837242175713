import { Reducer } from "redux";
import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { Auth, AuthBody, User } from "store/types";
import { RootState } from "store/ducks/state";
import createReducer from "util/createReducer";
import { Base64, getResponseError } from "util/index";
import jwt_decode from "jwt-decode";
import { UserAuth } from "../types";
import { APP_INFO, SYSTEM_MESSAGE } from "../../constants/system";

export enum AuthTypes {
  AuthStart = "@auth/AuthStart",
  AuthSuccess = "@auth/AuthSuccess",
  AuthFailure = "@auth/AuthFailure",
  LogoutStart = "@auth/LogoutStart",
  LogoutSuccess = "@auth/LogoutSuccess",
  LogoutFailure = "@auth/LogoutFailure",
  CleanState = "@auth/CleanState",
  ResetError = "@auth/ResetError",
}

export type Actions = {
  AuthStart: { type: AuthTypes.AuthStart };
  AuthSuccess: { type: AuthTypes.AuthSuccess; payload: Auth };
  AuthFailure: { type: AuthTypes.AuthFailure; payload: any };
  LogoutStart: { type: AuthTypes.LogoutStart };
  LogoutSuccess: { type: AuthTypes.LogoutSuccess };
  LogoutFailure: { type: AuthTypes.LogoutFailure; payload: any };
  CleanState: { type: AuthTypes.CleanState };
  ResetError: { type: AuthTypes.ResetError; payload: any };
};

export interface LoadingSection {
  "loading.auth": boolean;
  "loading.logout": boolean;
}

export interface AuthState {
  data: Auth;
  loading: LoadingSection;
  error: any;
  user?: UserAuth;
  isInactiveUser: boolean;
}

export const InitialState: AuthState = {
  data: {
    token: "",
  },
  loading: {
    "loading.auth": false,
    "loading.logout": false,
  },
  error: undefined,
  user: {} as UserAuth,
  isInactiveUser: false,
};

export const authReducer: Reducer<AuthState> = createReducer(InitialState, {
  [AuthTypes.AuthStart](state: AuthState) {
    state.loading["loading.auth"] = true;
    state.isInactiveUser = false;
    return state;
  },
  [AuthTypes.AuthSuccess](state: AuthState, action: Actions["AuthSuccess"]) {
    state.loading["loading.auth"] = false;
    state.error = undefined;

    let base64Url;
    let base64;
    if (action.payload.token) {
      base64Url = action.payload.token.split(".")[1];
      base64 = base64Url.replace("-", "+").replace("_", "/");
    }
    // state.user = JSON.parse(Base64.atob(base64)).user;
    const user = jwt_decode(action.payload.token) as UserAuth;
    state.data = action.payload;
    state.user = { ...user } as UserAuth;
    return state;
  },
  [AuthTypes.AuthFailure](state: AuthState, action: Actions["AuthFailure"]) {
    state.loading["loading.auth"] = false;
    state.user = {} as UserAuth;
    state.error = action.payload.error;
    return state;
  },
  [AuthTypes.LogoutStart](state: AuthState) {
    state.loading["loading.logout"] = true;
    return state;
  },
  [AuthTypes.LogoutSuccess](state: AuthState) {
    state.loading["loading.logout"] = false;
    state = { ...InitialState };
    return state;
  },
  [AuthTypes.LogoutFailure](
    state: AuthState,
    action: Actions["LogoutFailure"]
  ) {
    state.loading["loading.logout"] = false;
    state.user = {} as UserAuth;
    state.error = action.payload.error;
    return state;
  },
  [AuthTypes.CleanState](state: AuthState) {
    state = InitialState;
    return state;
  },
  [AuthTypes.ResetError](state: AuthState, action: Actions["ResetError"]) {
    state.error = action.payload;
    return state;
  },
});
export type ReduxDispatch = ThunkDispatch<any, any, any>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

function testAuth(dispatch, auth) {
  //professor001@gmail.com
  //aluno008@gmail.com
  const testIsAdmin = false;
  dispatch({
    type: AuthTypes.AuthSuccess,
    payload: {
      token:
        auth.usuario === "professor001@gmail.com"
          ? "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c3VhcmlvIjoiNiIsImlkX3Blc3NvYSI6IjEiLCJpZF9wZXJmaWxfdXN1YXJpbyI6IjQiLCJuaWNrbmFtZSI6InVuZGVmaW5lZDQ2Njk2NyIsImVtYWlsIjoicHJvZmVzc29yMDAxQGdtYWlsLmNvbSIsImRhdGFfdWx0aW1vX2FjZXNzbyI6IjIwMjItMDItMTcgMTI6MjA6MDUiLCJkYXRhX2NyaWFjYW8iOiIyMDIyLTAxLTEwIDExOjM1OjQ3IiwiYXRpdm8iOiIxIiwicGVyZmlsIjoiQVJUSUNVTEFET1IiLCJub21lIjoiUFJPRkVTU09SIDAwMSIsImNwZiI6IjYxMTM0OTAxNjU4IiwicmciOiIyNzE2NzgyNTIiLCJ0ZWxlZm9uZSI6IjcxOTg4Nzc2MDEwIiwiZGF0YV9uYXNjaW1lbnRvIjpudWxsLCJuaXMiOiI3NjIzNDMxODI0NCIsImlkX3NleG8iOiIxIiwic2V4byI6Ik1BU0NVTElOTyIsIm1hdHJpY3VsYSI6bnVsbCwiaXNfb3JnYW5pemFkb3IiOiIxIn0.6yqAzYj4_nfGACDVgiHTPBTN5FBXgzcF8zK78abGybg"
          : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c3VhcmlvIjoiMTEiLCJpZF9wZXNzb2EiOiI5IiwiaWRfcGVyZmlsX3VzdWFyaW8iOiIyIiwibmlja25hbWUiOiJhbHVubzAwOCIsImVtYWlsIjoiYWx1bm8wMDhAZ21haWwuY29tIiwiZGF0YV91bHRpbW9fYWNlc3NvIjoiMjAyMi0wMi0xNiAxNzoyMzowMiIsImRhdGFfY3JpYWNhbyI6IjIwMjItMDEtMTQgMTY6MzQ6MzgiLCJhdGl2byI6IjEiLCJwZXJmaWwiOiJDT01QRVRJRE9SIiwibm9tZSI6IkFMVU5PIDAwOCIsImNwZiI6IjgwMjU2MzQ5MDYxIiwicmciOiIxMTIyMzM0MDA4IiwidGVsZWZvbmUiOiI3MTk4ODc3NjAwOCIsImRhdGFfbmFzY2ltZW50byI6IjIwMTUtMDEtMDUgMDA6MDA6MDAiLCJuaXMiOm51bGwsImlkX3NleG8iOiIxIiwic2V4byI6Ik1BU0NVTElOTyIsIm1hdHJpY3VsYSI6IjExMjIzMzQ0NTAwOCIsImlzX29yZ2FuaXphZG9yIjoiMCJ9.GO724FpYs_SyvXRo2Tk0NipV64Gxa7H-ao_V7L9ZW2s",
    },
  });
  return;
}

/**
 * Este método é executado quando o usuário tenta se autenticar, validando sua credencial.
 *
 * @example
 *  handleAuth();
 *
 * @returns {Promise<any>}
 */
export function handleAuth(
  auth: AuthBody
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, _): Promise<any> => {
    dispatch({ type: AuthTypes.AuthStart } as Actions["AuthStart"]);

    // return testAuth(dispatch, auth)//DESCOMENTAR ESTA LINHA PARA EXECUTAR O TESTE

    return new Promise((resolve, reject) => {
      try {
        api
          .post("/auth", {
            ...auth,
            aplicacao: APP_INFO.NAME,
            device_info: "Dispositivo desconhecido",
          })
          .then((response: any) => {
            dispatch({
              type: AuthTypes.AuthSuccess,
              payload: response.data,
            });
            resolve(response);
          })
          .catch((err: any) => {
            dispatch({
              type: AuthTypes.AuthFailure,
              payload: {
                error:
                  err.response.status === 401
                    ? getResponseError(err)
                    : `Erro de Login 01: ${
                        SYSTEM_MESSAGE.ERROR_NO_INTERNET_CONNECTION
                      } \nRef.: ${getResponseError(err)}`,
              },
            });
            reject(
              err.response.status === 401
                ? getResponseError(err)
                : `Erro de Login 02: ${
                    SYSTEM_MESSAGE.ERROR_TRY_LATER_LATER
                  } \nRef.:${getResponseError(err)}`
            );
          });
      } catch (msgError) {
        dispatch({
          type: AuthTypes.AuthFailure,
          payload: `Erro de Login 03: ${SYSTEM_MESSAGE.ERROR_NO_INTERNET_CONNECTION} \nRef.: ${msgError}`,
        });
        reject(
          `Erro de Login 04: ${SYSTEM_MESSAGE.ERROR_TRY_LATER_LATER} \nRef.:${msgError}`
        );
      }
    });
  };
}

/**
 * Este método é executado quando o usuário clica em sair, para expirar o token do usuário.
 *
 * @example
 *  handleLogout();
 *
 * @returns {Promise<any>}
 */
export function handleLogout():
  | any
  | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({ type: AuthTypes.LogoutStart } as Actions["LogoutStart"]);

    return new Promise((resolve, reject) => {
      try {
        let token = `${getState().auth.data.token}`;
        while (token.indexOf("Bearer ") > -1)
          token = token.replace("Bearer ", "");
        api
          .get(`/auth/leave`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            dispatch({
              type: AuthTypes.LogoutSuccess,
            });
            resolve(true);
          })
          .catch((err: any) => {
            localStorage.clear();
            resolve(true);
          });
      } catch (msgError) {
        dispatch({
          type: AuthTypes.LogoutFailure,
          payload: `${SYSTEM_MESSAGE.ERROR_NO_INTERNET_CONNECTION} \nRef.: ${msgError}`,
        });
        reject(
          `Erro 04: ${SYSTEM_MESSAGE.ERROR_TRY_LATER_LATER} \nRef.:${msgError}`
        );
      }
    });
  };
}

// export function handleLogout() {
//   return (dispatch: any): any => {

//     dispatch({ type: AuthTypes.Logout });
//   };
// }

export function cleanState() {
  return (dispatch: any): any => {
    dispatch({ type: AuthTypes.CleanState });
  };
}
