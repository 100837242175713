import styled from 'styled-components'

export const Container = styled.form`
    background-color:white;
    padding: 41px 54px 29px 54px;
    width:700px;
    margin: 0 auto;
    @media only screen and (min-width : 0px) and (max-width : 480px) {
        width: calc(100vw - 20px);
        padding: 41px 10px 29px 10px;
        gap:1em;
    }
`;

export const Row = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    gap:15px 10px;
    @media only screen and (min-width : 0px) and (max-width : 480px) {
        flex-wrap: wrap;
        margin-bottom: 1em;
        gap:inherit;
    }
`;
export const Column = styled.div`
    width: 100%;
    flex-direction: column;
    display: flex;
    /* margin:0 5px; */
    @media only screen and (min-width : 0px) and (max-width : 480px) {
        gap:inherit;
    }
    
`;
export const Center = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex:1;
    color:inherit;
    font-weight: inherit;
    gap: 30px;
    @media only screen and (min-width : 0px) and (max-width : 480px) {
        flex-direction: column;
        gap:10px;
    }
`;

export const Error = styled.div`
    width: 100%;
    display: flex;
    margin-left:10px;
    margin-top:5px;
    color:#d32f2f;
    font-size:0.75rem;
    font-weight:400;
    letter-spacing:0.03333em;
    line-height:1.66;
`;
export const Box = styled.div`
    display:flex;
    border-radius: 15px;
    border:1px solid #70707075;
    flex-direction: column;
    flex:1;
    align-items: left;
`;
export const BoxTitle = styled.div`
    font-size:18px;
    background-color:white;
    padding:0 10px;
    width:195px ;
    margin-left:30px;
    margin-top:-10px;
    max-width: 80%;
`;
export const BoxContent = styled.div`
    padding-top:15px ;
    display:flex;
    flex-direction: row;
    flex:1;
    gap: 10px;
`;
export const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    color:#000000;
`;