import styled from 'styled-components'
import logoGray from '../../../assets/logoGray.png'
import blazon from '../../../assets/blazon_big.png'

interface IContetProps{
    isElectoralPeriod: boolean
}


export const Container = styled.div`
    display:flex;
    flex:1;
    height:100%;
    flex-direction:column;
`;
export const Content = styled.div<IContetProps>`
    padding: 77px;
    display: block;
    width: 100%;
    height: 100%;
    background-image:url(${(props) => props.isElectoralPeriod ? blazon : logoGray});
    background-size: auto 100%;
    background-attachment: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 75vw;
    max-height: 61vh;
    margin: auto auto;
    margin-top: calc(30px + 1vh) !important;
    background-position: cover;

    @media only screen and (max-width: 728px){
        max-width: 100vw;
        max-height: 49vh;
        margin: auto auto;
        margin-top: calc(40px + 1vh) !important;
    }

    
`;