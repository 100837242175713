import styled from 'styled-components'

interface IProps{
}

export const ListHeader = styled.div`
    display:flex;
    width:100%;
    border-bottom:1px solid rgb(133,133,133);
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 1em;
    .ColumnName{
        color:rgb(65,154,233);
    }

    @media only screen and (max-width: 728px){
        display: none;
    }
`;