import React from "react";
import { Container, Text, Left, Right, styleContainerMenu } from "./styles";

import * as icon from "@fortawesome/free-solid-svg-icons";

import Menu from "../../Menu";
import ButtonIcon from "../../ButtonIcon/index";
import logo from "../../../assets/logo.png";
import logoGovernoDoEstadoBahia from "../../../assets/logoGovernoDoEstado3.png";
import logoGovernoDoEstado from "../../../assets/logoGovernoDoEstado2.png";
import logoGovernoDoEstadoVertical from "../../../assets/logoGovernoDoEstadoVertical.png"
import { isAdminLogged, isMobile } from "../../../util";
import { useSelector } from "react-redux";
import { RootState } from "store/ducks/state";
import { IS_ELECTORAL_PERIOD } from "constants/system";

export default function ApplicationTopBar(props) {
  const { isNavBarOpen, setIsNavBarOpen } = props;
  const auth = useSelector((state: RootState) => state.auth);
  React.useEffect(() => {
    // console.log("carregou");
    // console.log(props);
  }, []);

  return (
    <Container isAdminLogged={true}>
      <Left>
        {window.innerWidth > 768 &&
          isNavBarOpen &&
          (IS_ELECTORAL_PERIOD ? (
            <ButtonIcon
              width="250px"
              height="100%"
              size="lg"
              color="white"
              bg={true}
              bgImg={logoGovernoDoEstadoBahia}
              cStyle={{ padding: "5px 60px 5px 5px" }}
              cClassName="logoGov"
              imageStyle={{
                width: "95%",
                height: "100%",
                backgroundSize: "contain",
              }}
              isAdminLogged={true}
            />
          ) : (
            <ButtonIcon
              width="250px"
              height="100%"
              size="lg"
              color="white"
              bg={true}
              bgImg={logoGovernoDoEstado}
              cStyle={{ padding: "5px" }}
              cClassName="logoGov"
              imageStyle={{
                width: "95%",
                height: "100%",
                backgroundSize: "contain",
              }}
              isAdminLogged={true}
            />
          ))}
        {window.innerWidth > 768 &&
          !isNavBarOpen &&
          (IS_ELECTORAL_PERIOD ? (
            <ButtonIcon
              width="70px"
              height="100%"
              size="lg"
              color="white"
              bg={true}
              bgImg={logoGovernoDoEstadoVertical}
              isAdminLogged={true}
            />
          ) : (
            <ButtonIcon
              width="70px"
              height="100%"
              size="lg"
              color="white"
              bg={true}
              bgImg={logo}
              isAdminLogged={true}
            />
          ))}
        <ButtonIcon
          onClick={() => setIsNavBarOpen(!isNavBarOpen)}
          width="55px"
          height="100%"
          bg={false}
          size="2x"
          color="white"
          icon={icon.faBars}
          cStyle={isMobile() ? styleContainerMenu : {}}
          iconStyle={
            isMobile() && {
              width: "30px",
              height: "30px",
              padding: "3px",
              backgroundColor: "transparent",
            }
          }
          cClassName="buttonMenu"
          // marginLeft={window.innerWidth > 768 ? 30 : 10}
        />
        <Text> EDUCA+ BAHIA </Text>
      </Left>
      <Right>
        {/* <ButtonIcon
          href={`${true ? "/gestao" : "/estudante"}/inscricoes`}
          width="70px"
          height="100%"
          size="lg"
          color="white"
          icon={icon.faAddressBook}
          isAdminLogged={true}
        />
        {true && (
          <ButtonIcon
            href={`${
              true ? "/gestao" : "/estudante"
            }/relatorios`}
            width="70px"
            height="100%"
            size="lg"
            color="white"
            icon={icon.faChartBar}
            isAdminLogged={true}
          />
        )}
        {true && (
          <ButtonIcon
            href={`${
              true ? "/gestao" : "/estudante"
            }/configuracoes`}
            width="70px"
            height="100%"
            size="lg"
            color="white"
            icon={icon.faCog}
            isAdminLogged={true}
          />
        )} */}
        <Menu isAdminLogged={true} />
      </Right>
    </Container>
  );
}
