import React from "react";

import { Container, Header, Status, ContentColumn } from "./styles";
import { CheckCircle, Error } from "@mui/icons-material";
import Column, { ColumnOption } from "../Column";

interface IData {
  pair?: boolean;
  data?: any;
  columns?: any;
  forceArrayToColumns?: boolean;
}
const Row: React.FC<IData> = ({ data, columns = [], pair = false }) => {
  columns = columns.map((column) =>
    column && column.text ? column.text : column
  );

  const dataColumns =
    Object.keys(data) && Object.keys(data).length > 0 ? Object.keys(data) : [];

  return (
    <Container pair={pair}>
      {dataColumns.map((key, index) => {
        let cstyles = {};
        if (data[key]) {
          if (data[key] && data[key].styles)
            cstyles = { ...cstyles, ...data[key].styles };
          return renderColumns(columns, data, key, index, cstyles);
          
        }
      })}
    </Container>
  );
};
export default Row;

const renderColumns = (columns, data, key, index, cstyles) => {
  // console.log("🚀 ~ file: index.tsx ~ line 40 ~ renderColumns ~ data", data)
  return key === "status" ? (
    renderColumnStatus(data, key, index, cstyles,data?.status?.statusStyle, data?.status?.iconVisible)
  ) : key === "buttons" ? (
    renderButtons(data, key, index, cstyles)
  ) : key === "buttons2" ? (
    renderButtons(data, key, index, cstyles)
  ) : data[key] !== null ? (
    renderNormalColumn(columns, data, key, index, cstyles)
  ) : (
    <></>
  );
};

const renderColumnStatus = (data, key, index, cstyles, statusStyle, iconVisible = true) => (
  <Column cStyle={cstyles} key={index}>
    {console.log('iconVisible',iconVisible)}
    <Status
      style={statusStyle?statusStyle:{ maxWidth: 100 }}
      bg={["ativo", "ok"].includes(
          (data[key] && data[key].text
            ? data[key].text
            : data[key]
          ).toLowerCase()
        )
          ? "#169A00"
          : "#D50016"
      }
    >
      {!iconVisible?<></>:
      ["ativo", "ok"].includes(
        (data[key] && data[key].text ? data[key].text : data[key]).toLowerCase()
      ) ? (
        <CheckCircle
          style={{
            width: "18px",
            height: "18px",
            marginRight: "4px",
          }}
          color="inherit"
        />
      ) : (
        <Error
          style={{
            width: "18px",
            height: "18px",
            marginRight: "4px",
          }}
          color="inherit"
        />
      )

    }
      {data[key] && data[key].text ? data[key].text : data[key]}
    </Status>
  </Column>
);

const renderButtons = (data, key, index, cstyles) => {
  return (
    <ColumnOption
      cStyle={cstyles !== undefined ? cstyles : { width: "auto" }}
      key={index}
    >
      {data[key] && data[key].buttons ? data[key].buttons : data[key]}
    </ColumnOption>
  );
};

const renderNormalColumn = (columns, data, key, index, cstyles) => {
  return (
    <Column cStyle={cstyles} key={index}>
      {columns[index] && <Header>{`${columns[index]}`}</Header>}
      <ContentColumn>
        {data[key] && data[key].text && data[key].text.title ? (
          <>
            <p style={{ color: "#0077E1" }}>{data[key].text.title}</p>
            <p>{data[key]?.text?.subTitle}</p>
          </>
        ) : data[key] && data[key].text ? (
          data[key].text
        ) : (
          data[key]
        )}
      </ContentColumn>
    </Column>
  );
};
