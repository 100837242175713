import { useDispatch } from "react-redux";
import { Reducer } from "redux";
import createReducer from "util/createReducer";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "store/ducks/state";
import * as icon from "@fortawesome/free-solid-svg-icons";

export enum UserTypes {
  AlertShow = "@user/AlertShow",
  AlertLoading = "@user/AlertLoading",
  AlertSuccess = "@user/AlertSuccess",
  AlertConfirmation = "@user/AlertConfirmation",
  AlertInformation = "@user/AlertInformation",
  AlertError = "@user/AlertError",
  AlertClose = "@user/AlertClose",
  SetState = "@user/SetState",
}

export type Actions = {
  SetState: { type: UserTypes.SetState; payload: AlertState };
  AlertShow: { type: UserTypes.AlertShow; payload: AlertState };
  AlertLoading: { type: UserTypes.AlertLoading };
  AlertConfirmation: {
    type: UserTypes.AlertConfirmation;
    payload: {
      description: string;
      callBack: () => void;
    };
  };
  AlertInformation: {
    type: UserTypes.AlertInformation;
    payload: { title: string; msg: string };
  };
  AlertSuccess: { type: UserTypes.AlertSuccess; payload: string };
  AlertError: { type: UserTypes.AlertError; payload: string };
  AlertClose: { type: UserTypes.AlertClose };
};

export interface Loading {
  "loading.alert": boolean;
}

export interface AlertState {
  type?: string;
  visible?: boolean;
  okButton?: any;
  backButton?: any;
  confirmationButton?: any;
  loading?: Loading;
  icon?: any;
  iconColor?: string;
  title?: string;
  description: string;
  input?: {
    label: string;
    type: string;
    value?: any;
  };
  callbackOnSuccess?: () => void;
  callbackOnError?: () => void;
}

export const InitialState: AlertState = {
  type: "alert",
  visible: false,
  okButton: undefined,
  backButton: undefined,
  confirmationButton: undefined,
  icon: undefined,
  iconColor: "orange",
  title: "ALERTA!",
  description: "",
  loading: {
    "loading.alert": false,
  },
};

export const alertReducer: Reducer<AlertState> = createReducer(InitialState, {
  [UserTypes.AlertShow](state: AlertState, action: Actions["AlertShow"]) {
    state = {
      ...state,
      ...action.payload,
      visible: true,
      iconColor: "orange",
      type:
        action.payload?.type ||
        (action.payload.confirmationButton ? "confirm" : "alert"),
      title: action.payload?.title || "ALERTA!",
    } as AlertState;
    return state;
  },
  [UserTypes.AlertConfirmation](
    state: AlertState,
    action: Actions["AlertConfirmation"]
  ) {
    state = {
      ...state,
      ...action.payload,
      visible: true,
      icon: icon.faCircleQuestion,
      iconColor: "orange",
      input: undefined,
      type: "confirm",
      title: "CONFIRME!",
      description: action.payload.description,
      backButton: {
        label: "CANCELAR",
      },
      confirmationButton: {
        label: "CONFIRMAR",
        onClick: action.payload.callBack,
      },
    } as AlertState;
    return state;
  },
  [UserTypes.AlertLoading](state: AlertState) {
    state.visible = true;
    state.loading["loading.alert"] = true;
    state.type = "loading";
    state.iconColor = "grey";
    state.title = "";
    return state;
  },
  [UserTypes.AlertSuccess](state: AlertState, action: Actions["AlertSuccess"]) {
    state.visible = true;
    state.loading["loading.alert"] = false;
    state.type = "success";
    state.title = "SUCESSO!";
    state.input = undefined;
    state.icon = icon.faCircleCheck;
    state.iconColor = "green";
    state.okButton = {};
    state.backButton = undefined;
    state.confirmationButton = undefined;
    state.description = action.payload;
    return state;
  },
  [UserTypes.AlertInformation](
    state: AlertState,
    action: Actions["AlertInformation"]
  ) {
    state.visible = true;
    state.loading["loading.alert"] = false;
    state.type = "info";
    state.title = action.payload.title || "ATENÇÃO!";
    state.input = undefined;
    state.icon = icon.faInfoCircle;
    state.iconColor = "gray";
    state.okButton = {};
    state.backButton = undefined;
    state.confirmationButton = undefined;
    state.description = action.payload.msg;
    return state;
  },
  [UserTypes.SetState](state: AlertState, action: Actions["SetState"]) {
    state = action.payload;
    return state;
  },
  [UserTypes.AlertError](state: AlertState, action: Actions["AlertError"]) {
    state.loading["loading.alert"] = false;
    state.type = "error";
    state.title = "ERRO!";
    state.icon = icon.faTriangleExclamation;
    state.iconColor = "red";
    state.input = undefined;
    state.okButton = {};
    state.backButton = undefined;
    state.confirmationButton = undefined;
    state.description = action.payload;
    state.visible = true;
    return state;
  },
  [UserTypes.AlertClose](state: AlertState) {
    state.visible = false;
    state.type = "alert";
    state.okButton = undefined;
    state.backButton = undefined;
    state.confirmationButton = undefined;
    state.loading["loading.alert"] = false;
    return state;
  },
});

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export const checkTokeExpred = (dispatch, err) => {
  err = err && err.length > 0 ? String(err).toLowerCase() : "";
  // console.log("checkTokeExpred:", err);
  if (err.indexOf("token") > -1 && err.indexOf("expirado") > -1)
    dispatch(
      showAlert(
        {
          description:
            "Sua sessão de login expirou. Por favor, faça login novamente!",
          okButton: {
            onClick: () => {
              console.log("checkTokeExpred.botao:", "apertado");
              window.location.href = "../../../../../../logout";
            },
          },
        },
        true
      )
    );
};

export function setState(
  obj: AlertState
): any | ThunkAction<any, RootState, any, any> {
  return (dispatch, getState): any => {
    dispatch({
      type: UserTypes.SetState,
      payload: obj,
    } as Actions["SetState"]);
  };
}

export function showAlert(
  obj: AlertState,
  ignoreIfVisible: boolean = false
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    let visible: boolean = getState().alert?.visible;
    return new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          console.table("showAlert.obj:", obj);
          if (!ignoreIfVisible || visible === false)
            dispatch({
              type: UserTypes.AlertShow,
              payload: obj,
            } as Actions["AlertShow"]);
        }, 100);
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function alertLoading():
  | any
  | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        console.log("alertLoading ==>> ");
        dispatch({ type: UserTypes.AlertLoading } as Actions["AlertLoading"]);
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function closeAlert():
  | any
  | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      console.log("closeAlert ==>>> ");
      try {
        dispatch({ type: UserTypes.AlertClose } as Actions["AlertClose"]);
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function setAlertCallBackOnSuccess(
  func: () => void
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      dispatch(setState({ ...getState().alert, callbackOnSuccess: func }));
    });
  };
}

export function setAlertCallBackOnError(
  func: () => void
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      dispatch(setState({ ...getState().alert, callbackOnError: func }));
    });
  };
}

export function confirmationAlert(
  text: string,
  callBack: () => void = undefined
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({
          type: UserTypes.AlertConfirmation,
          payload: {
            description: text,
            callBack: callBack,
          },
        } as Actions["AlertConfirmation"]);

        if (getState().alert.callbackOnSuccess) {
          getState().alert.callbackOnSuccess();
          dispatch(
            setState({ ...getState().alert, callbackOnSuccess: undefined })
          );
        }
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function alertInformation(
  title: "ATENÇÃO!",
  msg: string
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({
          type: UserTypes.AlertInformation,
          payload: { title: title, msg: msg },
        } as Actions["AlertInformation"]);

        if (getState().alert.callbackOnSuccess) {
          getState().alert.callbackOnSuccess();
          dispatch(
            setState({ ...getState().alert, callbackOnSuccess: undefined })
          );
        }
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function alertSuccess(
  msg: string
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({
          type: UserTypes.AlertSuccess,
          payload: msg,
        } as Actions["AlertSuccess"]);

        if (getState().alert.callbackOnSuccess) {
          getState().alert.callbackOnSuccess();
          dispatch(
            setState({ ...getState().alert, callbackOnSuccess: undefined })
          );
        }
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function alertError(
  msg: string
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    return new Promise((resolve, reject) => {
      console.log("alertError:", msg);
      try {
        dispatch({
          type: UserTypes.AlertError,
          payload: msg,
        } as Actions["AlertError"]);

        if (getState().alert.callbackOnError) {
          getState().alert.callbackOnError();
          dispatch(
            setState({ ...getState().alert, callbackOnError: undefined })
          );
        }
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };
}
function getState() {
  throw new Error("Function not implemented.");
}
