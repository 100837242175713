import styled from "styled-components";

interface IProps {
  isNavBarOpen: boolean;
  fullContent: boolean;
}
export const GridLayout = styled.div<IProps>`
  display: grid;
  grid-template-columns: ${(props) =>
      props.fullContent ? 0 : props.isNavBarOpen ? "250px" : "70px"} auto;
  grid-template-rows: ${(props) => (props.fullContent ? 0 : 70)}px auto;
  grid-template-areas:
    "TOPBAR TOPBAR"
    "LEFTNAV CONTENT";
  width: 100vw;
  min-height: 100vh;
  animation: all 0.5s;

  @media only screen and (max-width: 1366px) {
    grid-template-rows: ${(props) => (props.fullContent ? 0 : 55)}px auto;
  }

  @media only screen and (max-width: 728px) {
    grid-template-columns: ${(props) => (props.isNavBarOpen ? "0" : "0")} auto;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1566px) {
    grid-template-columns: ${(props) =>
        props.fullContent ? 0 : props.isNavBarOpen ? "225px" : "70px"} auto;
  }
`;
