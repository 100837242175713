import React, { useEffect,useRef } from "react";
import SelectFilter from "../../Selects/selectFilter";
import InputFilter from "../../Inputs/InputFilter";
import ButtonFilter from "../../Buttons/filterButton";
import { handleGetSex, handleGetDeficiency } from "store/ducks/parameters";
import { isMobile } from "../../../util";
import { AutoCompleteCustom } from "components";

import { Container, sxButtonFlat, sxButtonNormal } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/ducks/state";
import { handleFilterParentValue } from "util/index";
import DateFilter from "components/Inputs/DateFilter";

interface IOptions {
  label: string;
  value: string;
}
export interface IFilter {
  ref?: string;
  value?: any;
  setLastValue?: boolean;
  setFirstValue?: boolean;
  label: string;
  key?:any;
  options?: IOptions[];
  type: string;
  style?: any;
  parent?: string;
  hintWhenEmpty?: string;
  noHintWhenEmpty?: boolean;
  parentIsNotRequired?: boolean;
  hideMixed?: boolean;
  hideEmpty?: boolean;
  unique?: boolean;
}
interface IProps {
  filters: IFilter[];
  setFilters?: any;
  onSearch?: any;
  onChangeFilter?: any;
  mode?: "flat" | "normal";
  isAdmin?: boolean;
  clearFilters?: any;
}
const ContentFilters: React.FC<IProps> = ({
  filters,
  setFilters,
  onSearch,
  clearFilters,
  onChangeFilter,
  mode = "normal",
  isAdmin = false,
}) => {
  return (
    <Container mode={mode}>
      {filters.map((item, index) => {
        if (item.ref && item.ref.length > 0) {
          if (item.type === "autocomplete") {
            return (
              <div
                style={{
                  ...item.style,
                  margin: "15px 0 0 20px",
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    color: "#555555",
                    fontWeight: "bold",
                  }}
                >
                  {item.label}
                </span>
                <AutoCompleteCustom
                  cStyles={{ marginTop: 6 }}
                  key={`filters-${item.ref}${item?.key}-${index}`}
                  name={item.ref}
                  label={""}
                  size="small"
                  onChange={(event, values) => {
                    console.log("autocomplete>:values", values);
                    const data = values ? values.value : null;
                    onChangeFilter(item.ref || null, data);
                  }}
                />
              </div>
            );
          } else if (item.type === "select") {
            return (
              <SelectFilter
                key={`filters-${item.ref}${index}`}
                hintWhenEmpty={
                  (!item.parentIsNotRequired && item.hintWhenEmpty) ||
                  "Selecione"
                }
                parentIsNotRequired={item.parentIsNotRequired}
                onChange={({ target }) => {
                  onChangeFilter(item.ref || null, target.value);
                }}
                mode={mode}
                onChangeFilter={onChangeFilter}
                name={item.ref || ""}
                value={item.value || ""}
                setLastValue={item.setLastValue || false}
                setFirstValue={item.setFirstValue || false}
                label={item.label}
                options={item.options}
                cStyles={item.style || {}}
                parent={item.parent}
                parentValue={handleFilterParentValue(filters, item)}
                hideMixed={item.hideMixed}
                hideEmpty={item.hideEmpty || false}
                noHintWhenEmpty={item.noHintWhenEmpty || false}
                unique={item.unique || false}
              />
            );
          } else if (item.type === "input") {
            return (
              <InputFilter
                key={`filters-${item.ref}${index}`}
                value={item.value}
                onChange={({ target }) => {
                  onChangeFilter(item.ref || null, target.value);
                }}
                label={item.label}
                mode={mode}
                cStyles={item.style || {}}
                onSearch={() => {
                  onSearch && onSearch();
                }}
              />
            );
          } else if (item.type === "date")
            return (
              <DateFilter
                key={`filters-${item.ref}${index}`}
                onChange={({ target }) => {
                  onChangeFilter(item.ref || null, target.value);
                }}
                label={item.label}
                value={item.value}
                cStyles={item.style || {}}
                onSearch={() => {
                  onSearch && onSearch();
                }}
              />
            );
        }
      })}
      <ButtonFilter
        label={mode === "flat" ? "Ir" : "Buscar"}
        onClick={() => onSearch()}
        cStyle={
          mode === "flat"
            ? { margin: 0, padding: 0, height: "auto" }
            : isMobile()
            ? { width: "100%" }
            : {}
        }
        sx={mode === "flat" ? sxButtonFlat(isAdmin) : sxButtonNormal}
      />
      <ButtonFilter
        label={mode === "flat" ? "Limpar filtros" : "Limpar filtros"}
        onClick={() => {
          clearFilters()
          // onSearch();
        }}
        cStyle={
          mode === "flat"
            ? {
                minWidth: 150,
                maxWidth: 150,
                margin: 0,
                padding: 0,
                height: "auto",
              }
            : isMobile()
            ? { width: "100%" }
            : { minWidth: 150, maxWidth: 150 }
        }
        sx={mode === "flat" ? sxButtonFlat(isAdmin) : sxButtonNormal}
      />
    </Container>
  );
};
export default ContentFilters;
