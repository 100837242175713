import { ThunkAction } from 'redux-thunk';
import { RootState } from 'store/ducks/state';
import { AuthTypes } from './auth';
import { UserTypes } from './user';

const Types = [
  UserTypes,
  AuthTypes,
];

export function handleResetState(): ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, _): Promise<any> => {
    Types.map((type: any) => {
      dispatch({ type: type.CleanState });
    });
  };
}
