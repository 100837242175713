// import { styles } from 'constants';
import styled from "styled-components";
import { isAdminLogged } from "../../../util";
export const ContainerModalRedux = styled.div`
  background-color: white;
  padding: 54px;
  width: 400px;
  @media only screen and (max-width: 728px){
    width:calc(100vw - 30px);
  }
`;
export const ContainerAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const DescriptionAlert = styled.div`
  font-size: 16px;
  color: #707070;
  margin-bottom:15px;
`;
export const TitleAlert = styled.div`
  font-size: 20px;
  color: #707070;
  font-weight: 500;

`;
export const AvatarNoAuthorized = styled.div`
  font-size:16px;
  background-color:orange;
  padding:5px 15px;
  width:auto;
  color:white;
  /* margin-bottom:13px; */
`;


export const ContainerAuthorizathion = styled.div`
  display:flex;
  width:100%;
  height:35px;
`;
export const ContainerButton = styled.div`
    display:flex;
    flex:1;
`;


export const Button = styled.label`
  width: 150px;
  margin: 0 auto;
  margin-top: 15px;
  border: 1px solid #777;
  background-color: transparent; // #169a00;
  color: #707070;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #c0c0c0;
  }

  span {
    color: #707070;
  }
`;
