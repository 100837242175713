import styled from "styled-components";

interface IProps {}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const Content = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 15px;
  }
`;
export const Gap = styled.div`
  display: flex;
  gap: 8px;
`;
export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 25px;
  flex-wrap: wrap;
  text-align: left;
`;
export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    color:#000000;
`;