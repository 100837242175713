import styled from "styled-components";
interface Props {
  bgTransparent?: boolean;
  isAdminLogged?: boolean;
}

export const ContentHeader = styled.div<Props>`
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px 50px;
  background-color: rgb(247, 247, 247);

  @media only screen and (max-width: 1366px) {
    padding: 20px 40px !important;
    min-height: 55px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  @media only screen and (max-width: 728px) {
    padding: 20px;
    background-color: ${(props) =>
      props.bgTransparent
        ? "transparent"
        : (props) => (props.isAdminLogged ? "rgb(0,77,145)" : "#0E6300")};
    height: auto;
    min-height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -1px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1566px) {
    padding: 20px 27px;
  }
`;
export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  margin-right: 18px;
  align-self: center;
  @media only screen and (max-width: 728px) {
    /* display:none; */
    /* color: #fff; */
  }
`;
export const ContentName = styled.div<Props>`
  display: flex;
  font-size: 1.8em;
  align-items: center;
  height: 100%;
  color: rgba(19, 19, 19, 0.75);
  flex: 10;
  align-self: center;
  @media only screen and (max-width: 728px) {
    font-size: 20px;
    text-align: left;
    color: ${(props) =>
      props.bgTransparent ? " rgba(19, 19, 19, 0.75)" : "#fff"};
  }
`;
export const ContentChildren = styled.div`
  display: flex;
  font-size: 1.8em;
  align-items: center;
  justify-content: right;
  height: 100%;
  flex: 4;
  gap: 10px;
  padding-right: 0px;
  @media only screen and (max-width: 728px) {
    /* display:none; */
    max-width: 40px !important;
  }
`;

export default ContentHeader;
