import { SIZES } from "./../../../constants/theme";
import styled from "styled-components";

interface IBackgroundProps {
  imgUrl: string;
  isHome: boolean;
}

interface IContainerProps {
  isHome: boolean;
}
interface IContainerInformation {
  isHome: boolean;
}
interface IButton {
  isAdminArea: boolean;
}
interface IIcon {
  background: string;
  isAdminArea?: boolean;
}
interface ILogoProps {
  logo: string;
  width?: any;
  height?: any;
  filter?: any;
}
interface IGradientProps {
  isAdminArea: boolean;
  isHome: boolean;
}

interface IProps {
  marginLeft?: number;
}

export const Container = styled.div<IContainerProps>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin: 0;
  padding: 0;
  min-height: 100vh;
  @media only screen and (min-width: 320px) and (max-width: 720px) {
    display: block;
    flex-direction: row;
  }

  @media only screen and (min-width: 1300px) {
    min-height: ${(props) => (props.isHome ? 650 : SIZES.minHeightPage)}px;
  }
`;

export const ContainerBackground = styled.div<IBackgroundProps>`
  flex: 3;
  /* height: 100vh; */
  background: url(${(props) => props.imgUrl});
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  background-position: center;

  @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    min-width: 700px;
    min-height: 510px;
  }
  @media only screen and (min-width: 1023px) and (max-width: 1263px) {
    min-height: 680px;
  }

  @media only screen and (min-width: 1300px) {
    min-height: ${(props) => (props.isHome ? 650 : SIZES.minHeightPage)}px;
  }

  @media only screen and (max-width: 720px) {
    max-width: 100vw !important;
    min-height: 90vh;
  }

  @media only screen and (max-width: 320px) {
    max-width: 100vw !important;
    min-height: 450px;
  }
  /* min-height: ${(props) => (props.isHome ? "95vh !important" : "100vh")};
  min-height:800px; */
`;
export const LinearGradient = styled.div<IGradientProps>`
  display: flex;
  background: ${(props) =>
    !props.isAdminArea
      ? "rgba(0, 255, 180, 0.75)"
      : "rgba(38, 112, 212, 0.75)"};

  background: ${(props) =>
    !props.isAdminArea
      ? "-o-linear-gradient(top, rgba(0, 255, 180, 0.75) 16%, rgba(38, 112, 212, 0.75) 100%)"
      : "-o-linear-gradient(top, rgba(0, 185, 255, 0.75) 16%, rgba(38, 112, 212, 0.75) 100%)"};

  background: ${(props) =>
    !props.isAdminArea
      ? "-webkit-gradient(linear, left top, left bottom, color-stop(16%, rgba(0, 255, 180, 0.75)), to(rgba(38, 112, 212, 0.75)))"
      : "-webkit-gradient(linear, left top, left bottom, color-stop(16%, rgba(0, 185, 255, 0.75)), to(rgba(38, 112, 212, 0.75)))"};
  background: ${(props) =>
    !props.isAdminArea
      ? "linear-gradient(180deg, rgba(0, 255, 180, 0.75) 16%, rgba(38, 112, 212, 0.75) 100%)"
      : "linear-gradient(180deg, rgba(0, 185, 255, 0.75) 16%, rgba(38, 112, 212, 0.75) 100%)"};
  /* min-height: ${(props) => (props.isHome ? "95vh" : "100vh")}; */
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media only screen and (min-width: 1300px) {
    height: 100%;
  }

  @media only screen and (max-width: 1260px) {
    min-height: 560px;
  }
  @media only screen and (max-width: 720px) {
    max-width: 100vw !important;
    min-height: 90vh;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
  }

  @media only screen and (max-width: 320px) and (max-height: 568px) {
    min-height: auto;
  }
`;

export const Rodape = styled.div`
  box-sizing: border-box;
  padding-bottom: 30px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  > p {
    margin: 0;
    color: white;
  }

  @media only screen and (max-width: 728px) {
    padding: 0;
    padding-bottom: 1vh;
    > p {
      display: none;
    }
  }
  @media only screen and (min-width: 728px) and (max-width: 1365px) {
    padding: 10px;
    > p {
      font-size: 15px;
    }
  }
`;
export const LogoGoverno = styled.div<IBackgroundProps>`
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    background: url(${(props) => props.imgUrl});
    background-size: contain;
    background-repeat: no-repeat;
    width: 25vw;
    height: 25vw;
    margin-top: 30px;
  }
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    background: url(${(props) => props.imgUrl});
    background-size: contain;
    background-repeat: no-repeat;
    width: 25vw;
    height: 25vw;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0 20px;
  }
`;
export const ContainerButtonIcon = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 2px;
  flex-direction: column;
  max-width: 100px;
  justify-content: center;
  align-items: center;
`;
export const ContainerButtonInfo = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  padding: 2px;
  flex-direction: column;
  text-align: left;
  align-content: flex-start;
  justify-content: flex-end;
  align-items: stretch;
  margin-left: -10px;
  font-size: 0.98em;
  @media only screen and (width: 320px) and (max-height: 568px) {
    font-size: 0.85em;
  }
  @media only screen and (width: 320px) and (height: 658px) {
    font-size: 0.85em;
  }
`;

export const ButtonText = styled.div<IProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: inherit;
  line-height: 1.3em;
  font-weight: bold;
  text-align: center;
  margin-left: ${(props) => props.marginLeft || 0}px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 0.8em;
  }
`;

export const Icon = styled.div<IIcon>`
  display: flex;
  flex: 1;
  height: 100%;
  /* // border:1px solid red; */
  background: url(${(props) => props.background});
  background-size: contain;
  background-attachment: center;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 70px;
  min-width: 50px;

  @media only screen and (max-width: 480px) {
    min-width: 40px;
  }
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    max-width: 60px;
    min-width: 45px;
  }
`;

export const Button = styled.a<IButton>`
  width: 13vw;
  min-width: 280px;
  min-height: 75px;
  gap: 0px;
  height: 4vw;
  box-sizing: border-box;
  margin: 0 15px;
  border: 1px solid white;
  background-color: ${(props) =>
    props.isAdminArea ? "rgba(1,75,146,1)" : "rgba(0,121,50,1)"};
  color: white;
  display: flex;
  padding: 10px 10px !important;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  display: flex;
  justify-content: space-meevenly;
  align-content: center;
  &:hover {
    background-color: ${(props) =>
      props.isAdminArea ? "rgba(1,75,146,0.75)" : "rgba(0,121,50,0.75)"};
    -webkit-box-shadow: 0px 0px 0px 0px #c0c0c0, inset 0px 0px 5px 0px #c0c0c0,
      inset 0px 0px 7px 10px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 0px 0px #c0c0c0, inset 0px 0px 5px 0px #c0c0c0,
      inset 0px 0px 7px 10px rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: ${(props) =>
      props.isAdminArea ? "rgba(1,75,146,0.75)" : "rgba(0,121,50,0.75)"};
    -webkit-box-shadow: 0px 0px 0px 0px #c0c0c0, inset 0px 0px 5px 0px #c0c0c0,
      inset 0px 0px 7px 10px rgba(255, 255, 255, 0.55);
    box-shadow: 0px 0px 0px 0px #c0c0c0, inset 0px 0px 5px 0px #c0c0c0,
      inset 0px 0px 7px 10px rgba(255, 255, 255, 0.55);
  }
  flex-direction: row;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 0 5px;
    min-width: 150px;
    width: 180px;
    padding: 8px 5px;
  }
`;

export const ContainerCronometro = styled.div`
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0 20px;
  }
`;
export const Data = styled.div`
  box-sizing: border-box;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
  color: white;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    font-size: 22px;
  }
  @media only screen and (width: 320px) and (height: 658px) {
    font-size: 28px;
  }
`;

export const Cronometro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: column;
  box-sizing: border-box;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
  }
`;
export const BoxContainer = styled.div`
  width: 100px;
  box-sizing: border-box;
  margin: 0 15px;
  border: 1px solid white;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 0 5px;
    width: 90px;
    padding: 8px 5px;
  }
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    font-size: 12px;
  }
  @media only screen and (width: 320px) and (height: 658px) {
    font-size: 12px;
  }
`;

export const Box = styled.div`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  color: white;
  justify-content: center;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    font-size: 18px;
  }
  @media only screen and (width: 320px) and (height: 658px) {
    font-size: 22px;
  }
`;

export const ContainerInformation = styled.div<IContainerInformation>`
  flex: 3;
  height: 100vh;
  display: ${(props) => (props.isHome ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;

  @media only screen and (min-width: 1300px) {
    min-height: ${(props) => (props.isHome ? 650 : SIZES.minHeightPage)}px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0 20px;
    height: auto;
    /* min-height: 610px; */
    margin-top: 40px;
  }
  max-width: 1066px;
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    /* min-height: 120vh; */
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* padding: 4vh; */
  margin-top: 30px;
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    font-size: 28px;
    padding: 0;
    margin-top: 10px;
    
  }
`;
export const Logo = styled.div<ILogoProps>`
  ${(props) =>
    props.filter
      ? `
  -webkit-filter: ${props.filter};
  filter: ${props.filter};
  `
      : ``};
  height: ${(props) => props.height || "11vw"};
  width: ${(props) => props.width || "11vw"};
  background: url(${(props) => props.logo});
  background-size: ${(props) => props.width || "11vw"}
    ${(props) => props.height || "11vw"};
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 30px;

  @media only screen and (min-width: 481px) and (max-width: 680px) {
    background-size: 47vw 47vw;
    height: 47vw;
    &:first-child {
      display: none;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-top: 1vh;
    height: 47vw;
    background-size: contain;
    &:first-child {
      display: none;
    }
    @media only screen and (min-height: 750px) and (max-height: 1280px) {
      margin-top: 3vh;
      margin-bottom: -40px;
    }
  }
  @media only screen and (max-width: 320px) and (max-height: 568px) {
    width: 100%;
    margin-top: 1vh;
    height: 41vw;
    background-size: contain;
    &:first-child {
      display: none;
    }
  }
`;

export const LogoSudesb = styled.div`
  display: flex;
  width: 90%;
  max-width: 1280px;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 728px) {
    display: none;
  }
`;

export const TextRodape = styled.div`
  display: flex;
  font-size: 15px;
  color: #ffffffd9;
`;
export const ContainerWelcome = styled.div`
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  p {
    color: white;
    font-size: 5vw;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0 10px;
    p {
      color: white;
      font-size: 36px;
    }
  }
  /*
  @media only screen and (min-width: 768px) and (max-width: 1366px) {
    p{
      color:white;
      font-size:64px;
    }
  }
  @media only screen and (min-width: 1440px) and (max-width: 1920px) {
    p{
      color:white;
      font-size:94px;
    }
  } */
`;
interface IPropsWelcomeTitle{
  IS_ELECTORAL_PERIOD?: boolean;
}
export const WelcameTitle = styled.div<IPropsWelcomeTitle>`
  /* color: #fff; */
  font-size: 3vw;
  font-family: "Roboto";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 8px;
  padding: 15px 15px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 2px 2px 0px rgba(64, 116, 181, 1),
    2px -2px 0px rgba(64, 116, 181, 1), -2px 2px 0px rgba(64, 116, 181, 1),
    -2px -2px 0px rgba(64, 116, 181, 1), 2px 0px 0px rgba(64, 116, 181, 1),
    0px 2px 0px rgba(64, 116, 181, 1), -2px 0px 0px rgba(64, 116, 181, 1),
    0px -2px 0px rgba(64, 116, 181, 1);

  @media only screen and (max-width: 480px) {
    font-size: 12vw;
    letter-spacing: 5px;
  }
  ${(props) =>props.IS_ELECTORAL_PERIOD && `
   font-size: 3vw;
   text-transform:none;
    @media only screen and (max-width: 480px) {
      font-size: 13vw;
    }

  
  
  `}
`;

export const WelcameText = styled.div`
  color: #f1f1f1c9;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: lighter;
  text-transform: none;
  max-width: 750px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  margin: 0 auto;
  letter-spacing: 0px;

  @media only screen and (max-width: 480px) {
    font-size: 5vw;
  }
`;

export const ContainerClashes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1366px;
  margin: 0 auto;
`;
export const ContainerHome = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: auto 299px;
  grid-template-rows: 1fr;
  grid-template-areas: "CONTENT RIGHTNAV";
  width: 100%;
  animation: all 0.5s;
  padding: 90px 27px;
  gap: 32px;
  @media only screen and (min-width: 1280px) and (max-width: 1566px) {
    gap: 27px;
    padding: 40px 27px;
  }

  @media only screen and (max-width: 728px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 15px;
    gap: 20px;
    grid-template-areas:
      "RIGHTNAV"
      "CONTENT";
  }
  @media only screen and (min-width: 1680px) {
    grid-template-columns: auto 399px;
  }
`;
export const RightNav = styled.div`
  grid-area: RIGHTNAV;
  display: flex;
  flex-direction: column;
  /* @media only screen and (max-width: 728px) {
        display:none;
    } */
  gap: inherit;
`;
export const Content = styled.div`
  grid-area: CONTENT;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  gap: inherit;
`;
export const ContainerBg = styled.div`
  background-color: rgb(247, 247, 247) !important;
`;

export default Container;
