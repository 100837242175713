import styled from "styled-components";
interface IProps {
  fullContent: boolean;
}
export const Container = styled.div<IProps>`
  transition: 0.2s;
  grid-area: CONTENT;
  padding: 0;
  height: calc(100vh - ${(props) => (props.fullContent ? 0 : 70)}px);
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #999;
    -webkit-border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 3px;
  }
  @media only screen and (max-width: 728px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;
