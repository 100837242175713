import styled from "styled-components";
interface IProps {
  isOpen: boolean;
}

export const Container = styled.a<IProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: ${(props) => (props.isOpen ? "none" : "center")};
  align-items: center;
  border-bottom: 1px solid #b4b4b458;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    padding-left: 5px;
  }
`;
export const Icon = styled.div`
  width: 59px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
`;

export const ArrowIcon = styled.div`
  width: auto;
  height: auto;
  display: flex;
  padding-left: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
  opacity: 0.5;
  transition: all 0.3s;
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    padding-left: 0px;
  }
  @media only screen and (max-width: 728px) {
    padding-left: 0px;
  }
`;

export const BoxMenu = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    height: 55px;
  }
  @media only screen and (max-width: 728px) {
    height: 50px;
  }
`;

export const MenuItems = styled.div`
  flex: 1;
  height: 100%;
  font-size: 1.1em;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.3s;
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    font-size: 1em;
  }
  @media only screen and (max-width: 728px) {
    font-size: 14px;
  }
`;

export const MenuTitle = styled.div`
  width: 100%;
  min-height: 50px;
  font-size: 1.1em;
  color: white;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    font-size: 1em;
  }
  @media only screen and (max-width: 728px) {
    font-size: 14px;
  }
`;

export const BoxSubMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  text-decoration: none;
  margin-left: 5px;
`;

export const SubMenu = styled.a`
  height: auto;
  font-size: 0.85em;
  color: white;
  align-items: center;
  display: flex;
  height: 40px;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    padding-left: 8px;
    border-left: 5px solid rgba(255, 255, 255, 0.35);
    font-size: 0.95em;
  }
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    font-size: 1em;
  }
  @media only screen and (max-width: 728px) {
    font-size: 14px;
  }
`;

export default Container;
