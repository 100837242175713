import { useDispatch } from "react-redux";
import { Reducer } from "redux";
import createReducer from "util/createReducer";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import api from "services/api";
import { RootState } from "store/ducks/state";
import { checkTokeExpred } from "./alert";
import { handleToken } from "../../util";

export enum UserTypes {
  SearchPageStart = "@user/SearchPageStart",
  SearchPageSuccess = "@user/SearchPageSuccess",
  SearchPageFailure = "@user/SearchPageFailure",
}

export type Actions = {
  SearchPageStart: {
    type: UserTypes.SearchPageStart;
    payload: any;
  };
  SearchPageSuccess: {
    type: UserTypes.SearchPageSuccess;
    payload: any;
  };
  SearchPageFailure: {
    type: UserTypes.SearchPageFailure;
    payload: any;
  };
};

export interface LoadingSection {
  "loading.create": boolean;
  "loading.update": boolean;
  "loading.search": boolean;
  "loading.get": boolean;
}

export interface ISearchHeaders {
  currentpage?: string;
  lastpage?: string;
  total?: string;
}

export interface ISearchPageData {}

export const initialSearchPageData = {} as ISearchPageData;

export interface SearchPageState {
  data: ISearchPageData;
  search: {
    list: any[];
    headers: ISearchHeaders;
  };
  loading: LoadingSection;
  error: any;
}

export const InitialState: SearchPageState = {
  data: initialSearchPageData,
  search: {
    list: [] as any[],
    headers: null as ISearchHeaders,
  },
  loading: {
    "loading.create": false,
    "loading.update": false,
    "loading.search": false,
    "loading.get": false,
  },
  error: undefined,
};

export const searchPageReducer: Reducer<SearchPageState> = createReducer(
  InitialState,
  {
    [UserTypes.SearchPageStart](state: SearchPageState) {
      state.loading["loading.search"] = true;
      return state;
    },
    [UserTypes.SearchPageSuccess](
      state: SearchPageState,
      action: Actions["SearchPageSuccess"]
    ) {
      state.loading["loading.search"] = false;
      state.search = {
        headers: action.payload.headers,
        list: action.payload.list,
      };
      return state;
    },
    [UserTypes.SearchPageFailure](
      state: SearchPageState,
      action: Actions["SearchPageFailure"]
    ) {
      state.loading["loading.search"] = false;
      state.error = action.payload;
      return state;
    },
  }
);

export type ReduxDispatch = ThunkDispatch<any, any, any>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function handleSearchPageSearch(
  filters: any = ""
): any | ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState): Promise<any> => {
    dispatch({
      type: UserTypes.SearchPageStart,
    } as Actions["SearchPageStart"]);

    return new Promise((resolve, reject) => {
      api
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/BA/distritos${filters}`,
          {
            headers: {
              Authorization: `Bearer ${handleToken(
                `${getState().auth.data.token}`
              )}`,
            },
          }
        )
        .then((response: any) => {
          dispatch({
            type: UserTypes.SearchPageSuccess,
            payload: {
              list: response.data,
              headers: response?.headers,
            },
          } as Actions["SearchPageSuccess"]);

          resolve(response.data);
        })
        .catch((err: any) => {
          const errorMsg =
            err.response && err.response.data ? err.response.data : err;
          dispatch({
            type: UserTypes.SearchPageFailure,
            payload: errorMsg,
          } as Actions["SearchPageFailure"]);

          checkTokeExpred(dispatch, errorMsg);
          reject(errorMsg);
        });
    });
  };
}
