import styled from 'styled-components';

interface Props {
  noHover?: boolean;
  isOpen: boolean;
}
interface IPropsContent{
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  ${({noHover}: Props) => noHover ? '' : `
    &:hover {
      cursor: pointer;
    }
  `}
  flex-direction: column;
  background: #cbcbcb;
  flex: 1;
  border-radius: 30px;
  margin-bottom: ${(props)=> props.isOpen?'40px':'14px'};
`;

export const Header = styled.div`
  font-weight: bold;
  color: #fff;
  background: #0068d9;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;