import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/ducks/state';
import * as icon from '@fortawesome/free-solid-svg-icons';
import {
    Container,
    Icon,
    Text,
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserLoggedFromLocalStorage, firstName, isLogged } from '../../../../util';

interface IPropsRow {
}


const Row: React.FC<IPropsRow> = () => {
    const userLogged = useSelector((state: RootState) => state.auth.user)
    console.log('userLogged:', userLogged)

    return (
        <Container>
            <Icon>
                <FontAwesomeIcon style={{ width: '60px', height: '60px' }} color="white" icon={icon.faUserCircle} />
            </Icon>
            <Text> Olá, {userLogged && userLogged.nome && firstName(userLogged?.nome)} </Text>

        </Container>
    );
}

export default Row;