import styled from "styled-components";
interface IPropsMode {
  mode?: string;
}

export const Container = styled.div<IPropsMode>`
  ${(props) =>
    props.mode === "normal" &&
    `
        display:flex;
        width:auto;
        min-width: 150px;
        height:80px;
        margin: 15px 0 0 20px;
        flex-direction: column;
    `}
  ${(props) =>
    props.mode === "flat" &&
    `
        display:flex;
        width:auto;
        min-width: 150px;
        flex-direction: column;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    `}
    #demo-simple-select-label {
    top: -10px;
  }
`;

export const Label = styled.div<IPropsMode>`
  ${(props) =>
    props.mode === "normal" &&
    `
        text-align: left;
        font-weight:bold;
        color:#555555;
        font-size:16px;
        margin-bottom:14px;
    `}
  ${(props) =>
    ["flat", "flat-round"].includes(props.mode) &&
    `
        display:none;
    `}
`;
