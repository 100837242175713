import React from "react";
import { GridLayout } from "./styles";
import TopBar from "../ApplicationMainScreen/ApplicationTopBar";
import LeftNav from "../ApplicationMainScreen/ApplicationLeftNav";
import Content from "../ApplicationMainScreen/ApplicationContent";
import { AuthState } from "store/ducks/auth";
import { RootState } from "store/ducks/state";
import { connect } from "react-redux";
import { isLogged } from "util/index";

interface StateProps {}
interface DispatchProps {}

interface OtherProps {
  children: React.ReactElement;
}

type Props = StateProps & DispatchProps & OtherProps;

export const Layout: React.FC<Props> = (props) => {
  const { children } = props;
  const [isNavBarOpen, setIsNavBarOpen] = React.useState<boolean>(true);

  return (
    <GridLayout isNavBarOpen={isNavBarOpen} fullContent={!isLogged()}>
      {isLogged() && (
        <TopBar isNavBarOpen={isNavBarOpen} setIsNavBarOpen={setIsNavBarOpen} />
      )}
      {isLogged() && (
        <LeftNav
          isNavBarOpen={isNavBarOpen}
          setIsNavBarOpen={setIsNavBarOpen}
        />
      )}
      <Content fullContent={!isLogged()}>{children}</Content>
    </GridLayout>
  );
};

export default connect(null, (dispatch: any) => ({}))(Layout);
