import styled from 'styled-components'

interface IProps{
    mode?: string;
}

export const Container = styled.div<IProps>`
    /* border:1px solid red; */
    ${props => props.mode === 'normal' && `
        display:block;
        width:auto;
        min-width: 150px;
        height:80px;
        margin: 15px 0 0 20px;
    ` }
    ${props => props.mode === 'flat' && `
        display:block;
        width:auto;
        min-width: 150px;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    ` }
    
`;

export const Label = styled.div`
    text-align: left;
    font-weight:bold;
    color:#555555;
    font-size:16px;
    margin-bottom:14px;
`;