import styled from 'styled-components'
import logoGray from '../../../assets/logoGray.png'
interface IProps{
}

export const Container = styled.div`
    display:flex;
    flex:1;
    height:100%;
    flex-direction:column;
`;
export const Content = styled.div`
    /* padding: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:100%;
    @media only screen and (min-width: 320px) and (max-width: 480px) {
        padding: 15px;
        /* min-height:calc( 100vh - 110px ); */
    }
`;
export const Text = styled.div`
    font-size: 22px;
    color:#ccc;
    @media only screen and (min-width: 320px) and (max-width: 480px) {
    }
`;
