import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {IS_ELECTORAL_PERIOD} from '../../../constants/system'

import {
  Container,
  Content,
} from './styles'
import ContentHeader from 'components/ApplicationMainScreen/ApplicationContentHeader'
import * as icon from '@fortawesome/free-solid-svg-icons';


export default function Home(props: RouteComponentProps) {


  return (
    <Container>
      <ContentHeader icon={icon.faHome} text="Seja Bem-vindo!" styleIcon={{color:'#BCBCBC'}} ></ContentHeader>
      <Content isElectoralPeriod={IS_ELECTORAL_PERIOD} >
      </Content>
    </Container>
  );
}
