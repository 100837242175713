import React from "react";

import { Container, ContainerList, ListContent, NothingFound } from "./styles";
import Pagination from "./Pagination";
import Row from "./Row";
import ListHeader from "./Header";
import ContentLoaderCustomized from "components/Loaders/ContentLoaderCustomized";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ISearchHeaders } from "store/ducks/searchPage";

interface IDataRow {
  [x: string]: {
    styles: any;
    [x: string]:
      | string
      | ReactJSXElement
      | {
          title: string;
          subTitle: string;
        };
  };
}
interface IColumnNames {
  styles?: any;
  text: string;
}
interface IProps {
  List: IDataRow[];
  ColumnsName: IColumnNames[];
  isLoading?: boolean;
  page?: number;
  setPage?: any;
  rowsPerPage?: number;
  setRowsPerPage?: any;
  infor: ISearchHeaders;
  forceArrayToColumns?: boolean;
  styleListHeader?:React.CSSProperties;
  styleListContent?:React.CSSProperties;

}

const List: React.FC<IProps> = ({
  List,
  ColumnsName,
  isLoading = false,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  forceArrayToColumns,
  infor = null,
  styleListHeader,
  styleListContent
}) => {
  // console.log("🚀 ~ file: index.tsx ~ line 38 ~ styleListHeader", styleListHeader)
  return (
    <ContentLoaderCustomized
      loading={isLoading}
      width={"100%"}
      rows={rowsPerPage * (window.screen.width < 768 ? 4 : 1)}
    >
      <Container>
        <ContainerList>
          <ListHeader styleListHeader={styleListHeader} columns={handleColumns(ColumnsName, List)} />
          <ListContent style={styleListContent}>
            {renderList(List, ColumnsName, rowsPerPage)}
          </ListContent>
        </ContainerList>
        <Pagination
          countData={infor && infor.total ? Number(infor.total) : List.length}
          page={page}
          lastpage={infor && infor.lastpage ? Number(infor.lastpage) : null}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Container>
    </ContentLoaderCustomized>
  );
};

export default List;

const renderList = (List, ColumnsName, rowsPerPage) => {
  return List && List.length ? (
    List.slice(0, rowsPerPage).map((data, index) => (
      <Row
        pair={index % 2 === 0}
        key={`list-row-${index}`}
        data={data}
        columns={ColumnsName}
      />
    ))
  ) : (
    <NothingFound>Nenhum registro encontrado...</NothingFound>
  );
};

const handleColumns = (ColumnsName, List) => {
  return ColumnsName.map((c, index) => {
    List &&
      List[0] &&
      Object.keys(List[0]).map((key, index2) => {
        List[0][key] === null && index2 === index && (c = null);
      });
    if (c !== null) return c;
  });
};
