import styled from 'styled-components'
interface IPropsButton {
    width:string;
}

export const Button = styled.button<IPropsButton>`
    width:${(props)=> props.width? props.width:'150px' };
    height:42px;
    display:flex;
    flex-direction:row;
    background-color: rgb(0,119,225);
    justify-content: center;
    align-items: center;
    padding:5px 10px;
    border-radius:8px;
    cursor:pointer;
    &:hover{
        background-color: rgba(0,119,225, 0.75);
    }
    &:active{
        background-color: rgba(0,119,225, 0.75);
    }
`;
export const Text = styled.div`
    margin:0 7px;
    font-size:0.5em;
    color:white;
`;