import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentHeader from "components/ApplicationMainScreen/ApplicationContentHeader";
import { FormControlLabel, Checkbox } from "@mui/material";
import {
  faUsers,
  faPrint,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import Option from "components/List/Option";
import ContentFilters from "components/ApplicationMainScreen/ApplicationContentFilters";
import EditVolunteers from "components/Forms/EditVolunteers";
import List from "components/List";
import IconButton from "components/Buttons/iconButton";
import {
  alertError,
  showAlert,
  closeAlert,
  alertLoading,
  alertSuccess,
} from "store/ducks/alert";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Modal from "components/Modal";
import SimpleForm from "components/Forms/SimpleForm";
import RegistrationOrganizerForm from "components/Forms/RegistrationOrganizerForm";
import InputFileForm from "components/Forms/InputFileForm";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSearchVolunteersSearch,
  deleteVolunter,
  verifiedVolunteer,
  generateShippingVolunters,
} from "store/ducks/volunteers";
import { SYSTEM_GLOBAL_PARAMETERS } from "constants/system";
import { RootState } from "store/ducks/state";
import { IFilter } from "components/ApplicationMainScreen/ApplicationContentFilters";
import { Container, Content, Gap, IconsBox, BoxButtonViewMode } from "./styles";
import {
  isMobile,
  isFullHD,
  handleFiltersToOnsearch,
  onChangeFilter,
  handleSchoolName,
  validValueAndComplement,
  open_url_navigator_web,
  addZeroLeft,
  export_file_api_url,
  export_file_shipping_api_url,
} from "../../util";
import {
  Check,
  Edit,
  Visibility,
  Print,
  Close,
  Settings,
  GridOn,
  GridView,
} from "@mui/icons-material";
export default function Volunteers(props: RouteComponentProps) {
  const dispatch = useDispatch();

  const volunteers = useSelector((state: RootState) => state.volunteers);
  const [tableList, setTableList] = React.useState<any[]>([]);
  const [viewMode, setViewMode] = React.useState<number>(1);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalVisible2, setModalVisible2] = React.useState<boolean>(false);
  const [modalVisible3, setModalVisible3] = React.useState<boolean>(false);
  const [modalEdit, setModalEdit] = React.useState<boolean>(false);
  const [idModal, setIdModal] = React.useState(null);
  const [dataModal, setDataModal] = React.useState(null);
  const [filters, setFilters] = React.useState<IFilter[]>(pageFilters());
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    SYSTEM_GLOBAL_PARAMETERS.DEFAULT_ROWS_PER_PAGE
  );
  console.log(" volunteers.search.list:", volunteers.search.list);
  console.log(" tableList:", tableList);

  useEffect(() => {
    setTableList(volunteers.search.list);
  }, [volunteers.search.list]);

  useEffect(() => {
    (async () => {
      onSearch();
    })();
  }, [, page, rowsPerPage, viewMode]);

  const handleModal = (id = null, index = 1, data: any = null) => {
    // index === 2 ? setModalVisible2(true) : setModalVisible(true);
    index === 3 && setModalEdit(true);
    setDataModal(data);
    setIdModal(id);
  };
  const thisFiltersCurrent = `?page=${page}&per_page=${rowsPerPage}&view_mode=${viewMode}${handleFiltersToOnsearch(
    filters
  )}`;
    console.log("🚀 ~ file: index.tsx ~ line 103 ~ Volunteers ~ filters", filters)

  const onSearch = () =>
    dispatch(handleSearchVolunteersSearch(thisFiltersCurrent));

  const columnMinimal = [
    {
      styles: { width: "auto", minWidth: 300, maxWidth: 300 },
      text: "Nome / CPF",
    },
    {
      styles: { minWidth: 200, width: "auto" },
      text: "NTE / Escola",
    },
    {
      styles: { maxWidth: 200, minWidth: 200, paddingLeft: 20 },
      text: "Municipio",
    },
    {
      styles: {
        maxWidth: 150,
        minWidth: 150,
        justifyContent: "center",
        paddingLeft: 20,
      },
      text: "Agência",
    },
    {
      styles: {
        maxWidth: 100,
        minWidth: 100,
        paddingLeft: 20,
        justifyContent: "center",
      },
      text: "Verificado",
    },
    {
      styles: {
        maxWidth: 150,
        minWidth: 150,
        height: "auto",
        justifyContent: "center",
        paddingLeft: 20,
      },
      text: "Situação",
    },
    {
      styles: { maxWidth: 150, minWidth: 150, justifyContent: "flex-end" },
      text: "Ações",
    },
  ];

  const columnFull = [
    {
      styles: { maxWidth: 300, minWidth: 300 },
      text: "Nome / CPF",
    },
    {
      styles: { maxWidth: 250, minWidth: 250 },
      text: "RG / Órgão Exp / UF ",
    },
    {
      styles: { maxWidth: 200, minWidth: 200 },
      text: "Data de Nasc / Sexo",
    },
    {
      styles: { maxWidth: 300, minWidth: 300 },
      text: "Endereço",
    },
    {
      styles: { maxWidth: 300, minWidth: 300 },
      text: "Contatos",
    },
    {
      styles: { minWidth: 200 },
      text: "Nome da Mãe",
    },
    {
      styles: { minWidth: 200, paddingLeft: 10 },
      text: "NTE / Escola",
    },
    {
      styles: {
        maxWidth: 150,
        minWidth: 150,
        justifyContent: "center",
        // paddingLeft: 20,
      },
      text: "Agência",
    },
    {
      styles: {
        maxWidth: 100,
        minWidth: 100,
        paddingLeft: 20,
        justifyContent: "center",
      },
      text: "Verificado",
    },
    {
      styles: {
        maxWidth: 150,
        minWidth: 150,
        justifyContent: "center",
        paddingLeft: 20,
      },
      text: "Situação",
    },
    {
      styles: { maxWidth: 150, minWidth: 150, justifyContent: "flex-end" },
      text: "Ações",
    },
  ];

  const ColumnNames = viewMode === 1 ? columnMinimal : columnFull;

  const handleGenerateShipping = () => {
    dispatch(alertLoading());
    dispatch(generateShippingVolunters())
      .then((data) => {
        dispatch(closeAlert());
        // data.id_arquivo
        open_url_navigator_web(export_file_shipping_api_url(data.id_arquivo));
        // console.log(data);
      })
      .catch((err) => {
        dispatch(closeAlert());
        dispatch(alertError(err.msg));
        console.log(err);
      });
  };

  const pageButtons = [
    {
      label: "Importar",
      icon: faFileExport,
      onClick: () => {
        setModalVisible3(true);
      },
    },
    {
      label: "Exportar",
      icon: faFileExport,
      style: { backgroundColor: "#17A2B8", width: 200 },
      onClick: () => {
        open_url_navigator_web(export_file_api_url(2));
      },
    },
    {
      label: "Gerar Remessa",
      icon: faPrint,
      style: { backgroundColor: "#28A745" },
      onClick: () => {
        handleGenerateShipping();
      },
    },
  ];

  const handleExclusionVolunteer = (id) => {
    dispatch(
      showAlert({
        title: "Alerta!",
        description: `Deseja realmente excluir esse usuário?`,
        backButton: {
          label: "Voltar",
        },
        confirmationButton: {
          onClick: (value) => {
            dispatch(closeAlert());
            dispatch(deleteVolunter(id, thisFiltersCurrent))
              .then((res) => {
                onSearch();
              })
              .catch((err) => {
                dispatch(alertError(err?.msg));
              });
          },
        },
      })
    );
  };

  const Buttons = (id, handleModal, dataModal) => {
    return (
      <Gap>
        <Option id={id}>
          <MenuItem
            onClick={() => {
              handleModal(id, 3, dataModal);
            }}
          >
            <ListItemIcon>
              <Edit sx={{ color: "#707070" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText disableTypography sx={{ color: "#707070" }}>
              Editar
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleExclusionVolunteer(id);
            }}
          >
            <ListItemIcon>
              <Close sx={{ color: "#ff0000" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText disableTypography sx={{ color: "#707070" }}>
              Excluir
            </ListItemText>
          </MenuItem>
        </Option>
      </Gap>
    );
  };

  const ButtonVerify: React.FC<any> = ({ id, isVerified, autoFocus }) => {
    const [checked, setChecked] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const handleVerifiedVolunteer = (id) => {
      // dispatch(alertLoading());
      setLoading(true);
      dispatch(verifiedVolunteer(id))
        .then((data) => {
          console.log("🚀 ~ file: index.tsx ~ line 212 ~ .then ~ data", data);
          setTableList(
            tableList.map((row) => {
              if (row.id_voluntario === id)
                row = {
                  ...row,
                  label_situacao: "Verificado",
                  cor_situacao: "#0b5394",
                  verificado: 1,
                  autoFocus: true,
                };
              return { ...row };
            })
          );
          setLoading(false);
          // onSearch()
        })
        .catch((err) => {
          dispatch(closeAlert());
          dispatch(alertError(err.msg));
          setChecked(false);
          console.log(err);
          setLoading(false);
        });
    };

    React.useEffect(() => {
      if (!isVerified && checked) {
        handleVerifiedVolunteer(id);
      }
    }, [checked]);

    // console.log(
    //   "🚀 ~ file: index.tsx ~ line 324 ~ Volunteers ~ checked",
    //   checked
    // );
    return (
      <Gap>
        {loading ? (
          <ReactLoading type="spin" width={20} height={20} color="#c0c0c0" />
        ) : (
          <Checkbox
            autoFocus={autoFocus}
            defaultChecked={isVerified}
            disabled={checked || isVerified}
            checked={checked}
            onChange={(e) => {
              setChecked(true);
            }}
          />
        )}
      </Gap>
    );
  };

  return (
    <Container>
      <ContentHeader
        icon={faUsers}
        text={"Voluntarios"}
        buttons={pageButtons}
      />
      <Content>
        <ContentFilters
          filters={filters}
          setFilters={setFilters}
          onSearch={onSearch}
          clearFilters={()=> setFilters(pageFilters())}
          onChangeFilter={(ref, value) =>
            onChangeFilter(ref, value, filters, setFilters)
          }
        />
        <ButtonViewMode viewMode={viewMode} setViewMode={setViewMode} />
        <List
          forceArrayToColumns={true}
          ColumnsName={ColumnNames}
          isLoading={volunteers.loading["loading.search"]}
          infor={volunteers.search.headers}
          List={handleList(
            tableList,
            Buttons,
            handleModal,
            viewMode,
            ButtonVerify
          )}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          styleListHeader={
            viewMode == 2 ? { minWidth: 2550 } : { minWidth: 1450 }
          }
          styleListContent={
            viewMode == 2 ? { minWidth: 2550 } : { minWidth: 1450 }
          }
        />
      </Content>
      <Modal visible={modalVisible2} setVisible={setModalVisible2} noPadding>
        <SimpleForm id={idModal} />
      </Modal>
      <Modal visible={modalVisible} setVisible={setModalVisible} noPadding>
        <RegistrationOrganizerForm id={idModal} />
      </Modal>
      <Modal visible={modalVisible3} setVisible={setModalVisible3} noPadding>
        <InputFileForm />
      </Modal>
      <Modal visible={modalEdit} setVisible={setModalEdit} noPadding>
        <EditVolunteers onSearch={onSearch} data={dataModal} id={idModal} />
      </Modal>
    </Container>
  );
}

function ButtonViewMode({ viewMode, setViewMode }) {
  return (
    <IconsBox>
      <BoxButtonViewMode onClick={() => setViewMode(viewMode === 1 ? 2 : 1)}>
        {viewMode === 1 ? (
          <GridOn color="inherit" />
        ) : (
          <GridView color="inherit" />
        )}
      </BoxButtonViewMode>
    </IconsBox>
  );
}

const handleList = (list, Buttons, handleModal, viewMode, ButtonVerify) => {
  return (
    list &&
    list.length > 0 &&
    list.map((item, index) => {
      const objectFull = {
        nome_cpf: {
          styles: { maxWidth: 300, minWidth: 300 },
          text: {
            title: `${item.nome}`,
            subTitle: `${item.nu_cpf}`,
          },
        },
        rg_uf: {
          styles: { maxWidth: 250, minWidth: 250 },
          text: {
            title: `${item.nu_rg}`,
            subTitle: `${item.orgao_expedidor_rg}-${item.uf_expedidor_rg}`,
          },
        },
        dtnasc_sexo: {
          styles: { maxWidth: 200, minWidth: 200 },
          text: {
            title: `${item.data_nascimento}`,
            subTitle: `${item.sexo}`,
          },
        },
        endereco: {
          styles: { maxWidth: 300, minWidth: 300 },
          text: {
            title: `${item.logradouro} ${item.numero}, ${item.bairro}`,
            subTitle: `${item.municipio}/${item.uf}, CEP:${item.cep}`,
          },
        },
        contato: {
          styles: { maxWidth: 300, minWidth: 300 },
          text: {
            title: `${item.endereco_email}`,
            subTitle: `${validValueAndComplement(
              item.nu_telefone
            )}${validValueAndComplement(item.nu_telefone_secundario, " | ")}`,
          },
        },
        nomeMae: {
          styles: { minWidth: 200, width: "auto" },
          text: `${item.nome_mae}`,
        },
        nte_escola: {
          styles: { minWidth: 200, width: "auto", paddingLeft: 18 },
          text: {
            title: `${item.nome_nte}`,
            subTitle: `${item?.cod_sec} - ${handleSchoolName(item.nome_escola)}`,
          },
        },
        agencia: {
          styles: { maxWidth: 150, minWidth: 150, justifyContent: "center" },
          text: `${addZeroLeft(item?.numero_agencia, 4)}`,
        },
        buttons2: {
          styles: { maxWidth: 150, minWidth: 150, justifyContent: "center" },
          buttons: (
            <ButtonVerify
              id={item.id_voluntario}
              isVerified={parseInt(item.verificado)}
            />
          ),
        },
        status: {
          styles: {
            maxWidth: 150,
            minWidth: 150,
            height: "auto",
            justifyContent: "center",
          },
          text: `${item.label_situacao}`,
          statusStyle: {
            color: "#fff",
            backgroundColor: item.cor_situacao,
            padding: 5,
            width: 150,
            justifyContent: "center",
          },
          iconVisible: false,
        },
        buttons: {
          styles: { maxWidth: 150, minWidth: 150, paddingRight: 25 },
          buttons: Buttons(item.id_voluntario, handleModal, item),
        },
      };
      const objectMinimal = {
        nome: {
          styles: { minWidth: 300, maxWidth: 300 },
          text: {
            title: `${item.nome}`,
            subTitle: `${item.nu_cpf}`,
          },
        },
        nte_escola: {
          styles: { minWidth: 200, width: "auto" },
          text: {
            title: `${item.nome_nte}`,
            subTitle: `${item?.cod_sec} - ${handleSchoolName(item.nome_escola)}`,
          },
        },
        municipio: {
          styles: { maxWidth: 200, minWidth: 200, paddingLeft: 32 },
          text: {
            title: `${item.municipio}`,
          },
        },
        agencia: {
          styles: {
            maxWidth: 150,
            minWidth: 150,
            justifyContent: "center",
            paddingLeft: 35,
          },
          text: `${addZeroLeft(item?.numero_agencia, 4)}`,
        },
        buttons2: {
          styles: { maxWidth: 150, minWidth: 150, justifyContent: "center" },
          buttons: (
            <ButtonVerify
              id={item.id_voluntario}
              isVerified={parseInt(item.verificado)}
              autoFocus={item?.autoFocus}
            />
          ),
        },
        status: {
          styles: {
            maxWidth: 150,
            minWidth: 150,
            height: "auto",
            justifyContent: "center",
          },
          text: `${item.label_situacao}`,
          statusStyle: {
            color: "#fff",
            backgroundColor: item.cor_situacao,
            padding: 5,
            justifyContent: "center",
          },
          iconVisible: false,
        },
        buttons: {
          styles: { maxWidth: 150, minWidth: 150, paddingRight: 25 },
          buttons: Buttons(item.id_voluntario, handleModal, item),
        },
      };
      return viewMode === 1 ? objectMinimal : objectFull;
    })
  );
};

const pageFilters = ()=>
  [
  {
    ref: "id_situacao",
    value: null,
    type: "select",
    label: "Situação",
    style: isFullHD()
      ? { minWidth: 200, maxWidth: 200 }
      : { minWidth: 150, maxWidth: 150 },
    options: [],
  },
  {
    ref: "id_nte",
    value: null,
    type: "autocomplete",
    key:new Date().getMilliseconds(),
    label: "NTE",
    style: isFullHD()
      ? { minWidth: 250, maxWidth: 250 }
      : { minWidth: 150, maxWidth: 150 },
    options: [],
  },
  {
    ref: "cod_sec",
    value: "",
    type: "input",
    label: "Cod SEC",
    style: isFullHD()
      ? { minWidth: 250, maxWidth: 250 }
      : { minWidth: 200, maxWidth: 200 },
  },
  {
    ref: "id_escola",
    value: null,
    type: "autocomplete",
    key:new Date().getMilliseconds(),
    label: "Escola",
    style: isFullHD()
      ? { minWidth: 300, maxWidth: 300 }
      : { minWidth: 250, maxWidth: 250 },
  },
  {
    ref: "id_municipio",
    value: null,
    type: "autocomplete",
    key:new Date().getMilliseconds(),
    label: "Município",
    style: isFullHD()
      ? { minWidth: 300, maxWidth: 300 }
      : { minWidth: 250, maxWidth: 250 },
  },
  {
    ref: "numero_agencia",
    value: "",
    type: "input",
    label: "Agência",
    style: isFullHD()
      ? { minWidth: 250, maxWidth: 250 }
      : { minWidth: 200, maxWidth: 200 },
  },
  {
    ref: "nu_cpf",
    value: "",
    type: "input",
    label: "CPF",
    style: isFullHD()
      ? { minWidth: 150, maxWidth: 150 }
      : { minWidth: 120, maxWidth: 120 },
  },
  {
    ref: "nome",
    value: "",
    type: "input",
    label: "Nome",
    style: isFullHD()
      ? { minWidth: 250, maxWidth: 250 }
      : { minWidth: 200, maxWidth: 200 },
  },
] as IFilter[]; 
