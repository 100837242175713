import styled from 'styled-components'
import logo from '../../assets/logo.png'

interface IProps{
    width: string;
    height: string;
    background:boolean;
    isAdminLogged:boolean;
    marginLeft:number;
}
interface IImage{
    bgImg:string;
    bgHeight:string;
    bgWidth:string;
}
export const Container = styled.a<IProps>`
    width: ${(props)=>props.width};
    height:${(props)=>props.height};
    margin-left:${(props)=>props.marginLeft || 0}px;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    background-color: ${(props)=>props.background? (props.isAdminLogged?'#0078e1':'#169A00') : 'transparent'};
    
    &:hover{
        background-color: ${(props)=>props.background? (props.isAdminLogged?'#0078e175':'#169A0075') :'transparent' };
    }
`;

export const Image = styled.div<IImage>`
    width:${(props)=>props.bgWidth?props.bgWidth:'44px'};
    height:${(props)=>props.bgHeight?props.bgHeight:'50px'};
    display:block;
    background: url(${(props)=>props.bgImg});  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;
export default Container;